import React from "react";
import { PracticeReview, TimeEntry } from "practice-reviews";
import { Grid } from "@mui/material";
import { dataGridDateValueFormatter } from "../util/formats";
import CrudTable from "../common/CrudTable";
import EditTimeEntryDialog from "./EditTimeEntryDialog";
import DeleteTimeEntryDialog from "./DeleteTimeEntryDialog";
import { makeStyles } from "makeStyles";
import { datagridStyles } from "styles/common";
import { GridColDef } from "@mui/x-data-grid-pro";
import _ from "lodash";
import { Permissions, useCurrentUser } from "users";

interface Props {
  practiceReview: PracticeReview;
}

const useStyles = makeStyles()((theme) => ({
  ...datagridStyles(theme)
}));

export const TimeEntriesTable: React.FunctionComponent<Props> = (props) => {
  const { classes, cx } = useStyles();
  const practiceReview = props.practiceReview;
  const columns: GridColDef[] = [
    {
      field: "user.name",
      headerName: "Practice Reviewer",
      valueGetter: (params) => (params.row as TimeEntry).user?.name,
      flex: 4
    },
    {
      field: "date",
      headerName: "Date",
      type: "date",
      valueGetter: (params) => (params.row as TimeEntry).enteredDate,
      valueFormatter: dataGridDateValueFormatter
    },
    {
      field: "enteredHours",
      headerName: "Hours",
      align: "right",
      headerAlign: "right"
    },
    {
      field: "isAdmin",
      headerName: "Admin",
      type: "boolean",
      cellClassName: (params) =>
        cx({
          [classes.hideBooleanXsInCell]: !(params.row as TimeEntry).isAdmin
        })
    }
  ];

  const { userHasPermission } = useCurrentUser();
  const canEdit = (practiceReview && !practiceReview.hasBeenReturned) || userHasPermission(Permissions.ReturnedPrUpdate);

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} xl={12}>
          <CrudTable
            title="Time Entries"
            rows={_.orderBy(practiceReview.timeEntries, (entry) => entry.enteredDate)}
            columnDefinitions={columns}
            collapsible={false}
            displayWithoutContainer={true}
            noDataMessage="No entries."
            renderAddDialog={(dialogProps) =>
              canEdit && (
                <EditTimeEntryDialog
                  title="Add Entry"
                  confirmButtonText="Add"
                  allEntries={practiceReview.timeEntries}
                  handleClose={dialogProps.onClose}
                  practiceReview={practiceReview}
                />
              )
            }
            renderEditDialog={(id, dialogProps) =>
              canEdit && (
                <EditTimeEntryDialog
                  title={practiceReview.timeEntries.find((t) => t.id === id)?.isAdmin ? "Edit Admin Entry" : "Edit Entry"}
                  confirmButtonText="Save"
                  allEntries={practiceReview.timeEntries}
                  handleClose={dialogProps.onClose}
                  practiceReview={practiceReview}
                  entryToEdit={practiceReview.timeEntries.find((t) => t.id === id)}
                />
              )
            }
            renderDeleteDialog={(id, dialogProps) =>
              canEdit &&
              !(practiceReview.timeEntries.find((t) => t.id === id) as TimeEntry)?.isAdmin && (
                <DeleteTimeEntryDialog
                  handleClose={dialogProps.onClose}
                  entry={practiceReview.timeEntries.find((t) => t.id === id) as TimeEntry}
                  practiceReviewId={practiceReview.id}
                />
              )
            }
          />
        </Grid>
      </Grid>
    </>
  );
};
