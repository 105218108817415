import React, { useMemo, useState } from "react";
import { LoadingScreen } from "chrome/LoadingScreen";
import Axios, { AxiosInstance } from "axios";
import { useAuthentication } from "./AuthenticationProvider";
import { useAppConfig } from "../util/AppConfig";
import { BrowserConsoleDebugLogger } from "../util/BrowserConsoleDebugLogger";

interface AxiosHandlers {
  secureAxios: AxiosInstance;
  insecureAxios: AxiosInstance;
  fileDownloadAxios: AxiosInstance;
}

const AxiosContext = React.createContext<AxiosHandlers>({
  secureAxios: Axios.create(),
  insecureAxios: Axios.create(),
  fileDownloadAxios: Axios.create()
});

export const useAxios = () => React.useContext(AxiosContext);

export const AxiosProvider: React.FunctionComponent = (props) => {
  const [token, setToken] = useState<string | null>(null);

  const authentication = useAuthentication();
  let { showBrowserConsoleDebugLogs } = useAppConfig();
  const browserConsoleDebugLogger = new BrowserConsoleDebugLogger(showBrowserConsoleDebugLogs ?? false);

  authentication.getAccessToken().then((t) => setToken(t));
  const secureAxios = Axios.create({ headers: { Authorization: `Bearer ${token}` } });
  secureAxios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      browserConsoleDebugLogger.log("SecureAxios: Attempting to refresh MSAL token...");
      await authentication.getAccessToken().then((t) => {
        setToken(t);
        secureAxios.defaults.headers = { Authorization: `Bearer ${t}` };
        return error;
      });
    }
  );
  const insecureAxios = Axios.create({});
  const fileDownloadAxios = Axios.create({ headers: { Authorization: `Bearer ${token}` }, responseType: "blob" });
  fileDownloadAxios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      browserConsoleDebugLogger.log("FileDownloadAxios: Attempting to refresh MSAL token...");
      await authentication.getAccessToken().then((t) => {
        setToken(t);
        fileDownloadAxios.defaults.headers = { Authorization: `Bearer ${t}`, responseType: "blob" };
        return error;
      });
    }
  );

  const contextValue = useMemo(
    () => ({
      secureAxios,
      insecureAxios,
      fileDownloadAxios
    }),
    [secureAxios, insecureAxios, fileDownloadAxios]
  );

  const authenticated = authentication.authenticated;

  return authenticated ? (
    <AxiosContext.Provider value={contextValue}>{props.children}</AxiosContext.Provider>
  ) : (
    <LoadingScreen mode="auth" />
  );
};
