import { useQuery } from "@apollo/client";
import { ScreenHeader } from "common/ScreenHeader";
import _ from "lodash";
import React from "react";
import CrudTable from "../common/CrudTable";
import { Helmet } from "react-helmet";
import { User, AllUsersQuery } from ".";
import EditUserDialog from "./EditUserDialog";

const ManageUsersScreen: React.FunctionComponent = () => {
  const usersQuery = useQuery<{ users: User[] }>(AllUsersQuery);

  const users = usersQuery.data?.users
    ? _.orderBy(
        usersQuery.data.users.map((user) => {
          return {
            ...user,
            firstName: user.firstName ?? user.name,
            email: user.email ?? user.userPrincipalName
          };
        }),
        (u) => u.name
      )
    : [];

  return (
    <>
      <Helmet>
        <title>Manage Users - PRS Online</title>
      </Helmet>

      <ScreenHeader title="Users" />
      <CrudTable
        rows={users}
        loading={usersQuery.loading}
        columnDefinitions={[
          {
            field: "firstName",
            headerName: "First Name",
            flex: 1
          },
          {
            field: "lastName",
            headerName: "Last Name",
            flex: 1
          },
          {
            field: "email",
            headerName: "Email",
            flex: 1
          },
          {
            field: "designation",
            headerName: "Designation",
            flex: 1
          },
          {
            field: "entityNumber",
            headerName: "Entity Number",
            flex: 1
          }
        ]}
        storageKey="Users"
        noDataMessage="Unable to load users"
        renderEditDialog={(id, props) => {
          return (
            <>
              <EditUserDialog user={users.find((u) => u.id === id)!} handleClose={props.onClose} confirmButtonText="Save" />
            </>
          );
        }}
      />
    </>
  );
};

export default ManageUsersScreen;
