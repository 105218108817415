import { alpha, createTheme, darken, lighten, responsiveFontSizes } from "@mui/material";
import { Theme } from "@mui/material/styles";
import type {} from "@mui/lab/themeAugmentation";
import { grey } from "@mui/material/colors";
import type {} from "@mui/x-data-grid-pro/themeAugmentation";

declare module "@mui/styles/defaultTheme" {
  interface DefaultTheme extends Theme {}
}

declare module "@mui/material/styles/createPalette" {
  interface Palette {
    cpaGrey: Palette["primary"];
    cpaLightGrey: Palette["primary"];
    cpaAccentLavender: Palette["primary"];
    cpaAccentTurquoise: Palette["primary"];
    cpaAccentGreen: Palette["primary"];
    cpaAccentYellow: Palette["primary"];
    cpaAccentOrange: Palette["primary"];
    neutral: Palette["primary"];
    white: PaletteOptions["primary"];
    border: React.CSSProperties["color"];
    highlight: React.CSSProperties["color"];
  }
  interface PaletteOptions {
    cpaGrey: PaletteOptions["primary"];
    cpaLightGrey: PaletteOptions["primary"];
    cpaAccentLavender: PaletteOptions["primary"];
    cpaAccentTurquoise: PaletteOptions["primary"];
    cpaAccentGreen: PaletteOptions["primary"];
    cpaAccentYellow: PaletteOptions["primary"];
    cpaAccentOrange: PaletteOptions["primary"];
    neutral: PaletteOptions["primary"];
    white: PaletteOptions["primary"];
    border: React.CSSProperties["color"];
    highlight: React.CSSProperties["color"];
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    neutral: true;
    white: true;
  }
}

declare module "@mui/material/IconButton" {
  interface IconButtonPropsColorOverrides {
    neutral: true;
    white: true;
  }
}

export const optionalScreenWidthLimit = "80rem";

const defaultTheme = createTheme();

// Measured from small MUI text fields, don't think these are exposed on the theme
export const smallTextFieldHorizontalPadding = "14px";
export const smallTextFieldVerticalPadding = "10.5px";
export const smallTextFieldPadding = `${smallTextFieldVerticalPadding} ${smallTextFieldHorizontalPadding}`;

const headingFontFamily = "Inter, Helvetica, Arial, Sans-Serif";
const bodyFontFamily = "Assistant, Helvetica, Arial, Sans-Serif";

const makeTheme = (primaryMainColor: string, secondaryMainColor: string) =>
  responsiveFontSizes(
    createTheme({
      palette: {
        background: {
          default: "#eaeaea"
        },
        primary: {
          main: primaryMainColor
        },
        highlight: lighten(primaryMainColor, 0.88),
        secondary: {
          main: secondaryMainColor
        },
        success: {
          main: "#008941"
        },
        info: {
          main: "#68c8c6"
        },
        warning: {
          main: "#f8971d"
        },
        error: {
          main: "#e35131"
        },
        text: {
          primary: "#231f20",
          secondary: "#717073"
        },
        cpaGrey: {
          main: "#717073",
          dark: darken("#717073", 0.25),
          light: lighten("#717073", 0.25)
        },
        cpaLightGrey: {
          main: "#c9cacc"
        },
        cpaAccentLavender: {
          main: "#98a5d4",
          light: lighten("#98a5d4", 0.75)
        },
        cpaAccentTurquoise: {
          main: "#68c8c6",
          light: lighten("#68c8c6", 0.75)
        },
        cpaAccentGreen: {
          main: "#8dc63f"
        },
        cpaAccentYellow: {
          main: "#f1cb00"
        },
        cpaAccentOrange: {
          main: "#f8971d"
        },
        neutral: {
          main: grey[300],
          dark: grey[400]
        },
        white: {
          main: defaultTheme.palette.common.white
        },
        border: defaultTheme.palette.grey[400]
      },
      typography: {
        fontFamily: headingFontFamily,
        h1: {
          fontSize: "2rem"
        },
        h2: {
          fontSize: "1.6875rem"
        },
        h3: {
          fontSize: "1.375rem"
        },
        h4: {
          fontSize: "1rem"
        },
        h5: {
          fontFamily: bodyFontFamily,
          fontSize: "1rem",
          fontWeight: 500
        },
        h6: {
          fontFamily: bodyFontFamily,
          fontSize: "0.875rem",
          fontWeight: 500
        },
        body1: {
          fontFamily: bodyFontFamily,
          fontSize: "1rem",
          fontWeight: 500 // Assistant at 400 is pretty light
        },
        body2: {
          fontFamily: bodyFontFamily,
          fontSize: "0.875rem",
          fontWeight: 500 // Assistant at 400 is pretty light
        },
        subtitle1: {
          fontFamily: bodyFontFamily,
          fontSize: "1rem",
          fontWeight: 500 // Assistant at 400 is pretty light
        },
        subtitle2: {
          fontFamily: bodyFontFamily,
          fontSize: "0.875rem",
          fontWeight: 600
        },
        caption: {
          fontSize: "0.75rem",
          fontFamily: bodyFontFamily
        },
        fontWeightBold: 700
      },
      components: {
        MuiButton: {
          defaultProps: {
            disableElevation: true, // flat buttons
            color: "neutral"
          },
          styleOverrides: {
            outlined: {
              backgroundColor: defaultTheme.palette.common.white // Don't want them transparent
            }
          },
          variants: [
            {
              props: { variant: "contained", color: "neutral" },
              style: {
                color: defaultTheme.palette.getContrastText(defaultTheme.palette.grey[300])
              }
            },
            {
              props: { variant: "outlined", color: "neutral" },
              style: {
                color: defaultTheme.palette.text.primary,
                borderColor: defaultTheme.palette.mode === "light" ? "rgba(0, 0, 0, 0.23)" : "rgba(255, 255, 255, 0.23)",
                "&.Mui-disabled": {
                  border: `1px solid ${defaultTheme.palette.action.disabledBackground}`
                },
                "&:hover": {
                  borderColor: defaultTheme.palette.mode === "light" ? "rgba(0, 0, 0, 0.23)" : "rgba(255, 255, 255, 0.23)",
                  backgroundColor: alpha(defaultTheme.palette.text.primary, defaultTheme.palette.action.hoverOpacity)
                }
              }
            },
            {
              props: { color: "neutral", variant: "text" },
              style: {
                color: defaultTheme.palette.text.primary,
                "&:hover": {
                  backgroundColor: alpha(defaultTheme.palette.text.primary, defaultTheme.palette.action.hoverOpacity)
                }
              }
            }
          ]
        },
        MuiTextField: {
          defaultProps: {
            size: "small", // Use small fields by default
            margin: "normal"
          }
        },
        MuiFormControl: {
          defaultProps: {
            size: "small" // Use small fields by default
          }
        },
        MuiAutocomplete: {
          defaultProps: {
            size: "small" // Use small fields by default
          },
          styleOverrides: {
            root: {
              "& .MuiAutocomplete-inputRoot.MuiOutlinedInput-root.MuiInputBase-adornedEnd.MuiInputBase-sizeSmall": {
                paddingRight: "39px" // MUI appears to have a style precedence bug on size:small Autocompletes
              }
            }
          }
        },
        MuiPaper: {
          defaultProps: {
            elevation: 3 // raise these a bit more
          }
        },
        MuiListItem: {
          styleOverrides: {
            root: {
              paddingTop: 0,
              paddingBottom: 0
            }
          }
        },
        MuiListItemIcon: {
          styleOverrides: {
            root: {
              minWidth: "2rem", // MUI default is too wide
              fontSize: "125%" // FontAwesome icons are too small by default
            }
          }
        },
        MuiFormHelperText: {
          styleOverrides: {
            root: {
              fontFamily: bodyFontFamily
            }
          }
        },
        MuiSkeleton: {
          styleOverrides: {
            root: {
              cursor: "wait"
            }
          }
        },
        MuiChip: {
          styleOverrides: {
            root: {
              fontFamily: bodyFontFamily
            }
          }
        },
        MuiIconButton: {
          styleOverrides: {
            // Make all icon buttons square
            root: {
              "& svg": {
                minWidth: "1em",
                minHeight: "1em"
              }
            }
          }
        },
        MuiTableCell: {
          styleOverrides: {
            head: {
              lineHeight: 1.3
            }
          }
        },
        MuiLink: {
          defaultProps: {
            underline: "hover"
          }
        },
        MuiDataGrid: {
          styleOverrides: {
            root: {
              overflow: "hidden", // When you resize columns, it can push content outside the datagrid
              "& .MuiDataGrid-detailPanelToggleCell[disabled]": {
                display: "none" // Hide the detail panel toggle from rows that don't have details
              },
              "& .MuiDataGrid-cell[data-field='__detail_panel_toggle__']:focus, & .MuiDataGrid-cell[data-field='__detail_panel_toggle__']:focus-within":
                {
                  outline: "none" // Don't show cell focus outline on row toggle cells
                }
            },
            columnHeaderTitleContainer: {
              padding: 0 // Remove extra horizontal padding on these that causes the headers not to align with data
            },
            columnHeaderTitle: {
              fontWeight: 600
            }
          }
        },
        MuiTooltip: {
          styleOverrides: {
            tooltip: {
              fontFamily: bodyFontFamily
            }
          },
          defaultProps: {
            PopperProps: {
              popperOptions: {
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, -8] // Tooltips are too far from their anchor by default
                    }
                  }
                ]
              }
            }
          }
        },
        MuiDialogActions: {
          styleOverrides: {
            root: {
              "> *:not(:first-child)": {
                marginLeft: defaultTheme.spacing(1)
              }
            }
          }
        }
      }
    })
  );

export const theme = makeTheme("#006fba", "#008941");
export const localTheme = makeTheme("#B79200", "#876C00");
export const devTheme = makeTheme("#404040", "#808080");
export const uatTheme = makeTheme("#008941", "#006fba");
