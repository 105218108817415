import React from "react";
import { Button, Dialog, DialogActions, DialogContent, Grid, Typography } from "@mui/material";
import ClosableDialogTitle from "common/ClosableDialogTitle";
import { gql, useMutation } from "@apollo/client";
import { TimeConversionFormula } from "./models";
import * as Yup from "yup";
import { Field as FormikField, Form as FormikForm, Formik } from "formik";
import { TextField as FmuiTextField } from "formik-mui";
import { Validations } from "common/validations/common-yup-validations";
import { TimeConversionFormulasQuery } from "./TimeConversionFormulasScreen";
import { LoadingButton } from "@mui/lab";

const AddTimeConversionFormulaMutation = gql`
  mutation AddTimeConversionFormula($formula: TimeConversionFormulaInput) {
    timeConversionFormulas {
      add(formula: $formula) {
        id
        minimumHoursForRow
        adminHoursToAdd
      }
    }
  }
`;

const EditTimeConversionFormulaMutation = gql`
  mutation EditTimeConversionFormula($formula: TimeConversionFormulaInput) {
    timeConversionFormulas {
      update(formula: $formula) {
        id
        minimumHoursForRow
        adminHoursToAdd
      }
    }
  }
`;

interface Props {
  handleClose: () => void;
  title: string;
  confirmButtonText: string;
  data?: TimeConversionFormula;
  existingMinimumHours: number[];
}

const EditTimeConversionDialog = (props: Props) => {
  const validationSchema = Yup.object().shape({
    minimumHoursForRow: Validations.requiredNonNegativeNumber().notOneOf(
      props.existingMinimumHours,
      "Formula for this number of partners already exists."
    ),
    adminHoursToAdd: Validations.requiredTimeIncrement()
  });

  const [addMutation, { loading: adding }] = useMutation<
    { timeFormulas: { add: TimeConversionFormula } },
    { formula: TimeConversionFormula }
  >(AddTimeConversionFormulaMutation, {
    refetchQueries: [{ query: TimeConversionFormulasQuery }]
  });
  const [editMutation, { loading: editing }] = useMutation<
    { timeFormulas: { update: TimeConversionFormula } },
    { formula: TimeConversionFormula }
  >(EditTimeConversionFormulaMutation);

  const mutate = Boolean(props.data) ? editMutation : addMutation;
  const loading = Boolean(props.data) ? editing : adding;

  return (
    <Dialog open={true} onClose={props.handleClose} fullWidth={true} scroll="paper" maxWidth="sm">
      <ClosableDialogTitle onClose={props.handleClose}>{props.title}</ClosableDialogTitle>
      <Formik
        initialValues={{ minimumHoursForRow: props.data?.minimumHoursForRow ?? 0.0, adminHoursToAdd: props.data?.adminHoursToAdd ?? 0.0 }}
        onSubmit={async (values) => {
          let formula: TimeConversionFormula = {
            id: props.data?.id,
            minimumHoursForRow: values.minimumHoursForRow,
            adminHoursToAdd: values.adminHoursToAdd
          };
          await mutate({
            variables: { formula }
          });
          props.handleClose();
        }}
        validationSchema={validationSchema}>
        {(formikProps) => (
          <FormikForm>
            <DialogContent>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Typography paragraph>
                    Administrative time conversion formulas control the administrative time billed to a firm for a practice review.
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <FormikField
                    component={FmuiTextField}
                    name="minimumHoursForRow"
                    label="Minimum PR Hours"
                    type="number"
                    inputProps={{ min: 0, step: 0.25 }}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={8}>
                  <FormikField
                    component={FmuiTextField}
                    name="adminHoursToAdd"
                    label="Admin Hours To Add"
                    type="number"
                    inputProps={{ min: 0, step: 0.25 }}
                    fullWidth
                    required
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={props.handleClose}>Cancel</Button>
              <LoadingButton color="primary" variant="contained" loading={loading} onClick={() => formikProps.submitForm()}>
                {props.confirmButtonText}
              </LoadingButton>
            </DialogActions>
          </FormikForm>
        )}
      </Formik>
    </Dialog>
  );
};

export default EditTimeConversionDialog;
