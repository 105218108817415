import React, { useState } from "react";

import NumberFormat from "react-number-format";
import { FormikProps } from "formik";
import MuiTextField from "@mui/material/TextField";
import { useUnsavedChanges } from "../../UnsavedChangesProvider";
import { makeStyles } from "makeStyles";

const useStyles = makeStyles()(() => ({
  numberFormatClass: {
    "& input": {
      textAlign: "right",
      fontVariantNumeric: "tabular-nums"
    }
  }
}));

interface Props {
  formikProps: FormikProps<any>;
  fieldName: string;
  label: string;
  required?: boolean;
  money?: boolean;
  hours?: boolean;
  months?: boolean;
  allowNegative?: boolean;
  className?: string;
  errorClassName?: string;
  fullWidth?: boolean;
  reportUnsavedChanges?: boolean;
  changeKey?: string;
  disabled?: boolean;
  hidden?: boolean;
}

export const FormikFormattedNumericField: React.FunctionComponent<Props> = (props) => {
  const { formikProps, fieldName, label, money, hours, months } = props;
  const { classes, cx } = useStyles();
  const { unsavedChanges } = useUnsavedChanges();

  const [focused, setFocused] = useState(false);

  return (
    <NumberFormat
      className={cx(
        classes.numberFormatClass,
        props.className,
        props.errorClassName
          ? {
              [props.errorClassName]: formikProps.touched[fieldName] && Boolean(formikProps.errors[fieldName])
            }
          : undefined
      )}
      hidden={props.hidden}
      value={formikProps.values[fieldName]}
      label={label}
      inputRef={(el: HTMLInputElement) => {
        if (!el) return;
        el.onfocus = () => setFocused(true);
        el.onblur = () => setFocused(false);
      }}
      thousandSeparator
      allowLeadingZeros={false}
      allowNegative={props.allowNegative ?? false}
      prefix={money ? "$" : undefined}
      format={
        !focused && Number(formikProps.values[fieldName]) < 0
          ? (value) => `(${money ? "$" : ""}${Math.abs(Number(value)).toLocaleString()}${hours ? "h" : ""})`
          : undefined
      }
      suffix={hours ? "h" : months ? " mo" : undefined}
      decimalScale={0}
      onValueChange={(numberFormatValues) => {
        const formattedWithParenthesesMatch = /\(\$?\-?(.+)h?\)/.exec(numberFormatValues.formattedValue);
        const parsedValue = formattedWithParenthesesMatch
          ? -Number(formattedWithParenthesesMatch[1].replace(/[^\d\.]/g, ""))
          : numberFormatValues.floatValue;

        if (props.reportUnsavedChanges && parsedValue !== undefined && parsedValue !== formikProps.values[fieldName]) {
          unsavedChanges(props.changeKey);
        }

        formikProps.setFieldValue(fieldName, parsedValue ?? "");
      }}
      required={props.required}
      customInput={MuiTextField}
      error={formikProps.touched[fieldName] && Boolean(formikProps.errors[fieldName])}
      onBlur={() => {
        formikProps.setFieldTouched(fieldName, true);
      }}
      disabled={props.disabled}
      helperText={formikProps.touched[fieldName] && formikProps.errors[fieldName]}
      fullWidth={props.fullWidth}
    />
  );
};
