import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@mui/material";

import React from "react";
import { makeStyles } from "../makeStyles";
import { staticDataStyles } from "../styles/common";

interface Props {
  label: string;
  value: string;
  icon?: IconDefinition;
  important?: boolean;
  className?: string;
}

const InlineStaticDataDisplay: React.FunctionComponent<Props> = (props) => {
  const useStyles = makeStyles<Props>()((theme) => ({
    ...staticDataStyles(theme),
    important: {
      fontWeight: 500
    },
    dataContainer: {
      display: "flex",
      "& svg": {
        marginRight: theme.spacing(1),
        color: theme.palette.text.secondary
      }
    }
  }));

  const { classes, cx } = useStyles(props);

  return (
    <div className={cx(classes.dataContainer, props.className)}>
      <Typography variant="body1" className={classes.inlineLabel}>
        {props.icon && <FontAwesomeIcon icon={props.icon} />}
        {props.label}:
      </Typography>
      <Typography variant="body1" className={props.important ? classes.important : ""}>
        {props.value}
      </Typography>
    </div>
  );
};

export default InlineStaticDataDisplay;
