import { Menu } from "@mui/material";

import React from "react";
import { makeStyles } from "../makeStyles";

const useStyles = makeStyles()((theme) => ({
  subMenu: {
    background: theme.palette.primary.main,
    minWidth: "250px",
    borderRadius: 0
  }
}));

export interface SubmenuProps {
  children: React.ReactNode;
  anchor: React.MutableRefObject<any>;
  open: boolean;
  onClose: () => void;
}

const NavSubMenu: React.FunctionComponent<SubmenuProps> = (props) => {
  const { classes } = useStyles();

  return (
    <div onClick={props.onClose} className={classes.subMenu}>
      <Menu
        anchorEl={props.anchor?.current}
        open={props.open}
        onClose={props.onClose}
        MenuListProps={{ disablePadding: true }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        PaperProps={{
          square: true,
          className: classes.subMenu
        }}>
        <div>{props.children}</div>
      </Menu>
    </div>
  );
};

export default NavSubMenu;
