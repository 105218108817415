import React, { useState } from "react";
import { Ina } from "./models";
import { IconButton, Button, DialogContentText, Tooltip, Paper, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons/faArrowRight";
import { DataGridWithHeader } from "common/DataGridWithHeader";
import { datagridStyles, inaPanelStyles, tableStyles } from "styles/common";
import { InternalRefetchQueryDescriptor, Reference, useApolloClient, useMutation } from "@apollo/client";
import { ConfirmationDialog } from "common/ConfirmationDialog";
import useLocalStorage from "util/useLocalStorage";
import ReassignInasDialog from "./ReassignInasDialog";
import SetPriorityOfInasDialog from "./SetPriorityOfInasDialog";
import { GetInasForUserQuery, InaTypeCode, CompleteInaMutation } from ".";
import { makeStyles } from "../makeStyles";
import { GridColDef, GridSortModel } from "@mui/x-data-grid-pro";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { InaMenu } from "./InaMenu";

const useStyles = makeStyles<Props>()((theme) => ({
  ...datagridStyles(theme),
  ...tableStyles(theme),
  ...inaPanelStyles(theme),
  root: {},
  actions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "100%",
    "& :not(:first-child)": {
      marginLeft: theme.spacing(1)
    }
  },
  dangerCheck: {
    "& .MuiDataGrid-booleanCell[data-value='true']": {
      color: theme.palette.error.main
    }
  },
  prlessInaHeader: {
    display: "flex",
    alignItems: "baseline",
    padding: theme.spacing(2),
    color: theme.palette.primary.main,
    "& h2": {
      fontWeight: 500
    }
  },
  prlessInaHeaderActions: {
    marginLeft: "auto",
    alignSelf: "center",
    display: "flex",
    "& > *": {
      marginLeft: theme.spacing(3)
    }
  }
}));

interface Props {
  title: string;
  inas: Ina[];
  columns: GridColDef[];
  defaultSortModel: GridSortModel;
  getNavigationRoute?: (ina: Ina) => string;
  getFixedNavigationRoute?: () => string;
  className?: string;
  completable?: boolean;
  getCompletionBlockingReason?: (ina: Ina) => string | null;
  showOverdue?: boolean;
  headerActions?: (selectedInas: Ina[]) => React.ReactElement;
  extraActions?: (ina: Ina) => React.ReactElement;
  actionsWidth?: number;
  getRowDetails?: (ina: Ina) => React.ReactElement;
  getDetailPanelHeight?: (ina: Ina) => number;
  extraRefetchQueries?: ((ina: Ina) => InternalRefetchQueryDescriptor)[];
  itemType?: string;
}

export const InaPanel: React.FunctionComponent<Props> = (props) => {
  const { classes } = useStyles(props);
  const history = useHistory();
  const apolloClient = useApolloClient();

  const inaTypeCode = props.inas[0]?.type.typeCode;
  const itemType = props.itemType ?? "PR";
  const defaultSortModel: GridSortModel = [
    {
      field: "practiceReview.startDate",
      sort: "asc"
    }
  ];
  const [sortModel, setSortModel] = useLocalStorage(`INA panel sort model: ${inaTypeCode}`, defaultSortModel);

  const [selecting, setSelecting] = useState(false);
  const [selectedInas, setSelectedInas] = useState<Ina[]>([]);

  const [reassigningInas, setReassigningInas] = useState(false);
  const [singleInaToReassign, setSingleInaToReassign] = useState<Ina | null>(null);
  const [settingPriorityOfInas, setSettingPriorityOfInas] = useState(false);
  const [singleInaToSetPriorityOf, setSingleInaToSetPriorityOf] = useState<Ina | null>(null);
  const [completingIna, setCompletingIna] = useState<Ina | null>(null);

  const [completeInaMutate, completeInaMutation] = useMutation<{ ina: { complete: number } }, { inaId: number; inaTypeCode: InaTypeCode }>(
    CompleteInaMutation
  );

  async function completeIna(ina: Ina) {
    const result = await completeInaMutate({
      variables: { inaId: ina.id, inaTypeCode: ina.type.typeCode },
      refetchQueries: [{ query: GetInasForUserQuery }, ...(props.extraRefetchQueries ?? []).map((q) => q(ina))]
    });

    if ((result.data?.ina.complete ?? 0) > 0) {
      // Immediately remove the INA from the cache
      const inaCacheId = apolloClient.cache.identify(ina as any);
      apolloClient.cache.modify({
        fields: {
          inas(existingInas: Reference[]) {
            return existingInas.filter((inaRef) => inaRef.__ref !== inaCacheId);
          }
        }
      });

      setCompletingIna(null);
    }
  }

  const inasArePerformReview = props.inas[0]?.type.typeCode === InaTypeCode.PerformReview;

  const columns = props.columns.concat([
    {
      field: "actions",
      headerName: "Actions",
      width: props.actionsWidth ? props.actionsWidth : props.completable ? 150 : 80,
      renderCell: (params: { row: Ina }) => {
        const ina = params.row;
        const completionBlockingReason = props.getCompletionBlockingReason ? props.getCompletionBlockingReason(ina) : null;

        return (
          <div className={classes.actions}>
            {props.extraActions && props.extraActions(ina)}

            {props.completable && (
              <Tooltip title={completionBlockingReason ?? ""}>
                <span>
                  <Button
                    color="secondary"
                    variant="outlined"
                    size="small"
                    className={classes.actionTextButton}
                    onClick={() => setCompletingIna(ina)}
                    disabled={Boolean(completionBlockingReason)}>
                    Done
                  </Button>
                </span>
              </Tooltip>
            )}

            <InaMenu
              inas={[ina]}
              setInasToReassign={(inas) => setSingleInaToReassign(inas[0])}
              setInasToSetPriorityOf={(inas) => setSingleInaToSetPriorityOf(inas[0])}
              disableReassignment={(ina) => ina.type.typeCode === InaTypeCode.PerformReview}
            />

            {props.getNavigationRoute && (
              <IconButton color="primary" size="small" title="Go" onClick={() => history.push(props.getNavigationRoute!(ina))}>
                <FontAwesomeIcon icon={faArrowRight} />
              </IconButton>
            )}
          </div>
        );
      }
    }
  ]);

  return (
    <>
      {props.inas.length > 0 ? (
        <DataGridWithHeader
          title={props.title}
          subtitle={`${props.inas.length} ${itemType}${props.inas.length !== 1 ? "s" : ""}`}
          itemType={`${inaTypeCode} ${itemType} INAs`}
          columns={columns}
          rows={props.inas}
          className={props.className}
          getDetailPanelContent={props.getRowDetails ? (params) => props.getRowDetails!(params.row as Ina) : undefined}
          getDetailPanelHeight={
            Boolean(props.getDetailPanelHeight)
              ? (params) => props.getDetailPanelHeight!(params.row)
              : Boolean(props.getRowDetails)
              ? (params) => 56
              : undefined
          }
          collapsible
          sortModel={sortModel}
          onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
          checkboxSelection={selecting}
          selectionModel={selectedInas.map((ina) => ina.id)}
          onSelectionModelChange={(newSelectionModel) =>
            setSelectedInas(props.inas.filter((ina) => newSelectionModel.indexOf(ina.id) !== -1))
          }
          disableSelectionOnClick
          maxHeight={600}
          headerActions={
            <>
              {props.headerActions ? props.headerActions(selectedInas) : null}
              {selecting ? (
                <>
                  <Button
                    onClick={() => {
                      setSelecting(false);
                      setSelectedInas([]);
                    }}
                    size="small"
                    variant="outlined">
                    Cancel
                  </Button>
                  <Tooltip title={inasArePerformReview ? "Perform Review INAs may not be reassigned." : ""}>
                    <span>
                      <Button
                        onClick={() => setReassigningInas(true)}
                        color="primary"
                        variant="outlined"
                        size="small"
                        disabled={selectedInas.length === 0 || inasArePerformReview}>
                        Reassign
                      </Button>
                    </span>
                  </Tooltip>
                  <Button
                    onClick={() => setSettingPriorityOfInas(true)}
                    color="primary"
                    variant="outlined"
                    size="small"
                    disabled={selectedInas.length === 0}>
                    Set priority
                  </Button>
                </>
              ) : (
                <Button onClick={() => setSelecting(true)} variant="outlined" size="small">
                  Select
                </Button>
              )}
            </>
          }
        />
      ) : (
        <Paper sx={{ mb: 5 }}>
          <div className={classes.prlessInaHeader}>
            {props.title && <Typography variant="h2">{props.title}</Typography>}
            {props.getFixedNavigationRoute && (
              <div className={classes.prlessInaHeaderActions}>
                <IconButton color="primary" size="small" title="Go" onClick={() => history.push(props.getFixedNavigationRoute!())}>
                  <FontAwesomeIcon icon={faArrowRight} />
                </IconButton>
              </div>
            )}
          </div>
        </Paper>
      )}
      {completingIna !== null && (
        <ConfirmationDialog
          open={true}
          body={<DialogContentText>Do you want to complete this INA?</DialogContentText>}
          title="Complete INA?"
          noDanger
          cancel={() => setCompletingIna(null)}
          confirm={() => completeIna(completingIna)}
          loading={completeInaMutation.loading}
        />
      )}

      {reassigningInas && (
        <ReassignInasDialog
          inas={selectedInas}
          handleClose={(completedReassignment: boolean) => {
            setReassigningInas(false);
            if (completedReassignment) {
              setSelectedInas([]);
              setSelecting(false);
            }
          }}
        />
      )}

      {singleInaToReassign && <ReassignInasDialog inas={[singleInaToReassign]} handleClose={() => setSingleInaToReassign(null)} />}

      {settingPriorityOfInas && (
        <SetPriorityOfInasDialog
          inas={selectedInas}
          handleClose={(completedSettingPriority) => {
            setSettingPriorityOfInas(false);
            if (completedSettingPriority) {
              setSelectedInas([]);
              setSelecting(false);
            }
          }}
        />
      )}

      {singleInaToSetPriorityOf && (
        <SetPriorityOfInasDialog inas={[singleInaToSetPriorityOf]} handleClose={() => setSingleInaToSetPriorityOf(null)} />
      )}
    </>
  );
};
