import { Stack, Typography } from "@mui/material";
import React from "react";
import { makeStyles } from "../makeStyles";

interface Props {
  header: string;
  value?: string | null;
  height?: string;
  plainText?: boolean;
}

const ReadOnlyNotes: React.FunctionComponent<Props> = (props) => {
  const useStyles = makeStyles()((theme) => ({
    sectionHeader: {
      marginBottom: theme.spacing(1)
    },
    notes: {
      height: props.height,
      paddingRight: theme.spacing(1), // so the text doesn't run right up against the scrollbar
      overflowY: "auto",
      borderRadius: theme.shape.borderRadius,
      "& > :first-child :first-child": {
        marginTop: 0
      }
    },
    plainText: {
      whiteSpace: "pre-line"
    }
  }));

  const { classes } = useStyles();
  return (
    <Stack>
      <Typography variant="h3" className={classes.sectionHeader}>
        {props.header}
      </Typography>
      {props.plainText ? (
        <Typography variant="body2" className={classes.notes}>
          <span className={classes.plainText}>{props.value ?? "--"}</span>
        </Typography>
      ) : (
        <Typography variant="body2" className={classes.notes} sx={{ "& p": { m: 0 } }}>
          <span dangerouslySetInnerHTML={{ __html: props.value ?? "--" }}></span>
        </Typography>
      )}
    </Stack>
  );
};

ReadOnlyNotes.defaultProps = {
  height: "10em"
};

export default ReadOnlyNotes;
