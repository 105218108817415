import { gql } from "@apollo/client";

export const ActivityFieldsFragment = gql`
  fragment ActivityFields on Activity {
    id
    activityName
    dateInitiated
    assignedByUser {
      name
      id
    }
    assignedToUser {
      name
      id
    }
    completedDate
    completedByUser {
      name
      id
    }
    outcomeOrComments
    isCustomActivity
  }
`;

export const AddActivityMutation = gql`
  ${ActivityFieldsFragment}
  mutation AddActivity($activity: ActivityInput!) {
    activities {
      add(activity: $activity) {
        ...ActivityFields
      }
    }
  }
`;

export const EditActivityMutation = gql`
  ${ActivityFieldsFragment}
  mutation EditActivity($activity: ActivityInput!) {
    activities {
      update(activity: $activity) {
        ...ActivityFields
      }
    }
  }
`;

export const FetchPrActivitiesQuery = gql`
  query FetchPrActivities($prId: Int) {
    practiceReviewById(id: $prId) {
      activityLog {
        id
        activityName
        dateInitiated
        assignedByUser {
          name
          id
        }
        assignedToUsers {
          name
          id
        }
        completedDate
        completedByUser {
          name
          id
        }
        outcomeOrComments
        isCustomActivity
        activityEmails {
          id
          canBeResentToFirm
        }
      }
    }
  }
`;

export const DeleteActivityMutation = gql`
  mutation DeleteActivity($id: Int) {
    activities {
      delete(id: $id) {
        id
      }
    }
  }
`;

export const ResendActivityEmailsMutation = gql`
  mutation ResendActivityEmails($activityEmailIds: [Int!]!) {
    activities {
      resendEmails(activityEmailIds: $activityEmailIds) {
        id
      }
    }
  }
`;

export const FetchCustomActivitiesQuery = gql`
  query FetchCustomActivities {
    customActivities {
      id
      customActivityName
    }
  }
`;
