import React from "react";
import { PrsDatePicker } from "common/PrsDatePicker";
import { FieldProps, FormikErrors, FormikTouched } from "formik";
import { DateTime } from "luxon";

interface PrsDatePickerFieldProps<T> extends FieldProps<DateTime, T> {
  minDate?: DateTime;
  maxDate?: DateTime;
  className?: string;
  fullWidth?: boolean;
  label?: string;
  showTime?: boolean;
  required?: boolean;
  disabled?: boolean;
  margin?: "dense" | "normal" | "none";
  allowNonWorkingDays?: boolean;
  onChange?: (e: DateTime) => void;
  onBlur?: () => any;
}

function PrsDatePickerField<T>(props: PrsDatePickerFieldProps<T>) {
  return (
    <>
      <PrsDatePicker
        setValue={(val) => {
          props.form.setFieldValue(props.field.name, val);
          if (props.onChange) {
            props.onChange(val as DateTime);
          }
        }}
        value={props.field.value}
        pickerProps={{
          className: props.className,
          minDate: props.minDate,
          maxDate: props.maxDate,
          disabled: props.disabled
        }}
        onBlur={props.onBlur}
        fullWidth={props.fullWidth}
        label={props.label}
        showTime={props.showTime}
        required={props.required}
        margin={props.margin}
        allowNonWorkingDays={props.allowNonWorkingDays}
        errorText={
          props.form.touched[props.field.name as keyof FormikTouched<T>] &&
          (props.form.errors[props.field.name as keyof FormikErrors<T>] as string)
        }
      />
    </>
  );
}

export default PrsDatePickerField;
