import React, { memo, useState } from "react";
import { ChecklistSectionTemplate, MasterChecklistNode } from "checklists";
import { ChecklistSectionDisplay } from "checklists/ChecklistSectionDisplay";
import { MasterChecklistQuestionDisplay } from "./MasterChecklistQuestionDisplay";
import { MasterChecklistQuestionHeader } from "./MasterChecklistQuestionHeader";
import { MasterChecklistActions } from "./MasterChecklistActions";
import EditMasterChecklistSectionDialog from "./dialogs/EditMasterChecklistSectionDialog";
import { ConfirmationDialog } from "common/ConfirmationDialog";
import { DialogContentText } from "@mui/material";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { MasterChecklistQuery } from "./MasterChecklistScreen";

export const DeleteSubsectionMutation = gql`
  mutation DeleteMasterChecklistSection($id: Int!) {
    masterChecklistQuestionContainers {
      deleteSection(id: $id)
    }
  }
`;

interface Props {
  section: ChecklistSectionTemplate;
  siblings: MasterChecklistNode[];
}

const MasterChecklistSectionDisplay: React.FunctionComponent<Props> = (props) => {
  const [deleteMutation, { loading }] = useMutation<
    { masterChecklistQuestionContainers: { deleteTopLevelSection: boolean } },
    { id: number }
  >(DeleteSubsectionMutation, {
    refetchQueries: [{ query: MasterChecklistQuery }],
    awaitRefetchQueries: true
  });

  const [expanded, setExpanded] = useState(true);
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <ChecklistSectionDisplay
        section={{ ...props.section }}
        expanded={expanded}
        toggleExpanded={() => setExpanded(!expanded)}
        adornments={
          <MasterChecklistActions
            node={props.section}
            hidden={!isHovered}
            siblings={props.siblings}
            renderEditDialog={(node, dialogProps) => (
              <EditMasterChecklistSectionDialog
                title="Edit Section"
                confirmButtonText="Save"
                handleClose={dialogProps.onClose}
                data={node as ChecklistSectionTemplate}
                isHeader={false}
              />
            )}
            renderDeleteDialog={(node, dialogProps) => (
              <ConfirmationDialog
                title="Delete Section?"
                body={<DialogContentText>Are you sure you want to delete the section: {node.text}?</DialogContentText>}
                open={true}
                cancel={dialogProps.onClose}
                confirm={() => deleteMutation({ variables: { id: node.id } }).then(dialogProps.onClose)}
                loading={loading}
              />
            )}
            isQuestionContainer
            isSection
          />
        }>
        {props.section.children.map((child) => {
          switch (child.__typename) {
            case "ChecklistSectionTemplate":
              return <MasterChecklistSectionDisplay key={`section-${child.id}`} section={child} siblings={props.section.children} />;
            case "QuestionHeaderTemplate":
              return (
                <MasterChecklistQuestionHeader
                  key={`question-header-${child.id}`}
                  questionHeader={child}
                  siblings={props.section.children}
                />
              );
            case "QuestionTemplate":
              return <MasterChecklistQuestionDisplay key={`question-${child.id}`} question={child} siblings={props.section.children} />;
            default:
              return null;
          }
        })}
      </ChecklistSectionDisplay>
    </div>
  );
};

const MemoizedMasterChecklistSectionDisplay = memo(MasterChecklistSectionDisplay);
export { MemoizedMasterChecklistSectionDisplay as MasterChecklistSectionDisplay };
