import { PracticeReview } from "practice-reviews";

export enum NoticeStage {
  GenerateNotices = "GenerateNotices",
  ApproveNotices = "ApproveNotices",
  ProcessUnsentNotices = "ProcessUnsentNotices"
}

export interface UpcomingReviewNotice {
  id: number;
  __typeName?: string;
  practiceReview: PracticeReview;
  notes: string | null;
  noticeHtml: string | null;
  isGenerated: boolean;
  isReviewedAtGenerateStage: boolean;
  isReviewedAtApprovalStage: boolean;
  isModified: boolean;
}
