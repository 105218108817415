import React from "react";
import { FirmProfile } from "practice-reviews";
import { Typography, Table, TableBody, TableRow, TableCell, Grid } from "@mui/material";

import { useNotifications } from "notifications";
import { Formik, FormikHelpers, Form as FormikForm, Field as FormikField } from "formik";
import { TextField as FmuiTextField } from "formik-mui";
import { gql, useMutation } from "@apollo/client";
import { tableStyles, actionStyles } from "styles/common";
import FixedPlacesNumberFormat from "util/FixedPlacesNumberFormat";
import { smallTextFieldHorizontalPadding } from "styles/theme";
import { useUnsavedChanges } from "../../UnsavedChangesProvider";
import { LoadingButton } from "@mui/lab";
import { makeStyles } from "makeStyles";

const useStyles = makeStyles()((theme) => ({
  ...tableStyles(theme),
  ...actionStyles(theme),
  root: {},
  section: {
    marginTop: theme.spacing(1)
  },
  table: {
    "& th, & td, & th:last-child, & td:last-child": {
      paddingRight: 0
    }
  },
  columnHeader: {
    fontWeight: 500
  },
  inputCell: {
    "& input": {
      fontSize: "0.875rem"
    }
  },
  ftPtInputCell: {
    width: "5em"
  },
  rightPadding: {
    paddingRight: `${smallTextFieldHorizontalPadding} !important`
  },
  secondary: {
    color: theme.palette.text.secondary
  }
}));

interface Props {
  firmId: number;
  reviewYear: number;
  firmProfile: FirmProfile | null;
  practiceReviewId: number;
  disableInputs: boolean;
}

interface FormValues {
  fullTimePartners: string;
  designatedAccountantsFt: string;
  designatedAccountantsPt: string;
  candidatesFt: string;
  candidatesPt: string;
  otherFt: string;
  otherPt: string;
}

export const FirmProfileTable: React.FunctionComponent<Props> = (props) => {
  const { classes, cx } = useStyles();
  const notifications = useNotifications();
  const { unsavedChanges, changesSaved, setSaveFunction, unsavedChangesExist } = useUnsavedChanges();
  const changeKey = "firm profile";

  const initialFormValues: FormValues = {
    fullTimePartners: props.firmProfile?.fullTimePartners.toString() ?? "",
    designatedAccountantsFt: props.firmProfile?.designatedAccountantsFt.toString() ?? "",
    designatedAccountantsPt: props.firmProfile?.designatedAccountantsPt.toString() ?? "",
    candidatesFt: props.firmProfile?.candidatesFt.toString() ?? "",
    candidatesPt: props.firmProfile?.candidatesPt.toString() ?? "",
    otherFt: props.firmProfile?.otherFt.toString() ?? "",
    otherPt: props.firmProfile?.otherPt.toString() ?? ""
  };

  const [saveMutate, saveMutation] = useMutation<{ firmProfile: { save: FirmProfile } }, { firmProfile: Partial<FirmProfile> }>(
    gql`
      mutation SaveFirmProfile($firmProfile: FirmProfileInput!) {
        firmProfile {
          save(firmProfile: $firmProfile) {
            firmId
            reviewYear

            fullTimePartners
            designatedAccountantsFt
            designatedAccountantsPt
            candidatesFt
            candidatesPt
            otherFt
            otherPt
          }
        }
      }
    `,
    {
      update: (cache, { data }) => {
        // We need to manually update the cache here for the case when the firm profile record didn't previously exist
        // for the practice review. If we manually add it to the PR, then the UI will automatically refresh with the new
        // values.
        cache.modify({
          id: `PracticeReview:${props.practiceReviewId}`,
          fields: {
            firmProfileForReviewYear() {
              return data?.firmProfile.save;
            }
          }
        });
      }
    }
  );

  function getNumberValue(stringValue: string) {
    const numberValue = parseInt(stringValue);
    return isNaN(numberValue) ? 0 : numberValue;
  }

  async function save(values: FormValues, actions?: FormikHelpers<FormValues>) {
    const result = await saveMutate({
      variables: {
        firmProfile: {
          firmId: props.firmId,
          reviewYear: props.reviewYear,

          fullTimePartners: getNumberValue(values.fullTimePartners),
          designatedAccountantsFt: getNumberValue(values.designatedAccountantsFt),
          designatedAccountantsPt: getNumberValue(values.designatedAccountantsPt),
          candidatesFt: getNumberValue(values.candidatesFt),
          candidatesPt: getNumberValue(values.candidatesPt),
          otherFt: getNumberValue(values.otherFt),
          otherPt: getNumberValue(values.otherPt)
        }
      }
    });

    if (result.data?.firmProfile.save?.firmId) {
      notifications.success("Saved profile.");
      changesSaved(changeKey);
    }

    if (actions) {
      actions.setSubmitting(false);
    }
  }

  return (
    <div className={classes.root}>
      <Formik initialValues={initialFormValues} enableReinitialize onSubmit={save}>
        {(formikProps) => {
          setSaveFunction(async () => {
            save(formikProps.values);
            return true;
          }, changeKey);

          function getInputCell(fieldName: string, ftPtCell = true) {
            return (
              <TableCell
                align="right"
                colSpan={ftPtCell ? undefined : 2}
                className={cx(classes.inputCell, { [classes.ftPtInputCell]: ftPtCell })}>
                <FormikField
                  component={FmuiTextField}
                  name={fieldName}
                  fullWidth
                  autoComplete="off"
                  InputProps={{
                    inputComponent: FixedPlacesNumberFormat,
                    inputProps: {
                      places: 0,
                      "aria-autocomplete": "none",
                      spellCheck: "false"
                    },
                    className: classes.number,
                    placeholder: "0"
                  }}
                  disabled={props.disableInputs || formikProps.isSubmitting}
                  onChange={(e: React.ChangeEvent<any>) => {
                    formikProps.setFieldValue(fieldName, e.target.value);
                    unsavedChanges(changeKey);
                  }}
                />
              </TableCell>
            );
          }

          function getNumberTotal(stringValues: string[]) {
            if (stringValues.every((sv) => !sv || sv.trim() === "")) return "--";
            return stringValues.map((sv) => getNumberValue(sv)).reduce((v1, v2) => v1 + v2, 0);
          }

          const fullTimeTotal = getNumberTotal([
            formikProps.values.designatedAccountantsFt,
            formikProps.values.candidatesFt,
            formikProps.values.otherFt
          ]);
          const partTimeTotal = getNumberTotal([
            formikProps.values.designatedAccountantsPt,
            formikProps.values.candidatesPt,
            formikProps.values.otherPt
          ]);

          return (
            <>
              <Typography variant="h3">Firm Profile</Typography>

              <FormikForm>
                <Table size="small" className={(classes.section, classes.table)}>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" align="right">
                        Partners
                      </TableCell>
                      {getInputCell("fullTimePartners", false)}
                    </TableRow>
                    <TableRow>
                      <TableCell />
                      <TableCell component="th" align="right" className={cx(classes.columnHeader, classes.rightPadding)}>
                        Full-time
                      </TableCell>
                      <TableCell component="th" align="right" className={cx(classes.columnHeader, classes.rightPadding)}>
                        Part-time
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" align="right">
                        Professional Designated Accountants
                        <br />
                        <span className={classes.secondary}>(excluding partners)</span>
                      </TableCell>
                      {getInputCell("designatedAccountantsFt")}
                      {getInputCell("designatedAccountantsPt")}
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" align="right">
                        Candidates
                      </TableCell>
                      {getInputCell("candidatesFt")}
                      {getInputCell("candidatesPt")}
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" align="right">
                        Other
                      </TableCell>
                      {getInputCell("otherFt")}
                      {getInputCell("otherPt")}
                    </TableRow>
                    <TableRow className={classes.total}>
                      <TableCell component="th" align="right">
                        Totals
                      </TableCell>
                      <TableCell align="right" className={cx(classes.rightPadding, classes.number)}>
                        {fullTimeTotal}
                      </TableCell>
                      <TableCell align="right" className={cx(classes.rightPadding, classes.number)}>
                        {partTimeTotal}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>

                <Grid container justifyContent="flex-end" className={classes.section}>
                  <Grid item className={classes.actions}>
                    <LoadingButton
                      variant="outlined"
                      color="primary"
                      loading={saveMutation.loading}
                      onClick={() => formikProps.submitForm()}
                      disabled={props.disableInputs || !unsavedChangesExist(changeKey)}>
                      {unsavedChangesExist(changeKey) ? "Save" : "Saved"}
                    </LoadingButton>
                  </Grid>
                </Grid>
              </FormikForm>
            </>
          );
        }}
      </Formik>
    </div>
  );
};
