import { LicenseInfo } from "@mui/x-data-grid-pro";
import React, { useEffect } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { makeStyles } from "../makeStyles";
import { AppRoute } from "../Routes";
import { useAppConfig } from "../util/AppConfig";

import SiteAppBar from "./SiteAppBar";

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh"
  },
  header: {
    top: 0,
    zIndex: 1000,
    position: "sticky"
  },
  content: {
    flexGrow: 1
  },
  paddedContent: {
    padding: theme.spacing(3)
  }
}));

const Layout: React.FunctionComponent = (props) => {
  const { classes, cx } = useStyles();
  const { muiDataGridProLicenseKey } = useAppConfig();

  useEffect(() => {
    if (muiDataGridProLicenseKey) {
      LicenseInfo.setLicenseKey(muiDataGridProLicenseKey);
    }
  }, [muiDataGridProLicenseKey]);

  const onPrScreen = useRouteMatch(AppRoute.PracticeReview);

  return (
    <div className={classes.root}>
      {onPrScreen ? null : (
        <div className={classes.header}>
          <SiteAppBar />
        </div>
      )}

      <main className={cx(classes.content, { [classes.paddedContent]: !onPrScreen })}>{props.children}</main>
    </div>
  );
};

export default Layout;
