import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Theme,
  Typography
} from "@mui/material";

import ClosableDialogTitle from "common/ClosableDialogTitle";
import { gql, useMutation } from "@apollo/client";
import * as Yup from "yup";
import { Field as FormikField, Form as FormikForm, Formik, FormikProps } from "formik";
import { Validations } from "common/validations/common-yup-validations";
import { TextField as FmuiTextField, CheckboxWithLabel as FmuiCheckboxWithLabel } from "formik-mui";
import { MasterChecklistFieldsFragment } from "../queries";
import { QuestionContainerTemplate, QuestionTemplate, StandardParagraphTemplate } from "checklists/models";
import { QuestionTemplateInput } from "master-checklist/models";
import { MasterChecklistQuery } from "master-checklist/MasterChecklistScreen";
import { ConfirmationDialog } from "common/ConfirmationDialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons/faTrashAlt";
import { LoadingButton } from "@mui/lab";
import { makeStyles } from "../../makeStyles";

const AddQuestionMutation = gql`
  ${MasterChecklistFieldsFragment}
  mutation AddMasterChecklistQuestion($question: ChecklistQuestionTemplateInput!) {
    masterChecklistQuestionContainers {
      addQuestion(question: $question) {
        ...MasterChecklistFields
      }
    }
  }
`;

const EditQuestionMutation = gql`
  ${MasterChecklistFieldsFragment}
  mutation EditMasterChecklistQuestion($question: ChecklistQuestionTemplateInput!) {
    masterChecklistQuestionContainers {
      editQuestion(question: $question) {
        ...MasterChecklistFields
      }
    }
  }
`;

const useStyles = makeStyles()((theme) => ({
  standardParagraphsTitle: {
    fontWeight: "bold"
  },
  standardParagraphList: {
    height: "100px",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    paddingTop: "0",
    overflowY: "auto"
  }
}));

interface EditMasterChecklistQuestionDialogProps {
  handleClose: () => void;
  title: string;
  confirmButtonText: string;
  question?: QuestionTemplate;
  container?: QuestionContainerTemplate;
}

const EditMasterChecklistQuestionDialog = (props: EditMasterChecklistQuestionDialogProps) => {
  const { classes } = useStyles();

  const validationSchema = Yup.object().shape({
    text: Validations.requiredText()
  });

  const [addQuestion, { loading: loadingAdd }] = useMutation<
    { masterChecklistQuestionContainers: { addQuestion: QuestionContainerTemplate } },
    { question: QuestionTemplateInput }
  >(AddQuestionMutation, {
    refetchQueries: [{ query: MasterChecklistQuery }]
  });

  const [editQuestion, { loading: loadingEdit }] = useMutation<
    { masterChecklistQuestionContainers: { editQuestion: QuestionContainerTemplate } },
    { question: QuestionTemplateInput }
  >(EditQuestionMutation);

  const mutate = Boolean(props.question) ? editQuestion : addQuestion;
  const loading = Boolean(props.question) ? loadingEdit : loadingAdd;

  const [selectedStandardParagraph, setSelectedStandardParagraph] = useState<number | undefined>();
  const [deleteStandardParagraph, setDeleteStandardParagraph] = useState<number | undefined>();

  const selectParagraph = (index: number) => {
    if (selectedStandardParagraph === index) {
      setSelectedStandardParagraph(undefined);
    } else {
      setSelectedStandardParagraph(index);
    }
  };

  const addStandardParagraph = <T extends { standardParagraphs: StandardParagraphTemplate[] }>(formikProps: FormikProps<T>) => {
    formikProps.setValues({
      ...formikProps.values,
      standardParagraphs: [
        ...formikProps.values.standardParagraphs,
        {
          title: "New Standard Paragraph",
          cpaRef: "",
          text: "",
          recommendation: "",
          isSignificantByDefault: false,
          isRemedialByDefault: false
        } as StandardParagraphTemplate
      ]
    });

    setSelectedStandardParagraph(formikProps.values.standardParagraphs.length);
  };

  const removeStandardParagraph = <T extends { standardParagraphs: StandardParagraphTemplate[] }>(
    index: number,
    formikProps: FormikProps<T>
  ) => {
    setSelectedStandardParagraph(undefined);

    const newStandardParagraphsList = [...formikProps.values.standardParagraphs];
    newStandardParagraphsList.splice(index, 1);

    formikProps.setValues({
      ...formikProps.values,
      standardParagraphs: newStandardParagraphsList
    });

    setDeleteStandardParagraph(undefined);
  };

  return (
    <Dialog open={true} fullWidth={true} scroll="paper" maxWidth="sm">
      <ClosableDialogTitle onClose={props.handleClose}>{props.title}</ClosableDialogTitle>
      <Formik
        initialValues={{
          text: props.question?.text ?? "",
          cpaRef: props.question?.cpaRef ?? "",
          standardParagraphs: props.question?.standardParagraphs ?? []
        }}
        onSubmit={async (values) => {
          let question: QuestionTemplateInput = {
            id: props.question?.id,
            text: values.text,
            cpaRef: values.cpaRef,
            questionContainerId: props.container?.id,
            sortOrder: props.question?.sortOrder ?? props.container?.questions.length,
            standardParagraphTemplates: values.standardParagraphs
          };
          await mutate({
            variables: { question }
          });
          props.handleClose();
        }}
        validationSchema={validationSchema}>
        {(formikProps) => {
          return (
            <FormikForm>
              <DialogContent>
                <FormikField component={FmuiTextField} name="text" label="Question" required multiline fullWidth />
                <FormikField component={FmuiTextField} name="cpaRef" label="CPA Ref." fullWidth />
                <Grid container justifyContent="space-between" alignItems="center" sx={{ mt: 2, mb: 1 }}>
                  <Grid item>
                    <Typography variant="body1" className={classes.standardParagraphsTitle}>
                      Standard Paragraphs
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Button color="primary" variant="outlined" size="small" onClick={() => addStandardParagraph(formikProps)}>
                      Add
                    </Button>
                  </Grid>
                </Grid>
                <List className={classes.standardParagraphList}>
                  {formikProps.values.standardParagraphs.map((sp, index) => (
                    <ListItem button selected={selectedStandardParagraph === index} onClick={() => selectParagraph(index)}>
                      <ListItemText primary={sp.title} />
                      <ListItemSecondaryAction>
                        <IconButton edge="end" aria-label="delete" size="small" onClick={() => setDeleteStandardParagraph(index)}>
                          <FontAwesomeIcon icon={faTrashAlt} />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
                {selectedStandardParagraph !== undefined && (
                  <>
                    <FormikField
                      component={FmuiTextField}
                      name={`standardParagraphs[${selectedStandardParagraph}].title`}
                      label="Title"
                      fullWidth
                    />
                    <FormikField
                      component={FmuiTextField}
                      name={`standardParagraphs[${selectedStandardParagraph}].text`}
                      label="Description"
                      multiline
                      rows={4}
                      fullWidth
                    />
                    <FormikField
                      component={FmuiTextField}
                      name={`standardParagraphs[${selectedStandardParagraph}].recommendation`}
                      label="Recommendation"
                      multiline
                      rows={4}
                      fullWidth
                    />
                    <FormikField
                      component={FmuiTextField}
                      name={`standardParagraphs[${selectedStandardParagraph}].cpaRef`}
                      label="CPA Ref."
                      fullWidth
                    />
                    <FormikField
                      component={FmuiCheckboxWithLabel}
                      type="checkbox"
                      name={`standardParagraphs[${selectedStandardParagraph}].isSignificantByDefault`}
                      Label={{ label: "Significant by Default" }}
                    />
                    <FormikField
                      component={FmuiCheckboxWithLabel}
                      type="checkbox"
                      name={`standardParagraphs[${selectedStandardParagraph}].isRemedialByDefault`}
                      Label={{ label: "Remedial By Default" }}
                    />
                  </>
                )}
              </DialogContent>
              <DialogActions>
                <Button onClick={props.handleClose}>Cancel</Button>
                <LoadingButton color="primary" variant="contained" loading={loading} onClick={() => formikProps.submitForm()}>
                  {props.confirmButtonText}
                </LoadingButton>
              </DialogActions>

              {deleteStandardParagraph !== undefined && (
                <ConfirmationDialog
                  open={true}
                  title="Delete Standard Paragraph?"
                  body={<DialogContentText>Are you sure you want to delete this standard paragraph?</DialogContentText>}
                  confirm={() => removeStandardParagraph(deleteStandardParagraph, formikProps)}
                  cancel={() => setDeleteStandardParagraph(undefined)}
                />
              )}
            </FormikForm>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default EditMasterChecklistQuestionDialog;
