import { Button, Checkbox, Dialog, DialogActions, DialogContent, FormControlLabel, Grid, Stack, TextField } from "@mui/material";
import { useNotifications } from "notifications";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { NoticeStage, UpcomingReviewNotice } from "scheduling";
import ClosableDialogTitle from "common/ClosableDialogTitle";
import RichTextEditor from "common/RichTextEditor";
import { gql, useMutation } from "@apollo/client";
import { convertFromHTML, ContentState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { LoadingButton } from "@mui/lab";

interface Props {
  notice: UpcomingReviewNotice | null;
  noticeStage: NoticeStage;
  handleClose: () => void;
}

const EditUpcomingReviewNoticeDialog: React.FunctionComponent<Props> = (props) => {
  const notifications = useNotifications();

  const [notes, setNotes] = useState("");
  useEffect(() => setNotes(props.notice?.notes ?? ""), [props.notice]);

  const [prHasIncreasedRisk, setPrHasIncreasedRisk] = useState(false);
  useEffect(() => setPrHasIncreasedRisk(props.notice?.practiceReview.hasIncreasedRisk ?? false), [props.notice]);

  const [saveNoticeMutate, saveNoticeMutation] = useMutation<
    { upcomingReviewNotice: { updateAndMarkAsReviewed: Partial<UpcomingReviewNotice> } },
    {
      upcomingReviewNotice: Partial<UpcomingReviewNotice>;
      noticeStage: NoticeStage;
      isModified: boolean;
      stageIsGenerate: boolean;
      stageIsApprove: boolean;
      prHasIncreasedRisk: boolean;
    }
  >(
    gql`
      mutation SaveUpcomingReviewNoticeAndMarkAsReviewed(
        $upcomingReviewNotice: UpcomingReviewNoticeInput!
        $noticeStage: NoticeStage!
        $isModified: Boolean!
        $stageIsGenerate: Boolean!
        $stageIsApprove: Boolean!
        $prHasIncreasedRisk: Boolean!
      ) {
        upcomingReviewNotice {
          updateAndMarkAsReviewed(
            upcomingReviewNotice: $upcomingReviewNotice
            noticeStage: $noticeStage
            isModified: $isModified
            prHasIncreasedRisk: $prHasIncreasedRisk
          ) {
            id
            isModified
            noticeHtml
            notes
            isReviewedAtGenerateStage @include(if: $stageIsGenerate)
            isReviewedAtApprovalStage @include(if: $stageIsApprove)
            practiceReview {
              id
              hasIncreasedRisk
            }
          }
        }
      }
    `
  );

  const contentRetriever = useRef<{ getContentAsHtml: () => string | null }>({ getContentAsHtml: () => null });

  const originalHtml = useMemo(() => {
    if (!props.notice?.noticeHtml) {
      return null;
    } else {
      const contentBlocksAndMap = convertFromHTML(props.notice.noticeHtml);
      const contentState = ContentState.createFromBlockArray(contentBlocksAndMap.contentBlocks, contentBlocksAndMap.entityMap);
      return stateToHTML(contentState);
    }
  }, []);

  async function saveAndMarkAsReviewed() {
    const noticeHtml = contentRetriever.current.getContentAsHtml();

    const trimmedNotes = notes.trim() !== "" ? notes.trim() : null;

    const saveResult = await saveNoticeMutate({
      variables: {
        upcomingReviewNotice: {
          id: props.notice?.id,
          notes: trimmedNotes,
          noticeHtml: noticeHtml
        },
        noticeStage: props.noticeStage,
        isModified: originalHtml !== noticeHtml,
        stageIsGenerate: props.noticeStage === NoticeStage.GenerateNotices,
        stageIsApprove: props.noticeStage === NoticeStage.ApproveNotices,
        prHasIncreasedRisk: prHasIncreasedRisk
      }
    });

    if (saveResult.data?.upcomingReviewNotice.updateAndMarkAsReviewed) {
      props.handleClose();
      notifications.success("Saved notice.");
    }
  }

  if (!props.notice) return null;

  return (
    <Dialog open={true} onClose={props.handleClose} fullWidth={true} scroll="paper" maxWidth="md">
      <ClosableDialogTitle onClose={props.handleClose}>Edit Upcoming Review Notice</ClosableDialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <FormControlLabel
            control={
              <Checkbox title="Increased Risk" checked={prHasIncreasedRisk} onClick={() => setPrHasIncreasedRisk(!prHasIncreasedRisk)} />
            }
            label="Increased Risk"
          />
          <TextField label="Notes" multiline fullWidth value={notes} onChange={(e) => setNotes(e.target.value)} />
          <RichTextEditor
            required
            label="Email Content"
            html={props.notice.noticeHtml ?? ""}
            passContentRetriever={(getContentAsHtml) => {
              contentRetriever.current = { getContentAsHtml };
            }}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose}>Cancel</Button>
        <LoadingButton color="primary" variant="contained" loading={saveNoticeMutation.loading} onClick={() => saveAndMarkAsReviewed()}>
          Save and mark as reviewed
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default EditUpcomingReviewNoticeDialog;
