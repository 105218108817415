import { staticDataStyles } from "../styles/common";
import { makeStyles } from "../makeStyles";
import { MergeFieldType } from "common/HtmlMergeFields/models";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.core.css";
import "react-quill/dist/quill.snow.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useUnsavedChanges } from "../UnsavedChangesProvider";
import { useRef, useEffect, useMemo } from "react";
import { Tooltip } from "@mui/material";
import { faKrw } from "@fortawesome/free-solid-svg-icons";
import QuillMergeFieldSelect from "./HtmlMergeFields/QuillMergeFieldSelect";
import hljs from "highlight.js";
import asposeLinqHighlight from "./HtmlMergeFields/AsposeLinqHighlight";
import htmlMergeHighlight from "./HtmlMergeFields/HtmlMergeHighlight";
hljs.registerLanguage("asposelinq", asposeLinqHighlight);
hljs.registerLanguage("htmlmerge", htmlMergeHighlight);
const fontSizeArr = ["7pt", "8pt", "9pt", "10pt", "11pt", "12pt", "14pt", "16pt", "20pt", "24pt", "32pt"];
const Size = Quill.import("attributors/style/size");
Size.whitelist = fontSizeArr;
Quill.register(Size, true);
const Inline = Quill.import("blots/inline");
const BlockEmbed = Quill.import("blots/block/embed");

const Syntax = Quill.import("modules/syntax");

export enum SetFontsForPurpose {
  /** Set font to Calibri 11pt black and reset line height */
  ForEmail = "ForEmail",
  /** Set font to Arial 10pt black and reset line height */
  ForDocument = "ForDocument",
  /** Don't set anything, let mui and quill have their way.  This is the default behavior. */
  ForWeb = "ForWeb"
}

class RedactBlot extends Inline {
  static create(value: any) {
    const node = super.create(value);
    node.setAttribute("style", `text-decoration: line-through;`);
    return node;
  }

  static formats(domNode: any) {
    return domNode.getAttribute("style") || true;
  }

  format(name: string, value: string) {
    if (name === "redact" && value) {
      this.domNode.setAttribute("style", value);
    } else {
      super.format(name, value);
    }
  }

  formats() {
    let formats = super.formats();
    formats["redact"] = RedactBlot.formats(this.domNode);
    return formats;
  }
}

RedactBlot.blotName = "redact";
RedactBlot.tagName = "span";
Quill.register("formats/redact", RedactBlot);

class CpaaLogoBlot extends BlockEmbed {
  static create(value: any) {
    const node = super.create(value);
    node.setAttribute("src", "https://member.cpask.ca/images/Logo/cpa-logo.png");
    node.setAttribute("width", "258");
    node.setAttribute("height", "107");
    node.setAttribute("alt", "CPA Logo");
    return node;
  }
}

CpaaLogoBlot.blotName = "cpaalogo";
CpaaLogoBlot.tagName = "img";
Quill.register("formats/cpaalogo", CpaaLogoBlot);

const useStyles = makeStyles<Props>()((theme) => ({
  ...staticDataStyles(theme),
  textEditor: {
    ".ForEmail .ql-editor": {
      // This class is for resets for wysiwyg email.
      // Any changes to it should be applied to the block in CreateEmailInDraftsCommandHandler as well
      fontFamily: "Calibri",
      color: "black",
      lineHeight: "initial",
      fontSize: "11pt"
    },
    ".ForDocument .ql-editor": {
      fontFamily: "Arial",
      color: "black",
      lineHeight: "initial",
      fontSize: "10pt"
    },
    ".ForWeb .ql-editor": {},

    ".hljs-fieldTag": {
      border: "1px dotted green",
      backgroundColor: "#F0FFF0",
      padding: "0px 2px",
      whiteSpace: "nowrap" as "nowrap"
    },
    ".hljs-fieldFormatClause": {
      fontStyle: "italic",
      color: "#888"
    },
    ".hljs-docTag": {
      border: "1px dotted #833C0B",
      backgroundColor: "#FBE4D5",
      padding: "0px 2px",
      whiteSpace: "nowrap" as "nowrap"
    },
    ".markupTag": {
      fontSize: "0.85em",
      padding: "0px 2px",
      margin: "1px",
      whiteSpace: "nowrap" as "nowrap"
    },
    ".hljs-varTag": {
      border: "1px solid #CCC",
      borderRadius: "3px",
      backgroundColor: "#FAFAFA",
      color: "gray"
    },
    ".hljs-ifTag": {
      borderColor: "purple",
      backgroundColor: "#fff4ff",
      color: "purple"
    },
    ".elseTag": {
      borderWidth: "1px",
      borderStyle: "solid none solid none"
    },
    ".hljs-foreachTag": {
      borderColor: "#2a44a5",
      backgroundColor: "#eaf4ff",
      color: "#001564"
    },
    ".openTag": {
      borderWidth: "1px",
      borderStyle: "solid none solid solid",
      borderRadius: "5px 0px 0px 5px"
    },
    ".closeTag": {
      borderWidth: "1px",
      borderStyle: "solid solid solid none",
      borderRadius: "0px 5px 5px 0px"
    },
    ".ql-picker.ql-size": {
      width: "54px"
    }
  },
  toolbar: {
    position: "sticky",
    top: 0,
    backgroundColor: "#ffffffdb",
    zIndex: 1
  }
}));

interface Props {
  label?: string;
  name: string;
  html: string | null;
  hideToolbar?: boolean;
  readOnly?: boolean;
  className?: string;
  showMergeFields?: boolean;
  mergeFieldType?: MergeFieldType;
  minHeight?: string;
  reportUnsavedChanges?: boolean;
  changeKey?: string;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  required?: boolean;
  fullHeight?: boolean;
  fontSize?: boolean;
  links?: boolean;
  colors?: boolean;
  cpaalogo?: boolean;
  redaction?: boolean;
  templateMarkup?: boolean;
  onRedact?: () => void;
  error?: string;
  addNormalMargin?: boolean;
  setFontsForPurpose?: SetFontsForPurpose;
}

const QuillRichTextEditor: React.FunctionComponent<Props> = (props) => {
  const quillRef = useRef<ReactQuill>(null);
  const { unsavedChanges, unsavedChangesExist } = useUnsavedChanges();
  const unsavedChangesExistForChangeKey = unsavedChangesExist(props.changeKey); // TODO: report changes (first screen needing this doesn't have a save changes prompt)

  useEffect(() => {
    const init = (quill: any) => {};
    const check = () => {
      if (quillRef.current) {
        init(quillRef.current);
        return;
      }
      setTimeout(check, 200);
    };
    check();
  }, [quillRef]);

  const { classes } = useStyles(props);

  const toolbarContainerName = `toolbar-${props.name}`;

  const modules = useMemo(
    () => ({
      syntax: {
        // TODO: can this be turned off completely if it's not showMergeFields at all?
        highlight: (text: string) => hljs.highlightAuto(text, [props.templateMarkup ? "asposelinq" : "htmlmerge"]).value
      },
      toolbar: {
        container: `#${toolbarContainerName}`,
        handlers: {
          redact: (opt: any) => {
            const editor = quillRef.current?.getEditor();
            var range = editor?.getSelection();
            if (range && range.length > 0) {
              editor?.format("redact", opt);
            }
          },
          cpaalogo: (opt: any) => {
            const editor = quillRef.current?.getEditor();
            var range = editor?.getSelection();
            editor?.insertEmbed(range?.index ?? 0, "cpaalogo", {});
            editor?.setSelection((range?.index ?? 0) + 2, 0);
          }
        }
      }
    }),
    []
  );

  // formatting we'll allow (like, to be pasted or hotkeyed, as well as by the controls in the toolbar)
  const formats = [
    "bold",
    "italic",
    "underline",

    "list",

    ...(props.fontSize ? ["size"] : []),

    ...(props.colors ? ["background", "color"] : []),

    ...(props.links ? ["link"] : []),

    ...(props.cpaalogo ? ["cpaalogo"] : []),
    ...(props.redaction ? ["redact"] : [])
  ];

  function insertField(fieldText: string) {
    const editor = quillRef.current?.getEditor();
    editor?.focus(); // because if the editor doesn't have focus when you call getSelection() it's like "nothing, bro"
    const cursorPosition = editor?.getSelection()?.index ?? 0;
    editor?.insertText(cursorPosition, fieldText);
    editor?.setSelection(cursorPosition + fieldText.length, 0);
  }

  return (
    <div className={classes.textEditor}>
      <div id={toolbarContainerName} className={classes.toolbar}>
        <span className="ql-formats">
          {props.fontSize && (
            <select title="Size" className="ql-size">
              <option value="7pt">7pt</option>
              <option value="8pt">8pt</option>
              <option value="9pt">9pt</option>
              <option value="10pt">10pt</option>
              <option value="11pt" selected>
                11pt
              </option>
              <option value="12pt">12pt</option>
              <option value="14pt">14pt</option>
              <option value="16pt">16pt</option>
              <option value="20pt">20pt</option>
              <option value="24pt">24pt</option>
              <option value="32pt">32pt</option>
            </select>
          )}
          <button title="Bold" className="ql-bold" />
          <button title="Italic" className="ql-italic" />
          <button title="Underline" className="ql-underline" />
        </span>
        {props.colors && (
          <span className="ql-formats">
            <select title="Text Color" className="ql-color">
              <option value="#000000">Black</option>
              <option value="#231f20">MUI default</option>
              <option value="#7F7F7F">Gray</option>
              <option value="#006FBA">CPA Blue</option>
              <option value="#007397">CPA Light Blue</option>
              <option value="#009A49">CPA Green</option>
              <option value="#43B02A">CPA Light Green</option>
            </select>
            <select title="Highlight Color" className="ql-background">
              <option value="">No color</option>
              <option value="yellow">Yellow</option>
              <option value="orange">Orange</option>
              <option value="#43B02A">CPA Light Green</option>
            </select>
          </span>
        )}
        <span className="ql-formats">
          <button title="Numbered List" className="ql-list" value="ordered"></button>
          <button title="Bulleted List" className="ql-list" value="bullet"></button>
        </span>
        {(props.links || props.cpaalogo) && (
          <span className="ql-formats">
            {props.links && <button title="Link" className="ql-link"></button>}
            {props.cpaalogo && (
              <button title="Insert CPA Logo" className="ql-cpaalogo">
                <img src="/cpa.ico" width="18px" height="18px" />
              </button>
            )}
          </span>
        )}
        {props.redaction && (
          <span className="ql-formats">
            <button title="Redact Selection" className="ql-redact">
              <FontAwesomeIcon icon={faKrw} transform="shrink-3" />
            </button>
          </span>
        )}
        <span className="ql-formats">
          <button title="Clear all formatting" className="ql-clean" />
        </span>
        {(props.showMergeFields || props.templateMarkup) && (
          <span>
            <QuillMergeFieldSelect
              mergeFieldType={props.mergeFieldType!}
              setInsertedField={insertField}
              currentIdNestLevel={/*TODO: calculate and pass this in for nested merge fields*/ undefined}
            />
          </span>
        )}
      </div>
      <ReactQuill
        ref={quillRef}
        className={props.setFontsForPurpose ?? SetFontsForPurpose.ForWeb}
        bounds={`.${classes.textEditor}`}
        value={props.html ?? ""}
        onChange={(value, delta, source, editor) => {
          props.setFieldValue(props.name, value);
        }}
        formats={formats}
        modules={modules}
      />
    </div>
  );
};

export default QuillRichTextEditor;
