import React, { useState, useEffect } from "react";
import { PracticeClient, PracticeReview } from "practice-reviews";
import { CircularProgress, TextField, Typography, Stack, Grid } from "@mui/material";
import { gql, useMutation } from "@apollo/client";
import { makeStyles } from "makeStyles";
import { useCurrentUser, Permissions } from "users";
import { useUnsavedChanges } from "UnsavedChangesProvider";
import { LoadingButton } from "@mui/lab";
import { useNotifications } from "notifications";
import { actionStyles } from "styles/common";

interface PracticeClientOptionType {
  inputValue?: string;
  clientId?: number;
  clientName: string;
}

interface Props {
  practiceReviewId: number;
  practiceClients: PracticeClient[];
  disableInputs?: boolean;
}

export const ClientsToReview: React.FunctionComponent<Props> = (props) => {
  const useStyles = makeStyles()((theme) => ({
    ...actionStyles(theme),
    root: {
      "& > :not(:first-child)": {
        marginTop: theme.spacing(1)
      },
      "& .MuiAutocomplete-tag": {
        width: "100%",
        justifyContent: "space-between",
        fontFamily: theme.typography.body1.fontFamily
      }
    },
    header: {
      display: "flex",
      alignItems: "center",
      "& > :not(:first-child)": {
        marginLeft: theme.spacing(1)
      }
    }
  }));

  const { classes } = useStyles();
  const { userHasPermission } = useCurrentUser();
  const notifications = useNotifications();
  const { unsavedChanges, changesSaved, setSaveFunction, unsavedChangesExist } = useUnsavedChanges();
  const changeKey = "clients to review";

  function getPracticeClientsAsString(practiceClients: PracticeClient[]) {
    return practiceClients && practiceClients.length
      ? practiceClients
          .map((pc) => pc.clientName)
          .sort()
          .join("\r\n")
      : undefined;
  }

  const [practiceClientsString, setPracticeClientsString] = useState<string | undefined>(getPracticeClientsAsString(props.practiceClients));

  const [savePracticeClientsStringMutate, savePracticeClientsStringMutation] = useMutation<
    { practiceReview: { savePracticeClientsString: PracticeReview } },
    { practiceReviewId: number; practiceClientsString: string }
  >(gql`
    mutation SavePracticeClientsString($practiceReviewId: Int!, $practiceClientsString: String!) {
      practiceReview {
        savePracticeClientsString(practiceReviewId: $practiceReviewId, practiceClientsString: $practiceClientsString) {
          id
          practiceClients {
            id
            clientName
            toReview
          }
        }
      }
    }
  `);

  async function saveClients() {
    const result = await savePracticeClientsStringMutate({
      variables: {
        practiceReviewId: props.practiceReviewId,
        practiceClientsString: practiceClientsString || ""
      }
    });
    if (result.data?.practiceReview.savePracticeClientsString?.id) {
      notifications.success("Saved clients.");
      changesSaved(changeKey);
      setPracticeClientsString(getPracticeClientsAsString(result.data.practiceReview.savePracticeClientsString.practiceClients));

      return true;
    }

    return false;
  }

  // Hook up for unsaved changes
  useEffect(() => setSaveFunction(() => saveClients(), changeKey));

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h3">Clients to Review</Typography>
        {savePracticeClientsStringMutation.loading && <CircularProgress size={22} />}
      </div>
      {userHasPermission(Permissions.PrUpdateEstimateAndClients) ? (
        <Stack spacing={1} justifyContent="flex-start" alignItems="flex-start">
          <TextField
            multiline
            fullWidth
            margin="none"
            value={practiceClientsString}
            disabled={props.disableInputs || savePracticeClientsStringMutation.loading}
            onChange={(e) => {
              setPracticeClientsString(e.target.value);
              unsavedChanges(changeKey);
            }}
          />
          <Grid container justifyContent="flex-end">
            <Grid item className={classes.actions}>
              <LoadingButton
                variant="outlined"
                color="primary"
                loading={savePracticeClientsStringMutation.loading}
                onClick={() => saveClients()}
                disabled={props.disableInputs || !unsavedChangesExist(changeKey)}>
                {unsavedChangesExist(changeKey) ? "Save" : "Saved"}
              </LoadingButton>
            </Grid>
          </Grid>
        </Stack>
      ) : (
        <div style={{ whiteSpace: "pre-line" }}>{getPracticeClientsAsString(props.practiceClients)}</div>
      )}
    </div>
  );
};
