import React, { useState } from "react";
import { PracticeReview, PRBaseStatusCode } from "./models";
import { Chip, IconButton, lighten, Menu, MenuItem, Stack, Theme, Tooltip, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons/faEllipsisV";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons/faEnvelope";
import { faPhone } from "@fortawesome/free-solid-svg-icons/faPhone";
import { faUserTie } from "@fortawesome/free-solid-svg-icons/faUserTie";
import { faHouse } from "@fortawesome/free-solid-svg-icons/faHouse";
import { ChangePrStatusDialog } from "./ChangePrStatusDialog";
import { ChangePrIsNonAssuranceDialog } from "./ChangePrIsNonAssuranceDialog";
import { makeStyles } from "makeStyles";
import { useCurrentUser, Permissions } from "users";
import { ChangeMeetingDialog } from "../committee-meetings/ChangeMeetingDialog";
import GoToPrField from "./GoToPrField";
import { useHistory } from "react-router-dom";
import { AppRoute } from "../Routes";
import { useAppConfig } from "util/AppConfig";
import { versionIndicatorStyles } from "styles/common";

export function getPrScreenHeaderHeight(theme: Theme) {
  return theme.spacing(16);
}

const useStyles = makeStyles<Props>()((theme, props) => {
  const stripeHeight = "6px";
  const firmName = props.practiceReview.firm.name;

  return {
    versionIndicator: {
      ...versionIndicatorStyles(theme).versionIndicator,
      gridColumn: "1/3",
      gridRow: "1",
      marginTop: "-17px",
      marginLeft: "-23px",
      color: theme.palette.primary.light
    },
    header: {
      position: "sticky",
      top: 0,
      zIndex: 1200,
      overflow: "hidden"
    },
    stripe: {
      height: stripeHeight,
      background: `linear-gradient(to right, ${theme.palette.common.white}, ${theme.palette.secondary.main})`
    },
    headerContent: {
      padding: theme.spacing(3),
      paddingTop: theme.spacing(2),
      borderBottom: `1px solid ${theme.palette.common.white}`,

      height: `calc(${getPrScreenHeaderHeight(theme)} - ${stripeHeight})`,
      display: "grid",
      gridTemplateColumns: "auto minmax(0, 1fr) auto",
      gridTemplateRows: "auto auto",
      columnGap: theme.spacing(1),
      rowGap: theme.spacing(1),
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      "& a": {
        color: theme.palette.common.white
      },
      "& .MuiChip-outlined": {
        backgroundColor: theme.palette.common.white,
        "&.MuiChip-colorSecondary:hover": {
          backgroundColor: lighten(theme.palette.secondary.main, 0.8)
        }
      },
      "& .MuiInputLabel-shrink": {
        display: "none"
      },
      "& .MuiOutlinedInput-notchedOutline": {
        display: "none"
      }
    },
    inaLink: {
      gridColumn: "1 / 2",
      gridRow: "1 / 3"
    },
    firmInfo: {
      gridColumn: "2 / 3",
      gridRow: "1 / 2"
    },
    firmInfoTop: {
      display: "flex",
      alignItems: "baseline"
    },
    firmInfoName: {
      marginRight: theme.spacing(2),
      whiteSpace: "nowrap",
      flexShrink: 1,
      overflow: "hidden",
      textOverflow: "ellipsis",
      fontWeight: 700
    },
    firmName: {
      fontSize:
        firmName.length <= 55
          ? undefined
          : firmName.length <= 80
          ? theme.typography.h2.fontSize
          : firmName.length <= 90
          ? theme.typography.h3.fontSize
          : theme.typography.h4.fontSize
    },
    firmEntityNoAndType: {
      flexShrink: 0,
      marginRight: theme.spacing(2)
    },
    firmType: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1)
    },
    prSearchField: {
      gridColumn: "3 / 4",
      gridRow: "1 / 2"
    },
    prNumber: {
      fontWeight: 700
    },
    prStatusAndMenu: {
      display: "flex",
      alignItems: "center",
      flexWrap: "nowrap",
      alignSelf: "flex-start",
      marginLeft: "auto",
      "& :not(:first-child)": {
        marginLeft: theme.spacing(1)
      }
    },
    menu: {
      position: "relative",
      "& svg": { minWidth: "1em" }
    },
    headerBottom: {
      gridColumn: "2 / 4",
      gridRow: "2 / 3",
      display: "flex",
      alignItems: "center",
      flexWrap: "nowrap"
    },
    prAttributes: {
      display: "flex",
      alignItems: "center",
      "& > :not(:first-child)": {
        marginLeft: theme.spacing(2)
      }
    },
    contactInfoAndStatus: {
      display: "flex",
      marginLeft: theme.spacing(2),
      "& > *": {
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
        fontWeight: 500
      },
      "& > *:not(:first-child)": {
        borderLeft: `1px solid ${theme.palette.border}`
      },
      "& svg": {
        marginRight: theme.spacing(1),
        color: theme.palette.common.white
      }
    }
  };
});

interface Props {
  practiceReview: PracticeReview;
  reasonPrMeetingCannotBeChanged: string | null;
}

export const PrScreenHeader: React.FunctionComponent<Props> = (props) => {
  const { practiceReview } = props;
  const { classes, cx } = useStyles(props);
  const { userHasPermission } = useCurrentUser();
  const history = useHistory();
  const { version } = useAppConfig();

  const [menuAnchorElement, setMenuAnchorElement] = useState<HTMLElement | null>(null);
  const [changingStatus, setChangingStatus] = useState(false);
  const [changingIsNonAssurance, setChangingIsNonAssurance] = useState(false);
  const [changingMeeting, setChangingMeeting] = useState(false);

  return (
    <Stack className={classes.header}>
      <div className={classes.stripe} />
      <div className={classes.headerContent}>
        <div className={classes.inaLink}>
          <Tooltip title="INAs" placement="right">
            <IconButton color="white" onClick={() => history.push(AppRoute.INAs)}>
              <FontAwesomeIcon icon={faHouse} />
            </IconButton>
          </Tooltip>
        </div>
        {
          <div className={cx(classes.firmInfo, classes.firmInfoTop)}>
            <Typography variant="h1" className={cx(classes.firmInfoName, classes.firmName)}>
              {practiceReview.firm.name}
            </Typography>
            <Typography variant="subtitle1" className={classes.firmEntityNoAndType}>
              <span title="Entity No.">{practiceReview.firm.entityNumber}</span>
              <span title="Firm Type" className={classes.firmType}>
                {practiceReview.firm.entityType?.typeShortName ?? "Unknown"}
              </span>
            </Typography>
          </div>
        }
        <div className={classes.prSearchField}>
          <GoToPrField small width="15em" />
        </div>
        <div className={classes.headerBottom}>
          <div className={classes.prAttributes}>
            <Typography variant="h2" className={classes.prNumber}>
              PR {practiceReview.prNumber}
            </Typography>
            <Chip
              label={practiceReview.reviewType}
              title="Review Type"
              variant="outlined"
              color="primary"
              onClick={
                userHasPermission(Permissions.PrChangeType) && !practiceReview.hasBeenReturned
                  ? () => {
                      setChangingIsNonAssurance(true);
                    }
                  : undefined
              }
            />
            {practiceReview.hasIncreasedRisk && <Chip label="Increased Risk" color="error" />}
          </div>
          <div className={classes.contactInfoAndStatus}>
            <div>
              <Typography variant="subtitle2">
                <FontAwesomeIcon icon={faUserTie} />
                {`${practiceReview.contactTitle} ${practiceReview.contactName}${
                  practiceReview.contactDesignation ? `, ${practiceReview.contactDesignation}` : ""
                }`}
              </Typography>
            </div>
            <div>
              <Typography variant="subtitle2">
                <FontAwesomeIcon icon={faPhone} />
                {practiceReview.contactPhone}
              </Typography>
            </div>
            <div>
              <a href={practiceReview.contactEmailUri}>
                <Typography variant="subtitle2">
                  <FontAwesomeIcon icon={faEnvelope} />
                  {practiceReview.contactEmail}
                </Typography>
              </a>
            </div>
            <div>
              {
                <Typography variant="subtitle2">
                  {`Firm Status: ${practiceReview.firm.firmStatus}${
                    practiceReview.firm.subStatus ? `, ${practiceReview.firm.subStatus}` : ""
                  }`}
                </Typography>
              }
            </div>
          </div>
          <div className={classes.prStatusAndMenu}>
            <Chip
              label={practiceReview.status.statusName}
              color="secondary"
              variant="outlined"
              title="Status"
              onClick={userHasPermission(Permissions.PrChangeStatus) ? () => setChangingStatus(true) : undefined}
              disabled={practiceReview.status.baseStatusCode === PRBaseStatusCode.Removed}
            />
            <Chip label={practiceReview.phase.name} color="primary" variant="outlined" title="Phase" />
            <div className={classes.menu}>
              <IconButton color="white" onClick={(e) => setMenuAnchorElement(e.currentTarget)}>
                <FontAwesomeIcon icon={faEllipsisV} />
              </IconButton>
              <Menu
                id="pr-menu"
                anchorEl={menuAnchorElement}
                keepMounted
                open={!!menuAnchorElement}
                onClose={() => setMenuAnchorElement(null)}>
                {userHasPermission(Permissions.PrChangeStatus) && (
                  <MenuItem
                    onClick={() => {
                      setChangingStatus(true);
                      setMenuAnchorElement(null);
                    }}
                    disabled={practiceReview.status.baseStatusCode === PRBaseStatusCode.Removed}>
                    Change status
                  </MenuItem>
                )}
                {userHasPermission(Permissions.PrChangeType) && !practiceReview.hasBeenReturned && (
                  <MenuItem
                    onClick={() => {
                      setChangingIsNonAssurance(true);
                      setMenuAnchorElement(null);
                    }}>
                    Change type
                  </MenuItem>
                )}
                {userHasPermission(Permissions.PrChangeMeeting) && (
                  <Tooltip title={props.reasonPrMeetingCannotBeChanged ?? ""}>
                    <div>
                      <MenuItem
                        onClick={() => {
                          setChangingMeeting(true);
                          setMenuAnchorElement(null);
                        }}
                        disabled={Boolean(props.reasonPrMeetingCannotBeChanged)}>
                        {practiceReview.committeeMeeting ? "Change committee meeting" : "Assign to committee meeting"}
                      </MenuItem>
                    </div>
                  </Tooltip>
                )}
              </Menu>
              {changingStatus && <ChangePrStatusDialog practiceReview={practiceReview} onClose={() => setChangingStatus(false)} />}
              {changingIsNonAssurance && (
                <ChangePrIsNonAssuranceDialog practiceReview={practiceReview} onClose={() => setChangingIsNonAssurance(false)} />
              )}
              {changingMeeting && <ChangeMeetingDialog practiceReviews={[practiceReview]} onClose={() => setChangingMeeting(false)} />}
            </div>
          </div>
        </div>
        <Typography className={classes.versionIndicator} variant="subtitle2">
          Practice Review System v{version}
        </Typography>
      </div>
    </Stack>
  );
};
