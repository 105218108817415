import { Button, CircularProgress, Dialog, DialogActions, DialogContent, Grid, TextField } from "@mui/material";

import ClosableDialogTitle from "common/ClosableDialogTitle";
import { gql, Reference, useMutation, useQuery } from "@apollo/client";
import { Field as FormikField, Form as FormikForm, Formik, FormikErrors } from "formik";
import { TextField as FmuiTextField, Autocomplete as FmuiAutocomplete } from "formik-mui";
import { PracticeReview, Reviewer, TimeEntry, TimeEntryInput } from "../practice-reviews";
import { DateTime } from "luxon";
import { useCurrentUser, User, Permissions } from "../users";
import PrsDatePickerField from "../common/FormikFields/PrsDatePickerField";
import { useNotifications } from "../notifications";
import _ from "lodash";
import { LoadingButton } from "@mui/lab";
import { makeStyles } from "../makeStyles";
import React from "react";
import StackedStaticDataDisplay from "../common/StackedStaticDataDisplay";

const SaveTimeEntryMutation = gql`
  mutation SaveOrUpdateTimeEntry($timeEntry: TimeEntryInput!) {
    timeEntries {
      save(timeEntry: $timeEntry) {
        id
        enteredDate
        enteredHours
        user {
          id
          entityNumber
          name
        }
        practiceReview {
          id
          actualTimeToDate
        }
      }
    }
  }
`;

const useStyles = makeStyles()((theme) => ({
  optionIdentifier: {
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2)
  }
}));

interface Props {
  handleClose: () => void;
  title: string;
  confirmButtonText: string;
  practiceReview: PracticeReview;
  entryToEdit?: TimeEntry;
  allEntries: TimeEntry[];
}

interface FormValues {
  enteredDate: DateTime;
  enteredHours: number;
  userId: number;
}

const AvailableReviewersQuery = gql`
  query GetAvailableReviewers {
    availableReviewers {
      id
      name
    }
  }
`;

const EditTimeEntryDialog = (props: Props) => {
  const { classes } = useStyles();
  const notifications = useNotifications();
  const { user, userIsLeadReviewer, userLoading } = useCurrentUser();

  const { userHasPermission } = useCurrentUser();
  const canAssignAnyReviewer = userHasPermission(Permissions.TimeEntryEnterForAnyReviewer);

  const availableReviewersQuery = useQuery<{ availableReviewers: User[] }>(AvailableReviewersQuery, {
    skip: !canAssignAnyReviewer
  });
  const allAvailableReviewers = availableReviewersQuery.data?.availableReviewers || [];

  const practiceReviewReviewers =
    props.practiceReview.leadReviewer !== null
      ? [props.practiceReview.leadReviewer.user].concat(props.practiceReview.otherReviewers.map((r) => r.user))
      : props.practiceReview.otherReviewers.map((r) => r.user);

  function getEligibleReviewers() {
    let eligibleReviewers: User[];

    if (canAssignAnyReviewer) {
      eligibleReviewers = allAvailableReviewers;
    } else if (userIsLeadReviewer(props.practiceReview)) {
      eligibleReviewers = practiceReviewReviewers;
    } else {
      eligibleReviewers = practiceReviewReviewers.filter((r) => r.id === user.id);
    }

    return _.sortBy(eligibleReviewers, ["name"]);
  }

  const eligibleReviewers = getEligibleReviewers();
  const currentUserIsEligibleReviewer = eligibleReviewers.find((r) => r.id === user.id) !== undefined;

  const initialUserId = props.entryToEdit
    ? props.entryToEdit.user.id
    : currentUserIsEligibleReviewer
    ? user.id
    : eligibleReviewers.length === 1
    ? eligibleReviewers[0].id
    : -1;

  const entryValidation = (values: FormValues) => {
    const errors: FormikErrors<FormValues> = {};

    if (values.enteredDate === null) {
      errors.enteredDate = "A date is required.";
    } else {
      if (values.enteredDate > DateTime.local().endOf("day")) {
        errors.enteredDate = "You cannot enter time for a future date.";
      }
    }

    if (values.userId === -1) {
      errors.userId = "A reviewer is required.";
    }

    if (
      values.enteredHours === null ||
      (values.enteredHours === 0 && !props.entryToEdit?.isAdmin) ||
      typeof values.enteredHours !== "number"
    ) {
      errors.enteredHours = "Enter the hours spent by the reviewer.";
    } else if (values.enteredHours % 0.25 !== 0) {
      errors.enteredHours = "Hours must be recorded in 0.25 hour increments.";
    } else {
      const previousTimeEntry = props.allEntries.filter((e) => {
        return e.user.id === values.userId && e.enteredDate === values.enteredDate.toISODate() && e.id !== props.entryToEdit?.id;
      })?.[0];
      if ((previousTimeEntry?.enteredHours ?? 0) + values.enteredHours > 24) {
        errors.enteredHours = "Total hours for a given user must be less than 24 on the same day.";
      }
    }

    return errors;
  };

  const [mutate, { loading }] = useMutation<{ timeEntries: { __typename: string; save: TimeEntry } }, { timeEntry: TimeEntryInput }>(
    SaveTimeEntryMutation,
    {
      update: (cache, { data }) => {
        cache.modify({
          id: cache.identify(data?.timeEntries.save.practiceReview as any),
          fields: {
            actualTimeToDate() {
              return data?.timeEntries.save.practiceReview.actualTimeToDate;
            },
            timeEntries(existingTimeEntries: Reference[], { readField }) {
              const updatedEntry = cache.writeFragment({
                id: cache.identify(data?.timeEntries.save as any),
                data: data?.timeEntries.save,
                fragment: gql`
                  fragment MyTimeEntry on TimeEntry {
                    id
                    enteredDate
                    enteredHours
                    user
                  }
                `
              });
              if (existingTimeEntries.some((ref) => readField("id", ref) === readField("id", updatedEntry))) {
                return existingTimeEntries;
              } else {
                return [...existingTimeEntries, updatedEntry];
              }
            }
          }
        });
      }
    }
  );

  return (
    <Dialog open={true} onClose={props.handleClose} fullWidth={true} scroll="paper" maxWidth="sm">
      <ClosableDialogTitle onClose={props.handleClose}>{props.title}</ClosableDialogTitle>
      {userLoading || availableReviewersQuery.loading ? (
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      ) : (
        <Formik
          initialValues={{
            id: props.entryToEdit?.id,
            enteredHours: props.entryToEdit?.enteredHours ?? 0,
            enteredDate: props.entryToEdit ? DateTime.fromISO(props.entryToEdit.enteredDate) : DateTime.local(),
            userId: initialUserId,
            practiceReviewId: props.practiceReview.id
          }}
          validate={entryValidation}
          onSubmit={async (values) => {
            const timeEntry: TimeEntryInput = {
              id: props.entryToEdit?.id,
              enteredDate: values.enteredDate.toISODate(),
              enteredHours: values.enteredHours,
              userId: values.userId,
              practiceReviewId: props.practiceReview.id
            };
            await mutate({
              variables: { timeEntry }
            });
            notifications.success("Entry saved.");
            props.handleClose();
          }}>
          {(formikProps) => (
            <FormikForm>
              <DialogContent>
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    {props.entryToEdit?.isAdmin ? (
                      <StackedStaticDataDisplay
                        label="Reviewer"
                        value={props.entryToEdit.user.name}
                        tooltip="Admin time entries cannot be reassigned."
                      />
                    ) : (
                      <FormikField
                        name="userId"
                        component={FmuiAutocomplete}
                        disabled={formikProps.isSubmitting}
                        clearOnBlur
                        fullWidth
                        options={eligibleReviewers.map((r) => r.id)}
                        getOptionLabel={(option: number) => {
                          if (option === -1) {
                            return "";
                          }
                          const reviewerName = eligibleReviewers.find((r) => r.id === option)?.name ?? "unknown";
                          return option === props.practiceReview.leadReviewer?.user.id ? `${reviewerName} (Lead Reviewer)` : reviewerName;
                        }}
                        renderOption={(optionProps: React.HTMLAttributes<HTMLLIElement>, option: number) => {
                          const reviewerName = eligibleReviewers.find((r) => r.id === option)!.name;
                          return (
                            <li {...optionProps}>
                              <span>{reviewerName}</span>
                              {option === props.practiceReview.leadReviewer?.user.id && (
                                <span className={classes.optionIdentifier}>Lead</span>
                              )}
                            </li>
                          );
                        }}
                        renderInput={(params: any) => (
                          <TextField
                            label="Reviewer"
                            {...params}
                            error={!!formikProps.errors.userId}
                            helperText={formikProps.errors.userId}
                          />
                        )}
                      />
                    )}
                  </Grid>
                  <Grid item xs={5}>
                    <FormikField
                      component={PrsDatePickerField}
                      name="enteredDate"
                      label="Date"
                      // value={DateTime.fromISO(formikProps.values.enteredDate, {}}
                      disabled={formikProps.isSubmitting}
                      fullWidth={true}
                      showTime={false}
                      maxDate={DateTime.local()}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <FormikField
                      component={FmuiTextField}
                      name="enteredHours"
                      label="Hours"
                      type="number"
                      fullWidth
                      disabled={formikProps.isSubmitting}
                      InputProps={{
                        inputProps: { places: 2, min: 0, max: 24 },
                        placeholder: "0.00"
                      }}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={props.handleClose}>Cancel</Button>
                <LoadingButton color="primary" variant="contained" loading={loading} onClick={() => formikProps.submitForm()}>
                  {props.confirmButtonText}
                </LoadingButton>
              </DialogActions>
            </FormikForm>
          )}
        </Formik>
      )}
    </Dialog>
  );
};

export default EditTimeEntryDialog;
