import React from "react";
import { Link } from "@mui/material";

interface Props {
  href: string;
}

export function acrobatUrl(url: string) {
  return `acrobat:${url}`;
}

export const AcrobatLink: React.FunctionComponent<Props> = (props) => {
  return (
    <Link href={acrobatUrl(props.href)} target="_blank">
      {props.children}
    </Link>
  );
};
