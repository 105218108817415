import { PracticeReview, PRBaseStatusCode } from "practice-reviews";
import { BinderJob, CommitteeMeeting } from "committee-meetings";
import { DateTime } from "luxon";

export function getReasonPrMeetingCannotBeChanged(practiceReview: PracticeReview | undefined, availableMeetings: CommitteeMeeting[]) {
  if (!practiceReview) return null;

  if (practiceReview.status.baseStatusCode === PRBaseStatusCode.Completed) return "The PR is completed.";
  if (practiceReview.status.baseStatusCode === PRBaseStatusCode.Hold) return "The PR is on hold.";
  if (practiceReview.committeeMeeting && !practiceReview.committeeMeeting.isOpen) return "The PR's meeting is closed.";
  if (
    practiceReview.committeeMeeting &&
    DateTime.fromISO(practiceReview.committeeMeeting.meetingDate) < DateTime.local().startOf("day") &&
    practiceReview.committeeMeeting.binderGenerated &&
    practiceReview.committeeTab
  )
    return "The PR's meeting is in the past.";
  if (availableMeetings.length === 0 || availableMeetings.every((m) => m.id === practiceReview.committeeMeeting?.id)) {
    return "There are no other available meetings.";
  }

  return null;
}

export function getReasonMeetingPrsCannotBeReassigned(
  committeeMeeting: CommitteeMeeting | undefined,
  reviewsAssignedToMeeting: PracticeReview[],
  userHasPermission: boolean
): string | null {
  if (!committeeMeeting) return null;

  if (!userHasPermission) {
    return "You don't have permission to reassign PRs.";
  }

  if (reviewsAssignedToMeeting.length === 0) {
    return "There are no assigned files.";
  }

  if (!committeeMeeting.isOpen) {
    return "The meeting is closed.";
  }

  if (
    DateTime.fromISO(committeeMeeting.meetingDate) < DateTime.local().startOf("day") &&
    committeeMeeting.binderGenerated &&
    reviewsAssignedToMeeting.every((pr) => pr.committeeTab)
  ) {
    return "The meeting date has passed.";
  }

  return null;
}

export function getReasonBinderCannotBeGenerated(
  committeeMeeting: CommitteeMeeting | undefined,
  reviewsAssignedToMeeting: PracticeReview[],
  userHasPermission: boolean
): string | null {
  if (!committeeMeeting) return null;

  if (!userHasPermission) {
    return "You don't have permission to generate the binder.";
  }

  if (committeeMeeting.isOpen) {
    return "The meeting is still open.";
  }

  if (DateTime.fromISO(committeeMeeting.meetingDate) < DateTime.local().startOf("day")) {
    return "The meeting date has passed.";
  }

  if (committeeMeeting.subcommitteeDate === null || committeeMeeting.subcommitteeMeetings.length === 0) {
    return "Set the subcommittee meeting date and at least one subcommittee meeting time.";
  }

  if (reviewsAssignedToMeeting.some((pr) => !pr.committeeMeetingIsConfirmed)) {
    return "Some reviews are not confirmed for this meeting (director decision has not been signed off).";
  }

  return null;
}

export function getReasonBinderCannotBeAssembled(
  committeeMeeting: CommitteeMeeting | undefined,
  userHasPermission: boolean
): string | null {
  if (!committeeMeeting) return null;

  if (!userHasPermission) {
    return "You don't have permission to assemble the binder.";
  }

  if (DateTime.fromISO(committeeMeeting.meetingDate) < DateTime.local().startOf("day")) {
    return "The committee meeting date has passed.";
  }

  if (!committeeMeeting.binderGenerated) {
    return "The binder has not yet been generated.";
  }

  return null;
}

export function jobIsStale(job: BinderJob): boolean {
  if (job.isComplete || job.progress.allStepsComplete) {
    return false;
  }

  let lastUpdated = DateTime.fromISO(job.progress.lastUpdated);
  let now = DateTime.local();
  let diff = now.diff(lastUpdated, "minutes");
  return diff.minutes > 90;
}
