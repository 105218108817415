import React from "react";
import { ClienteleProfile } from "practice-reviews";
import { Typography, Table, TableHead, TableBody, TableRow, TableCell, Alert } from "@mui/material";
import { makeStyles } from "makeStyles";

import { actionStyles, tableStyles } from "styles/common";
import { formatNumber } from "util/formats";

const useStyles = makeStyles()((theme) => ({
  ...tableStyles(theme),
  ...actionStyles(theme),
  root: {},
  tableHeader: {
    "& th": {
      whiteSpace: "nowrap"
    }
  },
  table: {
    marginTop: theme.spacing(1),
    "& .MuiTableCell-sizeSmall": {
      padding: `6px ${theme.spacing(2)} 6px ${theme.spacing(1)}`
    },
    "& .MuiTableCell-sizeSmall:last-child": {
      paddingRight: theme.spacing(1)
    }
  },
  warningAlert: {
    marginTop: theme.spacing(1)
  }
}));

interface Props {
  clienteleProfile: ClienteleProfile | null;
}

export const ClienteleProfileTable: React.FunctionComponent<Props> = (props) => {
  const { classes, cx } = useStyles();
  let totalPercentage = 0;

  function getTableRow(rowName: string, hours: number, clients: number, headerClassName?: string) {
    totalPercentage = totalPercentage + Math.round((hours / (props.clienteleProfile!.totalHours || 1)) * 100);
    return (
      <TableRow>
        <TableCell component="th" scope="row" align="right" className={headerClassName}>
          {rowName}
        </TableCell>
        <TableCell align="right" className={classes.number}>
          {formatNumber(hours)}
        </TableCell>
        <TableCell align="right" className={classes.number}>{`${((hours / (props.clienteleProfile!.totalHours || 1)) * 100).toFixed(
          0
        )}%`}</TableCell>
        <TableCell align="right" className={classes.number}>
          {formatNumber(clients)}
        </TableCell>
      </TableRow>
    );
  }

  return (
    <div className={classes.root}>
      <Typography variant="h3">{`Clientele Profile${props.clienteleProfile ? ` (${props.clienteleProfile.reviewYear})` : ""}`}</Typography>
      {props.clienteleProfile ? (
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow className={classes.tableHeader}>
              <TableCell className={cx(classes.category, classes.topCategory)}>Audit</TableCell>
              <TableCell align="right">Hours</TableCell>
              <TableCell align="right">% Hours</TableCell>
              <TableCell align="right">Clients</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {getTableRow("Public Companies", props.clienteleProfile.publicCompaniesHours, props.clienteleProfile.publicCompaniesClients)}
            {getTableRow(
              "Offering Documents",
              props.clienteleProfile.offeringDocumentsHours,
              props.clienteleProfile.offeringDocumentsClients
            )}
            {getTableRow(
              "Other Entities under IFRS",
              props.clienteleProfile.otherEntitiesUnderIfrsHours,
              props.clienteleProfile.otherEntitiesUnderIfrsClients
            )}
            {getTableRow("Not-for-profit", props.clienteleProfile.notForProfitHours, props.clienteleProfile.notForProfitClients)}
            {getTableRow("Public Sector Engagements", props.clienteleProfile.publicSectorHours, props.clienteleProfile.publicSectorClients)}
            {getTableRow("Other Audit Engagements", props.clienteleProfile.otherAuditHours, props.clienteleProfile.otherAuditClients)}
            <TableRow>
              <TableCell component="th" className={cx(classes.category)} colSpan={4}>
                Review
              </TableCell>
            </TableRow>
            {getTableRow("Year-end", props.clienteleProfile.reviewYearEndHours, props.clienteleProfile.reviewYearEndClients)}
            {getTableRow(
              "Entities under IFRS",
              props.clienteleProfile.reviewEntitiesUnderIfrsHours,
              props.clienteleProfile.reviewEntitiesUnderIfrsClients
            )}
            {getTableRow(
              "Other Review Engagements",
              props.clienteleProfile.reviewInterimHours,
              props.clienteleProfile.reviewInterimClients
            )}
            <TableRow>
              <TableCell component="th" className={cx(classes.category)} colSpan={4}>
                Other
              </TableCell>
            </TableRow>
            {getTableRow(
              "Business Valuations",
              props.clienteleProfile.businessValuationsHours,
              props.clienteleProfile.businessValuationsClients
            )}
            {getTableRow("Insolvency", props.clienteleProfile.insolvencyHours, props.clienteleProfile.insolvencyClients)}
            {getTableRow(
              "Specified Auditing Procedures",
              props.clienteleProfile.trustAccountingHours,
              props.clienteleProfile.trustAccountingClients
            )}
            {getTableRow("Compilation (NTR)", props.clienteleProfile.compilationHours, props.clienteleProfile.compilationClients)}
            {getTableRow(
              "Accounting Services",
              props.clienteleProfile.accountingServicesHours,
              props.clienteleProfile.accountingServicesClients
            )}
            {getTableRow("Tax Services", props.clienteleProfile.taxServicesHours, props.clienteleProfile.taxServicesClients)}
            {getTableRow(
              "Handling of Trust Funds",
              props.clienteleProfile.handlingOfTrustFundsHours,
              props.clienteleProfile.handlingOfTrustFundsClients
            )}
            {getTableRow("Forensic/Litigation", props.clienteleProfile.forensicHours, props.clienteleProfile.forensicClients)}
            {getTableRow("Other Services", props.clienteleProfile.otherServicesHours, props.clienteleProfile.otherServicesClients)}
            <TableRow className={classes.total}>
              <TableCell component="th" scope="row" align="right">
                Totals
              </TableCell>
              <TableCell align="right" className={classes.number}>
                {formatNumber(props.clienteleProfile.totalHours)}
              </TableCell>
              <TableCell align="right" className={classes.number}>
                {props.clienteleProfile.totalHours ? totalPercentage + "% " : "0%"}
              </TableCell>
              <TableCell align="right" className={classes.number}>
                {formatNumber(props.clienteleProfile.totalClients)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      ) : (
        <Alert className={classes.warningAlert} severity="warning">
          There is no recent clientele profile available for the firm.
        </Alert>
      )}
    </div>
  );
};
