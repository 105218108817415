import { useQuery } from "@apollo/client";

import React from "react";
import { ScreenHeader } from "../common/ScreenHeader";
import CrudTable from "../common/CrudTable";
import { DecisionAdditionalParagraph } from "decisions";
import { FetchDecisionAdditionalParagraphsQuery } from "./queries";
import { Helmet } from "react-helmet";
import _ from "lodash";
import EditDecisionAdditionalParagraphDialog from "./EditDecisionAdditionalParagraphDialog";
import DeleteDecisionAdditionalParagraphDialog from "./DeleteDecisionAdditionalParagraphDialog";

const DecisionAdditionalParagraphsScreen: React.FunctionComponent = () => {
  const paragraphQuery = useQuery<{ decisionAdditionalParagraphs: DecisionAdditionalParagraph[] }>(FetchDecisionAdditionalParagraphsQuery);
  const paragraphs = paragraphQuery.data?.decisionAdditionalParagraphs ?? [];

  return (
    <>
      <Helmet>
        <title>Additional Paragraphs for Decisions - PRS Online</title>
      </Helmet>
      <ScreenHeader title="Additional Paragraphs for Decisions" />
      <CrudTable
        columnDefinitions={[
          {
            field: "name",
            headerName: "Name",
            flex: 1
          },
          {
            field: "isActive",
            headerName: "Active",
            type: "boolean"
          }
        ]}
        rows={paragraphs}
        loading={paragraphQuery.loading}
        noDataMessage="No additional paragraphs found."
        renderAddDialog={(props) => (
          <EditDecisionAdditionalParagraphDialog title="Add Additional Paragraph" confirmButtonText="Add" handleClose={props.onClose} />
        )}
        renderEditDialog={(id, dialogProps) => (
          <EditDecisionAdditionalParagraphDialog
            title="Edit Additional Paragraph"
            confirmButtonText="Save"
            handleClose={dialogProps.onClose}
            paragraph={paragraphs.find((ac) => ac.id === id)}
          />
        )}
        renderDeleteDialog={(id, dialogProps) => (
          <DeleteDecisionAdditionalParagraphDialog paragraph={paragraphs.find((ac) => ac.id === id)!} handleClose={dialogProps.onClose} />
        )}
      />
    </>
  );
};

export default DecisionAdditionalParagraphsScreen;
