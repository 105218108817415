import React from "react";
import { Typography } from "@mui/material";

import { staticDataStyles } from "styles/common";
import { PracticeReview } from "../practice-reviews";
import { makeStyles } from "../makeStyles";

const useStyles = makeStyles()((theme) => ({
  ...staticDataStyles(theme),
  prList: {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    columnGap: theme.spacing(1)
  },
  moreIndicator: {
    gridColumn: "1 / span 2"
  }
}));

interface Props {
  prs: PracticeReview[];
}

const PrList = (props: Props) => {
  const listLimit = 5;
  const { classes } = useStyles();

  return (
    <div>
      <Typography variant="body1" className={classes.label}>
        {props.prs.length > 1 ? "PRs" : "PR"}
      </Typography>
      <div className={classes.prList}>
        {props.prs.slice(0, listLimit).map((pr) => (
          <React.Fragment key={pr.id}>
            <Typography variant="body1">{pr.prNumber}</Typography>
            <Typography variant="body1">{pr.firm.name}</Typography>
          </React.Fragment>
        ))}
        {props.prs.length > listLimit && (
          <Typography variant="body2" className={classes.moreIndicator}>{`+ ${props.prs.length - listLimit} more`}</Typography>
        )}
      </div>
    </div>
  );
};

export default PrList;
