export default function asposeLinq(hljs: any) {
  return {
    name: "htmlmerge",
    contains: [
      {
        className: "fieldTag",
        begin: /\[\[(?!(TableStart|TableEnd|OptionalBlock))/,
        end: /\]\]/
      },
      {
        className: "ifTag markupTag openTag",
        begin: /\[\[OptionalBlockStart:/,
        end: /\]\]/
      },
      {
        className: "ifTag markupTag closeTag",
        begin: /\[\[OptionalBlockEnd:/,
        end: /\]\]/
      },
      {
        className: "foreachTag markupTag openTag",
        begin: /\[\[TableStart:/,
        end: /\]\]/
      },
      {
        className: "foreachTag markupTag closeTag",
        begin: /\[\[TableEnd:/,
        end: /\]\]/
      }
    ]
  };
}
