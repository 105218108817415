import { gql } from "@apollo/client";

const ReviewClientDetailFieldsFragment = gql`
  fragment ReviewClientDetailFields on ReviewClient {
    id
    practiceReviewId
    name
    partnerName
    refNum
    reviewedByUserId
    engagementTypeId
    engagementType {
      id
      name
    }
    clientFile {
      businessNature
      fiscalYearEnd
      cpabReview
      competitiveBid
      isHoursNotFees
      feesOrHoursAmount
      materiality
      assets
      liabilities
      revenue
      netIncomeBeforeTaxes
      reportDate
    }
  }
`;

export const AddReviewClientMutation = gql`
  ${ReviewClientDetailFieldsFragment}
  mutation AddReviewClient($reviewClient: ReviewClientInput!) {
    reviewClients {
      add(reviewClient: $reviewClient) {
        ...ReviewClientDetailFields
      }
    }
  }
`;

export const EditReviewClientMutation = gql`
  ${ReviewClientDetailFieldsFragment}
  mutation EditReviewClient($reviewClient: ReviewClientInput!) {
    reviewClients {
      update(reviewClient: $reviewClient) {
        ...ReviewClientDetailFields
      }
    }
  }
`;

export const DeleteReviewClientMutation = gql`
  mutation DeleteReviewClient($id: Int) {
    reviewClients {
      delete(id: $id) {
        id
      }
    }
  }
`;

const ReviewClientListExtraDisplayFieldsFragment = gql`
  fragment ReviewClientListExtraDisplayFields on ReviewClient {
    engagementType {
      id
      name
    }
    reviewer {
      id
      name
    }
    checklistCompletion {
      someQuestionsIncomplete
      someQuestionsComplete
      reportableDeficienciesExist
      nonReportableDeficienciesExist
      empty
    }
  }
`;

const EngagementTypeSelectFieldsFragment = gql`
  fragment EngagementTypeSelectFields on EngagementType {
    id
    name
    engagementGroup {
      id
      name
      sortOrder
    }
  }
`;

export const ReviewClientsListAndDropdownContentsQuery = gql`
  ${ReviewClientDetailFieldsFragment}
  ${ReviewClientListExtraDisplayFieldsFragment}
  ${EngagementTypeSelectFieldsFragment}
  query FetchReviewClients($id: Int!) {
    practiceReviewById(id: $id) {
      id
      reviewClients {
        ...ReviewClientDetailFields
        ...ReviewClientListExtraDisplayFields
      }
      engagementTypes {
        ...EngagementTypeSelectFields
      }
      firm {
        latestPartners {
          name
        }
      }
    }
  }
`;
