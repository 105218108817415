import React, { useCallback, useContext, useEffect, useMemo, useReducer, useState } from "react";
import {
  Answer,
  AnswerInput,
  AnswerType,
  Checklist,
  ChecklistNode,
  ChecklistSection,
  isQuestion,
  Question,
  QuestionContainer
} from "checklists";
import {
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Tab,
  Tabs
} from "@mui/material";
import { Skeleton } from "@mui/material";
import { LoadingButton, TabContext, TabPanel } from "@mui/lab";
import { PrChecklistTab } from "checklists/PrChecklistTab";
import { calculateCompletion, CompletionMarker } from "checklists/CompletionMarker";
import { buildChecklist } from "../checklists/buildChecklist";
import { checklistReducer, ChecklistState, ChecklistActionType, ChecklistAction } from "./checklistReducer";
import { gql, useMutation } from "@apollo/client";
import { useNotifications } from "../notifications";
import KeyboardEventHandler from "react-keyboard-event-handler";
import { ReviewClientsListAndDropdownContentsQuery } from "../practice-reviews/review-clients/queries";
import SearchChecklistDialog from "./SearchChecklistDialog";
import { LevelOfAdjustment } from "../level-of-adjustment";
import { LevelOfAdjustmentDialog } from "../level-of-adjustment/LevelOfAdjustmentDialog";
import { useUnsavedChanges } from "../UnsavedChangesProvider";
import { PrScreenQuery } from "../practice-reviews";
import { makeStyles } from "../makeStyles";
import { useCurrentUser, Permissions } from "users";
import { getPrScreenHeaderHeight } from "practice-reviews/PrScreenHeader";
import { PracticeReviewContext } from "../practice-reviews/PracticeReviewScreen";
import { useDimensions } from "react-dimensions-hook";
import ReadOnlyWrapper from "common/ReadOnlyWrapper";

export const PrChecklistDispatchContext = React.createContext<{
  dispatch: React.Dispatch<ChecklistAction>;
}>({ dispatch: () => null });

const useStyles = makeStyles<Props>()((theme, props) => {
  return {
    root: {
      maxWidth: undefined // Bug 64329 was to remove the checklist width limit, formerly "optionalScreenWidthLimit"
    },
    actions: {
      display: "flex",
      alignItems: "center",
      padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
      borderBottom: `1px solid ${theme.palette.divider}`,
      position: "sticky",
      top: `calc(${getPrScreenHeaderHeight(theme)} + ${props.checklistActionsVerticalOffset ?? 0}px)`,
      zIndex: 2, // above MUI floating labels
      background: theme.palette.common.white,
      "& > :not(:last-child)": {
        marginRight: theme.spacing(7)
      }
    },
    saveButton: {
      marginLeft: "auto"
    },
    tabs: {
      position: "sticky",
      zIndex: 2, // above MUI floating labels
      background: theme.palette.common.white,
      borderBottom: `1px solid ${theme.palette.divider}`,
      "& .MuiTab-wrapper": {
        alignItems: "flex-start",
        textAlign: "left"
      }
    },
    tabLabel: {
      display: "flex",
      alignItems: "center"
    },
    tabCompletionMarker: {
      marginRight: theme.spacing(1.5)
    }
  };
});

interface Props {
  checklistQuestionContainers: QuestionContainer[];
  practiceReviewId: number;
  levelOfAdjustment: LevelOfAdjustment;
  reviewClientId: number | null;
  checklistLoading: boolean;
  reviewerHasSubmittedReviewClients?: boolean;
  showLevelOfAdjustment: boolean;
  checklistActionsVerticalOffset?: number;
}

export const PrChecklist: React.FunctionComponent<Props> = (props) => {
  const { classes, theme } = useStyles(props);
  const notifications = useNotifications();

  const [activeTabId, setActiveTabId] = useState("");

  const [showDeficienciesOnly, setShowDeficienciesOnly] = useState(false);
  const [showDeficiencyType, setShowDeficiencyType] = useState<"all" | "RD" | "NRD">("all");
  const [showUnansweredOnly, setShowUnansweredOnly] = useState(false);
  const [showWithNotesOnly, setShowWithNotesOnly] = useState(false);

  const [searchDialogOpen, setSearchDialogOpen] = useState(false);
  const [levelOfAdjustmentDialogOpen, setLevelOfAdjustmentDialogOpen] = useState(false);
  const { practiceReview } = useContext(PracticeReviewContext);

  const { ref: actionsRef, dimensions: actionsDimensions } = useDimensions();

  const initialChecklistState: ChecklistState = {
    checklist: buildChecklist([]),
    reviewClientId: props.reviewClientId,
    currentQuestion: null,
    modified: false,
    findingInvalidAnswers: false
  };
  const memoizedReducer = useCallback(checklistReducer, []);
  const [state, dispatch] = useReducer(memoizedReducer, initialChecklistState);

  const { unsavedChanges, changesSaved, setSaveFunction } = useUnsavedChanges();
  useEffect(() => setSaveFunction(() => saveChecklist()), [state]);

  useMemo(() => {
    if (props.checklistQuestionContainers.filter((qc) => !qc.parentId).length === 0) return;

    const checklist = buildChecklist(props.checklistQuestionContainers);

    const firstIncompleteTab = checklist.tabSections.filter((s) => calculateCompletion(s).someQuestionsIncomplete)?.[0];
    const tabToActivate = firstIncompleteTab ?? checklist.tabSections[0];
    setActiveTabId(tabToActivate.id.toString());

    dispatch({ type: ChecklistActionType.Initialize, checklist: checklist });
    dispatch({ type: ChecklistActionType.MoveToFirstUnansweredQuestion, tabSectionId: tabToActivate.id });
  }, [props.checklistQuestionContainers]);

  useEffect(
    () =>
      dispatch({
        type: ChecklistActionType.UpdateFilters,
        filters: {
          showDeficienciesOnly,
          showDeficiencyType,
          showUnansweredOnly,
          showWithNotesOnly
        }
      }),
    [showUnansweredOnly, showWithNotesOnly, showDeficienciesOnly, showDeficiencyType]
  );

  useEffect(() => {
    if (state.modified) unsavedChanges();
    else changesSaved();
  }, [state.modified]);

  const [saveMutate, saveMutation] = useMutation<
    { checklist: { save: Answer[] } },
    { practiceReviewId: number; answers: AnswerInput[]; reviewClientId: number | null }
  >(
    gql`
      mutation SaveChecklist($practiceReviewId: Int!, $answers: [ChecklistAnswerInput!]!, $reviewClientId: Int) {
        checklist {
          save(practiceReviewId: $practiceReviewId, answers: $answers, reviewClientId: $reviewClientId) {
            id
            questionId
          }
        }
      }
    `,
    {
      refetchQueries: [
        {
          query: ReviewClientsListAndDropdownContentsQuery,
          variables: {
            id: props.practiceReviewId
          }
        },
        {
          query: PrScreenQuery,
          variables: {
            id: props.practiceReviewId
          }
        }
      ]
    }
  );

  async function saveChecklist() {
    if (!checklistIsValid(state.checklist)) {
      dispatch({ type: ChecklistActionType.FindInvalidAnswers });
      return false;
    }

    const modifiedAnswers = getModifiedAnswers(state.checklist);
    const answersWithSuperfluousDeficiencyFieldsStripped = stripSuperfluousDeficiencyData(modifiedAnswers);
    const mutationResult = await saveMutate({
      variables: {
        practiceReviewId: props.practiceReviewId,
        answers: answersWithSuperfluousDeficiencyFieldsStripped,
        reviewClientId: props.reviewClientId
      }
    });
    const savedAnswers = mutationResult.data?.checklist.save;

    if (savedAnswers) {
      dispatch({ type: ChecklistActionType.UpdateAfterSave, savedAnswers: savedAnswers });
      notifications.success("Checklist saved.");

      if (props.reviewerHasSubmittedReviewClients) {
        notifications.info("You will need to resubmit your client files to the lead reviewer.", 0);
      }

      return true;
    } else return false;
  }

  function checklistIsValid(checklist: Checklist) {
    return checklist.tabSections.every((s) => checklistNodeIsValid(s));
  }

  function checklistNodeIsValid(node: ChecklistNode): boolean {
    if (isQuestion(node)) {
      return !(node.answer?.invalid ?? false);
    } else {
      return (node as QuestionContainer).children.every((c) => checklistNodeIsValid(c));
    }
  }

  function getModifiedAnswers(checklist: Checklist) {
    let modifiedAnswers: AnswerInput[] = [];

    for (let tabSection of checklist.tabSections) {
      modifiedAnswers = modifiedAnswers.concat(getModifiedAnswersInQuestionContainer(tabSection));
    }

    return modifiedAnswers;
  }

  function getModifiedAnswersInQuestionContainer(questionContainer: QuestionContainer) {
    const childQuestionsWithModifiedAnswers = questionContainer.children.filter((c) => isQuestion(c) && c.answer?.modified);
    const modifiedChildAnswers = childQuestionsWithModifiedAnswers.map(
      (q) =>
        ({
          ...(q as Question).answer!,
          questionId: q.id,
          hasCustomDeficiency: undefined,
          modified: undefined,
          invalid: undefined
        } as AnswerInput)
    );

    const modifiedDescendantAnswers: AnswerInput[] = questionContainer.children
      .filter((c) => !isQuestion(c))
      .reduce((answers, qc) => answers.concat(getModifiedAnswersInQuestionContainer(qc as QuestionContainer)), [] as AnswerInput[]);

    return modifiedChildAnswers.concat(modifiedDescendantAnswers);
  }

  function stripSuperfluousDeficiencyData(answers: AnswerInput[]) {
    // Do not save any deficiency details if they were entered but then the answer was unanswered or changed to a non-deficiency.
    return answers.map((a) => {
      const answerIsDeficiency = a.isReportable || a.isNonReportable;
      const deficiencyIsCustom = a.standardParagraphId === null;

      return {
        ...a,
        standardParagraphId: answerIsDeficiency ? a.standardParagraphId : null,
        customCpaRef: answerIsDeficiency && deficiencyIsCustom ? a.customCpaRef : null,
        customParagraphText: answerIsDeficiency && deficiencyIsCustom ? a.customParagraphText : null,
        customRecommendationText: answerIsDeficiency && deficiencyIsCustom ? a.customRecommendationText : null,
        fileDetails: answerIsDeficiency ? a.fileDetails : null,
        isSignificant: a.isReportable ? a.isSignificant : false,
        requiresRemedialAction: a.isReportable ? a.requiresRemedialAction : false
      };
    });
  }

  function handleKeyEvent(key: string) {
    let answerType: AnswerType | undefined = undefined;
    switch (key) {
      case "y":
        answerType = AnswerType.Yes;
        break;
      case "r":
        answerType = AnswerType.Rd;
        break;
      case "n":
        answerType = AnswerType.Nrd;
        break;
      case "a":
      default:
        answerType = AnswerType.Na;
        break;
    }

    dispatch({
      type: ChecklistActionType.KeyboardAnswerCurrentQuestion,
      answerType,
      tabSectionId: parseInt(activeTabId),
      moveToNextQuestion: answerType === AnswerType.Yes || answerType === AnswerType.Na
    });
  }

  const dispatchContextValue = useMemo(() => ({ dispatch }), [dispatch]);
  const { userHasPermission } = useCurrentUser();

  return (
    <div className={classes.root}>
      <KeyboardEventHandler handleKeys={["y", "r", "n", "a"]} onKeyEvent={handleKeyEvent} />
      <PrChecklistDispatchContext.Provider value={dispatchContextValue}>
        <div className={classes.actions} ref={actionsRef}>
          <Button variant="outlined" onClick={() => setSearchDialogOpen(true)}>
            Search
          </Button>
          <Stack direction="row" spacing={5} alignItems="center">
            <Stack direction="row" spacing={0} alignItems="center">
              <FormControlLabel
                control={
                  <Checkbox
                    title="Show Deficiencies Only"
                    checked={showDeficienciesOnly}
                    onClick={() => {
                      setShowDeficienciesOnly(!showDeficienciesOnly);
                      setShowUnansweredOnly(false);
                    }}
                  />
                }
                label="Show Deficiencies Only"
              />
              <FormControl variant="outlined">
                <Select
                  margin="dense"
                  value={showDeficiencyType}
                  onChange={(e: SelectChangeEvent<any>) => setShowDeficiencyType(e.target.value)}
                  disabled={!showDeficienciesOnly}>
                  <MenuItem value="all">All</MenuItem>
                  <MenuItem value="RD">RD</MenuItem>
                  <MenuItem value="NRD">NRD</MenuItem>
                </Select>
              </FormControl>
            </Stack>
            <FormControlLabel
              control={
                <Checkbox
                  title="Show Unanswered Only"
                  checked={showUnansweredOnly}
                  onClick={() => {
                    setShowDeficienciesOnly(false);
                    setShowUnansweredOnly(!showUnansweredOnly);
                  }}
                />
              }
              label="Show Unanswered Only"
            />
            <FormControlLabel
              control={
                <Checkbox
                  title="Show With Notes Only"
                  checked={showWithNotesOnly}
                  onClick={() => setShowWithNotesOnly(!showWithNotesOnly)}
                />
              }
              label="Show With Notes Only"
            />
          </Stack>
          {props.showLevelOfAdjustment && (
            <Chip
              label={
                props.levelOfAdjustment?.level === 0 || !props.levelOfAdjustment
                  ? "No Adjustment"
                  : `Level ${props.levelOfAdjustment.level}`
              }
              title="Level of Adjustment"
              variant="outlined"
              color="primary"
              onClick={() => setLevelOfAdjustmentDialogOpen(true)}
            />
          )}
          <LoadingButton
            color="primary"
            variant="contained"
            className={classes.saveButton}
            loading={saveMutation.loading}
            onClick={() => saveChecklist()}
            hidden={
              !state.modified || !((practiceReview && !practiceReview.hasBeenReturned) || userHasPermission(Permissions.ReturnedPrUpdate))
            }>
            Save
          </LoadingButton>
        </div>
        {props.checklistQuestionContainers.length > 0 || !props.checklistLoading ? (
          <>
            <Tabs
              className={classes.tabs}
              sx={{
                top: `calc(${getPrScreenHeaderHeight(theme)} + ${props.checklistActionsVerticalOffset ?? 0}px + ${
                  actionsDimensions.height
                }px)`
              }}
              value={activeTabId}
              indicatorColor="primary"
              textColor="primary"
              onChange={(e, newTab) => {
                setActiveTabId(newTab);
              }}>
              {state.checklist.tabSections.map((section) => (
                <Tab
                  key={section.id}
                  label={
                    <div className={classes.tabLabel}>
                      <CompletionMarker section={section} className={classes.tabCompletionMarker} />
                      {(section as ChecklistSection).shortDescription}
                    </div>
                  }
                  value={section.id.toString()}
                />
              ))}
            </Tabs>
            <TabContext value={activeTabId}>
              {state.checklist.tabSections.map((section) => (
                <TabPanel key={section.id} value={section.id.toString()}>
                  <PrChecklistTab
                    tabSection={section}
                    isFiltered={state.isFiltered}
                    findingInvalidAnswers={state.findingInvalidAnswers}
                    currentQuestionTreePath={state.currentQuestion ? state.currentQuestion.treePath : null}
                  />
                </TabPanel>
              ))}
            </TabContext>
          </>
        ) : (
          <Grid container direction="column" spacing={3} sx={{ p: 3 }}>
            {[...Array(10)].map((x, index) => (
              <Grid item key={index} xs={12}>
                <Skeleton variant="rectangular" width="100%" height="3rem" />
              </Grid>
            ))}
          </Grid>
        )}
      </PrChecklistDispatchContext.Provider>

      <SearchChecklistDialog
        open={searchDialogOpen}
        handleClose={() => setSearchDialogOpen(false)}
        checklist={state.checklist}
        currentTabSectionId={parseInt(activeTabId)}
        navigateToQuestion={(question, tabSectionId) => {
          setActiveTabId(tabSectionId.toString());
          dispatch({ type: ChecklistActionType.FindQuestion, question: question, tabSectionId: tabSectionId });
        }}
      />

      {levelOfAdjustmentDialogOpen && (
        <LevelOfAdjustmentDialog
          onClose={() => setLevelOfAdjustmentDialogOpen(false)}
          levelOfAdjustment={props.levelOfAdjustment}
          practiceReviewId={props.practiceReviewId}
        />
      )}
    </div>
  );
};
