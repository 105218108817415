export default function asposeLinq(hljs: any) {
  return {
    name: "asposelinq",
    contains: [
      {
        className: "fieldTag",
        begin: /<<\[/,
        end: />>/,
        contains: [
          {
            className: "fieldFormatClause",
            begin: /:[^>-]+/
          }
        ]
      },
      {
        className: "docTag",
        begin: /<<doc \[/,
        end: />>/
      },
      {
        className: "varTag markupTag",
        begin: /<<var \[/,
        end: />>/
      },
      {
        className: "ifTag markupTag openTag",
        begin: /<<if \[/,
        end: />>/
      },
      {
        className: "ifTag markupTag elseTag",
        begin: /<<else/,
        end: />>/
      },
      {
        className: "ifTag markupTag closeTag",
        begin: /<<\/if/,
        end: />>/
      },
      {
        className: "foreachTag markupTag openTag",
        begin: /<<foreach \[/,
        end: />>/
      },
      {
        className: "foreachTag markupTag closeTag",
        begin: /<<\/foreach/,
        end: />>/
      }
    ]
  };
}
