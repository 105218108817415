import { gql } from "@apollo/client";

export const FetchCommitteeMembersQuery = gql`
  query FetchCommitteeMembers($includeInactive: Boolean, $includeDeleted: Boolean) {
    committeeMembers(includeInactive: $includeInactive, includeDeleted: $includeDeleted) {
      id
      name
      firstName
      lastName
      designation
      startDate
      endDate
      isChair
      isActive
      deleted
    }
  }
`;

export const AddCommitteeMemberMutation = gql`
  mutation AddCommitteeMember($member: CommitteeMemberInput!) {
    committeeMember {
      add(member: $member) {
        id
        name
        firstName
        lastName
        designation
        startDate
        endDate
        isChair
        isActive
      }
    }
  }
`;

export const EditCommitteeMemberMutation = gql`
  mutation EditCommitteeMember($member: CommitteeMemberInput!) {
    committeeMember {
      edit(member: $member) {
        id
        name
        firstName
        lastName
        designation
        startDate
        endDate
        isChair
        isActive
      }
    }
  }
`;

export const DeleteCommitteeMemberMutation = gql`
  mutation DeleteCommitteeMember($memberId: Int!) {
    committeeMember {
      delete(memberId: $memberId) {
        id
        name
        firstName
        lastName
        designation
        isChair
        isActive
      }
    }
  }
`;
