import React from "react";
import { Notification } from "./NotificationProvider";
import { ErrorResponse } from "@apollo/client/link/error";

interface Notifications {
  addNotification: (notification: Notification) => void;
  success: (message: string, timeOutInSeconds?: number) => void;
  warning: (message: string) => void;
  error: (message: any) => void;
  serverError: (serverError: Error) => void;
  info: (message: string, timeOutInSeconds?: number) => void;
  removeAll: () => void;
  handleApolloErrorResponse: (errorResponse: ErrorResponse) => void;
}

export const NotificationContext = React.createContext<Notifications>({
  addNotification: (notification: Notification) => {
    //no-op
  },
  success: (message: string, timeOutInSeconds?: number) => {
    //no-op
  },
  warning: (message: string) => {
    //no-op
  },
  error: (message: any) => {
    //no-op
  },
  serverError: (serverError: Error) => {
    // no-op
  },
  info: (message: string, timeOutInSeconds?: number) => {
    //no-op
  },
  removeAll: () => {
    //no-op
  },
  handleApolloErrorResponse: (errorResponse: ErrorResponse) => {
    //no-op
  }
});
