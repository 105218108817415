import React, { createContext, useState } from "react";
import { useQuery } from "@apollo/client";
import { PracticeReview, PRPhaseCode, ReviewClient } from "./models";
import { Grid, Tab, Tabs } from "@mui/material";
import { Skeleton } from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import { AppRoute } from "Routes";
import { EstimateTime } from "scheduling/estimate-time/EstimateTime";
import { SchedulingAndReviewers } from "scheduling/scheduling-reviewers/SchedulingAndReviewers";
import { TimeEntries } from "time-entry/TimeEntries";
import { FirmDetails } from "practice-reviews/FirmDetails";
import { ReportsAndQuestionnaires } from "practice-reviews/ReportsAndQuestionnaires";
import { FirmChecklist } from "checklists/FirmChecklist";
import { ReviewClients } from "practice-reviews/review-clients/ReviewClients";
import { Helmet } from "react-helmet";
import ActivityLog from "activities/ActivityLog";
import ReviewHistory from "./ReviewHistory";
import { DecisionTab } from "decisions/DecisionTab";
import { SummaryTab } from "./summary/SummaryTab";
import { PrScreenQuery } from "practice-reviews";
import { makeStyles } from "makeStyles";
import { useCurrentUser, Permissions } from "users";
import { AvailableMeetingsQuery, CommitteeMeeting, getReasonPrMeetingCannotBeChanged } from "../committee-meetings";
import { DecisionTypeCode } from "../decisions";
import { ReviewClientChecklists } from "../checklists/ReviewClientChecklists";
import { FirmResponseTab } from "../firm-response/FirmResponseTab";
import { PrScreenHeader, getPrScreenHeaderHeight } from "./PrScreenHeader";
import { ExemptionTab } from "../exemptions/ExemptionTab";
import { getMonitorDirectedPDInaccessibleReason } from "decisions/decisionLogic";
import { DirectedPdTab } from "./DirectedPdTab";
import { PrOverview } from "./PrOverview";

export const PracticeReviewContext = createContext<{
  practiceReview: PracticeReview | undefined;
}>({
  practiceReview: undefined
});

export enum PracticeReviewTabs {
  Overview = "overview",
  FirmDetails = "firm-details",
  Exemption = "exemption",
  History = "history",
  ActivityLog = "activity-log",
  EstimateTime = "estimate-time",
  SchedulingReviewers = "scheduling-reviewers",
  ReportsQuestionnaires = "reports-questionnaires",
  FirmChecklist = "firm-checklist",
  ClientFiles = "client-files",
  ClientChecklists = "client-checklists",
  Decision = "decision",
  DirectedPD = "directed-pd",
  TimeEntry = "time-entry",
  FirmResponse = "firm-response",
  Summary = "summary"
}

export function getRouteForPracticeReview(
  practiceReview: PracticeReview | null,
  tab?: PracticeReviewTabs,
  client?: ReviewClient,
  decisionTypeCode?: DecisionTypeCode
) {
  return practiceReview ? getRouteForPracticeReviewId(practiceReview.id, tab, client, decisionTypeCode) : "";
}

export function getRouteForPracticeReviewId(
  practiceReviewId: number,
  tab?: PracticeReviewTabs,
  reviewClient?: ReviewClient,
  decisionTypeCode?: DecisionTypeCode
) {
  if (tab === PracticeReviewTabs.Decision) {
    return AppRoute.PracticeReviewDecision.replace(":id", practiceReviewId.toString()).replace(
      "/:decisionTab?",
      decisionTypeCode ? `/${decisionTypeCode}` : ""
    );
  } else if (tab === PracticeReviewTabs.ClientChecklists) {
    return AppRoute.PracticeReviewClientChecklists.replace(":id", practiceReviewId.toString()).replace(
      "/:reviewClientId?",
      reviewClient?.id ? `/${reviewClient.id}` : ""
    );
  } else return AppRoute.PracticeReview.replace(":id", practiceReviewId.toString()).replace("/:tab?", tab ? `/${tab}` : "");
}

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    backgroundColor: theme.palette.common.white,
    "& .MuiTabPanel-root": {
      flexGrow: 1,
      height: "100%"
    }
  },
  content: {
    flexGrow: 1,
    display: "flex",
    alignItems: "stretch"
  },
  tabContent: {
    flexGrow: 1,
    borderLeft: `1px solid ${theme.palette.divider}`,
    minHeight: `calc(100vh - ${getPrScreenHeaderHeight(theme)})`
  },
  tabs: {
    alignSelf: "flex-start",
    position: "sticky",
    top: getPrScreenHeaderHeight(theme),
    left: 0,
    width: "11em",
    minHeight: `calc(100vh - ${getPrScreenHeaderHeight(theme)})`,
    backgroundColor: theme.palette.primary.main,
    flexShrink: 0,
    "& .MuiTabs-flexContainer": {
      alignItems: "stretch"
    },
    "& .MuiTab-root": {
      color: theme.palette.common.white,
      minWidth: "unset",
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      alignItems: "flex-start",
      textAlign: "left",
      "&:hover": {
        backgroundColor: theme.palette.primary.dark
      },
      "&.Mui-selected": {
        backgroundColor: theme.palette.primary.dark
      }
    },
    "& .MuiTabs-indicator": {
      display: "none"
    }
  },
  unpaddedTabPanel: {
    padding: 0
  }
}));

export const PracticeReviewScreen: React.FunctionComponent = () => {
  const { classes } = useStyles();
  const history = useHistory();
  const { userHasPermission, userIsLeadReviewer } = useCurrentUser();

  const routingParams = useParams<{ id: string; tab?: string; reviewClientId?: string; decisionTab?: DecisionTypeCode }>();
  const practiceReviewId = parseInt(routingParams.id);
  const reviewClientId = routingParams.reviewClientId ? parseInt(routingParams.reviewClientId) : undefined;

  const clientChecklistRouteMatch = useRouteMatch(AppRoute.PracticeReviewClientChecklists);
  const decisionRouteMatch = useRouteMatch(AppRoute.PracticeReviewDecision);
  let routeTab = clientChecklistRouteMatch
    ? PracticeReviewTabs.ClientChecklists
    : decisionRouteMatch
    ? PracticeReviewTabs.Decision
    : routingParams.tab;

  const [activeTab, setActiveTab] = useState(routeTab ?? PracticeReviewTabs.Overview);

  // Check route tab permissions
  if (routeTab === PracticeReviewTabs.SchedulingReviewers && !userHasPermission(Permissions.ViewSchedulingAndReviewers)) {
    routeTab = undefined;
  }

  if (routeTab !== undefined && activeTab !== routeTab) {
    // otherwise you can't switch from tab to tab by just pushing history, because the active tab already has a value...
    setActiveTab(() => routeTab as string);
  }

  const practiceReviewQuery = useQuery<{ practiceReviewById: PracticeReview }>(PrScreenQuery, {
    variables: {
      id: practiceReviewId
    }
  });
  const practiceReview = practiceReviewQuery.data?.practiceReviewById;

  const userHasPermissionToViewWholePr = userHasPermission(Permissions.PracticeReviewViewAll);
  const userIsMerelyNonLeadReviewer = !userIsLeadReviewer(practiceReview) && !userHasPermissionToViewWholePr;

  const directedPDTabInaccessibleReason = getMonitorDirectedPDInaccessibleReason(practiceReview);

  const reviewIsNotReadyToPerform = (practiceReview?.phase.id ?? 0) < PRPhaseCode.PerformPracticeReview;

  const availableMeetingsQuery = useQuery<{ availableMeetings: CommitteeMeeting[] }>(AvailableMeetingsQuery);
  const availableMeetings = availableMeetingsQuery.data?.availableMeetings ?? [];
  const reasonPrMeetingCannotBeChanged = getReasonPrMeetingCannotBeChanged(practiceReview, availableMeetings);

  return (
    <PracticeReviewContext.Provider
      value={{
        practiceReview: practiceReview
      }}
      key={practiceReview?.id}>
      <div className={classes.root}>
        <Helmet>
          <title>{practiceReview ? `PR ${practiceReview.prNumber} - PRS Online` : `Practice Review - PRS Online`}</title>
        </Helmet>

        {(practiceReviewQuery.loading && !practiceReviewQuery.data) || !practiceReview ? (
          <Grid container direction="column" spacing={3} sx={{ p: 3 }}>
            <Grid item xs={12}>
              <Skeleton variant="rectangular" width="100%" height="8rem" />
            </Grid>
            <Grid item xs={12}>
              <Skeleton variant="rectangular" width="100%" height="50rem" />
            </Grid>
          </Grid>
        ) : (
          <>
            <PrScreenHeader practiceReview={practiceReview} reasonPrMeetingCannotBeChanged={reasonPrMeetingCannotBeChanged} />
            <div className={classes.content}>
              <Tabs
                value={activeTab}
                indicatorColor="primary"
                textColor="primary"
                orientation="vertical"
                className={classes.tabs}
                onChange={(e, newTab) => {
                  setActiveTab(newTab);
                  history.push(getRouteForPracticeReview(practiceReview, newTab));
                }}>
                <Tab label="Overview" value={PracticeReviewTabs.Overview} />
                <Tab label="Firm Details" value={PracticeReviewTabs.FirmDetails} />
                {practiceReview.exemption && <Tab label="Exemption" value={PracticeReviewTabs.Exemption} />}
                <Tab label="History" value={PracticeReviewTabs.History} />
                <Tab label="Activity Log" value={PracticeReviewTabs.ActivityLog} />
                <Tab label="Estimate Time" value={PracticeReviewTabs.EstimateTime} />
                {userHasPermission(Permissions.ViewSchedulingAndReviewers) && (
                  <Tab label="Scheduling / Reviewers" value={PracticeReviewTabs.SchedulingReviewers} />
                )}
                <Tab label="Reports / Questionnaires" value={PracticeReviewTabs.ReportsQuestionnaires} />
                <Tab label="Summary" value={PracticeReviewTabs.Summary} disabled={reviewIsNotReadyToPerform} />
                {!userIsMerelyNonLeadReviewer && (
                  <Tab label="Firm Checklist" value={PracticeReviewTabs.FirmChecklist} disabled={reviewIsNotReadyToPerform} />
                )}
                <Tab label="Client Files" value={PracticeReviewTabs.ClientFiles} disabled={reviewIsNotReadyToPerform} />
                <Tab label="Client Checklists" value={PracticeReviewTabs.ClientChecklists} disabled={reviewIsNotReadyToPerform} />
                {!userIsMerelyNonLeadReviewer && (
                  <Tab label="Decision" value={PracticeReviewTabs.Decision} disabled={reviewIsNotReadyToPerform} />
                )}
                <Tab label="Time Entry" value={PracticeReviewTabs.TimeEntry} disabled={reviewIsNotReadyToPerform} />
                {!userIsMerelyNonLeadReviewer && (
                  <Tab label="Firm Response" value={PracticeReviewTabs.FirmResponse} disabled={reviewIsNotReadyToPerform} />
                )}
                {!Boolean(directedPDTabInaccessibleReason) && (
                  <Tab value={PracticeReviewTabs.DirectedPD} label={<span>Directed PD</span>} />
                )}
              </Tabs>
              <div className={classes.tabContent}>
                <TabContext value={activeTab}>
                  <TabPanel value={PracticeReviewTabs.Overview}>
                    <PrOverview practiceReview={practiceReview} reasonPrMeetingCannotBeChanged={reasonPrMeetingCannotBeChanged} />
                  </TabPanel>
                  <TabPanel value={PracticeReviewTabs.FirmDetails}>
                    <FirmDetails practiceReview={practiceReview} />
                  </TabPanel>
                  {practiceReview.exemption && (
                    <TabPanel value={PracticeReviewTabs.Exemption}>
                      <ExemptionTab practiceReview={practiceReview} />
                    </TabPanel>
                  )}
                  <TabPanel value={PracticeReviewTabs.History}>
                    <ReviewHistory practiceReview={practiceReview} />
                  </TabPanel>
                  <TabPanel value={PracticeReviewTabs.ActivityLog}>
                    <ActivityLog practiceReview={practiceReview} />
                  </TabPanel>
                  <TabPanel value={PracticeReviewTabs.EstimateTime}>
                    <EstimateTime practiceReview={practiceReview} />
                  </TabPanel>
                  <TabPanel value={PracticeReviewTabs.SchedulingReviewers}>
                    <SchedulingAndReviewers practiceReview={practiceReview} />
                  </TabPanel>
                  <TabPanel value={PracticeReviewTabs.Summary}>
                    <SummaryTab practiceReview={practiceReview} />
                  </TabPanel>
                  <TabPanel value={PracticeReviewTabs.ReportsQuestionnaires}>
                    <ReportsAndQuestionnaires practiceReview={practiceReview} />
                  </TabPanel>
                  <TabPanel value={PracticeReviewTabs.FirmChecklist} className={classes.unpaddedTabPanel}>
                    <FirmChecklist practiceReviewId={practiceReview.id} levelOfAdjustment={practiceReview.levelOfAdjustment} />
                  </TabPanel>
                  <TabPanel value={PracticeReviewTabs.ClientFiles} className={classes.unpaddedTabPanel}>
                    <ReviewClients practiceReview={practiceReview} />
                  </TabPanel>
                  <TabPanel value={PracticeReviewTabs.ClientChecklists} className={classes.unpaddedTabPanel}>
                    <ReviewClientChecklists practiceReview={practiceReview} reviewClientId={reviewClientId} />
                  </TabPanel>
                  <TabPanel value={PracticeReviewTabs.Decision} className={classes.unpaddedTabPanel}>
                    <DecisionTab
                      practiceReview={practiceReview}
                      subtab={routingParams.decisionTab}
                      setActiveDecisionTab={(newTab) =>
                        history.push(getRouteForPracticeReview(practiceReview, PracticeReviewTabs.Decision, undefined, newTab))
                      }
                    />
                  </TabPanel>
                  <TabPanel value={PracticeReviewTabs.TimeEntry}>
                    <TimeEntries practiceReview={practiceReview} />
                  </TabPanel>
                  <TabPanel value={PracticeReviewTabs.FirmResponse}>
                    <FirmResponseTab practiceReview={practiceReview} />
                  </TabPanel>
                  {!Boolean(directedPDTabInaccessibleReason) && (
                    <TabPanel value={PracticeReviewTabs.DirectedPD}>
                      <DirectedPdTab practiceReview={practiceReview} />
                    </TabPanel>
                  )}
                </TabContext>
              </div>
            </div>
          </>
        )}
      </div>
    </PracticeReviewContext.Provider>
  );
};
