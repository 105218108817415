import React, { useEffect } from "react";
import { ReviewClient } from "practice-reviews";
import { gql, useLazyQuery } from "@apollo/client";
import { PrChecklist } from "./PrChecklist";
import {
  ChecklistSectionFieldsFragment,
  QuestionHeaderFieldsFragment,
  QuestionFieldsFragment,
  AnswerFieldsFragment,
  QuestionContainerFieldsFragment
} from "./queries";
import { QuestionContainer } from "checklists";
import { LevelOfAdjustment } from "../level-of-adjustment";

const ReviewClientChecklistQuery = gql`
  ${QuestionContainerFieldsFragment}
  ${QuestionFieldsFragment}
  ${AnswerFieldsFragment}
  ${ChecklistSectionFieldsFragment}
  ${QuestionHeaderFieldsFragment}
  query FetchReviewClientChecklist($practiceReviewId: Int!, $engagementTypeId: Int!, $reviewClientId: Int!) {
    reviewClientChecklistQuestionContainers(practiceReviewId: $practiceReviewId, engagementTypeId: $engagementTypeId) {
      ...QuestionContainerFields
      questions {
        ...QuestionFields
        answer(reviewClientId: $reviewClientId) {
          ...AnswerFields
        }
      }
      ...ChecklistSectionFields
      ...QuestionHeaderFields
    }
  }
`;

interface Props {
  practiceReviewId: number;
  levelOfAdjustment: LevelOfAdjustment;
  reviewClient: ReviewClient;
  reviewerHasSubmittedReviewClients?: boolean;
  checklistActionsVerticalOffset?: number;
}

export const ReviewClientChecklist: React.FunctionComponent<Props> = (props) => {
  const [runChecklistQuery, checklistQuery] = useLazyQuery<
    { reviewClientChecklistQuestionContainers: QuestionContainer[] },
    { practiceReviewId: number; engagementTypeId: number; reviewClientId: number }
  >(ReviewClientChecklistQuery, {
    fetchPolicy: "network-only",
    variables: {
      practiceReviewId: props.practiceReviewId,
      engagementTypeId: props.reviewClient.engagementType.id,
      reviewClientId: props.reviewClient.id
    }
  });
  const checklistQuestionContainers = checklistQuery.data?.reviewClientChecklistQuestionContainers ?? [];

  useEffect(() => {
    const awaitedRunChecklistQuery = async () => await runChecklistQuery();
    awaitedRunChecklistQuery();
  }, [runChecklistQuery]);

  return (
    <PrChecklist
      checklistQuestionContainers={checklistQuestionContainers}
      practiceReviewId={props.practiceReviewId}
      levelOfAdjustment={props.levelOfAdjustment}
      reviewClientId={props.reviewClient.id}
      checklistLoading={checklistQuery.loading}
      reviewerHasSubmittedReviewClients={props.reviewerHasSubmittedReviewClients}
      showLevelOfAdjustment={false}
      checklistActionsVerticalOffset={props.checklistActionsVerticalOffset}
    />
  );
};
