import React, { memo, useState } from "react";
import { MasterChecklistNode, QuestionContainerTemplate, QuestionTemplate } from "checklists";
import { MasterChecklistActions } from "./MasterChecklistActions";
import { DialogContentText, Typography } from "@mui/material";

import { checklistStyles, checklistQuestionStyles, staticDataStyles } from "styles/common";

import EditMasterChecklistQuestionDialog from "./dialogs/EditMasterChecklistQuestionDialog";
import { ConfirmationDialog } from "common/ConfirmationDialog";
import gql from "graphql-tag";
import { MasterChecklistFieldsFragment } from "./queries";
import { useMutation } from "@apollo/client";
import { makeStyles } from "../makeStyles";

const DeleteQuestionMutation = gql`
  ${MasterChecklistFieldsFragment}
  mutation DeleteMasterChecklistQuestion($id: Int!) {
    masterChecklistQuestionContainers {
      deleteQuestion(id: $id) {
        ...MasterChecklistFields
      }
    }
  }
`;

const useStyles = makeStyles<Props>()((theme, props) => ({
  ...staticDataStyles(theme),
  ...checklistStyles(theme),
  ...checklistQuestionStyles(theme, props),
  hoveredQuestion: {
    backgroundColor: theme.palette.grey[100]
  },
  masterChecklistQuestionBlock: {
    paddingRight: "0 !important" // align with section controls
  }
}));

interface Props {
  question: QuestionTemplate;
  adornments?: React.ReactNode;
  siblings: MasterChecklistNode[];
}

const MasterChecklistQuestionDisplay: React.FunctionComponent<Props> = (props) => {
  const { classes, cx } = useStyles(props);

  const [deleteMutation, { loading }] = useMutation<
    { masterChecklistQuestionContainers: { delete: QuestionContainerTemplate } },
    { id: number }
  >(DeleteQuestionMutation);
  const [isHovered, setIsHovered] = useState(false);
  return (
    <div onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <div className={cx({ [classes.hoveredQuestion]: isHovered })}>
        <div className={cx(classes.questionBlock, classes.questionCounter, classes.masterChecklistQuestionBlock)}>
          <div className={classes.questionTop}>
            <div className={cx(classes.numberedItemText, classes.numberedQuestionText)}>
              <Typography variant="body1" className={classes.questionTextBody}>
                {props.question.text}
              </Typography>
            </div>
            <div className={classes.cpaRef}>
              <Typography variant="body1">{props.question.cpaRef}</Typography>
            </div>
            <MasterChecklistActions
              node={props.question}
              hidden={!isHovered}
              siblings={props.siblings}
              renderEditDialog={(node, dialogProps) => (
                <EditMasterChecklistQuestionDialog
                  title="Edit Question"
                  confirmButtonText="Save"
                  question={node as QuestionTemplate}
                  handleClose={dialogProps.onClose}
                />
              )}
              renderDeleteDialog={(node, dialogProps) => (
                <ConfirmationDialog
                  title="Delete Question?"
                  body={<DialogContentText>Are you sure you want to delete this question?</DialogContentText>}
                  cancel={dialogProps.onClose}
                  open={true}
                  confirm={() => deleteMutation({ variables: { id: node.id } })}
                  loading={loading}
                />
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const MemoizedMasterChecklistQuestionDisplay = memo(MasterChecklistQuestionDisplay);
export { MemoizedMasterChecklistQuestionDisplay as MasterChecklistQuestionDisplay };
