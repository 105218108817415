import React, { Dispatch } from "react";
import { Button, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import { useNotifications } from "notifications/useNotifications";
import { gql, useMutation } from "@apollo/client";
import ClosableDialogTitle from "../common/ClosableDialogTitle";
import { LoadingButton } from "@mui/lab";

export const ResetTestDataDialog = (props: { isOpen: boolean; setIsOpen: Dispatch<boolean> }) => {
  const [resetTestDataMutate, resetTestDataMutation] = useMutation<{ resetTestData: string }>(gql`
    mutation {
      resetTestData
    }
  `);
  const { success } = useNotifications();

  return (
    <Dialog
      fullWidth
      open={props.isOpen}
      onClose={() => {
        props.setIsOpen(false);
      }}
      maxWidth="sm">
      <ClosableDialogTitle onClose={() => props.setIsOpen(false)}>Test Data Reset</ClosableDialogTitle>
      <DialogContent>
        <Typography>
          This will return all the test PRs to the beginning of the scheduling workflow. Do you want to reset the test data?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            props.setIsOpen(false);
          }}
          disabled={resetTestDataMutation.loading}>
          Cancel
        </Button>
        <LoadingButton
          onClick={async () => {
            const result = await resetTestDataMutate();
            if (result.data?.resetTestData === "success") {
              success("Successfully reset test data. Refresh the page.");
            }
            props.setIsOpen(false);
          }}
          loading={resetTestDataMutation.loading}
          color="warning"
          variant="contained">
          Reset
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
