import { useMutation, useQuery, useApolloClient } from "@apollo/client";
import { ConfirmationDialog } from "common/ConfirmationDialog";
import gql from "graphql-tag";
import React, { useCallback } from "react";
import CrudTable from "../common/CrudTable";
import EditAdjustmentQuestionTemplateDialog from "./EditAdjustmentQuestionTemplateDialog";
import _ from "lodash";
import { AdjustmentQuestionTemplate } from "./models";
import { Helmet } from "react-helmet";
import { DialogContentText, IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons/faArrowDown";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons/faArrowUp";
import { ScreenHeader } from "../common/ScreenHeader";

enum Directions {
  Up = -1,
  Down = 1
}

export const AdjustmentQuestionTemplatesQuery = gql`
  query FetchAdjustmentQuestionTemplates {
    adjustmentQuestionTemplates {
      id
      questionText
      sortOrder
    }
  }
`;

const DeleteAdjustmentQuestionTemplateMutation = gql`
  mutation DeleteAdjustmentQuestionTemplate($id: Int) {
    adjustmentQuestionTemplates {
      delete(id: $id) {
        id
        questionText
        sortOrder
      }
    }
  }
`;

const ReorderAdjustmentQuestionTemplateMutation = gql`
  mutation ReorderAdjustmentQuestionTemplates($templates: [SortAdjustmentQuestionTemplateInput]) {
    adjustmentQuestionTemplates {
      reorder(templates: $templates)
    }
  }
`;

const AdjustmentQuestionTemplatesScreen: React.FunctionComponent = () => {
  const apolloClient = useApolloClient();
  const [deleteMutation, { loading: deleting }] = useMutation<{ adjustmentQuestionTemplates: { delete: boolean } }, { id: number }>(
    DeleteAdjustmentQuestionTemplateMutation,
    {
      refetchQueries: [{ query: AdjustmentQuestionTemplatesQuery }]
    }
  );
  const [reorderMutation] = useMutation<{ adjustmentQuestionTemplates: { reorder: boolean } }, { templates: AdjustmentQuestionTemplate[] }>(
    ReorderAdjustmentQuestionTemplateMutation
  );

  const debouncedReorderMutation = useCallback(_.debounce(reorderMutation, 3000), [reorderMutation]);

  const query = useQuery<{ adjustmentQuestionTemplates: AdjustmentQuestionTemplate[] }>(AdjustmentQuestionTemplatesQuery);
  const adjustmentQuestionTemplates = [...(query.data?.adjustmentQuestionTemplates ?? [])].sort((a, b) => a.sortOrder! - b.sortOrder!);

  function moveTemplate(templateToMove: AdjustmentQuestionTemplate, direction: Directions) {
    const templates = [...adjustmentQuestionTemplates];
    const rowIndex = templates.findIndex((t) => t.id === templateToMove.id);

    const siblingNode = templates[rowIndex + direction];
    templates[rowIndex] = siblingNode;
    templates[rowIndex + direction] = templateToMove;

    const updated = templates.map((current, index) => {
      const template = { id: current.id, sortOrder: index };
      apolloClient.cache.modify({
        id: apolloClient.cache.identify(current as any),
        fields: {
          sortOrder: () => index
        }
      });
      return template;
    });
    debouncedReorderMutation({ variables: { templates: updated } });
  }

  return (
    <>
      <Helmet>
        <title>Adjustment Question Templates - PRS Online</title>
      </Helmet>
      <ScreenHeader title="Adjustment Question Templates" />
      <CrudTable
        title=""
        rows={adjustmentQuestionTemplates}
        loading={query.loading}
        columnDefinitions={[
          {
            field: "questionText",
            headerName: "Question Text",
            flex: 100
          },
          {
            field: "moveUp",
            headerName: " ",
            renderCell: (params) => {
              const template = params.row as AdjustmentQuestionTemplate;
              return template === _.minBy(adjustmentQuestionTemplates, (t) => t.sortOrder) ? null : (
                <IconButton color="primary" onClick={() => moveTemplate(template, Directions.Up)}>
                  <FontAwesomeIcon icon={faArrowUp} />
                </IconButton>
              );
            }
          },
          {
            field: "moveDown",
            headerName: " ",
            renderCell: (params) => {
              const template = params.row as AdjustmentQuestionTemplate;
              return template === _.maxBy(adjustmentQuestionTemplates, (t) => t.sortOrder) ? null : (
                <IconButton color="primary" onClick={() => moveTemplate(template, Directions.Down)}>
                  <FontAwesomeIcon icon={faArrowDown} />
                </IconButton>
              );
            }
          }
        ]}
        storageKey={`Adjustment Question Templates`}
        noDataMessage="No templates created"
        renderAddDialog={(props) => (
          <EditAdjustmentQuestionTemplateDialog
            title="Add Adjustment Question Template"
            confirmButtonText="Add"
            handleClose={props.onClose}
          />
        )}
        renderEditDialog={(id, props) => (
          <EditAdjustmentQuestionTemplateDialog
            title="Add Adjustment Question Template"
            confirmButtonText="Save"
            handleClose={props.onClose}
            data={adjustmentQuestionTemplates.find((t) => t.id === id)}
          />
        )}
        renderDeleteDialog={(id, props) => {
          const template = adjustmentQuestionTemplates.find((t) => t.id === id);
          return (
            <ConfirmationDialog
              open={true}
              title="Delete Adjustment Question Template?"
              body={
                <>
                  <DialogContentText>Are you sure you want to delete the Adjustment Question Template:</DialogContentText>
                  <DialogContentText>{`${template!.questionText!.slice(0, 100)}${
                    template!.questionText!.length > 100 ? "..." : ""
                  }?`}</DialogContentText>
                </>
              }
              noDanger={false}
              confirm={() => deleteMutation({ variables: { id: id } }).then(props.onClose)}
              cancel={props.onClose}
              loading={deleting}
            />
          );
        }}
      />
    </>
  );
};

export default AdjustmentQuestionTemplatesScreen;
