import { useMutation, useQuery } from "@apollo/client";
import { DialogContentText } from "@mui/material";
import { ConfirmationDialog } from "common/ConfirmationDialog";
import { ScreenHeader } from "common/ScreenHeader";
import gql from "graphql-tag";
import React from "react";
import { Helmet } from "react-helmet";
import CrudTable from "../common/CrudTable";
import EditTimeConversionFormulaDialog from "./EditTimeConversionDialog";
import { TimeConversionFormula } from "./models";

export const TimeConversionFormulasQuery = gql`
  query FetchTimeFormulas {
    timeConversionFormulas {
      id
      adminHoursToAdd
      minimumHoursForRow
    }
  }
`;

const DeleteTimeConversionFormulaMutation = gql`
  mutation DeleteTimeConversionFormula($id: Int) {
    timeConversionFormulas {
      delete(id: $id) {
        id
        adminHoursToAdd
        minimumHoursForRow
      }
    }
  }
`;

const TimeConversionFormulasScreen: React.FunctionComponent = () => {
  const [deleteMutation, { loading: deleting }] = useMutation<{ timeFormulas: { delete: TimeConversionFormula } }, { id: number }>(
    DeleteTimeConversionFormulaMutation,
    {
      refetchQueries: [{ query: TimeConversionFormulasQuery }]
    }
  );
  const query = useQuery<{ timeConversionFormulas: TimeConversionFormula[] }>(TimeConversionFormulasQuery);
  const formulas = query.data?.timeConversionFormulas ?? [];

  return (
    <>
      <Helmet>
        <title>Administrative Time Conversion Formulas - PRS Online</title>
      </Helmet>
      <ScreenHeader title="Administrative Time Conversion Formulas" />
      <CrudTable
        rows={formulas}
        columnDefinitions={[
          {
            field: "minimumHoursForRow",
            headerName: "Minimum PR Hours Entered",
            flex: 20
          },
          {
            field: "adminHoursToAdd",
            headerName: "Admin Hours to Add",
            flex: 100
          }
        ]}
        storageKey={`Admin Time Formulas`}
        noDataMessage="No formulas created"
        sortModel={[
          {
            field: "minimumHoursForRow",
            sort: "asc"
          }
        ]}
        renderAddDialog={(props) => (
          <EditTimeConversionFormulaDialog
            title="Add Administrative Time Conversion Formula"
            confirmButtonText="Add"
            handleClose={props.onClose}
            existingMinimumHours={formulas.map((f) => f.minimumHoursForRow)}
          />
        )}
        renderEditDialog={(id, props) => (
          <EditTimeConversionFormulaDialog
            title="Edit Administrative Time Conversion Formula"
            confirmButtonText="Save"
            handleClose={props.onClose}
            data={formulas.find((f) => f.id === id)}
            existingMinimumHours={formulas.map((f) => (f.id === id ? -1 : f.minimumHoursForRow))}
          />
        )}
        renderDeleteDialog={(id, props) => (
          <ConfirmationDialog
            open={true}
            title="Delete Administrative Time Conversion Formula?"
            body={
              <DialogContentText>
                {`Are you sure you want to delete the formula for ${formulas.find((f) => f.id === id)?.minimumHoursForRow} minimum hours?`}
              </DialogContentText>
            }
            noDanger={false}
            confirm={() => deleteMutation({ variables: { id } }).then(props.onClose)}
            cancel={props.onClose}
            loading={deleting}
          />
        )}
      />
    </>
  );
};

export default TimeConversionFormulasScreen;
