import React, { memo, useMemo } from "react";
import { Theme } from "@mui/material";
import { Answer, isQuestion, Question, QuestionContainer, ChecklistCompletion } from "checklists";
import { makeStyles } from "../makeStyles";

export function calculateCompletion(questionContainer: QuestionContainer): ChecklistCompletion {
  const completion: ChecklistCompletion = {
    someQuestionsIncomplete: false,
    someQuestionsComplete: false,
    reportableDeficienciesExist: false,
    nonReportableDeficienciesExist: false,
    empty: false
  };

  if ((questionContainer?.children?.length ?? 0) === 0) return { ...completion, empty: true };

  const sectionQuestions = questionContainer.children.filter((c) => isQuestion(c)).map((c) => c as Question);

  for (let question of sectionQuestions) {
    if (!answerIsComplete(question.answer)) completion.someQuestionsIncomplete = true;
    else {
      completion.someQuestionsComplete = true;
    }

    if (question.answer?.isReportable) {
      completion.reportableDeficienciesExist = true;
    }

    if (question.answer?.isNonReportable) {
      completion.nonReportableDeficienciesExist = true;
    }
  }

  const questionContainerChildren = questionContainer.children.filter((c) => !isQuestion(c)).map((c) => c as QuestionContainer);

  for (let questionContainerChild of questionContainerChildren) {
    const childCompletion = calculateCompletion(questionContainerChild);
    if (childCompletion.someQuestionsIncomplete) completion.someQuestionsIncomplete = true;
    if (childCompletion.someQuestionsComplete) completion.someQuestionsComplete = true;
    if (childCompletion.reportableDeficienciesExist) completion.reportableDeficienciesExist = true;
    if (childCompletion.nonReportableDeficienciesExist) completion.nonReportableDeficienciesExist = true;
  }

  return completion;
}
function answerIsComplete(answer?: Answer) {
  if (!answer || answer.invalid) return false;

  return answer.isYes || answer.isReportable || answer.isNonReportable || answer.isNa;
}

const useStyles = makeStyles<Props>()((theme: Theme) => {
  const size = "0.8rem";

  return {
    marker: {
      width: size,
      height: size,
      border: `1px solid ${theme.palette.common.black}`,
      transform: "rotate(45deg)"
    },
    complete: {
      backgroundColor: theme.palette.success.main
    },
    completeWithReportableDeficiencies: {
      backgroundColor: theme.palette.error.main
    },
    completeWithNonReportableDeficiencies: {
      backgroundColor: theme.palette.warning.main
    },
    partial: {
      backgroundImage: `linear-gradient(45deg, ${theme.palette.success.main}, ${theme.palette.success.main} 50%, ${theme.palette.common.white} 50%, ${theme.palette.common.white})`
    },
    partialWithReportableDeficiencies: {
      backgroundImage: `linear-gradient(45deg, ${theme.palette.error.main}, ${theme.palette.error.main} 50%, ${theme.palette.common.white} 50%, ${theme.palette.common.white})`
    },
    partialWithNonReportableDeficiencies: {
      backgroundImage: `linear-gradient(45deg, ${theme.palette.warning.main}, ${theme.palette.warning.main} 50%, ${theme.palette.common.white} 50%, ${theme.palette.common.white})`
    }
  };
});

interface Props {
  section?: QuestionContainer;
  completion?: ChecklistCompletion;
  className?: string;
}

const CompletionMarker: React.FunctionComponent<Props> = (props) => {
  const { classes, cx } = useStyles(props);

  if (!props.section && !props.completion) throw new Error("Either a section or a completion object must be supplied.");

  const sectionCompletion = useMemo(() => props.completion || calculateCompletion(props.section!), [props.section, props.completion]);

  return (
    <div
      className={cx(classes.marker, props.className, {
        [classes.complete]:
          sectionCompletion.empty ||
          (!sectionCompletion.someQuestionsIncomplete &&
            sectionCompletion.someQuestionsComplete &&
            !sectionCompletion.reportableDeficienciesExist),
        [classes.completeWithReportableDeficiencies]:
          !sectionCompletion.someQuestionsIncomplete &&
          sectionCompletion.someQuestionsComplete &&
          sectionCompletion.reportableDeficienciesExist,
        [classes.completeWithNonReportableDeficiencies]:
          !sectionCompletion.someQuestionsIncomplete &&
          sectionCompletion.someQuestionsComplete &&
          sectionCompletion.nonReportableDeficienciesExist &&
          !sectionCompletion.reportableDeficienciesExist,
        [classes.partial]:
          sectionCompletion.someQuestionsIncomplete &&
          sectionCompletion.someQuestionsComplete &&
          !sectionCompletion.reportableDeficienciesExist,
        [classes.partialWithReportableDeficiencies]:
          sectionCompletion.someQuestionsIncomplete &&
          sectionCompletion.someQuestionsComplete &&
          sectionCompletion.reportableDeficienciesExist,
        [classes.partialWithNonReportableDeficiencies]:
          sectionCompletion.someQuestionsIncomplete &&
          sectionCompletion.someQuestionsComplete &&
          sectionCompletion.nonReportableDeficienciesExist &&
          !sectionCompletion.reportableDeficienciesExist
      })}></div>
  );
};

const MemoizedCompletionMarker = memo(CompletionMarker);
export { MemoizedCompletionMarker as CompletionMarker };
