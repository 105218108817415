import { Button, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";

import { useNotifications } from "notifications";
import React from "react";
import ClosableDialogTitle from "common/ClosableDialogTitle";
import { useMutation } from "@apollo/client";
import { SaveEstimateMutation } from "scheduling";
import { PREstimate } from "practice-reviews";
import { useUnsavedChanges } from "../../UnsavedChangesProvider";
import { LoadingButton } from "@mui/lab";
import { makeStyles } from "makeStyles";

const useStyles = makeStyles()((theme) => ({
  dialogContent: {
    "& > :not(:first-child)": {
      marginTop: theme.spacing(2)
    }
  }
}));

interface Props {
  practiceReviewId: number;
  systemEstimate: number;
  overrideEstimate: number | null;
  notes: string | null;
  open: boolean;
  handleClose: () => void;
  changeKey: string;
}

const FinalizeEstimateDialog: React.FunctionComponent<Props> = (props) => {
  const { classes } = useStyles();
  const notifications = useNotifications();
  const { changesSaved } = useUnsavedChanges();

  const [saveMutate, saveMutation] = useMutation<
    { estimate: { save: PREstimate } },
    { practiceReviewId: number; overrideEstimate: number | null; notes: string | null; finalize: boolean }
  >(SaveEstimateMutation, {
    onCompleted: (result) => {
      if (result?.estimate?.save?.id) {
        notifications.success("Estimate finalized.");
        changesSaved(props.changeKey);
      }

      props.handleClose();
    }
  });

  return (
    <Dialog open={props.open} onClose={props.handleClose} fullWidth={true} scroll="paper">
      <ClosableDialogTitle onClose={props.handleClose}>Finalize estimate?</ClosableDialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography variant="body1">
          {props.overrideEstimate
            ? `The system estimate is ${props.systemEstimate} hours and has been overridden to ${props.overrideEstimate} hours.`
            : `The system estimate is ${props.systemEstimate} hours.`}
        </Typography>
        <Typography variant="body1">
          Do you want to finalize the estimate, complete the Estimate Time INAs, and create Schedule Review INAs for this review?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} disabled={saveMutation.loading}>
          Cancel
        </Button>
        <LoadingButton
          color="primary"
          variant="contained"
          loading={saveMutation.loading}
          onClick={() =>
            saveMutate({
              variables: {
                practiceReviewId: props.practiceReviewId,
                overrideEstimate: props.overrideEstimate,
                notes: props.notes,
                finalize: true
              }
            })
          }>
          Finalize
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default FinalizeEstimateDialog;
