import React, { useEffect, useState } from "react";
import { makeStyles } from "../makeStyles";
import { DocType, PracticeReview, RemoveAttachedDocumentFromPracticeReviewMutation } from "practice-reviews";
import { Box, Button, DialogContentText, Link, Stack, TextField, Typography } from "@mui/material";
import StackedStaticDataDisplay from "../common/StackedStaticDataDisplay";
import { formatDate } from "../util/formats";
import { useUnsavedChanges } from "../UnsavedChangesProvider";
import { useApolloClient, useMutation } from "@apollo/client";
import {
  ApproveExemptionLetterMutation,
  ConfirmExemptionMutation,
  Exemption,
  ExemptionStatus,
  ReleaseExemptionsForApprovalMutation,
  SaveExemptionNotesMutation
} from ".";
import { LoadingButton, Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from "@mui/lab";
import { useNotifications } from "notifications";
import { MicrosoftWordLink } from "common/MicrosoftWordLink";
import { documentListStyles } from "styles/common";
import { AxiosResponse } from "axios";
import { useAppConfig } from "../util/AppConfig";
import { useAxios } from "../auth/SecureAxios";
import { ConfirmationDialog } from "../common/ConfirmationDialog";
import { optionalScreenWidthLimit } from "styles/theme";
import { DateTime } from "luxon";
import { EndExemptionDialog } from "./EndExemptionDialog";
import { useCurrentUser, Permissions } from "../users";
import { getOpenableUrl } from "../util/utilities";

const useStyles = makeStyles()((theme) => ({
  ...documentListStyles(theme),
  signedLetterControls: {
    "& .removeButton": {
      visibility: "hidden"
    },
    "&:hover .removeButton": {
      visibility: "visible"
    }
  }
}));

interface Props {
  practiceReview: PracticeReview;
}

export const ExemptionTab: React.FunctionComponent<Props> = (props) => {
  const { classes } = useStyles();
  const appConfig = useAppConfig();
  const notifications = useNotifications();
  const { unsavedChanges, changesSaved } = useUnsavedChanges();
  const apolloClient = useApolloClient();
  const { secureAxios } = useAxios();
  const { userHasPermission } = useCurrentUser();

  const exemption = props.practiceReview.exemption!;

  const [notes, setNotes] = useState("");
  useEffect(() => {
    setNotes(exemption.notes ?? "");
  }, [props.practiceReview.exemption!.notes]);

  const [attachingSignedLetter, setAttachingSignedLetter] = useState(false);
  const [removingSignedLetter, setRemovingSignedLetter] = useState(false);
  const [confirmingEndExemption, setConfirmingEndExemption] = useState(false);

  const [saveMutate, saveMutation] = useMutation<{ exemption: { saveNotes: Exemption } }, { exemptionId: number; notes: string | null }>(
    SaveExemptionNotesMutation
  );

  async function save() {
    const normalizedNotes = notes.trim() === "" ? null : notes.trim();

    const result = await saveMutate({
      variables: {
        exemptionId: exemption.id,
        notes: normalizedNotes
      }
    });

    if (result.data?.exemption.saveNotes?.id) {
      notifications.success("Saved exemption.");
    }
  }

  const [releaseLetterForApprovalMutate, releaseLetterForApprovalMutation] = useMutation<
    { exemption: { releaseLettersForApproval: Exemption[] } },
    { exemptionIds: number[] }
  >(ReleaseExemptionsForApprovalMutation);

  async function releaseLetterForApproval() {
    const result = await releaseLetterForApprovalMutate({
      variables: {
        exemptionIds: [exemption.id]
      }
    });

    if ((result.data?.exemption.releaseLettersForApproval?.length ?? 0) > 0) {
      notifications.success("Released exemption for approval.");
    }
  }

  const [approveLetterMutate, approveLetterMutation] = useMutation<{ exemption: { approveLetter: Exemption } }, { exemptionId: number }>(
    ApproveExemptionLetterMutation
  );

  async function approveLetter() {
    const result = await approveLetterMutate({
      variables: {
        exemptionId: exemption.id
      }
    });

    if (result.data?.exemption.approveLetter?.id) {
      notifications.success("Approved exemption letter.");
    }
  }

  async function signedLetterAttached(files: FileList) {
    setAttachingSignedLetter(true);

    const formData = new FormData();
    formData.append("PracticeReviewId", props.practiceReview.id.toString());
    formData.append("DocumentTypeCode", DocType.SignedExemptionLetter);
    const file = files[0];
    formData.append("Documents", file);
    let extension = file?.name?.split(".").pop();
    if (extension === file?.name) {
      extension = "";
    }
    formData.append("FileName", `Signed Exemption Letter ${DateTime.now().toFormat("yyyy-MM-dd")}.${extension}`);

    let postResult: AxiosResponse<PracticeReview>;
    const postRequestEndpoint = `${appConfig.apiEndpoint}/api/practice-review-document/upload`;
    try {
      postResult = await secureAxios.post(postRequestEndpoint, formData, {});
      if (postResult === undefined || postResult?.status === 401) {
        postResult = await secureAxios.post(postRequestEndpoint, formData, {});
      }
    } catch (e: any) {
      setAttachingSignedLetter(false);
      notifications.serverError(e.message);
      return;
    }

    if (postResult?.status !== 200) {
      notifications.serverError(new Error(postResult?.statusText));
    } else {
      const updatedPr: PracticeReview = postResult.data;
      updatePracticeReviewInCache(updatedPr);

      notifications.success(`Attached document.`);
    }

    setAttachingSignedLetter(false);
  }

  function updatePracticeReviewInCache(updatedPr: PracticeReview) {
    const cacheId = `PracticeReview:${updatedPr.id}`;

    apolloClient.cache.modify({
      id: cacheId,
      fields: {
        signedExemptionLetterUrl() {
          return updatedPr.signedExemptionLetterUrl;
        },
        attachedDocuments() {
          return updatedPr.attachedDocuments.map((ad) => ({ ...ad, __typename: "AttachedDocument" }));
        }
      }
    });
  }

  const [removeSignedLetterMutate, removeSignedLetterMutation] = useMutation<
    { practiceReview: { removeAttachedDocument: PracticeReview } },
    { practiceReviewId: number; attachedDocumentId: number; documentType: DocType }
  >(RemoveAttachedDocumentFromPracticeReviewMutation);

  async function removeSignedLetter() {
    const signedLetterDocument = props.practiceReview.attachedDocuments.filter((ad) => ad.type === DocType.SignedExemptionLetter)[0];
    const result = await removeSignedLetterMutate({
      variables: {
        practiceReviewId: props.practiceReview.id,
        attachedDocumentId: signedLetterDocument.id,
        documentType: DocType.SignedExemptionLetter
      }
    });

    if (result.data?.practiceReview.removeAttachedDocument?.id) {
      updatePracticeReviewInCache(result.data.practiceReview.removeAttachedDocument);
      notifications.success("Removed signed letter.");
    }

    setRemovingSignedLetter(false);
  }

  const [confirmExemptionMutate, confirmExemptionMutation] = useMutation<{ exemption: { confirm: Exemption } }, { exemptionId: number }>(
    ConfirmExemptionMutation
  );

  async function confirmExemption() {
    const result = await confirmExemptionMutate({
      variables: {
        exemptionId: exemption.id
      }
    });

    if (result.data?.exemption.confirm?.id) {
      notifications.success("Exemption confirmed.");
    }
  }

  return (
    <Stack spacing={5} sx={{ maxWidth: optionalScreenWidthLimit }}>
      <Stack direction="row" spacing={3} alignItems="flex-start">
        <StackedStaticDataDisplay
          label="Exemption Status"
          value={exemption.status === ExemptionStatus.PendingRenewal ? "Pending Renewal" : exemption.status.toString()}
        />
        <Box sx={{ flexGrow: 1 }} />
        {exemption.status !== ExemptionStatus.Ended && userHasPermission(Permissions.ExemptionConfirm) && (
          <Button variant="outlined" size="small" onClick={() => setConfirmingEndExemption(true)}>
            End Exemption
          </Button>
        )}
      </Stack>

      {exemption.status !== ExemptionStatus.Ended && (
        <Stack direction="row" spacing={10} alignItems="flex-start">
          <Timeline
            position="right"
            sx={{
              margin: 0,
              padding: 0,
              flex: 0,
              flexBasis: "20em",
              "& .MuiTimelineItem-root": {
                "::before": { display: "none" },
                "&:last-child": {
                  minHeight: "unset"
                }
              }
            }}>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color={exemption.letterIsReviewed ? "primary" : undefined} />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>{exemption.letterIsReviewed ? "Exemption Letter Reviewed" : "Review Exemption Letter"}</TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color={exemption.letterIsApproved ? "primary" : undefined} />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>{exemption.letterIsApproved ? "Exemption Letter Approved" : "Approve Exemption Letter"}</TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color={exemption.exemptionLetterAcknowledged ? "primary" : undefined} />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                {exemption.exemptionLetterAcknowledged ? "Exemption Letter Acknowledged" : "Waiting for Exemption Acknowledgement"}
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color={exemption.status === ExemptionStatus.Exempt ? "primary" : undefined} />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>{exemption.status === ExemptionStatus.Exempt ? "Confirmed Exemption" : "Confirm Exemption"}</TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color={exemption.status === ExemptionStatus.Exempt ? "secondary" : undefined} />
              </TimelineSeparator>
              <TimelineContent>Exempted</TimelineContent>
            </TimelineItem>
          </Timeline>

          <Stack spacing={2} sx={{ alignSelf: "stretch" }}>
            {props.practiceReview.exemptionLetterUrl && (
              <Stack direction="row" spacing={2} alignItems="center">
                <MicrosoftWordLink href={props.practiceReview.exemptionLetterUrl}>Exemption Letter</MicrosoftWordLink>

                {!exemption.letterIsReviewed && userHasPermission(Permissions.ExemptionReviewLetter) && (
                  <LoadingButton
                    variant="outlined"
                    color="primary"
                    loading={releaseLetterForApprovalMutation.loading}
                    onClick={() => releaseLetterForApproval()}>
                    Release for Approval
                  </LoadingButton>
                )}

                {exemption.letterIsReviewed && !exemption.letterIsApproved && userHasPermission(Permissions.ExemptionConfirm) && (
                  <LoadingButton variant="outlined" color="primary" loading={approveLetterMutation.loading} onClick={() => approveLetter()}>
                    Approve
                  </LoadingButton>
                )}
              </Stack>
            )}

            {exemption.letterIsApproved && (
              <Stack direction="row" spacing={2} alignItems="center" className={classes.signedLetterControls}>
                {!props.practiceReview.signedExemptionLetterUrl ? (
                  <Typography variant="body1" className={classes.missingForm}>
                    Signed Exemption Letter
                  </Typography>
                ) : (
                  <Link href={getOpenableUrl(props.practiceReview.signedExemptionLetterUrl)} target="_blank">
                    <Typography variant="body1">Signed Exemption Letter</Typography>
                  </Link>
                )}

                {!exemption.exemptionLetterAcknowledged && userHasPermission(Permissions.ExemptionConfirm) && (
                  <LoadingButton
                    variant="outlined"
                    size="small"
                    component="label"
                    loading={attachingSignedLetter}
                    disabled={attachingSignedLetter}>
                    Attach
                    <input type="file" hidden accept="*/*" onChange={(e) => signedLetterAttached(e.target.files!)} />
                  </LoadingButton>
                )}

                {exemption.exemptionLetterAcknowledged &&
                  (exemption.status === ExemptionStatus.Pending || exemption.status === ExemptionStatus.PendingRenewal) &&
                  userHasPermission(Permissions.ExemptionConfirm) && (
                    <LoadingButton
                      variant="outlined"
                      size="small"
                      color="error"
                      onClick={() => setRemovingSignedLetter(true)}
                      disabled={attachingSignedLetter || removeSignedLetterMutation.loading}
                      className="removeButton">
                      Remove
                    </LoadingButton>
                  )}
              </Stack>
            )}

            {props.practiceReview.signedExemptionLetterUrl &&
              (exemption.status === ExemptionStatus.Pending || exemption.status === ExemptionStatus.PendingRenewal) &&
              userHasPermission(Permissions.ExemptionConfirm) && (
                <LoadingButton
                  variant="contained"
                  color="primary"
                  size="small"
                  component="label"
                  loading={confirmExemptionMutation.loading}
                  onClick={() => confirmExemption()}>
                  Confirm Exemption
                </LoadingButton>
              )}

            <Box sx={{ flexGrow: 1 }} />

            <Link href={props.practiceReview.sharePointUrl} target="_blank">
              <Typography variant="h3">View all documents in SharePoint</Typography>
            </Link>
          </Stack>
        </Stack>
      )}

      <StackedStaticDataDisplay
        label="Started"
        value={
          exemption.startedOn
            ? `${formatDate(exemption.startedOn)}${
                exemption.approvedByUser ? ` (approved by ${exemption.approvedByUser?.name ?? "unknown"})` : ""
              }`
            : "--"
        }
      />

      <Stack direction="row" spacing={5} alignItems="flex-end">
        <StackedStaticDataDisplay
          sx={{ width: "20em" }}
          label="Ended"
          value={exemption.endedOn ? `${formatDate(exemption.endedOn)} (by ${exemption.endedByUser?.name ?? "unknown"})` : "--"}
        />

        {exemption.status === ExemptionStatus.Ended && (
          <StackedStaticDataDisplay label="Ended Reason" value={exemption.endedReason ?? "--"} />
        )}
      </Stack>

      {userHasPermission(Permissions.ExemptionReviewLetter) ? (
        <TextField
          label="Notes"
          multiline
          fullWidth
          margin="none"
          value={notes}
          onChange={(e) => {
            setNotes(e.target.value);
            unsavedChanges();
          }}
        />
      ) : (
        <StackedStaticDataDisplay label="Notes" value={exemption.notes ?? "--"} />
      )}

      {userHasPermission(Permissions.ExemptionReviewLetter) && (
        <Stack direction="row" justifyContent="flex-end">
          <LoadingButton
            variant="outlined"
            color="primary"
            loading={saveMutation.loading}
            onClick={async () => {
              await save();
              changesSaved();
            }}>
            Save
          </LoadingButton>
        </Stack>
      )}

      {removingSignedLetter && (
        <ConfirmationDialog
          open={true}
          body={<DialogContentText>Do you want to remove the signed exemption letter?</DialogContentText>}
          title="Remove file?"
          cancel={() => setRemovingSignedLetter(true)}
          confirm={() => removeSignedLetter()}
          loading={removeSignedLetterMutation.loading}
        />
      )}

      {confirmingEndExemption && (
        <EndExemptionDialog practiceReview={props.practiceReview} onClose={() => setConfirmingEndExemption(false)} />
      )}
    </Stack>
  );
};
