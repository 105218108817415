import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import React from "react";
import EditRetentionPolicyDialog from "./EditRetentionPolicyDialog";
import CrudTable from "../common/CrudTable";
import { RetentionPolicy } from "./models";
import { Helmet } from "react-helmet";
import { ScreenHeader } from "../common/ScreenHeader";

export const GetRetentionPolicyQuery = gql`
  query RetentionPolicyQuery {
    retentionPolicy {
      id
      dataItemName
      expirationMarginInMonths
    }
  }
`;

const RetentionPoliciesScreen: React.FunctionComponent = () => {
  const query = useQuery<{ retentionPolicy: RetentionPolicy[] }>(GetRetentionPolicyQuery);
  const policies = query.data?.retentionPolicy || [];

  return (
    <>
      <Helmet>
        <title>Retention Policies - PRS Online</title>
      </Helmet>
      <ScreenHeader title="Retention Policies" />
      <CrudTable
        rows={policies}
        loading={query.loading}
        columnDefinitions={[
          {
            field: "dataItemName",
            headerName: "Data Item Name",
            flex: 1,
            sortable: true
          },
          {
            field: "expirationMarginInMonths",
            headerName: "Expiration Margin In Months",
            flex: 1
          }
        ]}
        storageKey={`Retention Policies`}
        noDataMessage="No policies created"
        renderEditDialog={(id, props) => (
          <EditRetentionPolicyDialog
            title="Edit Retention Policy"
            confirmButtonText="Save"
            handleClose={props.onClose}
            data={query.data?.retentionPolicy?.find((r) => r.id === id)!}
          />
        )}
        sortModel={[
          {
            field: "dataItemName",
            sort: "asc"
          }
        ]}
      />
    </>
  );
};

export default RetentionPoliciesScreen;
