import { InteractionRequiredAuthError, InteractionType } from "@azure/msal-browser";
import { useAccount, useIsAuthenticated, useMsal, useMsalAuthentication } from "@azure/msal-react";
import { useAppConfig } from "util/AppConfig";

import React, { useCallback, useMemo } from "react";
import { useNotifications } from "notifications";
import { LoadingScreen } from "chrome/LoadingScreen";
import { useAppInsights } from "util/AppInsightsProvider";
import { SeverityLevel } from "@microsoft/applicationinsights-web";

interface Authentication {
  getAccessToken: () => Promise<string | null>;
  handleRedirectPromise: any;
  authenticated: boolean;
}

const AuthenticationContext = React.createContext<Authentication>({
  getAccessToken: () => Promise.resolve(null),
  handleRedirectPromise: null,
  authenticated: false
});

export const useAuthentication = () => React.useContext(AuthenticationContext);

export const AuthenticationProvider: React.FunctionComponent = (props) => {
  const notifications = useNotifications();
  const appInsights = useAppInsights();

  let { azureAdScopes } = useAppConfig();

  const { error } = useMsalAuthentication(InteractionType.Redirect);
  if (error) notifications.error(error.errorMessage);

  if (!azureAdScopes) notifications.error("API authentication scopes not found.");

  const { accounts, instance: msalInstance } = useMsal();

  const account = useAccount(accounts && accounts.length > 0 ? accounts[0] : {});

  const getAccessToken = useCallback(async () => {
    if (!account) return null;

    try {
      const tokenResponse = await msalInstance.acquireTokenSilent({ scopes: azureAdScopes!, account: account });
      return tokenResponse?.accessToken ?? null;
    } catch (err: any) {
      if (err instanceof InteractionRequiredAuthError) {
        const popupTokenRequest = {
          scopes: azureAdScopes!,
          account: account
        };
        await msalInstance.acquireTokenPopup(popupTokenRequest);
        return null;
      } else {
        console.error(err);
        appInsights.trackTrace({ message: err?.toString ? err.toString() : err, severityLevel: SeverityLevel.Error });
        return null;
      }
    }
  }, [account, msalInstance, azureAdScopes]);

  const authenticated = useIsAuthenticated();

  const contextValue = useMemo(
    () => ({
      getAccessToken,
      handleRedirectPromise: msalInstance.handleRedirectPromise,
      authenticated
    }),
    [getAccessToken, msalInstance, authenticated]
  );

  return authenticated ? (
    <AuthenticationContext.Provider value={contextValue}>{props.children}</AuthenticationContext.Provider>
  ) : (
    <LoadingScreen mode="auth" />
  );
};
