import React, { memo } from "react";
import { Collapse, IconButton, Typography } from "@mui/material";
import { expanderStyles, getCounterStyle, checklistIndentSize, sectionHeaderPadding, checklistStyles } from "styles/common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons/faChevronDown";
import { ChecklistSection } from "checklists";
import { ChecklistSectionTemplate } from "./models";
import { makeStyles } from "../makeStyles";

const useStyles = makeStyles<Props>()((theme, props: Props) => {
  return {
    ...checklistStyles(theme),
    ...expanderStyles(theme),
    numberedSectionText: {
      "&::before": {
        content: `counter(section-${props.section.parentId}, ${getCounterStyle(props.section.depth)}) "."`
      }
    },
    sectionCounter: {
      counterReset: `section-${props.section.id}`,
      counterIncrement: `section-${props.section.parentId}`
    },
    sectionHeaderContainer: {
      display: "flex",
      alignItems: "flex-start",
      cursor: "pointer",
      marginBottom: theme.spacing(1),
      padding: sectionHeaderPadding,
      paddingRight: 0,
      width: "100%",
      "&:hover": {
        backgroundColor: theme.palette.grey[100]
      }
    },
    expandedSection: {
      marginBottom: theme.spacing(1)
    },
    collapsedSection: {
      marginBottom: theme.spacing(3)
    },
    sectionExpander: {
      marginRight: theme.spacing(0.5),
      fontSize: "0.875rem",
      width: "20px", // Make the button the same width at all font sizes
      flexShrink: 0
    },
    outerSectionExpander: {
      fontSize: "1rem"
    },
    indentSection: {
      paddingLeft: `calc(${checklistIndentSize} * ${props.section.depth - 1})`
    },
    sectionText: {
      lineHeight: 1.35,
      fontWeight: 600,
      flexGrow: 1
    },
    outerSectionText: {
      color: theme.palette.primary.main,
      fontFamily: theme.typography.h3.fontFamily,
      fontSize: theme.typography.h3.fontSize
    }
  };
});

interface Props {
  section: ChecklistSection | ChecklistSectionTemplate;
  children: React.ReactNode;
  adornments?: React.ReactNode;
  expanded: boolean;
  isFiltered?: boolean;
  toggleExpanded?: (section: ChecklistSection | ChecklistSectionTemplate) => void;
}

const ChecklistSectionDisplay: React.FunctionComponent<Props> = (props) => {
  const { classes, cx } = useStyles(props);

  return props.section.hidden ? (
    <div className={classes.sectionCounter} />
  ) : (
    <div
      className={cx(classes.sectionCounter, {
        [classes.collapsedSection]: !props.expanded,
        [classes.expandedSection]: props.expanded
      })}>
      <div className={cx(classes.sectionHeaderContainer, { [classes.indentSection]: props.section.depth > 1 })}>
        {!props.isFiltered && (
          <IconButton
            size="small"
            className={cx(classes.sectionExpander, { [classes.outerSectionExpander]: props.section.depth === 1 })}
            onClick={() => props.toggleExpanded?.(props.section)}>
            <FontAwesomeIcon icon={faChevronDown} className={cx(classes.expandedIcon, { rotated: props.expanded })} />
          </IconButton>
        )}

        <Typography
          variant="body1"
          className={cx(classes.sectionText, classes.numberedItemText, {
            [classes.outerSectionText]: props.section.depth === 1,
            [classes.numberedSectionText]: props.section.depth > 1
          })}
          onClick={() => props.toggleExpanded?.(props.section)}>
          {props.section.text}
        </Typography>
        <div className={classes.adornments}>{props.adornments}</div>
      </div>
      <Collapse in={props.isFiltered || props.expanded} timeout="auto" unmountOnExit>
        {props.children}
      </Collapse>
    </div>
  );
};

const MemoizedChecklistSectionDisplay = memo(ChecklistSectionDisplay);
export { MemoizedChecklistSectionDisplay as ChecklistSectionDisplay };
