import React from "react";
import { PracticeReview } from "practice-reviews";

import { TimeEntriesHeader } from "./TimeEntriesHeader";
import { TimeEntriesTable } from "./TimeEntriesTable";
import { makeStyles } from "../makeStyles";

interface Props {
  practiceReview: PracticeReview;
}

export const TimeEntries: React.FunctionComponent<Props> = (props) => {
  const useStyles = makeStyles()((theme) => ({
    root: {
      "& > :not(:first-child)": {
        marginTop: theme.spacing(3)
      }
    }
  }));

  const { classes } = useStyles();
  const practiceReview = props.practiceReview;
  return (
    <div className={classes.root}>
      <TimeEntriesHeader practiceReview={practiceReview} />
      <TimeEntriesTable practiceReview={practiceReview} />
    </div>
  );
};
