import { GridValueFormatterParams } from "@mui/x-data-grid-pro";
import { DateTime, Settings } from "luxon";

export const standardDateFormat = "MMM d, yyyy";
export const standardDateTimeFormat = "MMM d, yyyy h:mm a";
export const standardTimeFormat = "h:mm a";
export const monthOfYearLongFormat = "MMMM yyyy";

Settings.defaultLocale = "en-US";

export const formatDate = (date: string | null) => {
  return date !== null ? DateTime.fromISO(date).toFormat(standardDateFormat) : "--";
};

export const formatDateTime = (date?: string | null) => {
  return date !== null && date !== undefined ? DateTime.fromISO(date).toFormat(standardDateTimeFormat) : "--";
};

export const formatTime = (date?: string | null) => {
  return date !== null && date !== undefined ? DateTime.fromISO(date).toFormat(standardTimeFormat) : "--";
};

export const dataGridDateValueFormatter = (params: GridValueFormatterParams) => (params.value ? formatDate(params.value as string) : "--");

export const formatNumber = (number: number) => {
  return Intl.NumberFormat("en-CA").format(number);
};

export const formatCurrency = (amount: number | null, showCents?: boolean) =>
  amount !== null
    ? Intl.NumberFormat("en-CA", {
        style: "currency",
        currency: "CAD",
        maximumFractionDigits: showCents ? 2 : 0,
        currencySign: "accounting"
      }).format(amount)
    : null;
