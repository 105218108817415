import React, { createContext, useContext } from "react";
import { DateTime } from "luxon";
import { DateTime as BusinessDaysDateTime } from "luxon-business-days";
import { LoadingScreen } from "chrome/LoadingScreen";
import { useQuery, gql } from "@apollo/client";
import LuxonDateAdapter from "@mui/lab/AdapterLuxon";
import { LocalizationProvider } from "@mui/lab";
import { StatutoryHoliday } from "../stat-holidays";

type BusinessDaysDateTime = typeof BusinessDaysDateTime;

export const CalendarProviderContext = createContext<{
  holidays: StatutoryHoliday[];
  configureForBusinessDaysMath: (dateTime: DateTime) => BusinessDaysDateTime;
}>({ holidays: [], configureForBusinessDaysMath: (dateTime) => BusinessDaysDateTime.fromISO(dateTime.toISO()) });

// Luxon does not natively support a way to change the starting day of a week so we have to create a custom wrapper and extended class to overcome this limitation
// Adapted code from: https://github.com/mui-org/material-ui-pickers/issues/1626

interface Day {
  index: number;
  name: string;
  dayName: string;
}

class DataWrapper {
  default: Day[] = [
    {
      index: 0,
      name: "Sun",
      dayName: "S"
    },
    {
      index: 1,
      name: "Mon",
      dayName: "M"
    },
    {
      index: 2,
      name: "Tue",
      dayName: "T"
    },
    {
      index: 3,
      name: "Wed",
      dayName: "W"
    },
    {
      index: 4,
      name: "Thu",
      dayName: "T"
    },
    {
      index: 5,
      name: "Fri",
      dayName: "F"
    },
    {
      index: 6,
      name: "Sat",
      dayName: "S"
    }
  ];
  dayNames = ["S", "M", "T", "W", "T", "F", "S"];

  processedDayNames: string[] = [];

  startDay: Day | undefined;

  setStartDay(dayName: string) {
    const startDayArray = this.default.filter((day) => dayName === day.name);
    this.startDay = startDayArray[0];
  }

  processWeekDayOrder() {
    const days = [...this.dayNames];
    const remainingDays = days.splice(0, this.startDay!.index);
    this.processedDayNames = days.concat(remainingDays);
    return this.processedDayNames;
  }
}

class CustomLuxonDateAdapter extends LuxonDateAdapter {
  wrapper: DataWrapper;

  constructor() {
    super();
    this.wrapper = new DataWrapper();
    this.wrapper.setStartDay("Sun");
  }

  getWeekdays = () => {
    return this.wrapper.processWeekDayOrder();
  };

  getWeekArray = (date: DateTime) => {
    const index = this.wrapper.startDay!.index;
    const endDate = date
      .endOf("month")
      // if a month ends on sunday, luxon will consider it already the end of the week
      // but we need to get the _entire_ next week to properly lay that out
      // so we add one more day to cover that before getting the end of the week
      .plus({ days: index !== 0 ? this.wrapper.dayNames.length + 1 - index : 1 })
      .endOf("week");
    const startDate = date
      .startOf("month")
      .startOf("week")
      // -1 day since we want a start of Sunday instead of Monday
      .minus({ days: index !== 0 ? this.wrapper.dayNames.length + 1 - index : 1 });

    const { days } = endDate.diff(startDate, "days").toObject();

    const weeks: DateTime[][] = [];
    new Array(Math.round(days!))
      .fill(0)
      .map((_, i) => i)
      .map((day) => startDate.plus({ days: day }))
      .forEach((v, i) => {
        if (i === 0 || (i % 7 === 0 && i > 6)) {
          weeks.push([v]);
          return;
        }

        weeks[weeks.length - 1].push(v);
      });

    return weeks.filter((w) => w.some((d) => d.month === date.month));
  };
}

// End of adapted code

export const CalendarProvider = (props: { children: React.ReactNode }) => {
  const { data, loading } = useQuery<{ statutoryHolidays: { id: number; holidayDate: string; holidayName: string }[] }>(gql`
    query StatutoryHolidays {
      statutoryHolidays {
        id
        holidayDate
        holidayName
      }
    }
  `);

  if (loading) {
    return <LoadingScreen mode="init" />;
  } else if (data && data.statutoryHolidays) {
    return (
      <CalendarProviderContext.Provider
        value={{
          holidays: data.statutoryHolidays,
          configureForBusinessDaysMath: (dateTime) => {
            const convertedDate = BusinessDaysDateTime.fromISO(dateTime.toISO());
            convertedDate.setupBusiness({
              holidayMatchers: [
                (dt: BusinessDaysDateTime) => {
                  const dateIsHoliday = data.statutoryHolidays.some((sh) =>
                    BusinessDaysDateTime.fromISO(sh.holidayDate).startOf("day").equals(dt.startOf("day"))
                  );
                  return dateIsHoliday;
                }
              ]
            });
            return convertedDate;
          }
        }}>
        <LocalizationProvider dateAdapter={CustomLuxonDateAdapter}>{props.children}</LocalizationProvider>
      </CalendarProviderContext.Provider>
    );
  } else {
    return <LoadingScreen mode="init" />;
  }
};

export function useCalendar() {
  const context = useContext(CalendarProviderContext);

  return {
    ...context
  };
}
