import { Button, Dialog, DialogActions, DialogContent, Grid } from "@mui/material";
import ClosableDialogTitle from "common/ClosableDialogTitle";
import { gql, useMutation } from "@apollo/client";
import * as Yup from "yup";
import { Field as FormikField, Form as FormikForm, Formik } from "formik";
import { TextField as FmuiTextField } from "formik-mui";
import { SystemEstimatedTimeRule } from "./models";
import { GetSystemEstimatedTimeRulesQuery } from "./SystemEstimatedTimeRulesScreen";
import { Validations } from "common/validations/common-yup-validations";
import { LoadingButton } from "@mui/lab";
import _ from "lodash";
import StackedStaticDataDisplay from "../common/StackedStaticDataDisplay";

const EditSystemEstimatedTimeRuleMutation = gql`
  mutation UpdateSystemEstimatedTimeRule($estimate: SystemEstimatedTimeRuleInput) {
    systemEstimatedTimeRules {
      edit(systemEstimatedTimeRule: $estimate) {
        minimumPartnersForRule
        approvedToSign_DidntAudit_DidntReview
        audited_Reviewed
        audited_DidntReview
        didntAudit_Reviewed
        overallCompilation
        overallQam
        overallTimeToCompileReport
      }
    }
  }
`;

const AddSystemEstimatedTimeRuleMutation = gql`
  mutation UpdateSystemEstimatedTimeRule($estimate: SystemEstimatedTimeRuleInput) {
    systemEstimatedTimeRules {
      add(systemEstimatedTimeRule: $estimate) {
        minimumPartnersForRule
        approvedToSign_DidntAudit_DidntReview
        audited_Reviewed
        audited_DidntReview
        didntAudit_Reviewed
        overallCompilation
        overallQam
        overallTimeToCompileReport
      }
    }
  }
`;

interface Props {
  handleClose: () => void;
  title: string;
  confirmButtonText: string;
  rule?: SystemEstimatedTimeRule;
  existingRules: number[];
}

const EditSystemEstimatedTimeRuleDialog = (props: Props) => {
  const validationSchema = Yup.object().shape({
    minimumPartnersForRule: Validations.requiredNonNegativeNumber().notOneOf(props.existingRules, "Rule already exists."),
    approvedToSign_DidntAudit_DidntReview: Validations.requiredNonNegativeNumber(),
    audited_Reviewed: Validations.requiredNonNegativeNumber(),
    audited_DidntReview: Validations.requiredNonNegativeNumber(),
    didntAudit_Reviewed: Validations.requiredNonNegativeNumber(),
    overallCompilation: Validations.requiredNonNegativeNumber(),
    overallQam: Validations.requiredNonNegativeNumber(),
    overallTimeToCompileReport: Validations.requiredNonNegativeNumber()
  });

  const [addMutate, addMutation] = useMutation<
    { systemEstimatedTimeRules: { add: SystemEstimatedTimeRule } },
    { estimate: SystemEstimatedTimeRule }
  >(AddSystemEstimatedTimeRuleMutation, {
    refetchQueries: [{ query: GetSystemEstimatedTimeRulesQuery }]
  });

  const [editMutate, editMutation] = useMutation<
    { systemEstimatedTimeRules: { edit: SystemEstimatedTimeRule } },
    { estimate: SystemEstimatedTimeRule }
  >(EditSystemEstimatedTimeRuleMutation);

  const editMode = props.rule?.minimumPartnersForRule;

  return (
    <Dialog open={true} onClose={props.handleClose} fullWidth={true} scroll="paper" maxWidth="sm">
      <ClosableDialogTitle onClose={props.handleClose}>{props.title}</ClosableDialogTitle>
      <Formik
        initialValues={
          props.rule ?? {
            minimumPartnersForRule: (_.max(props.existingRules) ?? 0) + 1,
            approvedToSign_DidntAudit_DidntReview: 0,
            audited_Reviewed: 0,
            audited_DidntReview: 0,
            didntAudit_Reviewed: 0,
            overallCompilation: 0,
            overallQam: 0,
            overallTimeToCompileReport: 0
          }
        }
        onSubmit={async (values) => {
          var estimate: SystemEstimatedTimeRule = {
            ...values
          };

          if (editMode) {
            await editMutate({
              variables: { estimate }
            });
          } else {
            await addMutate({
              variables: { estimate }
            });
          }

          props.handleClose();
        }}
        validationSchema={validationSchema}>
        {(formikProps) => (
          <FormikForm>
            <DialogContent>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  {editMode ? (
                    <>
                      <StackedStaticDataDisplay label="Minimum Partners for Rule" value={`${props.rule?.minimumPartnersForRule}`} />
                    </>
                  ) : (
                    <FormikField
                      type="number"
                      inputProps={{ min: 0 }}
                      component={FmuiTextField}
                      name="minimumPartnersForRule"
                      label="Minimum Partners for Rule"
                      fullWidth
                    />
                  )}
                </Grid>
                <Grid item>
                  <FormikField
                    type="number"
                    inputProps={{ min: 0 }}
                    component={FmuiTextField}
                    name="approvedToSign_DidntAudit_DidntReview"
                    label="For Each Partner With Signing Approval Who Did Not Sign Anything"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item>
                  <FormikField
                    type="number"
                    inputProps={{ min: 0 }}
                    component={FmuiTextField}
                    name="audited_Reviewed"
                    label="For Each Partner Who Signed A Review AND an Audit"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item>
                  <FormikField
                    type="number"
                    inputProps={{ min: 0 }}
                    component={FmuiTextField}
                    name="audited_DidntReview"
                    label="For Each Partner Who Signed an Audit but NOT a Review"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item>
                  <FormikField
                    type="number"
                    inputProps={{ min: 0 }}
                    component={FmuiTextField}
                    name="didntAudit_Reviewed"
                    label="For Each Partner Who Signed a Review but NOT an Audit"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item>
                  <FormikField
                    type="number"
                    inputProps={{ min: 0 }}
                    component={FmuiTextField}
                    name="overallCompilation"
                    label="Add for Compilation"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item>
                  <FormikField
                    inputProps={{ min: 0 }}
                    type="number"
                    component={FmuiTextField}
                    name="overallQam"
                    label="Add for QAM"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item>
                  <FormikField
                    type="number"
                    inputProps={{ min: 0 }}
                    component={FmuiTextField}
                    name="overallTimeToCompileReport"
                    label="Add for Compiling Report and Reviewing With Firm"
                    fullWidth
                    required
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={props.handleClose}>Cancel</Button>
              <LoadingButton
                color="primary"
                variant="contained"
                loading={addMutation.loading || editMutation.loading}
                onClick={() => formikProps.submitForm()}>
                {props.confirmButtonText}
              </LoadingButton>
            </DialogActions>
          </FormikForm>
        )}
      </Formik>
    </Dialog>
  );
};

export default EditSystemEstimatedTimeRuleDialog;
