import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import ClosableDialogTitle from "common/ClosableDialogTitle";
import { gql, useApolloClient, useMutation } from "@apollo/client";
import { ChecklistSectionTemplate } from "checklists/models";
import { ReorderMasterChecklistInput } from "../models";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import { LoadingButton } from "@mui/lab";
import { makeStyles } from "../../makeStyles";
import { CSSObject } from "tss-react";

const ReorderChecklistMutation = gql`
  mutation ReorderMasterChecklist($containers: [ReorderMasterChecklistInput], $questions: [ReorderQuestionTemplateInput]) {
    masterChecklistQuestionContainers {
      reorder(containers: $containers, questions: $questions)
    }
  }
`;

const useStyles = makeStyles()((theme) => ({
  // some of this styling was stolen from the material ui source here:
  // https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/Tab/Tab.js
  // to make these draggable items look like material tabs
  draggableItem: {
    ...(theme.typography.button as CSSObject),
    userSelect: "none",
    padding: theme.spacing(2),
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: 1.5
  },
  draggableList: {
    overflowY: "scroll",
    maxHeight: "400px",
    borderBottom: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius
  },
  instructions: {
    marginBottom: theme.spacing(2)
  }
}));

interface Props {
  handleClose: () => void;
  tabs: ChecklistSectionTemplate[];
}

const EditTabOrderDialog: React.FunctionComponent<Props> = (props) => {
  /* Reordering uses react-beautiful-dnd. Much of this code is taken from the examples in the documentation:
   * https://github.com/atlassian/react-beautiful-dnd/blob/master/docs/about/examples.md
   */
  const apolloClient = useApolloClient();
  const { classes, theme } = useStyles();

  const [tabs, setTabs] = useState<ChecklistSectionTemplate[]>([]);

  const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    background: isDragging ? theme.palette.grey[100] : theme.palette.common.white,
    ...draggableStyle
  });

  const [reorderMutation, { loading }] = useMutation<
    { masterChecklistQuestionContainers: { reorder: boolean } },
    { containers: ReorderMasterChecklistInput[]; questions: ReorderMasterChecklistInput[] }
  >(ReorderChecklistMutation);

  useEffect(() => {
    setTabs(props.tabs);
  }, [props.tabs]);

  const reorder = (list: ChecklistSectionTemplate[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result.map((item, index) => {
      item.sortOrder = index;
      return item;
    });
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const reorderedItems = reorder(tabs, result.source.index, result.destination.index);
    setTabs(reorderedItems);
  };

  const submitNewTabsOrder = async () => {
    const updatedContainers = tabs.map((item) => {
      return { id: item.id, sortOrder: item.sortOrder };
    });

    await reorderMutation({ variables: { containers: updatedContainers, questions: [] } });

    updateApolloCache();
    props.handleClose();
  };

  const updateApolloCache = () => {
    tabs.forEach((item) => {
      apolloClient.cache.modify({
        id: apolloClient.cache.identify(item as any),
        fields: {
          sortOrder: () => item.sortOrder
        }
      });
    });
  };

  return (
    <Dialog open={true} onClose={props.handleClose} fullWidth={true} scroll="paper" maxWidth="sm">
      <ClosableDialogTitle onClose={props.handleClose}>Edit Tab Order</ClosableDialogTitle>
      <DialogContent>
        <Typography className={classes.instructions}>Drag and drop the items below to reorder the tabs.</Typography>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div className={classes.draggableList} {...provided.droppableProps} ref={provided.innerRef}>
                {tabs.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                    {(provided, snapshot) => (
                      <div
                        className={classes.draggableItem}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
                        {item.shortDescription}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose}>Cancel</Button>
        <LoadingButton
          color="primary"
          variant="contained"
          loading={loading}
          onClick={() => {
            submitNewTabsOrder();
          }}>
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default EditTabOrderDialog;
