import {
  Checkbox,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { datagridStyles, staticDataStyles } from "styles/common";
import ClosableDialogTitle from "common/ClosableDialogTitle";
import { Checklist, isQuestion, isSection, Question, QuestionContainer } from "./models";
import _ from "lodash";
import Highlighter from "react-highlight-words";
import { makeStyles } from "../makeStyles";
import { DataGridPro, GridRowModel } from "@mui/x-data-grid-pro";

enum SearchField {
  Any,
  Question,
  CpaRef,
  StandardParagraph,
  Recommendation
}

interface SearchResult {
  question: Question;
  tabSectionId: number;
  standardParagraphId: number;
  tabLocation: string;
  location: string;
  questionText: string;
  cpaRef: string | null;
  standardParagraphText: string;
  recommendationText: string;
  weight?: number;
}

const useStyles = makeStyles<Props>()((theme) => ({
  ...staticDataStyles(theme),
  ...datagridStyles(theme, 40),
  searchDialogContent: {
    paddingTop: 0
  },
  searchCriteria: {
    position: "sticky",
    top: 0,
    backgroundColor: theme.palette.common.white,
    zIndex: 1,
    display: "flex",
    alignItems: "center",
    paddingBottom: theme.spacing(3),
    "& > *": {
      flexShrink: 0
    },
    "& > :not(:first-child)": {
      marginLeft: theme.spacing(1)
    }
  },
  fieldSelector: {
    width: "16em"
  },
  searchText: {
    flexGrow: 1,
    flexShrink: 1
  },
  wrappedText: {
    lineHeight: 1.2
  },
  searchResultsGrid: {
    "& .MuiDataGrid-cell": {
      alignItems: "flex-start",
      whiteSpace: "normal",
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1)
    },
    "& .MuiDataGrid-row": {
      cursor: "pointer"
    }
  },
  highlightedSearchTerm: {
    backgroundColor: theme.palette.highlight
  }
}));

interface Props {
  open: boolean;
  handleClose: () => void;
  checklist: Checklist;
  currentTabSectionId: number;
  navigateToQuestion: (question: Question, tabSectionId: number) => void;
}

const SearchChecklistDialog: React.FunctionComponent<Props> = (props) => {
  const { classes, cx } = useStyles(props);

  const [selectedSearchField, setSelectedSearchField] = useState(SearchField.Any);
  const [searchText, setSearchText] = useState("");
  const [debouncedSearchText, setDebouncedSearchText] = useState("");
  const [includeAllTabs, setIncludeAllTabs] = useState(false);
  const [searchResults, setSearchResults] = useState<{ results: SearchResult[]; resultsOnOtherTabs: number }>({
    results: [],
    resultsOnOtherTabs: 0
  });

  const searchQuestions = useMemo(() => {
    return props.checklist.tabSections.flatMap((tabSection) => getQuestions(tabSection, "", tabSection));
  }, [props.checklist]);

  function getQuestions(questionContainer: QuestionContainer, location: string, tabSection: QuestionContainer): SearchResult[] {
    const updatedLocation = questionContainer.depth > 0 ? `${location.length > 0 ? `${location}, ` : ""}${questionContainer.text}` : "";

    const searchQuestionsForContainer = questionContainer.children
      .filter((c) => isQuestion(c))
      .flatMap((child) => {
        const question = child as Question;

        return question.standardParagraphs.length > 0
          ? question.standardParagraphs.map(
              (standardParagraph) =>
                ({
                  question: question,
                  tabSectionId: tabSection.id,
                  standardParagraphId: standardParagraph.id,
                  location: updatedLocation,
                  tabLocation: isSection(tabSection) ? tabSection.shortDescription : tabSection.text,
                  questionText: question.text,
                  cpaRef: question.cpaRef,
                  standardParagraphText: standardParagraph.text,
                  recommendationText: standardParagraph.recommendation
                } as SearchResult)
            )
          : ({
              question: question,
              tabSectionId: tabSection.id,
              standardParagraphId: 0,
              location: updatedLocation,
              tabLocation: isSection(tabSection) ? tabSection.shortDescription : tabSection.text,
              questionText: question.text,
              cpaRef: question.cpaRef,
              standardParagraphText: "",
              recommendationText: ""
            } as SearchResult);
      });

    const searchQuestionsForChildren = isSection(questionContainer)
      ? questionContainer.children
          .filter((child) => !isQuestion(child))
          .flatMap((child) => getQuestions(child as QuestionContainer, updatedLocation, tabSection))
      : [];

    return searchQuestionsForContainer.concat(searchQuestionsForChildren);
  }

  const search = useCallback(() => {
    if (searchText.length < 3) return [];

    const questionResults =
      selectedSearchField === SearchField.Any || selectedSearchField === SearchField.Question
        ? searchQuestions
            .filter((sq) => sq.questionText.toLowerCase().indexOf(searchText.toLowerCase()) !== -1)
            .map((sr) => ({ ...sr, weight: 2 }))
        : [];

    const cpaRefResults =
      selectedSearchField === SearchField.Any || selectedSearchField === SearchField.CpaRef
        ? searchQuestions
            .filter((sq) => (sq.cpaRef ?? "").toLowerCase().indexOf(searchText.toLowerCase()) !== -1)
            .map((sr) => ({ ...sr, weight: 0.1 }))
        : [];

    const standardParagraphResults =
      selectedSearchField === SearchField.Any || selectedSearchField === SearchField.StandardParagraph
        ? searchQuestions
            .filter((sq) => sq.standardParagraphText.toLowerCase().indexOf(searchText.toLowerCase()) !== -1)
            .map((sr) => ({ ...sr, weight: 1 }))
        : [];

    const recommendationResults =
      selectedSearchField === SearchField.Any || selectedSearchField === SearchField.Recommendation
        ? searchQuestions
            .filter((sq) => sq.recommendationText.toLowerCase().indexOf(searchText.toLowerCase()) !== -1)
            .map((sr) => ({ ...sr, weight: 1 }))
        : [];

    const unifiedResults = [...questionResults, ...cpaRefResults, ...standardParagraphResults, ...recommendationResults];
    const countFromOtherTabs = unifiedResults.filter((sr) => sr.tabSectionId !== props.currentTabSectionId).length;
    const resultsToShow = includeAllTabs ? unifiedResults : unifiedResults.filter((sr) => sr.tabSectionId === props.currentTabSectionId);

    const resultsByQuestionAndStandardParagraph = _.groupBy(resultsToShow, (sr) => `${sr.question.id}-${sr.standardParagraphId}`);

    const resultsByWeight = _.map(resultsByQuestionAndStandardParagraph, (questionResultsGroup) => ({
      ...questionResultsGroup[0],
      weight: _.sum(questionResultsGroup.map((qr) => qr.weight))
    }));

    const sortedResults = _.orderBy(resultsByWeight, (result) => result.weight, "desc");

    setSearchResults({ results: sortedResults, resultsOnOtherTabs: countFromOtherTabs });
  }, [searchText, selectedSearchField, includeAllTabs, searchQuestions]);

  const setDebouncedSearchTextCallback = useCallback((text) => _.debounce(() => setDebouncedSearchText(text), 300), []);
  useEffect(() => setDebouncedSearchTextCallback(searchText), [searchText]);

  useEffect(() => {
    search();
  }, [debouncedSearchText, selectedSearchField, includeAllTabs]);

  const searchResultCell = (text: string, truncate: boolean) => (
    <div className={classes.wrappedText}>
      <Highlighter
        highlightClassName={classes.highlightedSearchTerm}
        searchWords={[searchText]}
        textToHighlight={truncate ? truncateResultText(text) : text}
      />
    </div>
  );

  function truncateResultText(text: string) {
    const cutoff = 150;
    const ellipsis = "\u2026";

    if (text.length < cutoff) return text;

    const searchTextStartIndex = text.indexOf(searchText);
    const bracketLength = (cutoff - searchText.length) / 2;

    // If the search text is closer to the beginning of the string than the bracket length,
    // or if we don't find the search text for some reason, just return the first part of the text.
    if (searchTextStartIndex === -1 || searchTextStartIndex - 1 < bracketLength) {
      return `${text.substr(0, cutoff)}${text.length > cutoff ? ellipsis : ""}`;
    }

    // If the search text is closer to the end of the string than the bracket length, return the last part of the string.
    const searchTextEndIndex = searchTextStartIndex + searchText.length;
    if (text.length - searchTextEndIndex - 1 < bracketLength) {
      return `${text.length > cutoff ? ellipsis : ""}${text.substr(text.length - cutoff)}`;
    }

    // Both ends need to be cut off.
    return `${ellipsis}${text.substr(searchTextStartIndex - bracketLength, cutoff)}${ellipsis}`;
  }

  return (
    <Dialog open={props.open} onClose={props.handleClose} scroll="paper" maxWidth="xl" fullScreen>
      <ClosableDialogTitle onClose={props.handleClose}>Search Checklist</ClosableDialogTitle>
      <DialogContent className={cx(classes.searchDialogContent)}>
        <div className={classes.searchCriteria}>
          <label className={classes.label}>Search questions by</label>
          <FormControl variant="outlined" className={classes.fieldSelector}>
            <Select
              margin="dense"
              value={selectedSearchField}
              onChange={(e: SelectChangeEvent<any>) => setSelectedSearchField(e.target.value)}>
              <MenuItem value={SearchField.Any}>Any</MenuItem>
              <MenuItem value={SearchField.Question}>Question</MenuItem>
              <MenuItem value={SearchField.CpaRef}>CPA Canada Reference</MenuItem>
              <MenuItem value={SearchField.StandardParagraph}>Standard Paragraph</MenuItem>
              <MenuItem value={SearchField.Recommendation}>Recommendation</MenuItem>
            </Select>
          </FormControl>
          <TextField
            className={classes.searchText}
            value={searchText}
            onChange={(e: React.ChangeEvent<any>) => setSearchText(e.target.value)}
            placeholder="Type to search"
            autoFocus
            margin="none"
          />
          <FormControlLabel
            control={<Checkbox title="Include all tabs" checked={includeAllTabs} onClick={() => setIncludeAllTabs(!includeAllTabs)} />}
            label="Include All Tabs"
          />
        </div>

        <DataGridPro
          rows={searchResults.results}
          autoHeight
          hideFooter
          disableColumnMenu
          density="compact"
          rowHeight={120}
          getRowId={(searchResult: GridRowModel) => `${searchResult.questionId}-${searchResult.standardParagraphId}`}
          className={cx(classes.datagridRoot, classes.searchResultsGrid)}
          components={{
            NoRowsOverlay: () => (
              <div className={classes.noDataMessage}>
                {searchResults.resultsOnOtherTabs > 0
                  ? `No results (${searchResults.resultsOnOtherTabs} results on other tabs)`
                  : "No results"}
              </div>
            )
          }}
          onRowClick={(params) => {
            props.handleClose();
            _.delay(() => props.navigateToQuestion(params.row.question, params.row.tabSectionId), 0);
          }}
          columns={[
            {
              field: "location",
              headerName: "Location",
              flex: 2.5,
              sortable: false,
              renderCell: (params) =>
                searchResultCell(includeAllTabs ? `${params.row.tabLocation}, ${params.row.location}` : params.row.location, false)
            },
            {
              field: "questionText",
              headerName: "Question",
              flex: 3,
              sortable: false,
              renderCell: (params) => searchResultCell(params.row.questionText, true)
            },
            {
              field: "cpaRef",
              headerName: "CPA Canada Ref.",
              flex: 1.5,
              sortable: false,
              renderCell: (params) => searchResultCell(params.row.cpaRef ?? "", true)
            },
            {
              field: "standardParagraphText",
              headerName: "Standard Paragraph",
              flex: 3,
              sortable: false,
              renderCell: (params) => searchResultCell(params.row.standardParagraphText, true)
            },
            {
              field: "recommendationText",
              headerName: "Recommendation",
              flex: 3,
              sortable: false,
              headerClassName: classes.lastColumnHeader,
              renderCell: (params) => searchResultCell(params.row.recommendationText, true)
            }
          ]}
        />
      </DialogContent>
    </Dialog>
  );
};

export default SearchChecklistDialog;
