import { User } from "users";
import { PracticeReview } from "practice-reviews";

export enum ExemptionStatus {
  Pending = "Pending",
  PendingRenewal = "PendingRenewal",
  Exempt = "Exempt",
  Ended = "Ended"
}

export interface Exemption {
  id: number;
  status: ExemptionStatus;

  startedOn: string | null;
  startedReason: string | null;
  approvedByUser: User | null;

  endedOn: string | null;
  endedByUser: User | null;
  endedReason: string | null;

  notes: string | null;

  letterIsReviewed: boolean;
  letterIsApproved: boolean;
  exemptionLetterSent: string | null;
  exemptionLetterAcknowledged: string | null;

  practiceReview: PracticeReview;
  newPracticeReviewForFirm: PracticeReview | null;

  markedAsReviewed: boolean;
}
