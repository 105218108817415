import React, { ReactChildren, ReactElement, ReactNode } from "react";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";

interface RequireType {
  children: ReactChildren | ReactNode | ReactElement;
  title: string;
  readOnly: boolean;
}

const AppWrapper = ({ children, title, readOnly }: RequireType) => {
  if (readOnly)
    return (
      <Tooltip title={title}>
        <Box sx={{ cursor: "not-allowed" }}>
          <Box sx={{ pointerEvents: "none" }}>{children}</Box>
        </Box>
      </Tooltip>
    );
  return <> {children} </>;
};

export default AppWrapper;
