import { useContext } from "react";
import { NotificationContext } from "./NotificationContext";

export function useNotifications() {
  const context = useContext(NotificationContext);

  return {
    addNotification: context.addNotification,
    success: context.success,
    warning: context.warning,
    error: context.error,
    serverError: context.serverError,
    info: context.info,
    removeAll: context.removeAll,
    handleApolloErrorResponse: context.handleApolloErrorResponse
  };
}
