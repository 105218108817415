export enum SignOffChecklistTypeCode {
  Reviewer = "Reviewer",
  Manager = "Manager",
  Director = "Director"
}

export interface SignOffChecklistType {
  id: number;
  typeCode: SignOffChecklistTypeCode;
  name: string;
}

export interface SignOffChecklistItemTemplate {
  id: number;
  signOffChecklistType: SignOffChecklistType;
  questionText: string;
  sortOrder: number;
  __typename?: string;
}

export interface SignOffChecklistItemTemplateInput {
  id?: number;
  signOffChecklistTypeId?: number;
  questionText?: string;
  sortOrder?: number;
}
