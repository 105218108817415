import { useMutation, gql } from "@apollo/client";
import {
  Dialog,
  DialogContent,
  Typography,
  DialogActions,
  Button,
  Select,
  MenuItem,
  TextField,
  FormControl,
  InputLabel
} from "@mui/material";

import { AdjustmentQuestion, AdjustmentQuestionAnswer, LevelOfAdjustment } from "./models";
import React, { useState } from "react";
import { AdjustmentQuestionButtonGroup } from "./AdjustmentQuestionButtonGroup";
import ClosableDialogTitle from "../common/ClosableDialogTitle";
import { LoadingButton } from "@mui/lab";
import { makeStyles } from "../makeStyles";

const SaveLevelOfAdjustmentMutation = gql`
  mutation SaveLevelOfAdjustment($id: Int!, $level: Int!, $reason: String, $questions: [AdjustmentQuestionInput]) {
    levelOfAdjustment {
      update(id: $id, level: $level, reason: $reason, questions: $questions) {
        id
        level
        reasonForAdjustment
        adjustmentQuestions {
          id
          answer
          questionText
        }
      }
    }
  }
`;

const useStyles = makeStyles()((theme) => ({
  content: {
    "& > :not(:first-child)": {
      marginTop: theme.spacing(2)
    }
  },
  question: {
    display: "flex",
    alignItems: "flex-start",
    marginBottom: theme.spacing(2),
    "& > :first-child": {
      flexGrow: 1
    },
    "& > :last-child": {
      marginLeft: theme.spacing(3),
      flexShrink: 0
    }
  }
}));

interface Props {
  onClose: () => void;
  levelOfAdjustment: LevelOfAdjustment;
  practiceReviewId: number;
}

export const LevelOfAdjustmentDialog: React.FunctionComponent<Props> = (props) => {
  const { classes } = useStyles();

  const [saveLevelOfAdjustmentMutate, saveLevelOfAdjustmentMutation] = useMutation<
    { levelOfAdjustment: { update: LevelOfAdjustment } },
    { id: number; level: number; reason: string | null; questions: Partial<AdjustmentQuestion>[] }
  >(SaveLevelOfAdjustmentMutation);

  const loa = props.levelOfAdjustment;

  const [selectedLevelOfAdjustment, setSelectedLevelOfAdjustment] = useState(loa.level);
  const [reason, setReason] = useState(loa.reasonForAdjustment ?? "");
  const [questions, setQuestions] = useState<Map<number, AdjustmentQuestion>>(new Map(loa.adjustmentQuestions.map((aq) => [aq.id, aq])));

  const setAnswer = (questionId: number, newAnswer: AdjustmentQuestionAnswer) => {
    const questionToUpdate = { ...questions.get(questionId)!, answer: newAnswer };
    setQuestions(questions.set(questionId, questionToUpdate));
  };

  return (
    <Dialog
      open={true}
      onClose={() => {
        props.onClose();
      }}>
      <ClosableDialogTitle onClose={props.onClose}>Choose Level of Adjustment</ClosableDialogTitle>
      <DialogContent className={classes.content}>
        {loa.adjustmentQuestions.map((aq) => {
          return (
            <div key={aq.id} className={classes.question}>
              <Typography>{aq.questionText}</Typography>
              <AdjustmentQuestionButtonGroup setAnswer={setAnswer} adjustmentQuestion={aq} />
            </div>
          );
        })}

        <FormControl variant="outlined" size="small" fullWidth>
          <InputLabel htmlFor="level-of-adjustment">Level of Adjustment</InputLabel>
          <Select
            value={selectedLevelOfAdjustment}
            label="Level of Adjustment"
            onChange={(e) => {
              setSelectedLevelOfAdjustment(e.target.value as number);
            }}
            inputProps={{
              id: "level-of-adjustment"
            }}>
            <MenuItem value={0}>No Adjustment</MenuItem>
            <MenuItem value={1}>Level 1</MenuItem>
            <MenuItem value={2}>Level 2</MenuItem>
          </Select>
        </FormControl>

        <TextField
          value={reason}
          fullWidth
          multiline
          label="Reason for Adjustment"
          onChange={(e) => {
            setReason(e.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            props.onClose();
          }}>
          Cancel
        </Button>
        <LoadingButton
          loading={saveLevelOfAdjustmentMutation.loading}
          onClick={async () => {
            await saveLevelOfAdjustmentMutate({
              variables: {
                id: props.levelOfAdjustment.id,
                level: selectedLevelOfAdjustment,
                reason: reason.trim() === "" ? null : reason.trim(),
                questions: Array.from(questions.values()).map((q) => ({ id: q.id, answer: q.answer }))
              }
            });
            props.onClose();
          }}
          color="primary"
          variant="contained">
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
