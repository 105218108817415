import React, { useEffect, useRef, useState } from "react";
import { PREstimate } from "practice-reviews";
import { Typography, Table, TableBody, TableRow, TableCell, Button, Grid, Box } from "@mui/material";
import { Formik, Form as FormikForm, Field as FormikField, FormikHelpers, FormikErrors } from "formik";
import { TextField as FmuiTextField } from "formik-mui";
import FixedPlacesNumberFormat from "util/FixedPlacesNumberFormat";
import { useMutation } from "@apollo/client";
import { useNotifications } from "notifications";
import { SaveEstimateMutation } from "scheduling";
import FinalizeEstimateDialog from "scheduling/estimate-time/FinalizeEstimateDialog";
import { tableStyles, actionStyles } from "styles/common";
import { formatDate } from "util/formats";
import RichTextEditor from "common/RichTextEditor";
import { useUnsavedChanges } from "../../UnsavedChangesProvider";
import { LoadingButton } from "@mui/lab";
import { makeStyles } from "makeStyles";
import { PrScreenQuery } from "practice-reviews";
import { ApolloCache } from "@apollo/client";

const useStyles = makeStyles()((theme) => ({
  ...tableStyles(theme),
  ...actionStyles(theme),
  root: {
    display: "flex",
    flexDirection: "column",
    "& form > *": {
      marginTop: theme.spacing(1)
    }
  },
  table: {
    marginBottom: theme.spacing(1),
    "& td.MuiTableCell-root": {
      width: "10em"
    }
  },
  inputCell: {
    "& input": {
      fontSize: "0.875rem"
    }
  },
  overrideText: {
    fontStyle: "italic"
  },
  overrideEstimateInputCell: {
    paddingRight: "0 !important"
  },
  date: {
    color: theme.palette.text.secondary,
    fontWeight: 400
  }
}));

interface Props {
  estimate: PREstimate;
  practiceReviewId: number;
  currentReviewActualTime: number | null;
  previousReviewActualTime: number | null;
  previousReviewDate: string | null;
  disableInputs: boolean;
}

interface FormValues {
  overrideEstimate: string;
  notes: string | null;
  finalize: boolean;
}

export const EstimateTimeTable: React.FunctionComponent<Props> = (props) => {
  const { classes, cx } = useStyles();
  const notifications = useNotifications();
  const { unsavedChanges, changesSaved, setSaveFunction, unsavedChangesExist } = useUnsavedChanges();
  const changeKey = "estimate time";

  const [confirmingFinalize, setConfirmingFinalize] = useState(false);
  const [finalEstimate, setFinalEstimate] = useState(props.estimate.finalEstimate);
  useEffect(() => setFinalEstimate(props.estimate.finalEstimate), [props.estimate.finalEstimate]);

  const notesContentRetriever = useRef<{ getContentAsHtml: () => string | null }>({ getContentAsHtml: () => null });

  const initialFormValues: FormValues = {
    overrideEstimate: props.estimate.overrideEstimate?.toString() ?? "",
    notes: props.estimate.notes,
    finalize: false
  };

  function validate(values: FormValues) {
    const errors: FormikErrors<FormValues> = {};

    if (values.overrideEstimate.trim() === "") return errors;

    const overrideEstimateAsFloat = parseFloat(values.overrideEstimate);

    if (isNaN(overrideEstimateAsFloat)) {
      errors.overrideEstimate = "Enter a number.";
    }

    if (overrideEstimateAsFloat <= 0) {
      errors.overrideEstimate = "Enter a number higher than zero.";
    }

    return errors;
  }

  function getOverrideEstimateAsNumber(overrideEstimateString: string) {
    return overrideEstimateString.trim() === "" ? null : parseFloat(overrideEstimateString);
  }

  const updateCache = (cache: ApolloCache<any>, { data }: any) => {
    // Fetch PR from the cache
    const existingPR = cache.readQuery<any>({
      query: PrScreenQuery,
      variables: {
        id: props.practiceReviewId
      }
    });
    // Update existing cached PR with mutated estimates
    const mutatedEstimates = data;
    cache.writeQuery({
      query: PrScreenQuery,
      data: { ...existingPR, estimate: mutatedEstimates.estimate.save }
    });
  };

  const [saveMutate, saveMutation] = useMutation<
    { estimate: { save: PREstimate } },
    { practiceReviewId: number; overrideEstimate: number | null; notes: string | null; finalize: boolean }
  >(SaveEstimateMutation, { update: updateCache });

  async function saveOrFinalize(values: FormValues, actions?: FormikHelpers<FormValues>) {
    if (values.finalize) {
      setConfirmingFinalize(true);
    } else {
      const overrideEstimate = getOverrideEstimateAsNumber(values.overrideEstimate);

      const notesHtml = notesContentRetriever.current.getContentAsHtml();

      const result = await saveMutate({
        variables: { practiceReviewId: props.practiceReviewId, overrideEstimate: overrideEstimate, notes: notesHtml, finalize: false }
      });

      if (result.data?.estimate.save?.id) {
        notifications.success(props.estimate.isFinalized ? "Saved notes." : "Saved override estimate and notes.");
        changesSaved(changeKey);
      }
    }

    if (actions) {
      actions.setSubmitting(false);
    }
  }

  function updateFinalEstimate(overrideEstimateString: string) {
    const overrideEstimate = getOverrideEstimateAsNumber(overrideEstimateString);
    setFinalEstimate(overrideEstimate ?? props.estimate.totalSystemEstimate);
  }

  function getTableRow(rowName: string, hours: number | null, important: boolean, headerClass?: string, date?: string | null) {
    return (
      <TableRow className={cx({ [classes.total]: important })}>
        <TableCell component="th" scope="row" align="right" className={headerClass}>
          {rowName}
          {date && (
            <>
              <br />
              <span className={classes.date}>{formatDate(date)}</span>
            </>
          )}
        </TableCell>
        <TableCell align="right" style={{ verticalAlign: "top" }} className={classes.number}>
          {hours?.toFixed(2) ?? "--"}
        </TableCell>
      </TableRow>
    );
  }

  const partnerSubtotal = props.estimate.partners.map((p) => p.timeEstimate).reduce((te1, te2) => te1 + te2, 0);

  return (
    <div className={classes.root}>
      <Formik initialValues={initialFormValues} validate={validate} onSubmit={saveOrFinalize}>
        {(formikProps) => {
          setSaveFunction(async () => {
            formikProps.setFieldValue("finalize", false, false);
            saveOrFinalize(formikProps.values);

            return true;
          }, changeKey);

          return (
            <>
              <Typography variant="h3">Estimate Time</Typography>

              <FormikForm>
                <Table className={classes.table} size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" className={cx(classes.category, classes.topCategory)} colSpan={2}>
                        Partners/Others
                      </TableCell>
                    </TableRow>
                    {getTableRow("Subtotal", partnerSubtotal, false)}
                    <TableRow>
                      <TableCell component="th" className={cx(classes.category)} colSpan={2}>
                        Overall - Firm-wide
                      </TableCell>
                    </TableRow>
                    {getTableRow("Compilation", props.estimate.compilationTotal, false)}
                    {getTableRow("Other, QAM", props.estimate.otherQamTotal, false)}
                    {getTableRow("Finalize Review", props.estimate.finalizeReviewTotal, false)}
                    {getTableRow("Total System Estimate", props.estimate.totalSystemEstimate, true, undefined, props.estimate.date)}

                    {getTableRow("Previous Review's Time", props.previousReviewActualTime, false, undefined, props.previousReviewDate)}
                    <TableRow>
                      <TableCell component="th" align="right" className={classes.overrideText}>
                        Override
                      </TableCell>
                      <TableCell
                        align="right"
                        className={cx({
                          [classes.overrideText]: props.estimate.isFinalized && Boolean(props.estimate.overrideEstimate),
                          [classes.overrideEstimateInputCell]: !props.estimate.isFinalized
                        })}>
                        {props.estimate.isFinalized ? (
                          props.estimate.overrideEstimate?.toFixed(2) ?? "--"
                        ) : (
                          <FormikField
                            component={FmuiTextField}
                            name="overrideEstimate"
                            fullWidth
                            autoComplete="off"
                            disabled={props.disableInputs || formikProps.isSubmitting || props.estimate.isFinalized}
                            error={formikProps.touched.overrideEstimate && Boolean(formikProps.errors.overrideEstimate)}
                            helperText={formikProps.touched.overrideEstimate && formikProps.errors.overrideEstimate}
                            InputProps={{
                              inputComponent: FixedPlacesNumberFormat,
                              inputProps: {
                                places: 2,
                                "aria-autocomplete": "none",
                                spellCheck: "false"
                              },
                              placeholder: "0.00"
                            }}
                            onChange={(e: React.ChangeEvent<any>) => {
                              formikProps.setFieldValue("overrideEstimate", e.target.value);
                              updateFinalEstimate(e.target.value);
                              unsavedChanges(changeKey);
                            }}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                    {getTableRow("Final Estimate", finalEstimate, true)}
                    {getTableRow("Actual (to date)", props.currentReviewActualTime, false)}
                  </TableBody>
                </Table>

                <Box minHeight="5em">
                  <RichTextEditor
                    label="Notes"
                    html={formikProps.values.notes}
                    passContentRetriever={(getContentAsHtml) => {
                      notesContentRetriever.current = { getContentAsHtml };
                    }}
                    hideToolbar
                    readOnly={props.disableInputs}
                    reportUnsavedChanges
                    changeKey={changeKey}
                    fullHeight
                  />
                </Box>

                <Grid container justifyContent="flex-end">
                  <Grid item className={classes.actions}>
                    <LoadingButton
                      variant="outlined"
                      loading={saveMutation.loading}
                      onClick={() => {
                        formikProps.setFieldValue("finalize", false, false);
                        formikProps.submitForm();
                      }}
                      disabled={props.disableInputs || !unsavedChangesExist(changeKey)}>
                      {!unsavedChangesExist(changeKey) ? "Saved" : props.estimate.isFinalized ? "Save notes" : "Save"}
                    </LoadingButton>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={() => {
                        formikProps.setFieldValue("finalize", true, false);
                        formikProps.submitForm();
                      }}
                      disabled={props.estimate.isFinalized}>
                      {props.estimate.isFinalized ? "Final" : "Finalize estimate"}
                    </Button>
                  </Grid>
                </Grid>
              </FormikForm>

              <FinalizeEstimateDialog
                practiceReviewId={props.practiceReviewId}
                systemEstimate={props.estimate.totalSystemEstimate}
                overrideEstimate={getOverrideEstimateAsNumber(formikProps.values.overrideEstimate)}
                notes={notesContentRetriever.current.getContentAsHtml()}
                open={confirmingFinalize}
                handleClose={() => setConfirmingFinalize(false)}
                changeKey={changeKey}
              />
            </>
          );
        }}
      </Formik>
    </div>
  );
};
