import { SectionHeaderToPrint } from "master-checklist";

export interface Checklist {
  tabSections: ChecklistSection[];
  questionsByTabAndAbsoluteSortOrder: _.Dictionary<_.Dictionary<Question>>;
}

/**
 * The ChecklistNodeTreePath is a helper object that will be attached to each checklist
 * node, to assist in various checklist operations that would otherwise require a lot
 * of inefficient tree traversal.
 *
 * After the checklist tree is built, each node will have a tree path. This tree path
 * will have an entry for its node, and all of its parent nodes in the tree.
 *
 * For a question node, the tree path might look something like:
 * ```jsonc
 * {
 *   [0]: { "id": 123, "__typename": "Section" },
 *   [1]: { "id": 345, "__typename": "Section" },
 *   [2]: { "id": 567, "__typename": "Question" },
 * }
 * ```
 *
 * This is useful, for example, in auto navigating to a specific question. When we
 * navigate to a specific question, all of the parent sections of those questions need
 * to be expanded. From the target question node, we can easily see its parent sections
 * listed in the treePath as per the example above.
 */
export interface ChecklistNodeTreePath {
  [depth: number]: {
    id: number;
    __typename: "ChecklistSection" | "QuestionHeader" | "Question";
  };
}

interface ChecklistNodeBase {
  id: number;
  parentId: number | null;
  treePath: ChecklistNodeTreePath;
  sortOrder: number;
  depth: number;
  hidden: boolean;
}

interface QuestionContainerBase extends ChecklistNodeBase {
  text: string;
  questions: Question[];
  children: ChecklistNode[];
}

export interface ChecklistSection extends QuestionContainerBase {
  shortDescription: string;
  expanded: boolean;
  __typename: "ChecklistSection";
}

export interface QuestionHeader extends QuestionContainerBase {
  cpaRef: string | null;
  __typename: "QuestionHeader";
}

export interface QuestionBase extends ChecklistNodeBase {
  text: string;
  cpaRef: string | null;
}

export interface Question extends QuestionBase {
  standardParagraphs: StandardParagraph[];
  answer?: Answer;
  absoluteSortOrder: number;
  __typename: "Question";
}

export type QuestionContainer = ChecklistSection | QuestionHeader;
export type ChecklistNode = QuestionContainer | Question;

export interface MasterChecklist {
  tabSections: ChecklistSectionTemplate[];
}

interface QuestionContainerTemplateBase extends ChecklistNodeBase {
  text: string;
  questions: QuestionTemplate[];
  children: MasterChecklistNode[];
}

export interface ChecklistSectionTemplate extends QuestionContainerTemplateBase {
  shortDescription: string;
  sectionHeaderToPrint?: SectionHeaderToPrint;
  isFirmSection?: boolean;
  isAssignedToEngagementTypes?: boolean;
  __typename: "ChecklistSectionTemplate";
}

export interface QuestionHeaderTemplate extends QuestionContainerTemplateBase {
  cpaRef: string | null;
  __typename: "QuestionHeaderTemplate";
}

export interface QuestionTemplate extends QuestionBase {
  standardParagraphs: StandardParagraphTemplate[];
  __typename: "QuestionTemplate";
}

export interface StandardParagraphTemplate {
  id: number;
  title: string;
  cpaRef: string | null;
  text: string;
  recommendation: string;
  isSignificantByDefault: boolean;
  isRemedialByDefault: boolean;
}

export type QuestionContainerTemplate = ChecklistSectionTemplate | QuestionHeaderTemplate;
export type MasterChecklistNode = QuestionContainerTemplate | QuestionTemplate;

export interface StandardParagraph {
  id: number;
  title: string;
  cpaRef: string | null;
  text: string;
  recommendation: string;
  isSignificantByDefault: boolean;
  isRemedialByDefault: boolean;
}

export interface Answer {
  id?: number;
  questionId?: number;
  reviewClientId?: number | null;

  isYes: boolean;
  isNa: boolean;
  isReportable: boolean;
  isNonReportable: boolean;
  note: string | null;
  standardParagraphId: number | null;
  hasCustomDeficiency: boolean;
  customCpaRef: string | null;
  customParagraphText: string | null;
  customRecommendationText: string | null;
  isSignificant: boolean;
  requiresRemedialAction: boolean;
  fileDetails: string | null;

  modified?: boolean;
  invalid?: boolean;
}

export interface AnswerInput extends Omit<Answer, "hasCustomDeficiency" | "modified" | "invalid"> {
  questionId: number;
}

export enum AnswerType {
  Yes,
  Rd,
  Nrd,
  Na
}

export function isSection(node: ChecklistNode): node is ChecklistSection {
  return node.__typename === "ChecklistSection";
}

export function isHeader(node: ChecklistNode): node is QuestionHeader {
  return node.__typename === "QuestionHeader";
}

export function isContainer(node: ChecklistNode): node is QuestionContainer {
  return isSection(node) || isHeader(node);
}

export function isQuestion(node: ChecklistNode): node is Question {
  return node.__typename === "Question";
}

export function isSectionTemplate(node: ChecklistNode | MasterChecklistNode): node is ChecklistSectionTemplate {
  return node.__typename === "ChecklistSectionTemplate";
}

export function isHeaderTemplate(node: ChecklistNode | MasterChecklistNode): node is QuestionHeaderTemplate {
  return node.__typename === "QuestionHeaderTemplate";
}

export function isContainerTemplate(node: ChecklistNode | MasterChecklistNode): node is QuestionContainerTemplate {
  return isSectionTemplate(node) || isHeaderTemplate(node);
}

export function isQuestionTemplate(node: ChecklistNode | MasterChecklistNode): node is QuestionTemplate {
  return node.__typename === "QuestionTemplate";
}

export interface ChecklistCompletion {
  someQuestionsIncomplete: boolean;
  someQuestionsComplete: boolean;
  reportableDeficienciesExist: boolean;
  nonReportableDeficienciesExist: boolean;
  empty: boolean;
}
