import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from "@mui/material";
import _ from "lodash";
import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Prompt, useHistory, useLocation } from "react-router-dom";
import { Location } from "history";
import ClosableDialogTitle from "./common/ClosableDialogTitle";

export const UnsavedChangesContext = createContext<{
  unsavedChanges: (key?: string) => void;
  changesSaved: (key?: string) => void;
  unsavedChangesExist: (key?: string) => boolean;
  setSaveFunction: (saveFunction: () => Promise<boolean>, key?: string) => void;
}>({
  unsavedChanges: () => {},
  changesSaved: () => {},
  unsavedChangesExist: () => false,
  setSaveFunction: (saveFunction: () => Promise<boolean>) => {}
});

export const UnsavedChangesProvider: React.FunctionComponent = (props) => {
  const [unsavedChangeKeys, setUnsavedChangeKeys] = useState<string[]>([]);
  const saveFunctions = useRef<Map<string, () => Promise<boolean>>>(new Map<string, () => Promise<boolean>>());

  const [navigating, setNavigating] = useState<{
    confirming: boolean;
    confirmed: boolean;
    location: Location<any> | null;
  }>({
    confirming: false,
    confirmed: false,
    location: null
  });

  const location = useLocation();
  const history = useHistory();

  const previousLocation = useRef(location);

  useEffect(() => {
    // Adapted from https://dev.to/eons/detect-page-refresh-tab-close-and-route-change-with-react-router-v5-3pd
    // This effect is what prevents immediate page unloading on refresh/close tab/close browser/navigate away.

    const shouldWarnUser = unsavedChangeKeys.length > 0;

    window.onbeforeunload = (event: BeforeUnloadEvent) => {
      if (shouldWarnUser) {
        const e = event || window.event;
        e.preventDefault();
        if (e) {
          e.returnValue = ""; // Legacy method for cross browser support
        }
        return ""; // Legacy method for cross browser support
      }
    };
  }, [unsavedChangeKeys]);

  function handleNavigationWhileUnsavedChangesExist(nextLocation: Location<any>) {
    if (!navigating.confirmed) {
      // Raise the confirmation dialogue
      setNavigating({
        confirming: true,
        confirmed: false,
        location: nextLocation
      });
      return false;
    }

    return true;
  }

  async function saveThenNavigate() {
    setNavigating({
      ...navigating,
      confirming: false
    });

    let savedWithoutErrors = true;

    for (const [key, saveFunction] of Array.from(saveFunctions.current.entries())) {
      if (unsavedChangeKeys.includes(key)) {
        const saved = await saveFunction();
        if (!saved) {
          savedWithoutErrors = false;
        }
      }
    }

    if (savedWithoutErrors) {
      setNavigating({
        ...navigating,
        confirmed: true
      });
    }
  }

  function navigateImmediately() {
    setNavigating({ confirming: false, confirmed: true, location: navigating.location });
  }

  function cancel() {
    setNavigating({ confirming: false, confirmed: false, location: null });
  }

  useEffect(() => {
    if (navigating.confirmed) {
      // If they've confirmed navigation either with saving or without, go to the new location
      history.push(navigating.location!.pathname);
    }
  }, [navigating.confirmed]);

  useEffect(() => {
    // Reset the state and location when the route changes

    if (location !== previousLocation.current) {
      saveFunctions.current.clear();
      setUnsavedChangeKeys([]);
      setNavigating({
        confirming: false,
        confirmed: false,
        location: null
      });
    }
    previousLocation.current = location;
  });

  const defaultKey = "default";

  const recordUnsavedChanges = useCallback(
    (key?: string) => {
      setUnsavedChangeKeys(_.uniq([...unsavedChangeKeys, key ?? defaultKey]));
    },
    [unsavedChangeKeys]
  );
  const recordChangesSaved = useCallback(
    (key?: string) => {
      setUnsavedChangeKeys((previousKeys) => previousKeys.filter((k) => k !== (key ?? defaultKey)));
    },
    [setUnsavedChangeKeys]
  );
  const unsavedChangesExist = useCallback((key?: string) => unsavedChangeKeys.indexOf(key ?? defaultKey) !== -1, [unsavedChangeKeys]);
  const setSaveFunction = useCallback((newSaveFunction, newSaveFunctionKey) => {
    saveFunctions.current.set(newSaveFunctionKey ?? defaultKey, newSaveFunction);
  }, []);

  const contextValue = useMemo(
    () => ({
      unsavedChanges: recordUnsavedChanges,
      changesSaved: recordChangesSaved,
      unsavedChangesExist,
      setSaveFunction
    }),
    [recordUnsavedChanges, recordChangesSaved, unsavedChangesExist, setSaveFunction]
  );

  return (
    <UnsavedChangesContext.Provider value={contextValue}>
      <Prompt when={unsavedChangeKeys.length > 0} message={handleNavigationWhileUnsavedChangesExist} />

      <Dialog open={navigating.confirming} onClose={cancel} maxWidth="sm" fullWidth scroll="paper">
        <ClosableDialogTitle onClose={cancel}>
          {saveFunctions.current.size > 0 ? "Save changes?" : "Leave without saving?"}
        </ClosableDialogTitle>
        <DialogContent>
          <DialogContentText>
            {saveFunctions.current.size > 0
              ? "Do you want to save your changes before leaving this screen?"
              : "You have unsaved changes. Do you still want to leave this screen?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {saveFunctions.current.size > 0 ? (
            <>
              <Button variant="outlined" onClick={cancel}>
                Cancel
              </Button>
              <Button color="error" variant="outlined" onClick={() => navigateImmediately()}>
                No
              </Button>
              <Button color="primary" variant="contained" onClick={() => saveThenNavigate()}>
                Yes
              </Button>
            </>
          ) : (
            <>
              <Button onClick={cancel}>Cancel</Button>
              <Button color="error" variant="outlined" onClick={() => navigateImmediately()}>
                OK
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
      {props.children}
    </UnsavedChangesContext.Provider>
  );
};

export function useUnsavedChanges() {
  return useContext(UnsavedChangesContext);
}
