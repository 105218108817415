import { Autocomplete, Button, Chip, Dialog, DialogActions, DialogContent, TextField } from "@mui/material";
import ClosableDialogTitle from "common/ClosableDialogTitle";
import React from "react";
import { CommitteeMeeting } from "./models";
import { Formik, FormikHelpers } from "formik";
import { useMutation, useQuery } from "@apollo/client";
import { useNotifications } from "notifications";
import { LoadingButton } from "@mui/lab";
import { GenerateCommitteeMeetingMinutesMutation } from ".";
import { CommitteeMember, FetchCommitteeMembersQuery } from "../committee-members";
import { DialogContentText } from "@mui/material";
import _ from "lodash";
import { getOpenableUrl } from "../util/utilities";

interface Props {
  committeeMeetingId: number;
  onClose: () => void;
}

export const CommitteeMeetingRegretsDialog: React.FunctionComponent<Props> = (props) => {
  const notifications = useNotifications();

  interface FormValues {
    regretsMemberIds: [];
  }

  const initialFormValues: FormValues = {
    regretsMemberIds: []
  };

  const committeeMembersQuery = useQuery<{ committeeMembers: CommitteeMember[] }>(FetchCommitteeMembersQuery);
  const committeeMembers = _.orderBy(committeeMembersQuery.data?.committeeMembers, [(cm) => cm.lastName, (cm) => cm.firstName]) ?? [];

  const [generateMinutesMutate, generateMinutesMutation] = useMutation<
    { committeeMeeting: { generateMinutes: CommitteeMeeting } },
    { meetingId: number; attendingMemberIds: number[]; regretsMemberIds: number[] }
  >(GenerateCommitteeMeetingMinutesMutation);

  async function generateMinutes(values: FormValues, actions: FormikHelpers<FormValues>) {
    const result = await generateMinutesMutate({
      variables: {
        meetingId: props.committeeMeetingId,
        attendingMemberIds: committeeMembers.filter((cm) => values.regretsMemberIds.every((rid) => rid !== cm.id)).map((cm) => cm.id),
        regretsMemberIds: values.regretsMemberIds
      }
    });

    if (result.data?.committeeMeeting.generateMinutes.id) {
      notifications.success("Generated minutes for meeting.");

      if (result.data.committeeMeeting.generateMinutes.minutesUrl) {
        window.open(getOpenableUrl(result.data.committeeMeeting.generateMinutes.minutesUrl));
      }
    }

    actions.setSubmitting(false);
    props.onClose();
  }

  return (
    <Dialog open={true} onClose={props.onClose} fullWidth maxWidth="sm">
      <Formik initialValues={initialFormValues} onSubmit={generateMinutes}>
        {(formikProps) => (
          <>
            <ClosableDialogTitle onClose={props.onClose}>Generate Committee Meeting Minutes</ClosableDialogTitle>
            <DialogContent>
              <DialogContentText>Select the committee members who were not present at the meeting.</DialogContentText>
              <Autocomplete
                multiple
                disableClearable
                filterSelectedOptions
                options={committeeMembers}
                getOptionLabel={(cm) => cm.name}
                renderTags={(committeeMembers: CommitteeMember[], getTagProps: any) =>
                  committeeMembers.map((cm: CommitteeMember, index: number) => (
                    <Chip key={cm.id} label={cm.name} size="small" {...getTagProps({ index })} title={cm.name} />
                  ))
                }
                renderInput={(params) => <TextField {...params} label="Committee Members Regrets" />}
                value={committeeMembers.filter((cm) => formikProps.values.regretsMemberIds.some((rmid) => rmid === cm.id))}
                onChange={(e, newMembers) => {
                  formikProps.setFieldValue(
                    "regretsMemberIds",
                    newMembers.map((cm) => cm.id)
                  );
                }}
              />
            </DialogContent>

            <DialogActions>
              <Button onClick={() => props.onClose()} disabled={generateMinutesMutation.loading}>
                Cancel
              </Button>
              <LoadingButton
                color="primary"
                variant="contained"
                onClick={() => formikProps.submitForm()}
                disabled={!formikProps.isValid}
                loading={generateMinutesMutation.loading}>
                OK
              </LoadingButton>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};
