import React, { useState } from "react";
import { PracticeReview, PrScreenQuery } from "practice-reviews";
import { Grid, Button, Typography, DialogContentText, Stack, IconButton } from "@mui/material";
import { useQuery, useMutation, useApolloClient } from "@apollo/client";
import { ReviewClient, ReviewClientInput } from "../models";
import { actionStyles } from "styles/common";
import _ from "lodash";
import { PartnerGroup } from "./PartnerGroup";
import { Permissions, useCurrentUser } from "users";
import { ConfirmationDialog } from "common/ConfirmationDialog";
import { useHistory } from "react-router-dom";
import { getRouteForPracticeReview, PracticeReviewTabs } from "practice-reviews/PracticeReviewScreen";
import {
  ReviewClientsListAndDropdownContentsQuery,
  AddReviewClientMutation,
  EditReviewClientMutation,
  DeleteReviewClientMutation
} from "./queries";
import { Skeleton } from "@mui/material";
import { useUnsavedChanges } from "../../UnsavedChangesProvider";
import { useNotifications } from "../../notifications";
import { makeStyles } from "makeStyles";
import { ClientFileForm } from "./ClientFileForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";

const remDrawerWidth = 30;

const useStyles = makeStyles()((theme) => ({
  ...actionStyles(theme),
  root: {
    height: "100%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between"
  },
  addButtonGrid: {
    textAlign: "right",
    padding: theme.spacing(3)
  },
  closeButton: {
    display: "flex",
    alignItems: "flex-start"
  },
  content: {
    alignItems: "flex-start",
    width: `calc(100% - 1px)`,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.enteringScreen,
      delay: 50
    })
  },
  contentWithOpen: {
    flexGrow: 0,
    width: `calc(100% - ${remDrawerWidth}rem)`,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  drawer: {
    width: 0,
    marginLeft: "-1px", // omg hax
    borderLeft: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflow: "hidden",
    "& form": {
      minWidth: `${remDrawerWidth - 1}rem`,
      padding: theme.spacing(3)
    }
  },
  drawerOpen: {
    width: `${remDrawerWidth}rem`,
    marginBottom: theme.spacing(2),
    borderBottomLeftRadius: theme.spacing(1),
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.enteringScreen,
      delay: 10
    })
  },
  sectionHeader: {
    marginBottom: theme.spacing(1)
  },
  deleteWarningReviewClientName: {
    fontWeight: "bold"
  }
}));

interface Props {
  practiceReview: PracticeReview;
}

export const ReviewClients: React.FunctionComponent<Props> = (props) => {
  const { practiceReview } = props;

  const { user, userIsLeadReviewer } = useCurrentUser();
  const { classes, cx } = useStyles();
  const history = useHistory();
  const { unsavedChanges, changesSaved } = useUnsavedChanges();
  const notifications = useNotifications();
  const apolloClient = useApolloClient();

  const [reviewClientBeingEdited, setReviewClientBeingEdited] = useState<ReviewClient | null>(null);
  const [addingReviewClient, setAddingReviewClient] = useState<boolean>(false);
  const [confirmingDeleteFile, setConfirmingDeleteFile] = useState<ReviewClient | null>(null);
  const [justAddedReviewClient, setJustAddedReviewClient] = useState<ReviewClient | null>(null);

  const { userHasPermission } = useCurrentUser();
  const canEdit = !practiceReview.hasBeenReturned || userHasPermission(Permissions.ReturnedPrUpdate);

  const editPaneOpen = () =>
    reviewClientBeingEdited !== null || addingReviewClient || (!mainQueryResult.loading && reviewClientsGroupedByPartner?.isEmpty());

  const mainQueryResult = useQuery<{ practiceReviewById: PracticeReview }>(ReviewClientsListAndDropdownContentsQuery, {
    variables: {
      id: practiceReview.id
    }
  });
  const reviewClients = mainQueryResult.data?.practiceReviewById.reviewClients ?? [];
  const reviewClientsGroupedByPartner = _(reviewClients).groupBy((c) => c.partnerName);

  const engagementTypes = mainQueryResult.data?.practiceReviewById.engagementTypes ?? [];

  const latestFirmPartners = mainQueryResult.data?.practiceReviewById.firm.latestPartners ?? [];

  const [addReviewClient, { loading: loadingAdd }] = useMutation<
    { reviewClients: { add: ReviewClient } },
    { reviewClient: ReviewClientInput }
  >(AddReviewClientMutation, {
    refetchQueries: [
      {
        query: ReviewClientsListAndDropdownContentsQuery,
        variables: {
          id: practiceReview.id
        }
      },
      {
        query: PrScreenQuery,
        variables: {
          id: practiceReview.id
        }
      }
    ]
  });

  const reviewClientClicked = (reviewClient: ReviewClient) => {
    setReviewClientBeingEdited(reviewClient);
  };

  const markerClicked = (reviewClient: ReviewClient) => {
    const url = getRouteForPracticeReview(practiceReview, PracticeReviewTabs.ClientChecklists, reviewClient);
    history.push(url);
  };

  const closeEditPane = () => {
    setAddingReviewClient(false);
    setReviewClientBeingEdited(null);
    changesSaved();
  };

  const goToJustAddedReviewClient = (reviewClient: ReviewClient) => {
    const url = getRouteForPracticeReview(practiceReview, PracticeReviewTabs.ClientChecklists, reviewClient);
    setJustAddedReviewClient(null);
    history.push(url);
  };

  const deleteClicked = async (reviewClient: ReviewClient) => {
    if (confirmingDeleteFile !== reviewClient) {
      setConfirmingDeleteFile(reviewClient);
    } else {
      await deleteReviewClient({
        variables: {
          id: reviewClient.id
        }
      });
      closeEditPane();
      setConfirmingDeleteFile(null);
    }
  };

  const [editReviewClient, { loading: loadingEdit }] = useMutation<
    { reviewClients: { update: ReviewClient } },
    { reviewClient: ReviewClientInput }
  >(EditReviewClientMutation, {
    refetchQueries: [
      {
        query: PrScreenQuery,
        variables: {
          id: practiceReview.id
        }
      }
    ]
  });

  const [deleteReviewClient, { loading: loadingDelete }] = useMutation<{ reviewClients: { delete: ReviewClient } }, { id: number }>(
    DeleteReviewClientMutation,
    {
      refetchQueries: [
        {
          query: PrScreenQuery,
          variables: {
            id: practiceReview.id
          }
        }
      ]
    }
  );

  function notifyReviewerTheyMustResubmitReviewClients() {
    notifications.info(`You will need to resubmit your ${reviewClientDescriptor} files to the lead reviewer.`, 0);
  }

  async function saveReviewClient(reviewClientInput: ReviewClientInput) {
    if (reviewClientBeingEdited) {
      const result = await editReviewClient({
        variables: {
          reviewClient: reviewClientInput
        }
      });

      if (result.data?.reviewClients.update.id) {
        notifications.success(`Saved ${reviewClientDescriptor}.`);

        if (reviewerHasSubmittedReviewClients) {
          notifyReviewerTheyMustResubmitReviewClients();
        }

        closeEditPane();
        return true;
      } else return false;
    } else {
      const result = await addReviewClient({
        variables: {
          reviewClient: reviewClientInput
        }
      });

      const addedFile = result.data?.reviewClients.add;
      if (addedFile) {
        if (reviewerHasSubmittedReviewClients) {
          notifyReviewerTheyMustResubmitReviewClients();
        }

        closeEditPane();

        // Add the new file into the cached practice review so that it immediately appears on the screen.
        const prCacheId = `PracticeReview:${props.practiceReview.id}`;
        apolloClient.cache.modify({
          id: prCacheId,
          fields: {
            reviewClients(existingFiles: ReviewClient[]) {
              return existingFiles.concat([addedFile]);
            }
          }
        });

        if (addedFile.reviewedByUserId === user.id) {
          setJustAddedReviewClient(addedFile);
        }

        return true;
      } else return false;
    }
  }

  const availablePartners: string[] = _.orderBy(
    _.union(
      latestFirmPartners?.map((m) => m.name),
      reviewClients?.map((r) => r.partnerName)
    ),
    (x) => x.toLowerCase()
  );

  const reviewers = (props.practiceReview.leadReviewer ? [props.practiceReview.leadReviewer] : []).concat(
    _.orderBy(props.practiceReview.otherReviewers, (r) => r.user.name)
  );

  const reviewerHasSubmittedReviewClients =
    !userIsLeadReviewer(props.practiceReview) &&
    reviewClientBeingEdited?.reviewedByUserId === user.id &&
    props.practiceReview.otherReviewers.filter((r) => r.userId === user.id)?.[0].reviewClientsSubmitted;

  const userCanDeleteReviewClientBeingEdited =
    reviewClientBeingEdited?.reviewedByUserId === user.id || userIsLeadReviewer(props.practiceReview);

  function userCanViewReviewClient(reviewClient: ReviewClient) {
    const userHasPermissionToViewWholePr = userHasPermission(Permissions.PracticeReviewViewAll);
    return userIsLeadReviewer(props.practiceReview) || reviewClient.reviewedByUserId === user.id || userHasPermissionToViewWholePr;
  }

  const reviewClientDescriptor = "client";
  const capitalizedReviewClientDescriptor = "Client";

  return (
    <div className={classes.root}>
      <div
        className={cx(classes.content, {
          [classes.contentWithOpen]: editPaneOpen() && canEdit
        })}>
        <div className={classes.addButtonGrid}>
          {!editPaneOpen() && !mainQueryResult.loading && (
            <Button
              variant="outlined"
              size="small"
              color="primary"
              onClick={() => setAddingReviewClient(true)}
              disabled={editPaneOpen()}
              hidden={!canEdit}>
              {`Add New ${capitalizedReviewClientDescriptor}`}
            </Button>
          )}
        </div>
        <Grid container rowSpacing={5} columnSpacing={5}>
          {mainQueryResult.loading ? ( // show skeleton when loading
            [...Array(4)].map((x, index) => (
              <Grid item xs={12} md={6} key={index}>
                <Skeleton variant="rectangular" width="100%" height="10rem" />
              </Grid>
            ))
          ) : reviewClientsGroupedByPartner.isEmpty() ? ( // show empty text when no review files
            <Grid item>
              <Typography variant="h3" sx={{ pl: 5 }}>
                {"No client files."}
              </Typography>
            </Grid>
          ) : (
            // show list of review files
            reviewClientsGroupedByPartner
              .map((reviewClients, partner) => (
                <Grid item xs={12} md={6} key={partner}>
                  <PartnerGroup
                    partnerName={partner}
                    reviewClients={reviewClients}
                    selectedReviewClientId={reviewClientBeingEdited?.id}
                    reviewClientClicked={reviewClientClicked}
                    markerClicked={markerClicked}
                    userCanViewReviewClient={userCanViewReviewClient}
                  />
                </Grid>
              ))
              .value()
          )}
        </Grid>
      </div>
      <div
        className={cx(classes.drawer, {
          [classes.drawerOpen]: editPaneOpen() && canEdit
        })}>
        <Stack direction="row" justifyContent="space-between" sx={{ pt: 3, pl: 3, pr: 3 }}>
          <Typography variant="h3" className={classes.sectionHeader}>
            {`${reviewClientBeingEdited ? "Edit" : "New"} ${capitalizedReviewClientDescriptor} Details${
              reviewClientBeingEdited?.refNum ? ` (${reviewClientBeingEdited.refNum})` : ""
            }`}
          </Typography>
          {!reviewClientsGroupedByPartner?.isEmpty() && ( // can't close the dialog if there are no files to show
            <Typography variant="h3" className={classes.closeButton}>
              <IconButton size="small" onClick={() => closeEditPane()} title="Cancel">
                <FontAwesomeIcon icon={faTimes} />
              </IconButton>
            </Typography>
          )}
        </Stack>

        <ClientFileForm
          reviewClientBeingEdited={reviewClientBeingEdited}
          practiceReview={props.practiceReview}
          availablePartners={availablePartners}
          cancel={!reviewClientsGroupedByPartner?.isEmpty() ? closeEditPane : null}
          canEdit={canEdit}
          delete={deleteClicked}
          engagementTypes={engagementTypes}
          loadingAdd={loadingAdd}
          loadingEdit={loadingEdit}
          loadingDelete={loadingDelete}
          reviewers={reviewers}
          saveReviewClient={saveReviewClient}
          unsavedChanges={unsavedChanges}
          userCanDeleteReviewClientBeingEdited={userCanDeleteReviewClientBeingEdited}
        />
      </div>
      {confirmingDeleteFile && (
        <ConfirmationDialog
          open={true}
          body={
            <>
              <DialogContentText className={classes.deleteWarningReviewClientName}>
                <span>{confirmingDeleteFile?.name}</span>
                <span>{confirmingDeleteFile?.refNum && ` (${confirmingDeleteFile.refNum})`}</span>
              </DialogContentText>
              <br />
              {`Are you sure you want to delete this ${reviewClientDescriptor} file and its checklist?`}
            </>
          }
          title={`Delete ${capitalizedReviewClientDescriptor}?`}
          cancel={() => setConfirmingDeleteFile(null)}
          confirm={() => deleteClicked(confirmingDeleteFile)}
        />
      )}

      {justAddedReviewClient && (
        <ConfirmationDialog
          open={true}
          body={
            <DialogContentText>
              {`${capitalizedReviewClientDescriptor} file created. Would you like to go straight to the ${reviewClientDescriptor} checklist for ${justAddedReviewClient.name}?`}
            </DialogContentText>
          }
          title={`Go to new ${reviewClientDescriptor} checklist?`}
          noDanger
          cancel={() => setJustAddedReviewClient(null)}
          cancelButtonText="No"
          confirm={() => goToJustAddedReviewClient(justAddedReviewClient)}
          confirmButtonText="Yes"
        />
      )}
    </div>
  );
};
