import { Button, Dialog, DialogActions, DialogContent, DialogContentText, MenuItem } from "@mui/material";

import ClosableDialogTitle from "common/ClosableDialogTitle";
import { PracticeReview } from "practice-reviews";
import React from "react";
import { PRBaseStatusCode } from "./models";
import { Formik, Field as FormikField, FormikErrors, FormikHelpers } from "formik";
import { TextField as FmuiTextField } from "formik-mui";
import { gql, useMutation } from "@apollo/client";
import { useNotifications } from "notifications";
import { Alert } from "@mui/material";
import { GetInasForUserQuery } from "inas";
import { useCurrentUser, Permissions } from "../users";

import { LoadingButton } from "@mui/lab";
import { makeStyles } from "../makeStyles";
import { FetchExemptionsQuery } from "../exemptions";
import { DateTime } from "luxon";
import PrsDatePickerField from "../common/FormikFields/PrsDatePickerField";

const useStyles = makeStyles()((theme) => ({
  contents: {
    "& > :not(:first-child)": {
      marginTop: theme.spacing(2)
    }
  },
  committeeWarning: {
    marginTop: theme.spacing(1)
  }
}));

interface Props {
  practiceReview: PracticeReview;
  onClose: () => void;
}

export const ChangePrStatusDialog: React.FunctionComponent<Props> = (props) => {
  const { classes, cx } = useStyles();
  const notifications = useNotifications();
  const { userHasPermission } = useCurrentUser();

  interface FormValues {
    statusCode: PRBaseStatusCode;
    reasonForChange: string;
    prNumber: string;
    exemptionStartDate: DateTime | null;
  }

  const initialFormValues: FormValues = {
    statusCode: props.practiceReview.status.baseStatusCode,
    reasonForChange: "",
    prNumber: "",
    exemptionStartDate: null
  };

  function validate(values: FormValues) {
    const errors: FormikErrors<FormValues> = {};

    if (values.statusCode === props.practiceReview.status.baseStatusCode) {
      errors.statusCode = "Select a new status.";
    }

    if (values.reasonForChange.trim() === "") {
      errors.reasonForChange = "Enter the reason for the status change.";
    }

    if (values.statusCode === PRBaseStatusCode.Removed && values.prNumber !== props.practiceReview.prNumber) {
      errors.prNumber = "Enter the PR number to confirm that it will be permanently removed.";
    }

    if (values.statusCode === PRBaseStatusCode.ExemptionPending && values.exemptionStartDate === null) {
      errors.exemptionStartDate = "Enter the exemption start date.";
    }

    return errors;
  }

  const [changeStatusMutate, changeStatusMutation] = useMutation<
    { practiceReview: { changeStatus: PracticeReview } },
    { practiceReviewId: number; newStatusCode: PRBaseStatusCode; reasonForChange: string; exemptionStartDate: string | null }
  >(
    gql`
      mutation ChangePrStatus(
        $practiceReviewId: Int!
        $newStatusCode: PRBaseStatusCode!
        $reasonForChange: String!
        $exemptionStartDate: Date
      ) {
        practiceReview {
          changeStatus(
            practiceReviewId: $practiceReviewId
            newStatusCode: $newStatusCode
            reasonForChange: $reasonForChange
            exemptionStartDate: $exemptionStartDate
          ) {
            id
            prNotes
            status {
              id
              baseStatusCode
              statusName
            }
            phase {
              id
              name
            }
            committeeMeeting {
              id
              name
            }
            exemption {
              id
              status
              notes
              startedOn
            }
          }
        }
      }
    `,
    {
      refetchQueries: [{ query: GetInasForUserQuery }, { query: FetchExemptionsQuery }]
    }
  );

  async function changeStatus(values: FormValues, actions: FormikHelpers<FormValues>) {
    const result = await changeStatusMutate({
      variables: {
        practiceReviewId: props.practiceReview.id,
        newStatusCode: values.statusCode,
        reasonForChange: values.reasonForChange.trim(),
        exemptionStartDate: values.exemptionStartDate !== null ? values.exemptionStartDate.toISODate() : null
      }
    });

    if (result.data?.practiceReview.changeStatus?.id) {
      notifications.success("Changed status.");
    }

    actions.setSubmitting(false);
    props.onClose();
  }

  return (
    <Dialog open={true} onClose={props.onClose} fullWidth maxWidth="sm">
      <Formik initialValues={initialFormValues} validate={validate} onSubmit={changeStatus}>
        {(formikProps) => (
          <>
            <ClosableDialogTitle onClose={props.onClose}>Change Status</ClosableDialogTitle>
            <DialogContent className={cx(classes.contents)}>
              <FormikField
                component={FmuiTextField}
                select
                name="statusCode"
                label="Status"
                fullWidth
                required
                disabled={formikProps.isSubmitting}
                error={formikProps.touched.statusCode && Boolean(formikProps.errors.statusCode)}
                helperText={formikProps.touched.statusCode && formikProps.errors.statusCode}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  formikProps.setFieldValue("statusCode", e.target.value);

                  if (e.target.value === PRBaseStatusCode.ExemptionPending) {
                    formikProps.setFieldValue("exemptionStartDate", DateTime.now().startOf("day"));
                  }
                }}>
                <MenuItem value={PRBaseStatusCode.InProgress} disabled={formikProps.values.statusCode === PRBaseStatusCode.InProgress}>
                  In Progress
                </MenuItem>
                <MenuItem value={PRBaseStatusCode.Hold} disabled={formikProps.values.statusCode === PRBaseStatusCode.Hold}>
                  Hold
                </MenuItem>
                {userHasPermission(Permissions.ExemptionSetPendingStatus) && (
                  <MenuItem
                    value={PRBaseStatusCode.ExemptionPending}
                    disabled={formikProps.values.statusCode === PRBaseStatusCode.ExemptionPending}>
                    Exemption Pending
                  </MenuItem>
                )}
                {userHasPermission(Permissions.PrRemove) && (
                  <MenuItem value={PRBaseStatusCode.Removed} disabled={formikProps.values.statusCode === PRBaseStatusCode.Removed}>
                    Removed
                  </MenuItem>
                )}
              </FormikField>

              <FormikField
                component={FmuiTextField}
                name="reasonForChange"
                label="Reason for Change"
                fullWidth
                required
                autoComplete="off"
                inputProps={{
                  "aria-autocomplete": "none",
                  spellCheck: "false"
                }}
              />

              {formikProps.values.statusCode === PRBaseStatusCode.ExemptionPending && (
                <FormikField
                  component={PrsDatePickerField}
                  name="exemptionStartDate"
                  label="Exemption Start Date"
                  required
                  disabled={formikProps.isSubmitting}
                  onChange={(newDate: DateTime | null) => {
                    formikProps.setFieldValue("exemptionStartDate", newDate);
                  }}
                  onBlur={() => {
                    formikProps.setFieldTouched("exemptionStartDate", true);
                  }}
                />
              )}

              {formikProps.values.statusCode === PRBaseStatusCode.Removed && (
                <div>
                  <DialogContentText>
                    Enter the PR number ({props.practiceReview.prNumber}) to confirm that you want to remove this PR. This action cannot be
                    undone.
                  </DialogContentText>
                  <FormikField
                    component={FmuiTextField}
                    name="prNumber"
                    label="PR Number"
                    fullWidth
                    disabled={formikProps.isSubmitting}
                    error={formikProps.touched.prNumber && Boolean(formikProps.errors.prNumber)}
                    helperText={formikProps.touched.prNumber && formikProps.errors.prNumber}
                  />
                </div>
              )}

              {props.practiceReview.committeeMeeting && formikProps.values.statusCode === PRBaseStatusCode.Hold && (
                <Alert severity="warning" className={classes.committeeWarning}>
                  This practice review has already been assigned to a PRC meeting. It will be removed from the meeting and will need to be
                  assigned to a new one.
                </Alert>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={() => props.onClose()} disabled={changeStatusMutation.loading}>
                Cancel
              </Button>
              <LoadingButton
                color="primary"
                variant="contained"
                onClick={() => formikProps.submitForm()}
                disabled={!formikProps.isValid}
                loading={changeStatusMutation.loading}>
                OK
              </LoadingButton>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};
