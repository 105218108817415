import { Box, SxProps, Theme, Tooltip, Typography } from "@mui/material";
import React from "react";
import { makeStyles } from "../makeStyles";
import { staticDataStyles } from "../styles/common";

const useStyles = makeStyles<Props>()((theme) => ({
  ...staticDataStyles(theme),
  important: {
    fontWeight: 500
  }
}));

interface Props {
  label: string;
  value: string;
  important?: boolean;
  className?: string;
  tooltip?: string;
  htmlValue?: boolean;
  sx?: SxProps<Theme>;
  nowrap?: boolean;
}

const StackedStaticDataDisplay: React.FunctionComponent<Props> = (props) => {
  const { classes } = useStyles(props);

  return (
    <Box className={props.className} sx={props.sx}>
      <Typography
        variant="body1"
        component="div"
        className={classes.label}
        sx={props.nowrap ? { whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" } : {}}>
        {props.label}
      </Typography>
      <Tooltip title={props.tooltip ?? props.nowrap ? props.value : ""}>
        {props.htmlValue ? (
          <Box dangerouslySetInnerHTML={{ __html: props.value }} sx={{ "& > p:first-child": { mt: 0 }, "& > p:last-child": { mb: 0 } }} />
        ) : (
          <Typography
            variant="body1"
            component="div"
            className={props.important ? classes.important : ""}
            sx={props.nowrap ? { whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" } : {}}>
            {props.value}
          </Typography>
        )}
      </Tooltip>
    </Box>
  );
};

export default StackedStaticDataDisplay;
