import { Box, Stack, Typography } from "@mui/material";
import { CompletionMarker } from "checklists/CompletionMarker";
import { ReviewClient } from "practice-reviews";
import React from "react";
import { makeStyles } from "makeStyles";
import { formatDate } from "../../util/formats";

interface Props {
  reviewClient: ReviewClient;
  isSelected: boolean;
  reviewClientClicked: (client: ReviewClient) => void;
  markerClicked: (client: ReviewClient) => void;
  userCanViewReviewClient: boolean;
}

const useStyles = makeStyles()((theme) => ({
  reviewClient: {
    padding: theme.spacing(1),
    display: "flex",
    alignItems: "baseline",
    border: "1px solid transparent",
    "&:hover": {
      border: `1px solid ${theme.palette.cpaAccentLavender.main}`,
      backgroundColor: theme.palette.cpaAccentLavender.light
    },
    cursor: "default"
  },
  selectedReviewClient: {
    border: `1px solid ${theme.palette.cpaAccentTurquoise.main} !important`,
    backgroundColor: `${theme.palette.cpaAccentTurquoise.light} !important`,
    cursor: "inherit !important"
  },
  marker: {
    marginTop: theme.spacing(1)
  },
  refNum: {
    color: theme.palette.cpaGrey.light,
    marginLeft: theme.spacing(3)
  },
  reviewClientDetails: {
    color: theme.palette.cpaGrey.light
  },
  clickableChecklistCompletion: {
    cursor: "pointer"
  }
}));

export const ReviewClientTile: React.FunctionComponent<Props> = (props) => {
  const { classes, cx } = useStyles();

  return (
    <Box
      className={cx(classes.reviewClient, {
        [classes.selectedReviewClient]: props.isSelected
      })}
      onClick={props.userCanViewReviewClient ? () => props.reviewClientClicked!(props.reviewClient) : undefined}
      title={!props.isSelected && props.userCanViewReviewClient ? `Click to edit client details` : undefined}
      sx={{ ml: 4, mr: 2 }}>
      <Box
        sx={{ mr: 2 }}
        className={cx({ [classes.clickableChecklistCompletion]: props.userCanViewReviewClient })}
        onClick={props.userCanViewReviewClient ? () => props.markerClicked!(props.reviewClient) : undefined}
        title={props.userCanViewReviewClient ? "Click to go to checklist" : undefined}>
        <CompletionMarker completion={props.reviewClient.checklistCompletion} className={classes.marker} />
      </Box>
      <div>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h4">{`${props.reviewClient.name}`}</Typography>
          <Typography variant="h4" className={classes.refNum}>
            <span>{props.reviewClient.refNum}</span>
          </Typography>
        </Stack>
        <Stack direction="row" spacing={2} className={cx(classes.reviewClientDetails)}>
          <Typography variant="caption">{props.reviewClient.engagementType?.name}</Typography>
          <Typography variant="caption">{props.reviewClient.reviewer?.name}</Typography>
        </Stack>
      </div>
    </Box>
  );
};
