import React from "react";
import { Button, Dialog, DialogActions, DialogContent, Grid } from "@mui/material";
import ClosableDialogTitle from "common/ClosableDialogTitle";
import { gql, useMutation } from "@apollo/client";
import * as Yup from "yup";
import { Field as FormikField, Form as FormikForm, Formik } from "formik";
import { Validations } from "common/validations/common-yup-validations";
import { TextField as FmuiTextField } from "formik-mui";
import { ChecklistSectionTemplateFieldsFragment, QuestionHeaderTemplateFieldsFragment, QuestionTemplateFieldsFragment } from "../queries";
import { isHeaderTemplate, QuestionContainerTemplate } from "checklists/models";
import { ChecklistSubsectionInput } from "../models";
import { MasterChecklistQuery } from "master-checklist/MasterChecklistScreen";
import { LoadingButton } from "@mui/lab";

const AddSectionMutation = gql`
  ${QuestionTemplateFieldsFragment}
  ${ChecklistSectionTemplateFieldsFragment}
  ${QuestionHeaderTemplateFieldsFragment}
  mutation AddMasterChecklistQuestion($section: ChecklistSubsectionInput!) {
    masterChecklistQuestionContainers {
      addSubsection(section: $section) {
        id
        parentId
        sortOrder
        text
        questions {
          ...QuestionTemplateFields
        }
        ...ChecklistSectionTemplateFields
        ...QuestionHeaderTemplateFields
      }
    }
  }
`;

const EditSectionMutation = gql`
  ${QuestionTemplateFieldsFragment}
  ${ChecklistSectionTemplateFieldsFragment}
  ${QuestionHeaderTemplateFieldsFragment}
  mutation EditMasterChecklistQuestion($section: ChecklistSubsectionInput!) {
    masterChecklistQuestionContainers {
      editSubsection(section: $section) {
        id
        parentId
        sortOrder
        text
        questions {
          ...QuestionTemplateFields
        }
        ...ChecklistSectionTemplateFields
        ...QuestionHeaderTemplateFields
      }
    }
  }
`;

interface EditMasterChecklistSectionDialogProps {
  handleClose: () => void;
  title: string;
  confirmButtonText: string;
  data?: QuestionContainerTemplate;
  container?: QuestionContainerTemplate;
  isHeader: boolean;
}

const EditMasterChecklistSectionDialog = (props: EditMasterChecklistSectionDialogProps) => {
  const validationSchema = Yup.object().shape({
    text: Validations.requiredText()
  });

  const [addSubsection, { loading: loadingAdd }] = useMutation<
    { masterChecklistQuestionContainers: { addSubsection: QuestionContainerTemplate } },
    { section: ChecklistSubsectionInput }
  >(AddSectionMutation, {
    refetchQueries: [{ query: MasterChecklistQuery }],
    awaitRefetchQueries: true
  });

  const [editSubsection, { loading: loadingEdit }] = useMutation<
    { masterChecklistQuestionContainers: { editQuestion: QuestionContainerTemplate } },
    { section: ChecklistSubsectionInput }
  >(EditSectionMutation);

  const mutate = Boolean(props.data) ? editSubsection : addSubsection;
  const loading = Boolean(props.data) ? loadingEdit : loadingAdd;

  return (
    <Dialog open={true} onClose={props.handleClose} fullWidth={true} scroll="paper" maxWidth="sm">
      <ClosableDialogTitle onClose={props.handleClose}>{props.title}</ClosableDialogTitle>
      <Formik
        initialValues={{
          text: props.data?.text ?? "",
          // CPA Refs are allowed but only on Question Headers
          cpaRef: props.data && isHeaderTemplate(props.data) ? props.data?.cpaRef ?? "" : undefined
        }}
        onSubmit={async (values) => {
          let section: ChecklistSubsectionInput = {
            id: props.data?.id,
            text: values.text,
            parentId: props.container?.id,
            isHeader: props.isHeader,
            sortOrder: props.container?.children.length,
            cpaRef: values.cpaRef
          };
          await mutate({
            variables: { section }
          });
          props.handleClose();
        }}
        validationSchema={validationSchema}>
        {(formikProps) => (
          <FormikForm>
            <DialogContent>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <FormikField component={FmuiTextField} name="text" label="Description" required multiline fullWidth />
                </Grid>
                {props.isHeader && (
                  <Grid item>
                    <FormikField component={FmuiTextField} name="cpaRef" label="CPA Ref." fullWidth />
                  </Grid>
                )}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={props.handleClose}>Cancel</Button>
              <LoadingButton color="primary" variant="contained" loading={loading} onClick={() => formikProps.submitForm()}>
                {props.confirmButtonText}
              </LoadingButton>
            </DialogActions>
          </FormikForm>
        )}
      </Formik>
    </Dialog>
  );
};

export default EditMasterChecklistSectionDialog;
