import React from "react";
import { theme as prodTheme, devTheme, uatTheme, localTheme } from "styles/theme";
import { ThemeProvider } from "@mui/material/styles";
import { ThemeCode, useAppConfig } from "util/AppConfig";

export const ThemeProviderByEnvironment: React.FunctionComponent = (props) => {
  const appConfig = useAppConfig();
  if (appConfig?.themeCode && appConfig.themeCode.toLowerCase() !== ThemeCode.PROD.toLowerCase()) {
    const favicon = document.getElementById("favicon") as HTMLLinkElement;
    if (favicon) {
      favicon.href = `/prs-${appConfig.themeCode}.png`;
    }
  }

  return (
    <ThemeProvider
      theme={
        appConfig?.themeCode?.toLowerCase() === ThemeCode.UAT.toLowerCase()
          ? uatTheme
          : appConfig?.themeCode?.toLowerCase() === ThemeCode.DEV.toLowerCase()
          ? devTheme
          : appConfig?.themeCode?.toLowerCase() === ThemeCode.LOCAL.toLowerCase()
          ? localTheme
          : prodTheme
      }>
      {props.children}
    </ThemeProvider>
  );
};
