import { gql } from "@apollo/client";

export const FetchMotionsQuery = gql`
  query FetchMotions {
    motionParagraphs {
      id
      shortDescription
      motionCode
      isActive
      isComply
      isNonComply
      isInUse
      sortOrder
      paragraphForMinutes
      paragraphForDecisionLetter
      motionType {
        id
        name
      }
    }
  }
`;

export const FetchMotionTypesQuery = gql`
  query FetchMotionTypes {
    motionTypes {
      id
      name
      typeCode
    }
  }
`;

export const AddMotionsMutation = gql`
  mutation AddMotion($motion: MotionParagraphInput) {
    motionParagraph {
      add(motion: $motion) {
        id
        shortDescription
        motionCode
        isActive
        isComply
        isNonComply
        sortOrder
        paragraphForMinutes
        paragraphForDecisionLetter
        motionType {
          id
          name
        }
      }
    }
  }
`;

export const UpdateMotionsMutation = gql`
  mutation UpdateMotion($motion: MotionParagraphInput) {
    motionParagraph {
      update(motion: $motion) {
        id
        shortDescription
        motionCode
        isActive
        isComply
        isNonComply
        sortOrder
        paragraphForMinutes
        paragraphForDecisionLetter
        motionType {
          id
          name
        }
      }
    }
  }
`;

export const DeleteMotionsMutation = gql`
  mutation DeleteMotion($id: Int!) {
    motionParagraph {
      delete(id: $id) {
        id
        shortDescription
        motionCode
        isActive
        isComply
        isNonComply
        sortOrder
        paragraphForMinutes
        paragraphForDecisionLetter
        motionType {
          id
          name
        }
      }
    }
  }
`;
