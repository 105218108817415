import { Decision, DecisionTypeCode } from ".";
import { PracticeReview } from "practice-reviews";

export function getManagerDecisionInaccessibleReason(practiceReview: PracticeReview) {
  const reviewerDecision = practiceReview.decisions.filter((d) => d.decisionType.typeCode === DecisionTypeCode.Reviewer)?.[0];

  if (!reviewerDecision?.signedOff) {
    return "The reviewer decision is not signed off.";
  }

  if (!practiceReview.isReturnedComplete) {
    return "The PR has not been returned complete.";
  }

  const managerDecision = practiceReview.decisions.filter((d) => d.decisionType.typeCode === DecisionTypeCode.Manager)?.[0];
  if (!managerDecision) {
    return "The manager decision has not been created yet.";
  }

  return null;
}

export function getDirectorDecisionInaccessibleReason(practiceReview: PracticeReview) {
  const managerDecision = practiceReview.decisions.filter((d) => d.decisionType.typeCode === DecisionTypeCode.Manager)?.[0];
  if (!managerDecision?.signedOff) {
    return "The manager decision is not signed off.";
  }

  const directorDecision = practiceReview.decisions.filter((d) => d.decisionType.typeCode === DecisionTypeCode.Director)?.[0];
  if (!directorDecision) {
    return "The director decision has not been created yet.";
  }

  return null;
}

export function getCommitteeDecisionInaccessibleReason(practiceReview: PracticeReview) {
  const directorDecision = practiceReview.decisions.filter((d) => d.decisionType.typeCode === DecisionTypeCode.Director)?.[0];
  if (!directorDecision?.signedOff) {
    return "The director decision is not signed off.";
  }

  const committeeDecision = practiceReview.decisions.filter((d) => d.decisionType.typeCode === DecisionTypeCode.Committee)?.[0];
  if (!committeeDecision) {
    return "The committee decision has not been created yet.";
  }

  return null;
}

export function getMonitorDirectedPDInaccessibleReason(practiceReview?: PracticeReview) {
  const committeeDecision = practiceReview?.decisions.filter((d) => d.decisionType.typeCode === DecisionTypeCode.Committee)?.[0];
  if (!committeeDecision?.signedOff) {
    return "The committee decision is not signed off.";
  }

  return null;
}

export function shouldConfirmDecisionChange(decision: Decision | null, allDecisions: Decision[], currentIsComply: boolean | null) {
  if (!decision || currentIsComply === null) return false;

  if (decision.decisionType.typeCode === DecisionTypeCode.Manager || decision.decisionType.typeCode === DecisionTypeCode.Director) {
    const previousDecisionCode =
      decision.decisionType.typeCode === DecisionTypeCode.Manager ? DecisionTypeCode.Reviewer : DecisionTypeCode.Manager;
    const previousDecision = allDecisions.find((d) => d.decisionType.typeCode === previousDecisionCode)!;
    return !decision.discussChangesCompleted && !decision.hasOutstandingDiscussChanges && previousDecision.isComply !== currentIsComply;
  } else return false;
}

export function shouldCancelDecisionChange(decision: Decision | null, allDecisions: Decision[], currentIsComply: boolean | null) {
  if (!decision || currentIsComply === null) return false;

  if (decision.decisionType.typeCode === DecisionTypeCode.Manager || decision.decisionType.typeCode === DecisionTypeCode.Director) {
    const previousDecisionCode =
      decision.decisionType.typeCode === DecisionTypeCode.Manager ? DecisionTypeCode.Reviewer : DecisionTypeCode.Manager;
    const previousDecision = allDecisions.find((d) => d.decisionType.typeCode === previousDecisionCode)!;
    return (
      decision.hasOutstandingDiscussChanges &&
      previousDecision.isComply !== decision.isComply &&
      previousDecision.isComply === currentIsComply
    );
  } else return false;
}
