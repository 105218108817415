import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import ClosableDialogTitle from "common/ClosableDialogTitle";
import { DateTime } from "luxon";
import { StatutoryHolidaysQuery } from "./StatHolidaysScreen";
import { gql, useMutation } from "@apollo/client";
import { LoadingButton } from "@mui/lab";
import { makeStyles } from "../makeStyles";

const CopyStatHolidaysMutation = gql`
  mutation CopyStatHolidays($copyFrom: DateTime, $copyTo: DateTime) {
    statutoryHolidays {
      copy(copyFrom: $copyFrom, copyTo: $copyTo)
    }
  }
`;

interface CopyHolidayDialogProps {
  handleClose: () => void;
  copyFromYear: number;
}

const useStyles = makeStyles()((theme) => ({
  input: {
    marginTop: theme.spacing(2),
    width: "80%"
  }
}));

const CopyHolidayDialog = (props: CopyHolidayDialogProps) => {
  const { classes } = useStyles();

  const [copyMutation, { loading }] = useMutation<{ statutoryHolidays: { copy: boolean } }, { copyFrom: string; copyTo: string }>(
    CopyStatHolidaysMutation,
    {
      refetchQueries: [{ query: StatutoryHolidaysQuery }]
    }
  );

  const currentYear = DateTime.now().year;
  const nextYear = currentYear + 1;

  const [copyToYear, setCopyToYear] = useState(props.copyFromYear === currentYear ? nextYear : currentYear);

  return (
    <Dialog open={true} onClose={props.handleClose} fullWidth={true} scroll="paper" maxWidth="sm">
      <ClosableDialogTitle onClose={props.handleClose}>{`Copy All Non-working Days From ${props.copyFromYear}`}</ClosableDialogTitle>
      <DialogContent>
        <Typography>
          Do you want to copy all non-working days from {props.copyFromYear}? Please review copied dates as they may need to be manually
          adjusted.
        </Typography>
        <FormControl className={classes.input}>
          <InputLabel id="copy-to-year-label" variant="outlined">
            Copy to year
          </InputLabel>
          <Select
            label="Copy to year *"
            labelId="copy-to-year-label"
            variant="outlined"
            margin="dense"
            value={copyToYear}
            onChange={(e) => setCopyToYear(e.target.value as number)}
            fullWidth
            required>
            {props.copyFromYear !== currentYear && <MenuItem value={currentYear}>{currentYear}</MenuItem>}
            {props.copyFromYear !== nextYear && <MenuItem value={nextYear}>{nextYear}</MenuItem>}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose}>Cancel</Button>
        <LoadingButton
          loading={loading}
          onClick={async () => {
            await copyMutation({
              variables: {
                copyFrom: DateTime.local(props.copyFromYear).toString(),
                copyTo: DateTime.local(copyToYear).toString()
              }
            });
            props.handleClose();
          }}
          color="primary"
          variant="contained">
          OK
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CopyHolidayDialog;
