import React from "react";
import { IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons/faChevronDown";

import { makeStyles } from "../makeStyles";

const useStyles = makeStyles()((theme) => ({
  expandedIcon: {
    transition: theme.transitions.create("transform", {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.standard
    }),
    "&.rotated": {
      transform: "rotate(180deg)"
    }
  }
}));

interface Props {
  expanded: boolean;
  toggle: () => void;
  className?: string;
  size?: "medium" | "small";
}

const CollapseToggle: React.FunctionComponent<Props> = (props) => {
  const { classes, cx } = useStyles();

  return (
    <IconButton onClick={() => props.toggle()} className={props.className} size={props.size}>
      <FontAwesomeIcon icon={faChevronDown} className={cx(classes.expandedIcon, { rotated: props.expanded })} />
    </IconButton>
  );
};

export default CollapseToggle;
