import { useApolloClient, useMutation, useQuery } from "@apollo/client";
import { DialogContentText, IconButton } from "@mui/material";
import { ConfirmationDialog } from "common/ConfirmationDialog";
import { ScreenHeader } from "common/ScreenHeader";
import gql from "graphql-tag";
import _ from "lodash";
import React, { useCallback } from "react";
import CrudTable from "../common/CrudTable";
import EditSignOffChecklistItemTemplateDialog from "./EditSignOffChecklistItemTemplateDialog";
import { SignOffChecklistItemTemplate, SignOffChecklistItemTemplateInput, SignOffChecklistType } from "./models";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons/faArrowDown";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons/faArrowUp";
import { DecisionType } from "../decisions";
import { makeStyles } from "../makeStyles";

enum Directions {
  Up = -1,
  Down = 1
}

export const SignOffChecklistItemTemplatesQuery = gql`
  query FetchSignOffChecklistItemTemplates {
    signOffChecklistItemTemplates {
      id
      signOffChecklistType {
        id
        name
        typeCode
      }
      questionText
      sortOrder
    }
  }
`;

const SignOffChecklistTypesQuery = gql`
  query FetchSignOffChecklistTypes {
    signOffChecklistTypes {
      id
      name
      typeCode
    }
  }
`;

const DeleteSignOffChecklistItemTemplateMutation = gql`
  mutation DeleteSignOffChecklistItemTemplate($id: Int) {
    signOffChecklistItemTemplates {
      delete(id: $id)
    }
  }
`;

const ReorderSignOffChecklisItemTemplatesMutation = gql`
  mutation ReorderSignOffChecklistItemTemplates($items: [SortSignOffChecklistItemInput]) {
    signOffChecklistItemTemplates {
      reorder(items: $items)
    }
  }
`;

const useStyles = makeStyles()((theme) => ({
  table: {
    marginBottom: theme.spacing(4)
  }
}));

const SignOffChecklistItemTemplatesScreen: React.FunctionComponent = () => {
  const apolloClient = useApolloClient();
  const { classes } = useStyles();

  function getItemsByChecklistType(items: SignOffChecklistItemTemplate[]) {
    return _.groupBy(items, (val) => val.signOffChecklistType.name);
  }

  const [deleteMutation, { loading: deleting }] = useMutation<{ signOffChecklistItemTemplates: { delete: boolean } }, { id: number }>(
    DeleteSignOffChecklistItemTemplateMutation,
    {
      refetchQueries: [{ query: SignOffChecklistItemTemplatesQuery }]
    }
  );

  const [reorderMutation] = useMutation<
    { signOffChecklistItemTemplates: { reorder: boolean } },
    { items: SignOffChecklistItemTemplateInput[] }
  >(ReorderSignOffChecklisItemTemplatesMutation);
  const debouncedReorderMutation = useCallback(_.debounce(reorderMutation, 3000), [reorderMutation]);

  const templatesQuery = useQuery<{ signOffChecklistItemTemplates: SignOffChecklistItemTemplate[] }>(SignOffChecklistItemTemplatesQuery);
  const signOffChecklistItemTemplates = [...(templatesQuery.data?.signOffChecklistItemTemplates ?? [])].sort(
    (a, b) => a.sortOrder - b.sortOrder
  );

  const signOffChecklistTypesQuery = useQuery<{ signOffChecklistTypes: SignOffChecklistType[] }>(SignOffChecklistTypesQuery);
  const signOffChecklistTypes = signOffChecklistTypesQuery.data?.signOffChecklistTypes ?? [];

  const templateGroups = getItemsByChecklistType(signOffChecklistItemTemplates);

  function moveTemplate(templateToMove: SignOffChecklistItemTemplate, listName: string, direction: Directions) {
    const templates = [...templateGroups[listName]];
    const rowIndex = templates.findIndex((t) => t.id === templateToMove.id);

    const siblingNode = templates[rowIndex + direction];
    templates[rowIndex] = siblingNode;
    templates[rowIndex + direction] = templateToMove;

    const updated = templates.map((current, index) => {
      const template = { id: current.id, sortOrder: index };
      apolloClient.cache.modify({
        id: apolloClient.cache.identify(current as any),
        fields: {
          sortOrder: () => index
        }
      });
      return template;
    });
    debouncedReorderMutation({ variables: { items: updated } });
  }

  const getItem = (id: number) => signOffChecklistItemTemplates.find((i) => i.id === id);

  return (
    <>
      <Helmet>
        <title>Sign-off Checklist Items - PRS Online</title>
      </Helmet>
      <ScreenHeader title="Sign-off Checklist Items" />
      {signOffChecklistTypes.map((checklistType) => (
        <div className={classes.table} key={checklistType.id}>
          <CrudTable
            title={checklistType.name}
            rows={templateGroups[checklistType.name] || []}
            columnDefinitions={[
              {
                field: "questionText",
                headerName: "Question Text",
                flex: 1,
                sortable: false
              },
              {
                field: "moveUp",
                headerName: " ",
                renderCell: (params) => {
                  const template = params.row as SignOffChecklistItemTemplate;
                  return template === _.minBy(signOffChecklistItemTemplates, (t) => t.sortOrder) ? null : (
                    <IconButton color="primary" onClick={() => moveTemplate(template, checklistType.name, Directions.Up)}>
                      <FontAwesomeIcon icon={faArrowUp} />
                    </IconButton>
                  );
                }
              },
              {
                field: "moveDown",
                headerName: " ",
                renderCell: (params) => {
                  const template = params.row as SignOffChecklistItemTemplate;
                  return template === _.maxBy(signOffChecklistItemTemplates, (t) => t.sortOrder) ? null : (
                    <IconButton color="primary" onClick={() => moveTemplate(template, checklistType.name, Directions.Down)}>
                      <FontAwesomeIcon icon={faArrowDown} />
                    </IconButton>
                  );
                }
              }
            ]}
            storageKey={`Sign-off items: ${checklistType}`}
            noDataMessage="No items created"
            renderAddDialog={(props) => (
              <EditSignOffChecklistItemTemplateDialog
                title="Add Sign-off Item"
                confirmButtonText="Add"
                handleClose={props.onClose}
                signOffChecklistTypeCode={checklistType.typeCode}
                signOffChecklistTypes={signOffChecklistTypes}
              />
            )}
            renderEditDialog={(id, props) => {
              const item = getItem(id);
              return (
                <EditSignOffChecklistItemTemplateDialog
                  title="Edit Sign-off Item"
                  confirmButtonText="Save"
                  handleClose={props.onClose}
                  signOffChecklistTypeCode={checklistType.typeCode}
                  signOffChecklistTypes={signOffChecklistTypes}
                  checklistItemTemplate={item}
                />
              );
            }}
            renderDeleteDialog={(id, props) => {
              const item = getItem(id);
              return (
                <ConfirmationDialog
                  open={true}
                  title="Delete Sign-off Item?"
                  body={
                    <DialogContentText>{`Are you sure you want to delete the sign-off item: ${item?.questionText}?`}</DialogContentText>
                  }
                  confirm={() => deleteMutation({ variables: { id } }).then(props.onClose)}
                  cancel={props.onClose}
                  loading={deleting}
                />
              );
            }}
          />
        </div>
      ))}
    </>
  );
};

export default SignOffChecklistItemTemplatesScreen;
