import { gql } from "@apollo/client";
import { DecisionFieldsFragment } from "../decisions";

export const PrScreenQuery = gql`
  query GetPracticeReview($id: Int!) {
    practiceReviewById(id: $id) {
      id
      prNumber
      prNumberPrefix
      prStage
      reviewYear
      isNonAssurance
      isDeskReview
      hasIncreasedRisk
      isDeficiencyReportPosted
      hasBeenReturned
      isReturnedComplete
      reasonReturnedIncomplete
      firm {
        id
        name
        entityNumber
        firmStatus
        subStatus
        lastRefreshedFromDataSource
        entityType {
          typeShortName
        }
        address1
        address2
        city
        province
        country
        postalCode
        phoneNumber
        faxNumber
        practiceLocale
        zone
        isCpab
        designatedPartnerFirstName
        designatedPartnerLastName
        designatedPartnerTitle
        designatedPartnerDesignation
        hoDesignatedPartnerFirstName
        hoDesignatedPartnerLastName
        hoDesignatedPartnerTitle
        hoDesignatedPartnerDesignation
        hoDesignatedPartnerEmail
        latestPartners {
          id
          reviewYear

          name
          designation
          yearDesignationObtained
          isOther
          withFirmSince
          compilation
          audit
          review
        }
        latestClienteleProfile {
          firmId
          reviewYear

          publicCompaniesHours
          publicCompaniesClients
          offeringDocumentsHours
          offeringDocumentsClients
          otherEntitiesUnderIfrsHours
          otherEntitiesUnderIfrsClients
          notForProfitHours
          notForProfitClients
          publicSectorHours
          publicSectorClients
          otherAuditHours
          otherAuditClients

          reviewYearEndHours
          reviewYearEndClients
          reviewEntitiesUnderIfrsHours
          reviewEntitiesUnderIfrsClients
          reviewInterimHours
          reviewInterimClients

          businessValuationsHours
          businessValuationsClients
          insolvencyHours
          insolvencyClients
          trustAccountingHours
          trustAccountingClients
          compilationHours
          compilationClients
          handlingOfTrustFundsHours
          handlingOfTrustFundsClients
          accountingServicesHours
          accountingServicesClients
          taxServicesHours
          taxServicesClients
          forensicHours
          forensicClients
          otherServicesHours
          otherServicesClients

          totalHours
          totalClients
        }
      }
      startDate
      endDate
      datesHaveBeenConfirmed
      actualTimeToDate
      timeEntries {
        id
        enteredDate
        enteredHours
        isAdmin
        user {
          id
          entityNumber
          name
        }
      }
      reviewType
      prNotes
      notesFromSchedulingPhase
      status {
        id
        baseStatusCode
        statusName
      }
      phase {
        id
        name
      }
      contactName
      contactTitle
      contactDesignation
      contactPhone
      contactEmail
      contactEmailUri

      schedulingQuestionnaireUrl
      isMissingSchedulingQuestionnaire
      isSchedulingQuestionnaireReviewedByLead

      taxQuestionnaireUrl
      isMissingTaxQuestionnaire
      isTaxQuestionnaireReviewedByLead

      confidentialClientListUrl
      isMissingConfidentialClientList

      deficiencyReportUrl
      practiceReviewReportUrl
      checklistNotesReportUrl
      decisionLetterUrl
      pdDeclarationFormUrl
      finalReportPackageUrl
      directedPdLetterUrl

      firmProfileForReviewYear {
        firmId
        reviewYear

        fullTimePartners
        designatedAccountantsFt
        designatedAccountantsPt
        candidatesFt
        candidatesPt
        otherFt
        otherPt
      }
      hasBeenEstimated
      estimate {
        id
        date
        isFinalized
        compilationTotal
        otherQamTotal
        finalizeReviewTotal
        totalSystemEstimate
        overrideEstimate
        finalEstimate
        notes
        partners {
          id
          firmPartnerId
          timeEstimate
        }
      }
      previousReview {
        id
        prNumberPrefix
        startDate
        actualTimeToDate
        reviewYear
        otherReviewers {
          practiceReviewId
          userId
          user {
            id
            name
          }
        }
        leadReviewer {
          practiceReviewId
          userId
          user {
            id
            name
          }
        }
        status {
          id
          baseStatusCode
        }
        mostAuthoritativeDecision {
          id
          complianceZone {
            id
            zoneCode
          }
        }
      }
      committeeMeeting {
        id
        name
        isOpen
      }
      leadReviewer {
        practiceReviewId
        userId
        user {
          id
          name
        }
        isLead
        reviewerNotes
      }
      otherReviewers {
        practiceReviewId
        userId
        user {
          id
          name
        }
        isLead
        reviewClientsSubmitted
        reviewClientsHaveBeenUnsubmitted
        reviewerNotes
        resubmissionNotes
      }
      upcomingReviewNotice {
        id
        notes
      }
      attachedDocuments {
        id
        type
        typeFriendlyName
        fileName
        url
      }
      sharePointUrl
      levelOfAdjustment {
        id
        level
        reasonForAdjustment
        adjustmentQuestions {
          id
          questionText
          answer
        }
      }
      practiceClients {
        id
        clientName
        toReview
      }
      reviewClients {
        id
        refNum
        name
        partnerName
        engagementType {
          id
          name
        }
        reviewedByUserId
        checklistCompletion {
          someQuestionsIncomplete
        }
        clientFile {
          businessNature
          fiscalYearEnd
          cpabReview
          competitiveBid
          isHoursNotFees
          feesOrHoursAmount
          materiality
          assets
          liabilities
          revenue
          netIncomeBeforeTaxes
          reportDate
        }
      }
      firmChecklistCompletion {
        someQuestionsComplete
        someQuestionsIncomplete
      }
      decisions {
        ...DecisionFields
      }
      firmResponse {
        id
        dateReceived
        firmAcknowledgesReports
        noFirmResponse
        responseHtml
        isRedacted
      }
      mostRecentlyTabled {
        practiceReviewId
        committeeMeetingId
        committeeMeeting {
          id
          name
        }
      }
      isFinalReportReviewedByCoordinator
      exemption {
        id
        status
        startedOn
        approvedByUser {
          id
          name
        }
        endedOn
        endedByUser {
          id
          name
        }
        endedReason
        notes
        letterIsReviewed
        letterIsApproved
        exemptionLetterAcknowledged
      }
      exemptionLetterUrl
      signedExemptionLetterUrl
    }
  }
  ${DecisionFieldsFragment}
`;

export const GeneratePrReportMutation = gql`
  mutation GeneratePrReport($practiceReviewId: Int!) {
    practiceReview {
      generateReport(practiceReviewId: $practiceReviewId, documentType: PracticeReviewReport) {
        id
        practiceReviewReportUrl
      }
    }
  }
`;

export const GenerateDeficiencyReportMutation = gql`
  mutation GenerateDeficiencyReport($practiceReviewId: Int!, $parameters: ReviewReportParametersInput!) {
    practiceReview {
      generateReport(practiceReviewId: $practiceReviewId, documentType: DeficiencyReport, parameters: $parameters) {
        id
        deficiencyReportUrl
      }
    }
  }
`;

export const GenerateChecklistNotesReportMutation = gql`
  mutation GenerateChecklistNotesReport($practiceReviewId: Int!) {
    practiceReview {
      generateReport(practiceReviewId: $practiceReviewId, documentType: ChecklistNotesReport) {
        id
        checklistNotesReportUrl
      }
    }
  }
`;

export const ReturnReviewMutation = gql`
  mutation ReturnReview($practiceReviewId: Int!, $complete: Boolean!, $reasonReturnedIncomplete: String) {
    practiceReview {
      return(practiceReviewId: $practiceReviewId, complete: $complete, reasonReturnedIncomplete: $reasonReturnedIncomplete) {
        id
        hasBeenReturned
        isReturnedComplete
        phase {
          id
          name
        }
      }
    }
  }
`;

export const SubmitReviewClientsMutation = gql`
  mutation SubmitReviewClients($practiceReviewId: Int!, $userId: Int!, $submitted: Boolean!, $resubmissionNotes: String) {
    reviewer {
      submitReviewClients(
        practiceReviewId: $practiceReviewId
        userId: $userId
        submitted: $submitted
        resubmissionNotes: $resubmissionNotes
      ) {
        practiceReviewId
        userId
        reviewClientsSubmitted
        resubmissionNotes
      }
    }
  }
`;

export const SaveSummaryNotesMutation = gql`
  fragment ReviewerNotesFields on Reviewer {
    userId
    practiceReviewId
    reviewerNotes
  }
  mutation SaveNotes($practiceReviewId: Int!, $notes: String) {
    practiceReview {
      saveSummaryNotes(practiceReviewId: $practiceReviewId, notes: $notes) {
        id
        leadReviewer {
          ...ReviewerNotesFields
        }
        otherReviewers {
          ...ReviewerNotesFields
        }
      }
    }
  }
`;

export const ToggleQuestionnaireItemReviewedChecklistItemMutation = gql`
  mutation ToggleQuestionnaireItemReviewedChecklistItem($practiceReviewId: Int!, $reviewedDocument: DocumentTypeCode!) {
    practiceReview {
      toggleQuestionnaireReviewed(practiceReviewId: $practiceReviewId, reviewedDocument: $reviewedDocument) {
        id
        isSchedulingQuestionnaireReviewedByLead
        isTaxQuestionnaireReviewedByLead
      }
    }
  }
`;

export const RemoveAttachedDocumentFromPracticeReviewMutation = gql`
  mutation RemoveAttachedDocumentFromPracticeReview($practiceReviewId: Int!, $attachedDocumentId: Int!, $documentType: DocumentTypeCode!) {
    practiceReview {
      removeAttachedDocument(practiceReviewId: $practiceReviewId, attachedDocumentId: $attachedDocumentId, documentType: $documentType) {
        id
        isMissingSchedulingQuestionnaire
        schedulingQuestionnaireUrl
        isMissingTaxQuestionnaire
        taxQuestionnaireUrl
        isMissingConfidentialClientList
        confidentialClientListUrl
        signedExemptionLetterUrl
        attachedDocuments {
          id
          type
          fileName
          url
        }
      }
    }
  }
`;

export const CreateRedactedCopyOfFirmResponseMutation = gql`
  mutation CreateRedactedCopyOfFirmResponse($practiceReviewId: Int!) {
    practiceReview {
      createRedactedCopyOfFirmResponse(practiceReviewId: $practiceReviewId) {
        id
        attachedDocuments {
          id
          type
          fileName
          url
        }
      }
    }
  }
`;

export const ApproveFinalReportsMutation = gql`
  mutation ApproveFinalReport(
    $committeeMeetingId: Int!
    $prIdsToApproveFinalReports: [Int!]!
    $prIdsToApproveDecisionTabledLetters: [Int!]!
  ) {
    practiceReview {
      approveFinalReports(
        committeeMeetingId: $committeeMeetingId
        prIdsToApproveFinalReports: $prIdsToApproveFinalReports
        prIdsToApproveDecisionTabledLetters: $prIdsToApproveDecisionTabledLetters
      ) {
        id
        status {
          id
          baseStatusCode
          statusName
        }
        phase {
          id
          name
        }
        attachedDocuments {
          id
          type
          fileName
          url
        }
      }
    }
  }
`;

export const ToggleIsDeficiencyReportPostedMutation = gql`
  mutation ToggleIsDeficiencyReportPosted($practiceReviewId: Int!) {
    practiceReview {
      toggleIsDeficiencyReportPosted(practiceReviewId: $practiceReviewId) {
        id
        isDeficiencyReportPosted
      }
    }
  }
`;
