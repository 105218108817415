import { gql, useLazyQuery } from "@apollo/client";
import React from "react";
import { Firm, PracticeReview } from ".";
import { CircularProgress, IconButton, InputAdornment, TextField } from "@mui/material";

import { useNotifications } from "../notifications";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons/faArrowRight";
import { useHistory } from "react-router-dom";

import _ from "lodash";
import { Autocomplete } from "@mui/material";
import { AutocompleteRenderInputParams } from "@mui/material";
import { makeStyles } from "../makeStyles";
import { getRouteForPracticeReviewId } from "./PracticeReviewScreen";

const GetPrByPrNumberQuery = gql`
  query GetPrByPrNumberQuery($prNumber: String!) {
    practiceReviewFromPrNumber(prNumber: $prNumber) {
      id
    }
  }
`;

const GetPrByFirmNameQuery = gql`
  query FirmSearchQuery($queryText: String) {
    firmSearch(queryText: $queryText) {
      name
      latestPracticeReview {
        id
      }
    }
  }
`;

const adornmentHeight = "1.5em";

const useStyles = makeStyles<Props>()((theme, props) => ({
  container: {
    width: props.width ?? "20em"
  },
  textField: {
    fontVariantNumeric: "tabular-nums",
    backgroundColor: theme.palette.common.white
  },
  endAdornment: {
    "& *": {
      padding: 0
    }
  },
  submitIcon: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    borderRadius: "50%",
    padding: "20%",
    marginRight: theme.spacing(1)
  },
  disabled: {
    backgroundColor: theme.palette.cpaLightGrey.main
  }
}));

const isValidPrNumber = (searchText: string) => /^\s*\d+(-\d+)?\s*$/.test(searchText);

interface Props {
  small?: boolean;
  width?: string;
}

const GoToPrField: React.FunctionComponent<Props> = (props) => {
  const { classes, cx } = useStyles(props);
  const notifications = useNotifications();
  const history = useHistory();

  const minSearchLength = 3;
  const [searchText, setSearchText] = React.useState<string>("");

  const searchByPrNumber = async () => {
    if (isValidPrNumber(searchText)) {
      await practiceReviewQuery({ variables: { prNumber: searchText } });
    }
  };

  const getLatestPr = (firm: Firm) => firm.latestPracticeReview;
  const goToPrById = (id: number) => {
    history.push(getPrUrl(id));
  };
  const getPrUrl = (id: number) => getRouteForPracticeReviewId(id);

  const debouncedSearch = _.debounce(async (queryText: string) => {
    if (queryText.length >= minSearchLength) {
      await firmQuery({
        variables: {
          queryText
        }
      });
    }
  }, 200);

  const [practiceReviewQuery, { loading, data: prQueryData, variables }] = useLazyQuery<{ practiceReviewFromPrNumber: PracticeReview }>(
    GetPrByPrNumberQuery,
    {
      variables: {
        prNumber: ""
      }
    }
  );

  const [firmQuery, { data: prsByFirm }] = useLazyQuery<{ firmSearch: Firm[] }>(GetPrByFirmNameQuery, {
    variables: { query: "" }
  });

  React.useEffect(() => {
    if (prQueryData === undefined) {
      return;
    }

    if (prQueryData?.practiceReviewFromPrNumber) {
      goToPrById(prQueryData.practiceReviewFromPrNumber.id);
    } else {
      notifications.info(`PR ${variables?.prNumber} was not found.`);
    }
  }, [prQueryData, variables]);

  return (
    <div className={classes.container}>
      <Autocomplete
        onInputChange={(e, v) => {
          setSearchText(v);
          debouncedSearch(v);
        }}
        onChange={(e, value, reason) => {
          if (reason === "selectOption" && value && typeof value !== "string") {
            goToPrById(getLatestPr(value).id);
          }
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            searchByPrNumber();
          }
        }}
        fullWidth
        freeSolo
        disableClearable
        options={(searchText.length >= minSearchLength ? prsByFirm?.firmSearch : undefined) ?? []}
        filterOptions={(firms: Firm[]) => firms.filter((firm) => firm.latestPracticeReview)}
        getOptionLabel={(option: Firm | string) => (typeof option === "string" ? option : option?.name ?? "")}
        sx={{
          "& .MuiAutocomplete-inputRoot.MuiOutlinedInput-root.MuiInputBase-adornedEnd.MuiInputBase-sizeSmall": {
            paddingRight: "0" // Need to override the padding we have in the theme to compensate for an MUI bug
          }
        }}
        renderInput={(params: AutocompleteRenderInputParams) => (
          <TextField
            {...params}
            label="PR Search"
            placeholder="Search by PR No. or firm"
            size={props.small ? "small" : "medium"}
            margin="none"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment position="end">
                  {loading ? (
                    <CircularProgress size={adornmentHeight} />
                  ) : (
                    <IconButton disabled={!isValidPrNumber(searchText)} onClick={searchByPrNumber} size={props.small ? "small" : "medium"}>
                      <FontAwesomeIcon
                        icon={faArrowRight}
                        className={cx(classes.submitIcon, { [classes.disabled]: !isValidPrNumber(searchText) })}
                      />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
              classes: { root: classes.textField }
            }}
          />
        )}
      />
    </div>
  );
};

export default GoToPrField;
