import React from "react";
import { Link } from "@mui/material";

interface Props {
  href: string;
}

export function msWordUrl(url: string) {
  return `ms-word:ofe|u|${url}`;
}

export const MicrosoftWordLink: React.FunctionComponent<Props> = (props) => {
  return (
    <Link href={msWordUrl(props.href)} target="_blank">
      {props.children}
    </Link>
  );
};
