import React, { useState, useEffect, useContext, createContext } from "react";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { useAppConfig } from "./AppConfig";

export const AppInsightsContext = createContext<ApplicationInsights>(
  new ApplicationInsights({
    config: {
      instrumentationKey: ""
    }
  })
);

export const AppInsightsProvider: React.FunctionComponent = (props) => {
  const [appInsightsInstance, setAppInsightsInstance] = useState<ApplicationInsights>(new ApplicationInsights({ config: {} }));
  const { appInsightsKey } = useAppConfig();

  useEffect(() => {
    if (!appInsightsKey) return;

    const appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: appInsightsKey
      }
    });
    appInsights.loadAppInsights();
    appInsights.trackPageView();

    setAppInsightsInstance(appInsights);
  }, [appInsightsKey]);

  return <AppInsightsContext.Provider value={appInsightsInstance}>{props.children}</AppInsightsContext.Provider>;
};

export function useAppInsights() {
  return useContext(AppInsightsContext);
}
