import { DateTime } from "luxon";
import * as Yup from "yup";
import { StatutoryHoliday } from "stat-holidays";

export const DateRules = {
  isValidDateTime: () => ({
    name: "isDate",
    test: (val: string | undefined) => {
      const testDate = DateTime.fromISO(val ?? "");
      return testDate.isValid;
    },
    message: ValidationMessages.date
  }),

  isWithinYear: (year: number) => ({
    name: "isWithinYear",
    test: (val: string | undefined) => {
      const testDate = DateTime.fromISO(val ?? "");
      return testDate.year === year;
    },
    message: ValidationMessages.dateYear(year)
  }),

  isNotOverlappingHoliday: (holidays: StatutoryHoliday[] | undefined, defaultValue: DateTime | undefined) => ({
    name: "isNotOverlappingHoliday",
    test: (testDate: string | undefined) => {
      const restrictedDates = holidays?.map((h) => h.holidayDate) ?? [];

      return (
        !restrictedDates.some((rd) => rd === testDate) ||
        (defaultValue && testDate ? defaultValue.equals(DateTime.fromISO(testDate)) : false)
      );
    },
    message: ValidationMessages.dateOverlapsHoliday
  })
};

export const StringRules = {
  isUnique: (existing: string[]) => ({
    name: "isUnique",
    test: (val?: string) => (val !== undefined ? !existing.includes(val) : false),
    message: ValidationMessages.unique
  }),
  isValidPrNumber: () => ({
    name: "isValidPrNumber",
    test: (val?: string) => (val !== undefined ? /^\s*\d+(-\d+)?\s*$/.test(val) : false),
    message: ValidationMessages.prNumberFormat
  })
};

export const NumberRules = {
  isTimeIncrement: () => ({
    name: "isTimeIncrement",
    test: (val?: number) => (val !== undefined ? val % 0.25 === 0 : false),
    message: ValidationMessages.isTimeIncrement
  })
};

export const ValidationMessages = {
  required: "Required.",
  positive: "Must be a positive number.",
  nonNegative: "Must be zero or more.",
  date: "Must be a valid date.",
  dateYear: (year: number) => `Date must be within the year ${year}.`,
  dateOverlapsHoliday: "Date is a non-working day.",
  unique: "Must be unique.",
  isTimeIncrement: "Must be quarter hours.",
  prNumberFormat: "Must be a valid PR number.",
  integer: "Must be an integer.",
  number: "Must be a number."
};

export const Validations = {
  requiredText: () => Yup.string().required(ValidationMessages.required),
  requiredNumber: () => Yup.number().typeError(ValidationMessages.number).required(ValidationMessages.required),
  requiredNonNegativeNumber: () =>
    Yup.number().typeError(ValidationMessages.number).min(0, ValidationMessages.positive).required(ValidationMessages.required),
  requiredNonNegativeInteger: () =>
    Yup.number()
      .typeError(ValidationMessages.integer)
      .min(0, ValidationMessages.nonNegative)
      .integer(ValidationMessages.integer)
      .required(ValidationMessages.required),
  requiredPositiveInteger: () =>
    Yup.number()
      .typeError(ValidationMessages.integer)
      .min(1, ValidationMessages.positive)
      .integer(ValidationMessages.integer)
      .required(ValidationMessages.required),
  requiredDate: (message?: string) =>
    Yup.mixed()
      .required(message ?? ValidationMessages.required)
      .test(DateRules.isValidDateTime()),
  requiredUniqueString: (existing: string[]) => Yup.string().required(ValidationMessages.required).test(StringRules.isUnique(existing)),
  requiredTimeIncrement: () =>
    Yup.number().min(0, ValidationMessages.positive).required(ValidationMessages.required).test(NumberRules.isTimeIncrement()),
  requiredPrNumber: () => Yup.string().required(ValidationMessages.required).test(StringRules.isValidPrNumber())
};
