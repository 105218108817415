import { gql } from "@apollo/client";

export const SaveFirmResponseMutation = gql`
  mutation SaveFirmResponse($firmResponse: FirmResponseInput!) {
    firmResponse {
      save(firmResponse: $firmResponse) {
        id
        dateReceived
        firmAcknowledgesReports
        noFirmResponse
        responseHtml
        isRedacted
        practiceReview {
          id
          attachedDocuments {
            id
            type
            typeFriendlyName
            fileName
            url
          }
        }
      }
    }
  }
`;
