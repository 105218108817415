import React from "react";
import { PracticeReview } from "practice-reviews";
import { Grid } from "@mui/material";

import { staticDataStyles } from "../styles/common";
import StackedStaticDataDisplay from "../common/StackedStaticDataDisplay";
import { makeStyles } from "../makeStyles";

const useStyles = makeStyles()((theme) => ({
  ...staticDataStyles(theme),
  header: {
    display: "flex"
  },
  headerDetails: {
    display: "flex",
    marginRight: "auto",
    alignItems: "center",
    ":first-child": {
      marginRight: theme.spacing(8)
    },
    "& > *": {
      marginRight: theme.spacing(4)
    }
  },
  headerActions: {
    display: "flex",
    marginLeft: "auto",
    alignItems: "center",
    "& > *": {
      marginLeft: theme.spacing(4)
    }
  }
}));

interface Props {
  practiceReview: PracticeReview;
}

export const TimeEntriesHeader: React.FunctionComponent<Props> = (props) => {
  const { classes } = useStyles();

  return (
    <Grid container direction="column">
      <Grid item className={classes.header}>
        <div className={classes.headerDetails}>
          <StackedStaticDataDisplay label="Estimated Hours" value={`${props.practiceReview.estimate.finalEstimate ?? 0}h`} />
          <StackedStaticDataDisplay label="Total Hours" value={`${props.practiceReview.actualTimeToDate ?? 0}h`} important={true} />
        </div>
        <div className={classes.headerActions}></div>
      </Grid>
    </Grid>
  );
};
