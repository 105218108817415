import { useMutation } from "@apollo/client";
import { ConfirmationDialog } from "../common/ConfirmationDialog";
import { useNotifications } from "../notifications";
import { DialogContentText } from "@mui/material";
import {
  DecisionAdditionalParagraph,
  DeleteDecisionAdditionalParagraphMutation,
  FetchDecisionAdditionalParagraphsQuery
} from "../decisions";

interface Props {
  handleClose: () => void;
  paragraph: DecisionAdditionalParagraph;
}

const DeleteDecisionAdditionalParagraphDialog = (props: Props) => {
  const notifications = useNotifications();
  const [mutate, { loading: deleting }] = useMutation<
    { decisionAdditionalParagraph: { delete: DecisionAdditionalParagraph } },
    { id: number }
  >(DeleteDecisionAdditionalParagraphMutation, {
    refetchQueries: [{ query: FetchDecisionAdditionalParagraphsQuery }]
  });

  return (
    <>
      <ConfirmationDialog
        open={true}
        title="Delete Additional Paragraph?"
        body={<DialogContentText>Are you sure you want to delete the additional paragraph?</DialogContentText>}
        confirmButtonText="Delete"
        confirm={async () => {
          await mutate({
            variables: { id: props.paragraph.id }
          });
          notifications.success("Additional paragraph deleted.");
          props.handleClose();
        }}
        loading={deleting}
        cancel={props.handleClose}
      />
    </>
  );
};

export default DeleteDecisionAdditionalParagraphDialog;
