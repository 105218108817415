import React, { useState } from "react";
import { PracticeReview } from "practice-reviews";
import {
  Grid,
  Button,
  Typography,
  MenuItem,
  FormControlLabel,
  InputAdornment,
  ListSubheader,
  DialogContentText,
  Tooltip,
  Box
} from "@mui/material";
import { Field as FormikField, Form as FormikForm, Formik, FormikHelpers } from "formik";
import {
  TextField as FmuiTextField,
  CheckboxWithLabel as FmuiCheckboxWithLabel,
  Autocomplete as FmuiAutocomplete,
  AutocompleteRenderInputParams
} from "formik-mui";
import MuiTextField from "@mui/material/TextField";
import MuiRadio from "@mui/material/Radio";
import PrsDatePickerField from "common/FormikFields/PrsDatePickerField";
import { EngagementType, Reviewer, ReviewClient, ReviewClientInput } from "../models";
import { actionStyles } from "styles/common";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamation } from "@fortawesome/free-solid-svg-icons/faExclamation";
import { useCurrentUser } from "users";
import * as Yup from "yup";
import { Validations } from "common/validations/common-yup-validations";
import { ConfirmationDialog } from "common/ConfirmationDialog";
import { FormikFormattedNumericField } from "./FormikFormattedNumericField";
import { DateTime } from "luxon";
import { LoadingButton } from "@mui/lab";
import { makeStyles } from "makeStyles";
import StackedStaticDataDisplay from "../../common/StackedStaticDataDisplay";

const useStyles = makeStyles()((theme) => ({
  ...actionStyles(theme),
  reviewClientDetailsActions: {
    marginTop: theme.spacing(2)
  },
  sectionHeader: {
    marginBottom: theme.spacing(1)
  },
  secondSectionHeader: {
    marginTop: theme.spacing(2)
  },
  hoursFeesAmountField: {
    width: "7em",
    margin: 0
  },
  engagementTypeName: {
    "li &": {
      paddingLeft: theme.spacing(3)
    }
  },
  currentValueEngagementType: {
    justifyContent: "space-between"
  },
  currentValueEngagementTypeCaption: {
    fontSize: "0.7em",
    fontStyle: "italic",
    fontWeight: "bold",
    marginLeft: theme.spacing(4),
    lineHeight: 1
  },
  currentValueWarningOnForm: {
    color: `${theme.palette.cpaAccentYellow.main}`
  },
  oldEngagementType: {
    textDecoration: "line-through",
    fontStyle: "italic",
    marginLeft: theme.spacing(2)
  },
  newEngagementType: {
    fontWeight: "bold",
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2)
  },
  leadMarker: {
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2)
  }
}));

interface Props {
  practiceReview: PracticeReview;
  reviewClientBeingEdited: ReviewClient | null;
  saveReviewClient: (reviewClientInput: ReviewClientInput) => Promise<boolean>;
  reviewers: Reviewer[];
  canEdit: boolean;
  availablePartners: string[];
  engagementTypes: EngagementType[];
  unsavedChanges: () => void;
  loadingAdd: boolean;
  loadingEdit: boolean;
  loadingDelete: boolean;
  userCanDeleteReviewClientBeingEdited: boolean;
  delete: (file: ReviewClient) => void;
  cancel: (() => void) | null;
}

interface FormValues {
  name: string;
  partnerName: string;
  businessNature: string;
  fiscalYearEnd: DateTime | null;
  reportDate: DateTime | null;
  cpabReview: boolean;
  competitiveBid: boolean;
  isHoursNotFees: boolean;
  feesOrHoursAmount: number | "";
  materiality: number | "";
  assets: number | "";
  liabilities: number | "";
  revenue: number | "";
  netIncomeBeforeTaxes: number | "";
  engagementTypeId: number | "";
  refNum: string;
  reviewedByUserId: number;
}

export const ClientFileForm: React.FunctionComponent<Props> = (props) => {
  const { classes, cx } = useStyles();
  const { user, userIsLeadReviewer } = useCurrentUser();

  const [confirmingEngagementTypeChange, setConfirmingEngagementTypeChange] = useState<EngagementType | null>(null);

  async function saveClientFile(values: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    if (
      props.reviewClientBeingEdited &&
      values.engagementTypeId !== props.reviewClientBeingEdited.engagementTypeId &&
      !confirmingEngagementTypeChange
    ) {
      const selectedEngagementType = props.engagementTypes.find((e) => e.id === values.engagementTypeId) ?? null;
      setConfirmingEngagementTypeChange(selectedEngagementType);
    } else {
      setConfirmingEngagementTypeChange(null);

      const reviewClientInput: ReviewClientInput = {
        id: props.reviewClientBeingEdited?.id,
        practiceReviewId: props.practiceReview.id,
        name: values.name,
        partnerName: values.partnerName,
        reviewedByUserId: values.reviewedByUserId,
        refNum: values.refNum ?? "",
        engagementTypeId: values.engagementTypeId as number,
        clientFile: {
          businessNature: values.businessNature,
          fiscalYearEnd: values.fiscalYearEnd!.toISODate(),
          cpabReview: values.cpabReview,
          competitiveBid: values.competitiveBid,
          isHoursNotFees: values.isHoursNotFees,
          feesOrHoursAmount: Number(values.feesOrHoursAmount),
          materiality: values.materiality !== "" ? values.materiality : null,
          assets: values.assets as number,
          liabilities: values.liabilities as number,
          revenue: values.revenue as number,
          netIncomeBeforeTaxes: values.netIncomeBeforeTaxes as number,
          reportDate: values.reportDate ? values.reportDate.toISODate() : null
        }
      };

      const successful = await props.saveReviewClient(reviewClientInput);

      if (successful) {
        formikHelpers.resetForm();
      }
    }
  }

  const initialFormValues: FormValues = props.reviewClientBeingEdited
    ? {
        name: props.reviewClientBeingEdited.name,
        partnerName: props.reviewClientBeingEdited.partnerName,
        businessNature: props.reviewClientBeingEdited.clientFile!.businessNature,
        cpabReview: props.reviewClientBeingEdited.clientFile!.cpabReview,
        competitiveBid: props.reviewClientBeingEdited.clientFile!.competitiveBid,
        isHoursNotFees: props.reviewClientBeingEdited.clientFile!.isHoursNotFees,
        refNum: props.reviewClientBeingEdited.refNum,
        reviewedByUserId: props.reviewClientBeingEdited.reviewedByUserId,
        engagementTypeId: props.reviewClientBeingEdited.engagementTypeId,
        feesOrHoursAmount: props.reviewClientBeingEdited.clientFile!.feesOrHoursAmount,
        materiality: props.reviewClientBeingEdited.clientFile!.materiality ?? "",
        assets: props.reviewClientBeingEdited.clientFile!.assets,
        liabilities: props.reviewClientBeingEdited.clientFile!.liabilities,
        revenue: props.reviewClientBeingEdited.clientFile!.revenue,
        netIncomeBeforeTaxes: props.reviewClientBeingEdited.clientFile!.netIncomeBeforeTaxes,
        fiscalYearEnd: DateTime.fromISO(props.reviewClientBeingEdited.clientFile!.fiscalYearEnd),
        reportDate: props.reviewClientBeingEdited.clientFile!.reportDate
          ? DateTime.fromISO(props.reviewClientBeingEdited.clientFile!.reportDate)
          : null
      }
    : {
        name: "",
        partnerName: "",
        businessNature: "",
        fiscalYearEnd: null,
        cpabReview: false,
        competitiveBid: false,
        isHoursNotFees: false,
        feesOrHoursAmount: "",
        materiality: "",
        assets: "",
        liabilities: "",
        revenue: "",
        netIncomeBeforeTaxes: "",
        engagementTypeId: "",
        refNum: "",
        reviewedByUserId: user.id,
        reportDate: null
      };

  const validationSchema = Yup.object().shape({
    name: Validations.requiredText(),
    partnerName: Validations.requiredText(),
    businessNature: Validations.requiredText(),
    fiscalYearEnd: Validations.requiredDate(),
    engagementTypeId: Validations.requiredNumber(),
    feesOrHoursAmount: Validations.requiredNumber(),
    assets: Validations.requiredNumber(),
    liabilities: Validations.requiredNumber(),
    revenue: Validations.requiredNumber(),
    netIncomeBeforeTaxes: Validations.requiredNumber()
  });

  const engagementTypesInGroups = _(props.engagementTypes).groupBy((e) => e.engagementGroup.name);

  return (
    <Formik initialValues={initialFormValues} validationSchema={validationSchema} enableReinitialize onSubmit={saveClientFile}>
      {(formikProps) => (
        <FormikForm>
          <Grid container columnSpacing={2}>
            {props.reviewers.length > 1 && (
              <Grid item xs={12}>
                {userIsLeadReviewer(props.practiceReview) ? (
                  <FormikField
                    component={FmuiTextField}
                    name="reviewedByUserId"
                    label="Reviewer"
                    fullWidth
                    required
                    select
                    disabled={!userIsLeadReviewer(props.practiceReview)}
                    hidden={!props.canEdit}>
                    {props.reviewers.map((reviewer) => (
                      <MenuItem key={reviewer.user.id} value={reviewer.user.id}>
                        {reviewer.user.name}
                        {reviewer.isLead && <span className={classes.leadMarker}>Lead</span>}
                      </MenuItem>
                    ))}
                  </FormikField>
                ) : (
                  <Box sx={{ mb: 1 }}>
                    <StackedStaticDataDisplay
                      label="Reviewer"
                      value={props.reviewers.find((r) => r.user.id === formikProps.values.reviewedByUserId)?.user.name ?? ""}
                    />
                  </Box>
                )}
              </Grid>
            )}
            <Grid item xs={12}>
              <FormikField
                name="partnerName"
                component={FmuiAutocomplete}
                fullWidth
                freeSolo
                hidden={!props.canEdit}
                options={props.availablePartners}
                value={undefined} // value needs to not be set so that filtering will work on a new file (https://material-ui.com/components/autocomplete/#controllable-states)
                onChange={(e: any, newValue: string) => {
                  formikProps.setFieldValue("partnerName", newValue ?? "");
                  props.unsavedChanges();
                }}
                inputValue={formikProps.values.partnerName}
                onInputChange={(e: any, newValue: string) => {
                  formikProps.setFieldValue("partnerName", newValue ?? "");
                  props.unsavedChanges();
                }}
                renderInput={(params: AutocompleteRenderInputParams) => {
                  return (
                    <>
                      <MuiTextField
                        {...params}
                        error={formikProps.touched.partnerName && Boolean(formikProps.errors.partnerName)}
                        onBlur={() => {
                          formikProps.setFieldTouched("partnerName", true);
                        }}
                        hidden={!props.canEdit}
                        helperText={formikProps.touched.partnerName && formikProps.errors.partnerName}
                        label="Partner Name"
                        required
                      />
                    </>
                  );
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormikField
                component={FmuiTextField}
                name="name"
                label={`Client Name`}
                hidden={!props.canEdit}
                fullWidth
                required
                autoComplete="off"
                inputProps={{
                  "aria-autocomplete": "none",
                  spellCheck: "false"
                }}
                onChange={(e: React.ChangeEvent<any>) => {
                  formikProps.setFieldValue("name", e.target.value);
                  props.unsavedChanges();
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormikField
                component={FmuiTextField}
                name="businessNature"
                label="Nature of Business"
                fullWidth
                required
                autoComplete="off"
                inputProps={{
                  "aria-autocomplete": "none",
                  spellCheck: "false"
                }}
                hidden={!props.canEdit}
                onChange={(e: React.ChangeEvent<any>) => {
                  formikProps.setFieldValue("businessNature", e.target.value);
                  props.unsavedChanges();
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormikField
                component={PrsDatePickerField}
                name="fiscalYearEnd"
                label="Fiscal Year End"
                required
                allowNonWorkingDays
                fullWidth
                hidden={!props.canEdit}
                onBlur={() => {
                  formikProps.setFieldTouched("fiscalYearEnd", true);
                }}
                onChange={(newValue: DateTime) => {
                  formikProps.setFieldValue("fiscalYearEnd", newValue);
                  props.unsavedChanges();
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <FormikField
                component={PrsDatePickerField}
                allowNonWorkingDays
                name="reportDate"
                label="Report Date"
                hidden={!props.canEdit}
                fullWidth
                onChange={(newValue: DateTime) => {
                  formikProps.setFieldValue("reportDate", newValue);
                  props.unsavedChanges();
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormikField
                component={FmuiTextField}
                select
                name="engagementTypeId"
                label="Engagement Type"
                hidden={!props.canEdit}
                fullWidth
                required
                InputProps={
                  props.reviewClientBeingEdited &&
                  formikProps.values.engagementTypeId &&
                  props.reviewClientBeingEdited.engagementTypeId !== formikProps.values.engagementTypeId // Opportunity: could check if anything meaningful would actually be lost
                    ? {
                        startAdornment: (
                          <InputAdornment position="start" className={classes.currentValueWarningOnForm}>
                            <Tooltip
                              title={`The engagement type will be changed from ${props.reviewClientBeingEdited.engagementType?.name}`}>
                              <FontAwesomeIcon icon={faExclamation} />
                            </Tooltip>
                          </InputAdornment>
                        )
                      }
                    : {}
                }
                onChange={(e: React.ChangeEvent<any>) => {
                  formikProps.setFieldValue("engagementTypeId", e.target.value);
                  props.unsavedChanges();
                }}>
                {engagementTypesInGroups
                  .flatMap((types, group) => [
                    <ListSubheader key={group}>{group}</ListSubheader>,
                    ...types.map((t) => (
                      <MenuItem
                        key={t.id}
                        value={t.id}
                        title={
                          props.reviewClientBeingEdited && t.id === props.reviewClientBeingEdited.engagementTypeId
                            ? "This is the currently saved engagement type"
                            : undefined
                        }
                        className={cx({
                          [classes.currentValueEngagementType]:
                            props.reviewClientBeingEdited && t.id === props.reviewClientBeingEdited.engagementTypeId,
                          "not-selected": t.id !== formikProps.values.engagementTypeId
                        })}>
                        <div className={classes.engagementTypeName}>
                          {t.name}

                          {props.reviewClientBeingEdited &&
                            t.id === props.reviewClientBeingEdited.engagementTypeId &&
                            formikProps.values.engagementTypeId &&
                            props.reviewClientBeingEdited.engagementTypeId !== formikProps.values.engagementTypeId && (
                              <>
                                <div className={classes.currentValueEngagementTypeCaption}>Current engagement type</div>
                              </>
                            )}
                        </div>
                      </MenuItem>
                    ))
                  ])
                  .value()}
              </FormikField>
            </Grid>

            <Grid item xs={6}>
              <FormikField
                component={FmuiCheckboxWithLabel}
                type="checkbox"
                hidden={!props.canEdit}
                name="cpabReview"
                Label={{ label: "CPAB Review" }}
                onChange={(e: React.ChangeEvent<any>, newValue: boolean) => {
                  formikProps.setFieldValue("cpabReview", newValue);
                  props.unsavedChanges();
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <FormikField
                component={FmuiCheckboxWithLabel}
                type="checkbox"
                hidden={!props.canEdit}
                name="competitiveBid"
                Label={{ label: "Competitive Bid" }}
                onChange={(e: React.ChangeEvent<any>, newValue: boolean) => {
                  formikProps.setFieldValue("competitiveBid", newValue);
                  props.unsavedChanges();
                }}
              />
            </Grid>

            <Grid item xs={6} sx={{ mt: 2 }}>
              <div>
                <FormControlLabel
                  label={`Fees${!formikProps.values.isHoursNotFees ? ":" : ""}`}
                  control={
                    <MuiRadio
                      hidden={!props.canEdit}
                      checked={!formikProps.values.isHoursNotFees}
                      onClick={() => {
                        formikProps.setFieldValue("isHoursNotFees", false);
                        props.unsavedChanges();
                      }}
                    />
                  }
                />
                {!formikProps.values.isHoursNotFees && (
                  <FormikFormattedNumericField
                    formikProps={formikProps}
                    className={classes.hoursFeesAmountField}
                    fieldName="feesOrHoursAmount"
                    hidden={!props.canEdit}
                    label="Fees"
                    required
                    money
                    reportUnsavedChanges
                  />
                )}
              </div>
            </Grid>
            <Grid item xs={6} sx={{ mt: 2 }}>
              <div>
                <FormControlLabel
                  label={`Hours${formikProps.values.isHoursNotFees ? ":" : ""}`}
                  control={
                    <MuiRadio
                      hidden={!props.canEdit}
                      checked={formikProps.values.isHoursNotFees}
                      onClick={() => {
                        formikProps.setFieldValue("isHoursNotFees", true);
                        props.unsavedChanges();
                      }}
                    />
                  }
                />

                {formikProps.values.isHoursNotFees && (
                  <FormikFormattedNumericField
                    formikProps={formikProps}
                    className={classes.hoursFeesAmountField}
                    fieldName="feesOrHoursAmount"
                    hidden={!props.canEdit}
                    label="Hours"
                    required
                    hours
                    reportUnsavedChanges
                  />
                )}
              </div>
            </Grid>
          </Grid>

          <Grid container columnSpacing={2} sx={{ mt: 3 }}>
            <Grid item>
              <Typography variant="h4" className={cx(classes.sectionHeader, classes.secondSectionHeader)}>
                Summary of Financial Results
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <FormikFormattedNumericField
                formikProps={formikProps}
                fieldName="materiality"
                label="Materiality"
                hidden={!props.canEdit}
                money
                fullWidth
                reportUnsavedChanges
              />
            </Grid>
            <Grid item xs={6}>
              <FormikFormattedNumericField
                formikProps={formikProps}
                fieldName="assets"
                label="Assets"
                hidden={!props.canEdit}
                money
                fullWidth
                required
                reportUnsavedChanges
              />
            </Grid>
            <Grid item xs={6}>
              <FormikFormattedNumericField
                formikProps={formikProps}
                fieldName="liabilities"
                label="Liabilities"
                hidden={!props.canEdit}
                money
                fullWidth
                required
                reportUnsavedChanges
              />
            </Grid>
            <Grid item xs={6}>
              <FormikFormattedNumericField
                formikProps={formikProps}
                fieldName="revenue"
                label="Revenue"
                hidden={!props.canEdit}
                money
                fullWidth
                required
                reportUnsavedChanges
              />
            </Grid>
            <Grid item xs={12}>
              <FormikFormattedNumericField
                formikProps={formikProps}
                fieldName="netIncomeBeforeTaxes"
                label="Net Income (Loss) Before Taxes"
                hidden={!props.canEdit}
                money
                allowNegative
                fullWidth
                required
                reportUnsavedChanges
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="space-between">
            <div className={cx(classes.actions, classes.reviewClientDetailsActions)}>
              {props.reviewClientBeingEdited && props.userCanDeleteReviewClientBeingEdited && (
                <LoadingButton
                  color="error"
                  variant="text"
                  disabled={props.loadingAdd || props.loadingEdit}
                  hidden={!props.canEdit}
                  loading={props.loadingDelete}
                  onClick={() => props.delete(props.reviewClientBeingEdited!)}>
                  Delete
                </LoadingButton>
              )}
            </div>
            <div className={cx(classes.actions, classes.reviewClientDetailsActions)}>
              {props.cancel && ( // can't close the dialog if there are no files to show
                <Button hidden={!props.canEdit} variant="text" onClick={() => props.cancel!()}>
                  Cancel
                </Button>
              )}
              <LoadingButton
                color="primary"
                variant="contained"
                disabled={props.loadingDelete}
                hidden={!props.canEdit}
                loading={props.loadingAdd || props.loadingEdit}
                onClick={() => formikProps.submitForm()}>
                Save
              </LoadingButton>
            </div>
          </Grid>
          {confirmingEngagementTypeChange && (
            <ConfirmationDialog
              open={true}
              body={
                <>
                  <DialogContentText>This client file's engagement type will be changed:</DialogContentText>
                  <DialogContentText className={classes.oldEngagementType}>
                    {props.reviewClientBeingEdited?.engagementType.name}
                  </DialogContentText>
                  <DialogContentText className={classes.newEngagementType}>{confirmingEngagementTypeChange.name}</DialogContentText>
                  <DialogContentText>
                    Any answers to checklist questions that are not part of the new engagement type will be deleted.
                  </DialogContentText>
                  <DialogContentText>Are you sure you want to proceed?</DialogContentText>
                </>
              }
              title="Change the Engagement Type?"
              cancel={() => setConfirmingEngagementTypeChange(null)}
              confirm={() => formikProps.submitForm()}
            />
          )}
        </FormikForm>
      )}
    </Formik>
  );
};
