export class BrowserConsoleDebugLogger {
  showBrowserConsoleDebugLogs: boolean;

  constructor(showBrowserConsoleDebugLogs: boolean) {
    this.showBrowserConsoleDebugLogs = showBrowserConsoleDebugLogs;
  }

  log(input: string) {
    if (this.showBrowserConsoleDebugLogs) console.log(input);
  }
}
