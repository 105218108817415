import { Grid } from "@mui/material";
import React from "react";
import FirmStatus from "../../practice-reviews/FirmStatus";
import { faCity } from "@fortawesome/free-solid-svg-icons/faCity";
import { faBuilding } from "@fortawesome/free-solid-svg-icons/faBuilding";
import { PracticeReview } from "practice-reviews";
import { actionStyles, staticDataStyles } from "../../styles/common";
import _ from "lodash";
import InlineStaticDataDisplay from "../../common/InlineStaticDataDisplay";
import { makeStyles } from "../../makeStyles";

const useStyles = makeStyles()((theme) => ({
  ...staticDataStyles(theme),
  ...actionStyles(theme),
  root: {
    "& > :not(:first-child)": {
      marginTop: theme.spacing(3)
    }
  },
  headerContainer: {
    display: "flex",
    marginRight: theme.spacing(3),
    "& svg": {
      marginRight: theme.spacing(1),
      color: theme.palette.text.secondary
    }
  }
}));

interface Props {
  practiceReview: PracticeReview;
}

const SchedulingHeader: React.FunctionComponent<Props> = (props) => {
  const { classes } = useStyles();
  const practiceReview = props.practiceReview;

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} lg={4}>
        <div className={classes.headerContainer}>
          <FirmStatus firm={practiceReview.firm} />
        </div>
      </Grid>
      <Grid item xs={12} lg={4}>
        <InlineStaticDataDisplay label="Main Office" value={practiceReview.firm.city} icon={faCity} />
      </Grid>
    </Grid>
  );
};

export default SchedulingHeader;
