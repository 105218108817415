import React from "react";
import { makeStyles } from "../makeStyles";
import { EnhancedMergeField, MergeFieldBehavior } from "common/HtmlMergeFields/models";
import { CollapsibleMergeFieldChildren } from "./CollapsibleMergeFieldChildren";
import { relative } from "path/posix";
import {
  getAsposeMergeFieldTextForInsert,
  ensureMergeFieldBehaviorTypeRepresentation,
  getMergeFieldTypeDisplayText
} from "common/HtmlMergeFields/utilities";

const useStyles = makeStyles()((theme) => ({
  fieldList: {
    listStyleType: "none",
    paddingBottom: "10px",
    paddingTop: "5px"
  },
  topLevel: {
    paddingLeft: "0",
    margin: "0",
    maxWidth: "800px",
    fontFamily: "Assistant,Helvetica,Arial,Sans-Serif",
    fontSize: "1rem",
    fontWeight: "500"
  },
  enhancedFieldOption: {
    position: "relative",
    backgroundColor: theme.palette.common.white,
    paddingLeft: "0px !important",
    paddingRight: "0px !important",
    border: "1px solid #CCC",
    marginTop: "-1px",
    "&.Object": {
      backgroundColor: "#F9F9F9"
    },
    "&.Field:hover": {
      backgroundColor: "#F0FFF0"
    },
    "&.RichTextHtml:hover": {
      backgroundColor: "#F0FFF0"
    },

    "&.Boolean:hover": {
      backgroundColor: "#FFF0FF"
    },

    "&.List:hover": {
      backgroundColor: "#F0F0FF"
    },

    "&.Document:hover": {
      backgroundColor: "#fff3eb"
    },

    "&.List.notTopLevel:hover": {
      borderLeftStyle: "dotted"
    }
  },
  clickableRegion: {
    cursor: "pointer"
  },
  fieldName: {
    paddingLeft: "16px",
    paddingRight: "16px",
    paddingTop: "2px"
  },
  fieldType: {
    fontSize: "small",
    fontStyle: "italic",
    paddingLeft: "24px",
    paddingRight: "16px",
    ".Object&": {
      color: "#8d8d8d"
    },
    ".List&": {
      color: "#0d30b7"
    },
    ".Field&": {
      color: "#215921"
    },
    ".RichTextHtml&": {
      color: "#215921"
    },
    ".Boolean&": {
      color: "#ad24ad"
    },
    ".Document&": {
      color: "#ab4400"
    }
  },
  fieldDescription: {
    fontSize: "0.75rem",
    fontWeight: "lighter",
    lineHeight: "1",
    paddingLeft: "24px",
    paddingRight: "16px",
    paddingBottom: "10px"
  },
  nestedUnder: {
    paddingLeft: "0px",
    marginLeft: "15px",
    borderLeft: "1px solid #CCC"
  },
  notRelevant: {
    backgroundColor: "#f3f3f3",
    color: "gray",
    fontSize: "small",
    border: "1px solid #DDD"
  }
}));

interface Props {
  fields: EnhancedMergeField[] | undefined;
  topLevel?: boolean;
  onInsertClick?: (textToInsert: string) => void;
  cookieTrail?: string;
}

export const MergeFieldDisplay: React.FunctionComponent<Props> = (props) => {
  const { cx, classes } = useStyles();
  function clickedForInsert(field: EnhancedMergeField) {
    if (props.onInsertClick) {
      var text = getAsposeMergeFieldTextForInsert(field);
      props.onInsertClick(text);
    }
  }
  return (
    <ul className={cx(classes.fieldList, { [classes.topLevel]: props.topLevel })}>
      {props.fields?.map((field) => {
        let ensuredType = ensureMergeFieldBehaviorTypeRepresentation(field.type);
        return (
          <li title="" className={cx(classes.enhancedFieldOption, ensuredType, { ["notTopLevel"]: !props.topLevel })} key={field.id}>
            <div className={classes.clickableRegion} onClick={() => clickedForInsert(field)}>
              <div className={classes.fieldName}>{field.field}</div>
              <div className={cx(classes.fieldType, ensuredType)}>{getMergeFieldTypeDisplayText(ensuredType)}</div>
              {field.description && <div className={classes.fieldDescription}>{field.description}</div>}
            </div>
            {Boolean(field.children?.length) && <CollapsibleMergeFieldChildren field={field} onInsertClick={props.onInsertClick} />}
          </li>
        );
      })}
    </ul>
  );
};
