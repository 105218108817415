import React from "react";
import { Button, Dialog, DialogActions, DialogContent, FormControl, useTheme } from "@mui/material";
import ClosableDialogTitle from "common/ClosableDialogTitle";
import { useMutation } from "@apollo/client";
import { Field as FormikField, Form as FormikForm, Formik } from "formik";
import { TextField as FmuiTextField, CheckboxWithLabel as FmuiCheckbox } from "formik-mui";
import RichTextEditor from "../common/RichTextEditor";
import { LoadingButton } from "@mui/lab";
import { MergeFieldType } from "../common/HtmlMergeFields/models";
import { getHtmlForField, htmlHasContent } from "../util/utilities";
import {
  AddDecisionAdditionalParagraphMutation,
  DecisionAdditionalParagraph,
  DecisionAdditionalParagraphInput,
  FetchDecisionAdditionalParagraphsQuery,
  UpdateDecisionAdditionalParagraphMutation
} from ".";

import * as Yup from "yup";
import _ from "lodash";

interface Props {
  handleClose: () => void;
  title: string;
  confirmButtonText: string;
  paragraph?: DecisionAdditionalParagraph;
}

interface FormValues {
  isActive: boolean;
  name: string | null;
  paragraphForMinutes: string | null;
  paragraphForDecisionLetter: string | null;
}

const EditDecisionAdditionalParagraphDialog = (props: Props) => {
  const theme = useTheme();

  const minutesContentRetriever = React.useRef<{ getContentAsHtml: () => string | null }>({ getContentAsHtml: () => null });
  const letterContentRetriever = React.useRef<{ getContentAsHtml: () => string | null }>({ getContentAsHtml: () => null });

  const [addMutation, { loading: loadingAdd }] = useMutation<
    {
      decisionAdditionalParagraph: { update: DecisionAdditionalParagraph };
    },
    { decisionAdditionalParagraph: DecisionAdditionalParagraphInput }
  >(AddDecisionAdditionalParagraphMutation, {
    refetchQueries: [{ query: FetchDecisionAdditionalParagraphsQuery }]
  });

  const [updateMutation, { loading: loadingUpdate }] = useMutation<
    {
      decisionAdditionalParagraph: { update: DecisionAdditionalParagraph };
    },
    { decisionAdditionalParagraph: DecisionAdditionalParagraphInput }
  >(UpdateDecisionAdditionalParagraphMutation);

  const mutate = props.paragraph?.id === undefined ? addMutation : updateMutation;
  const loading = loadingAdd || loadingUpdate;

  const initialValues: FormValues = {
    isActive: props.paragraph?.isActive ?? true,
    name: props.paragraph?.name ?? null,
    paragraphForMinutes: props.paragraph?.paragraphForMinutes ?? null,
    paragraphForDecisionLetter: props.paragraph?.paragraphForDecisionLetter ?? null
  };

  const save = async (values: FormValues) => {
    const input: DecisionAdditionalParagraphInput = {
      id: props.paragraph?.id,
      name: values.name!,
      isActive: values.isActive,
      paragraphForMinutes: getHtmlForField(minutesContentRetriever) ?? "",
      paragraphForDecisionLetter: getHtmlForField(letterContentRetriever) ?? ""
    };

    await mutate({
      variables: { decisionAdditionalParagraph: input }
    });

    props.handleClose();
  };

  const validationSchema = Yup.object({
    name: Yup.string().nullable().required("Enter a name."),
    paragraphForMinutes: Yup.string()
      .nullable()
      .test("empty", "Enter the paragraph to appear in the minutes.", () => getHtmlForField(minutesContentRetriever) !== null),
    paragraphForDecisionLetter: Yup.string()
      .nullable()
      .test("empty", "Enter the paragraph to appear in the decision letter.", () => getHtmlForField(letterContentRetriever) !== null)
  });

  return (
    <Dialog open={true} onClose={props.handleClose} fullWidth={true} scroll="body" maxWidth="md">
      <ClosableDialogTitle onClose={props.handleClose}>{props.title}</ClosableDialogTitle>
      <Formik initialValues={initialValues} onSubmit={save} validationSchema={validationSchema}>
        {(formikProps) => (
          <>
            <DialogContent>
              <FormikField component={FmuiTextField} name="name" label="Name" fullWidth required />

              <div>
                <FormikField type="checkbox" component={FmuiCheckbox} name="isActive" Label={{ label: "Active" }} />
              </div>
              <FormControl sx={{ mt: 1 }}>
                <FormikField
                  component={RichTextEditor}
                  name="paragraphForMinutes"
                  label="Paragraph for Minutes"
                  html={formikProps.values.paragraphForMinutes}
                  passContentRetriever={(getContentAsHtml: any) => {
                    minutesContentRetriever.current = { getContentAsHtml };
                  }}
                  showMergeFields
                  mergeFieldType={MergeFieldType.MeetingMinutes}
                  required
                  templateMarkup
                  error={
                    formikProps.errors.paragraphForMinutes && formikProps.touched.paragraphForMinutes
                      ? formikProps.errors.paragraphForMinutes
                      : undefined
                  }
                />
              </FormControl>
              <FormControl sx={{ mt: 2 }}>
                <FormikField
                  component={RichTextEditor}
                  name="paragraphForDecisionLetter"
                  label="Paragraph for Decision Letter"
                  html={formikProps.values.paragraphForDecisionLetter}
                  passContentRetriever={(getContentAsHtml: any) => {
                    letterContentRetriever.current = { getContentAsHtml };
                  }}
                  showMergeFields
                  mergeFieldType={MergeFieldType.DecisionLetter}
                  required
                  templateMarkup
                  error={
                    formikProps.errors.paragraphForDecisionLetter && formikProps.touched.paragraphForDecisionLetter
                      ? formikProps.errors.paragraphForDecisionLetter
                      : undefined
                  }
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={props.handleClose}>Cancel</Button>
              <LoadingButton color="primary" variant="contained" loading={loading} onClick={() => formikProps.submitForm()}>
                {props.confirmButtonText}
              </LoadingButton>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default EditDecisionAdditionalParagraphDialog;
