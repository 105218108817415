import { useMutation } from "@apollo/client";
import { ConfirmationDialog } from "../common/ConfirmationDialog";
import { useNotifications } from "../notifications";
import { Alert, DialogContentText, Link, Stack } from "@mui/material";
import { BinderJob, CommitteeMeeting } from "./models";
import { FetchBinderJobsQuery, AssembleBinderMutation, FetchBinderJobByMeetingIdQuery } from ".";

interface Props {
  onClose: () => void;
  committeeMeeting: CommitteeMeeting;
}

const ConfirmAssembleBinderDialog = (props: Props) => {
  const notifications = useNotifications();
  const [assembleMutate, assembleMutation] = useMutation<{ committeeMeeting: { assembleBinder: BinderJob } }, { meetingId: number }>(
    AssembleBinderMutation,
    {
      refetchQueries: [
        { query: FetchBinderJobsQuery },
        { query: FetchBinderJobByMeetingIdQuery, variables: { meetingId: props.committeeMeeting.id } }
      ]
    }
  );

  return (
    <>
      <ConfirmationDialog
        noDanger
        open={true}
        title={`${props.committeeMeeting.assembledBinders?.length ? "Reassemble" : "Assemble"} Binder`}
        body={
          <>
            <DialogContentText>{`Assemble a new binder PDF for "${props.committeeMeeting.name}"? This will:`}</DialogContentText>
            <DialogContentText>
              <ul>
                <li>
                  Get the latest version of each document from the libraries in SharePoint and compile them into a freshly assembled PDF
                  file,
                </li>
                <li>Save a timestamped copy of this assembled binder in the meeting's library folder in SharePoint.</li>
              </ul>
            </DialogContentText>
            <DialogContentText>
              This fresh binder file will include any changes that have recently been made to the Agenda, Office Listing, or Standard Motion
              Summary documents in the meeting folder.
            </DialogContentText>
            {(props.committeeMeeting.assembledBinders?.length ?? 0) > 0 && (
              <Alert color="warning">
                It will not include any changes that may have been made to previously assembled copies of the binder:
                <ul>
                  {props.committeeMeeting.assembledBinders?.map((binder) => {
                    return (
                      <li>
                        <Link href={`${props.committeeMeeting.sharePointUrl}/${binder.fileName}`}>{binder.fileName}</Link>
                      </li>
                    );
                  })}
                </ul>
              </Alert>
            )}
          </>
        }
        confirm={async () => {
          const result = await assembleMutate({
            variables: { meetingId: props.committeeMeeting.id }
          });

          if (result.data?.committeeMeeting.assembleBinder) {
            notifications.success("Started assembling the binder.");
          }

          props.onClose();
        }}
        loading={assembleMutation.loading}
        cancel={props.onClose}
      />
    </>
  );
};

export default ConfirmAssembleBinderDialog;
