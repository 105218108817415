import React from "react";
import { PracticeReview } from "practice-reviews";
import { Typography } from "@mui/material";
import { makeStyles } from "../../makeStyles";
import { DecisionTypeCode } from "../../decisions";
import { YesNoIcon } from "../../common/YesNoIcon";
import { summaryChecklistStyles } from "../../styles/common";
import { SummaryTabHeader } from "./SummaryTabHeader";
import { PrReportLinkAndActions } from "../report-links/PrReportLinkAndActions";
import { DeficiencyReportLinkAndActions } from "../report-links/DeficiencyReportLinkAndActions";
import { useCurrentUser } from "users";

interface Props {
  practiceReview: PracticeReview;
  userIsReviewerOnPr: boolean;
}

export const DecisionSummary: React.FunctionComponent<Props> = (props) => {
  const useStyles = makeStyles()((theme) => ({
    ...summaryChecklistStyles(theme)
  }));
  const { userIsLeadReviewer } = useCurrentUser();
  const { classes } = useStyles();
  const reviewerDecision = props.practiceReview.decisions.find((d) => d.decisionType.typeCode === DecisionTypeCode.Reviewer) ?? null;

  return (
    <div>
      <SummaryTabHeader header="Reviewer Decision" />
      <div className={classes.checklist}>
        <div className={classes.checklistRow}>
          <div className={classes.checklistItemState}>
            <YesNoIcon yes={props.practiceReview.deficiencyReportUrl !== null} />
          </div>
          <DeficiencyReportLinkAndActions
            practiceReview={props.practiceReview}
            buttonSize="small"
            documentClass={classes.checklistItem}
            actionClass={classes.checklistItemAction}
            userCanPostToPortal={
              userIsLeadReviewer(props.practiceReview) || (!props.userIsReviewerOnPr && props.practiceReview.hasBeenReturned)
            }
          />
        </div>
        <div className={classes.checklistRow}>
          <div className={classes.checklistItemState}>
            <YesNoIcon yes={reviewerDecision?.isComplete ?? false} />
          </div>
          <div className={classes.checklistItem}>
            <Typography variant="body1">Complete the Reviewer Decision</Typography>
          </div>
        </div>
        <div className={classes.checklistRow}>
          <div className={classes.checklistItemState}>
            <YesNoIcon yes={props.practiceReview.practiceReviewReportUrl !== null} />
          </div>
          <PrReportLinkAndActions
            practiceReview={props.practiceReview}
            buttonSize="small"
            documentClass={classes.checklistItem}
            actionClass={classes.checklistItemAction}
          />
        </div>
        <div className={classes.checklistRow}>
          <div className={classes.checklistItemState}>
            <YesNoIcon yes={reviewerDecision?.signedOff ?? false} />
          </div>
          <div className={classes.checklistItem}>
            <Typography variant="body1">Signed Off</Typography>
          </div>
        </div>
      </div>
    </div>
  );
};
