import React from "react";
import { PracticeReview } from "practice-reviews";
import { Typography } from "@mui/material";
import { makeStyles } from "../../makeStyles";
import { YesNoIcon } from "../../common/YesNoIcon";
import { summaryChecklistStyles } from "../../styles/common";
import { SummaryTabHeader } from "./SummaryTabHeader";

interface Props {
  practiceReview: PracticeReview;
}

export const FirmChecklistComplete: React.FunctionComponent<Props> = (props) => {
  const useStyles = makeStyles()((theme) => ({
    ...summaryChecklistStyles(theme)
  }));

  const { classes } = useStyles();
  return (
    <div>
      <SummaryTabHeader header="Firm Checklist" />
      <div className={classes.checklist}>
        <div className={classes.checklistRow}>
          <div className={classes.checklistItemState}>
            <YesNoIcon
              yes={
                props.practiceReview.firmChecklistCompletion.someQuestionsComplete &&
                !props.practiceReview.firmChecklistCompletion.someQuestionsIncomplete
              }
            />
          </div>
          <div className={classes.checklistItem}>
            <Typography variant="body1">Complete the Firm Checklist</Typography>
          </div>
        </div>
      </div>
    </div>
  );
};
