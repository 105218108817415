import { useMutation, useQuery } from "@apollo/client";
import { Button, DialogContentText } from "@mui/material";

import { ConfirmationDialog } from "common/ConfirmationDialog";
import { ScreenHeader } from "common/ScreenHeader";
import gql from "graphql-tag";
import _ from "lodash";
import { DateTime } from "luxon";
import React, { useState } from "react";
import { formatDate } from "util/formats";
import CrudTable from "../common/CrudTable";
import EditHolidayDialog from "./EditHolidayDialog";
import { StatutoryHoliday } from "./models";
import { Helmet } from "react-helmet";
import CopyHolidayDialog from "./CopyHolidayDialog";
import { makeStyles } from "../makeStyles";

export const StatutoryHolidaysQuery = gql`
  query FetchStatutoryHolidays {
    statutoryHolidays {
      id
      holidayName
      holidayDate
    }
  }
`;

const DeleteStatHolidayMutation = gql`
  mutation DeleteStatHoliday($id: Int) {
    statutoryHolidays {
      delete(id: $id)
    }
  }
`;

const useStyles = makeStyles()(() => ({
  table: {
    marginBottom: "2em"
  }
}));

const StatHolidaysScreen: React.FunctionComponent = () => {
  const { classes } = useStyles();

  function getHolidayGroupByYear(holidays: StatutoryHoliday[]) {
    return _.groupBy(holidays, (val) => DateTime.fromISO(val.holidayDate).year);
  }

  function getYears(data: StatutoryHoliday[]) {
    var yearsInData = Object.keys(getHolidayGroupByYear(data)).map(Number);
    var currentYear = DateTime.now().year;
    var nextYear = currentYear + 1;
    return new Set([currentYear, nextYear, ...yearsInData]);
  }

  const [deleteMutation, { loading: deleting }] = useMutation<{ statutoryHolidays: { delete: boolean } }, { id: number }>(
    DeleteStatHolidayMutation,
    {
      refetchQueries: [{ query: StatutoryHolidaysQuery }]
    }
  );

  const [copyYear, setCopyYear] = useState<number | null>(null);

  const queryData = useQuery<{ statutoryHolidays: StatutoryHoliday[] }>(StatutoryHolidaysQuery);
  const statutoryHolidays = queryData.data?.statutoryHolidays ?? [];

  const years = Array.from(getYears(statutoryHolidays)).sort((a, b) => Number(b) - Number(a));
  const dataGroups = getHolidayGroupByYear(statutoryHolidays);

  const getHoliday = (id: number) => statutoryHolidays.find((h) => h.id === id);

  return (
    <>
      <Helmet>
        <title>Non-working Days - PRS Online</title>
      </Helmet>
      <ScreenHeader title="Non-working Days" />
      {years.map((year) => (
        <div className={classes.table} key={year}>
          <CrudTable
            title={year.toString()}
            rows={dataGroups[year] || []}
            columnDefinitions={[
              {
                field: "holidayName",
                headerName: "Name",
                flex: 30,
                sortable: false
              },
              {
                field: "holidayDate",
                headerName: "Date",
                flex: 100,
                valueFormatter: (params) => formatDate(params.value as string)
              }
            ]}
            storageKey={`Non-working days ${year}`}
            noDataMessage="No holidays created"
            sortModel={[
              {
                field: "holidayDate",
                sort: "asc"
              }
            ]}
            renderAddDialog={(props) => (
              <EditHolidayDialog title="Add Non-working Day" confirmButtonText="Add" handleClose={props.onClose} year={year} />
            )}
            renderEditDialog={(id, props) => (
              <EditHolidayDialog
                title="Edit Non-working Day"
                confirmButtonText="Save"
                handleClose={props.onClose}
                year={year}
                holiday={getHoliday(id)}
              />
            )}
            renderDeleteDialog={(id, props) => (
              <ConfirmationDialog
                open={true}
                title="Delete Non-working Day?"
                body={
                  <DialogContentText>
                    {`Are you sure you want to delete the non-working day: ${statutoryHolidays.find((h) => h.id === id)?.holidayName}?`}
                  </DialogContentText>
                }
                noDanger={false}
                confirm={() => deleteMutation({ variables: { id: id } }).then(props.onClose)}
                cancel={props.onClose}
                loading={deleting}
              />
            )}
            headerActions={
              year !== DateTime.now().year + 1 && (
                <Button variant="outlined" onClick={() => setCopyYear(year)}>
                  Copy to Year
                </Button>
              )
            }
          />
        </div>
      ))}
      {copyYear !== null && <CopyHolidayDialog copyFromYear={copyYear} handleClose={() => setCopyYear(null)} />}
    </>
  );
};

export default StatHolidaysScreen;
