import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Collapse, IconButton, Typography } from "@mui/material";

import { ReviewClient } from "practice-reviews";
import React from "react";
import { expanderStyles } from "styles/common";
import useLocalStorage from "util/useLocalStorage";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons/faChevronDown";
import { ReviewClientTile } from "./ReviewClientTile";
import { makeStyles } from "makeStyles";

interface Props {
  partnerName: string;
  reviewClients: ReviewClient[];
  selectedReviewClientId?: number;
  reviewClientClicked: (reviewClient: ReviewClient) => void;
  markerClicked: (reviewClient: ReviewClient) => void;
  userCanViewReviewClient: (reviewClient: ReviewClient) => boolean;
}

const useStyles = makeStyles()((theme) => ({
  ...expanderStyles(theme),
  header: {
    display: "flex",
    alignItems: "baseline",
    padding: theme.spacing(1),
    color: theme.palette.primary.main,
    "& h3": {
      fontWeight: 500
    }
  }
}));

export const PartnerGroup: React.FunctionComponent<Props> = (props) => {
  const [expanded, setExpanded] = useLocalStorage(`Review Files Grouped By Partner Name: ${props.partnerName}`, true);
  const { classes, cx } = useStyles();
  return (
    <div>
      <div className={classes.header}>
        <IconButton size="small" onClick={() => setExpanded(!expanded)} className={classes.expander}>
          <FontAwesomeIcon icon={faChevronDown} className={cx(classes.expandedIcon, { rotated: expanded })} />
        </IconButton>
        <Typography variant="h3">{props.partnerName}</Typography>
      </div>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {props.reviewClients.map((c) => (
          <ReviewClientTile
            key={c.id}
            reviewClient={c}
            isSelected={c.id === props.selectedReviewClientId}
            reviewClientClicked={props.reviewClientClicked}
            markerClicked={props.markerClicked}
            userCanViewReviewClient={props.userCanViewReviewClient(c)}
          />
        ))}
      </Collapse>
    </div>
  );
};
