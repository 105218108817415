export interface LevelOfAdjustment {
  id: number;
  level: number;
  practiceReviewId: number;
  reasonForAdjustment: string | null;
  adjustmentQuestions: AdjustmentQuestion[];
}

export enum AdjustmentQuestionAnswer {
  Yes = "Yes",
  No = "No",
  NA = "NA"
}

export interface AdjustmentQuestion {
  id: number;
  questionText: string;
  answer: AdjustmentQuestionAnswer;
}
