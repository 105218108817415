import _ from "lodash";
import React, { createContext, useContext, useState } from "react";
import { User } from "users";

export const InasUserContext = createContext<{
  inasUser: User | null;
  setInasUser: (user: User | null) => void;
}>({
  inasUser: null,
  setInasUser: () => {}
});

export const InasUserProvider: React.FunctionComponent = (props) => {
  const [inasUser, setInasUser] = useState<User | null>(null);

  return (
    <InasUserContext.Provider
      value={{
        inasUser: inasUser,
        setInasUser: setInasUser
      }}>
      {props.children}
    </InasUserContext.Provider>
  );
};

export function useInasUser() {
  return useContext(InasUserContext);
}
