import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { makeStyles } from "../makeStyles";

interface Props {
  title: string;
  navLink?: React.ReactElement;
}

export const ScreenHeader = (props: Props) => {
  const useStyles = makeStyles()((theme) => ({
    screenHeader: {
      marginBottom: theme.spacing(3)
    }
  }));

  const { classes } = useStyles();

  return (
    <Stack direction="row">
      <Typography variant="h1" className={classes.screenHeader}>
        {props.title}
      </Typography>
      <Box sx={{ marginLeft: "auto" }}>{props.navLink}</Box>
    </Stack>
  );
};
