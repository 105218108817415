import React, { useEffect, useState } from "react";
import { makeStyles } from "../makeStyles";
import { Collapse, IconButton, Typography } from "@mui/material";
import { EnhancedMergeField, MergeFieldBehavior } from "common/HtmlMergeFields/models";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons/faChevronDown";
import { expanderStyles } from "styles/common";
import { MergeFieldDisplay } from "./MergeFieldDisplay";
import { ensureMergeFieldBehaviorTypeRepresentation } from "common/HtmlMergeFields/utilities";

const useStyles = makeStyles()((theme) => ({
  ...expanderStyles(theme),
  toggleAndLabel: {
    display: "flex",
    paddingLeft: "20px",
    paddingTop: "4px",
    marginBottom: "10px",
    ".fa-chevron-down": {
      minHeight: "12px",
      minWidth: "12px",
      width: "12px"
    }
  },
  toggle: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: "5px"
  },
  childFieldLabel: {
    cursor: "pointer",
    fontSize: "0.7rem"
  }
}));

interface Props {
  field: EnhancedMergeField;
  onInsertClick?: (textToInsert: string) => void;
}

export const CollapsibleMergeFieldChildren: React.FunctionComponent<Props> = (props) => {
  let ensuredType = ensureMergeFieldBehaviorTypeRepresentation(props.field.type);
  const { cx, classes } = useStyles();
  const [expanded, setExpanded] = useState<boolean>(ensuredType === MergeFieldBehavior.Object);

  return (
    <div>
      <div className={classes.toggleAndLabel} onClick={() => setExpanded(!expanded)}>
        <IconButton className={classes.toggle} key={props.field.id} size="small" onClick={() => setExpanded(!expanded)}>
          <FontAwesomeIcon key={props.field.id} icon={faChevronDown} className={cx(classes.expandedIcon, { rotated: expanded })} />
        </IconButton>
        <label className={classes.childFieldLabel}>
          Fields on {props.field.field} items{expanded ? ":" : "..."}
        </label>
      </div>
      <Collapse key={props.field.id} in={expanded} timeout={500} unmountOnExit title={props.field.id?.replace(/\./gm, " >> ")}>
        <MergeFieldDisplay key={props.field.id} fields={props.field.children} onInsertClick={props.onInsertClick} />
      </Collapse>
    </div>
  );
};
