import { useMutation, useQuery } from "@apollo/client";
import { ConfirmationDialog } from "common/ConfirmationDialog";
import React, { useCallback } from "react";
import { Activity, ActivityEmail } from "./models";
import { formatDate, formatDateTime } from "util/formats";
import { PracticeReview } from "practice-reviews";
import { useCurrentUser, Permissions } from "users";
import EditActivityDialog from "./EditActivityDialog";
import CrudTable from "common/CrudTable";
import { FetchPrActivitiesQuery, DeleteActivityMutation, ResendActivityEmailsMutation } from "./queries";
import { DateTime } from "luxon";
import { Box, DialogContentText, useTheme, Tooltip } from "@mui/material";
import { GridRowParams } from "@mui/x-data-grid-pro";
import { useNotifications } from "../notifications";
import { LoadingButton } from "@mui/lab";

export function compareActivity(a: Activity, b: Activity) {
  // If the completion date and the initiation date are the same, these are the same activity.
  if (b.completedDate === a.completedDate && b.dateInitiated === a.dateInitiated) {
    return 0;
  }
  // First compare the completion dates if both activities are complete
  if (b.completedDate != null && a.completedDate != null) {
    return b.completedDate < a.completedDate ? -1 : 1;
  }
  // Next if one is complete and the y isn't, the incomplete one comes first.
  if (b.completedDate != null && a.completedDate == null) {
    return -1;
  }
  if (b.completedDate == null && a.completedDate != null) {
    return 1;
  }
  // Finally, if neither are complete, compare the initiation dates.
  return DateTime.fromISO(b.dateInitiated!) < DateTime.fromISO(a.dateInitiated!) ? -1 : 1;
}

export function makeActivityLog(activities: Activity[]) {
  return [...activities].sort(compareActivity);
}

const ActivityLog: React.FunctionComponent<{ practiceReview: PracticeReview }> = (props: { practiceReview: PracticeReview }) => {
  const theme = useTheme();
  const notifications = useNotifications();

  const { userHasPermission } = useCurrentUser();
  const hasUpdatePermission = userHasPermission(Permissions.ActivityLogUpdate);

  const activityLogQuery = useQuery<{ practiceReviewById: { activityLog: Activity[] } }, { prId: number }>(FetchPrActivitiesQuery, {
    variables: {
      prId: props.practiceReview.id
    }
  });
  const logActivities = activityLogQuery.data?.practiceReviewById?.activityLog
    ? makeActivityLog(activityLogQuery.data?.practiceReviewById?.activityLog)
    : [];

  const [deleteMutation, { loading: deleting }] = useMutation<{ activities: { delete: Activity } }, { id: number }>(
    DeleteActivityMutation,
    {
      refetchQueries: [{ query: FetchPrActivitiesQuery, variables: { prId: props.practiceReview.id } }]
    }
  );

  const [resendEmailMutate, resendEmailMutation] = useMutation<
    { activities: { resendEmails: ActivityEmail[] } },
    { activityEmailIds: number[] }
  >(ResendActivityEmailsMutation);

  async function resendEmail(activity: Activity) {
    const activityEmailIdsToResend = activity.activityEmails.filter((ae) => ae.canBeResentToFirm).map((ae) => ae.id);
    const result = await resendEmailMutate({
      variables: {
        activityEmailIds: activityEmailIdsToResend
      }
    });

    if ((result.data?.activities?.resendEmails?.length ?? 0) > 0) {
      notifications.success("Created draft email for resending.");
    }
  }

  const getItem = (id: string) => {
    const identifiers = id.split("-");
    return logActivities.find((a) => a.__typename === identifiers[0] && a.id === parseInt(identifiers[1]));
  };

  const getDetails = useCallback(
    (params: GridRowParams) =>
      params.row.outcomeOrComments ? (
        <Box
          sx={{
            pl: 7.5,
            pt: 0,
            pr: 2,
            pb: 2,
            borderTop: `1px solid ${theme.palette.common.white}`,
            borderBottom: `1px solid ${theme.palette.grey[300]}`,
            backgroundColor: theme.palette.common.white,
            position: "relative",
            top: "-1px"
          }}>
          <Box sx={{ color: theme.palette.text.secondary, display: "inline" }}>Outcome/Comments:</Box>
          <Box sx={{ ml: 1, display: "inline" }}>{params.row.outcomeOrComments}</Box>
        </Box>
      ) : null,
    []
  );

  const getDetailsHeight = useCallback(() => 36, []);

  return activityLogQuery.loading ? null : (
    <CrudTable
      title="Activity Log"
      columnDefinitions={[
        {
          field: "activityName",
          headerName: "Process",
          flex: 2,
          sortable: true
        },
        {
          field: "dateInitiated",
          headerName: "Date Initiated",
          flex: 0.7,
          sortable: true,
          renderCell: ({ row }) => {
            const activity = row as Activity;
            return activity.dateInitiated ? (
              <Tooltip title={formatDateTime(activity.dateInitiated)}>
                <span>{formatDate(activity.dateInitiated)}</span>
              </Tooltip>
            ) : (
              <span>-</span>
            );
          }
        },
        {
          field: "assignedBy",
          headerName: "Assigned By",
          valueGetter: (params) => (params.row as Activity).assignedByUser?.name ?? "System",
          flex: 1,
          sortable: true
        },
        {
          field: "assignedTo",
          headerName: "Assigned To",
          flex: 1,
          sortable: true,
          valueGetter: (params) => {
            // this is only used for the data grid to be able to sort on this column
            const activityRow = params.row as Activity;
            if (activityRow.assignedToUser) {
              return activityRow.assignedToUser.name;
            }
            if (activityRow.assignedToUsers?.length) {
              return activityRow.assignedToUsers[0].name;
            }
            return "-";
          },
          renderCell: ({ row }) => {
            const activityRow = row as Activity;
            if (activityRow.assignedToUsers?.length) {
              const value = `${activityRow.assignedToUsers[0].name}${
                activityRow.assignedToUsers?.length > 1 ? ` + ${activityRow.assignedToUsers?.length - 1} more` : ""
              }`;
              const tooltip = activityRow.assignedToUsers.map((u, i) => (
                <>
                  {u.name}
                  {i < activityRow.assignedToUsers!.length - 1 && <br />}
                </>
              ));
              return (
                <Tooltip title={tooltip}>
                  <span>{value}</span>
                </Tooltip>
              );
            } else if (activityRow.assignedToUser) {
              return (
                <Tooltip title={activityRow.assignedToUser.name}>
                  <span>{activityRow.assignedToUser.name}</span>
                </Tooltip>
              );
            } else {
              return <span>-</span>;
            }
          }
        },
        {
          field: "completedDate",
          headerName: "Completed Date",
          flex: 0.7,
          sortable: true,
          renderCell: ({ row }) => {
            const activity = row as Activity;
            return activity.completedDate ? (
              <Tooltip title={formatDateTime(activity.completedDate)}>
                <span>{formatDate(activity.completedDate)}</span>
              </Tooltip>
            ) : (
              <span>-</span>
            );
          }
        },
        {
          field: "completedBy",
          headerName: "Completed By",
          flex: 1,
          sortable: true,
          valueGetter: (params) => (params.row as Activity).completedByUser?.name ?? "-"
        }
      ]}
      rows={logActivities}
      initialState={{
        detailPanel: { expandedRowIds: logActivities.filter((a) => Boolean(a.outcomeOrComments)).map((a) => `${a.__typename}-${a.id}`) }
      }}
      loading={activityLogQuery.loading}
      getRowId={(row) => `${row.__typename}-${row.id}`}
      disableEditReason={(activity) => (activity.assignedByUser === null ? "Cannot edit system activity" : undefined)}
      disableDeleteReason={(activity) => (activity.assignedByUser === null ? "Cannot delete system activity" : undefined)}
      getDetailPanelContent={getDetails}
      getDetailPanelHeight={getDetailsHeight}
      renderAddDialog={
        hasUpdatePermission
          ? (dialogProps) => (
              <EditActivityDialog
                title="Add Activity"
                confirmButtonText="Add"
                handleClose={dialogProps.onClose}
                practiceReview={props.practiceReview}
              />
            )
          : undefined
      }
      renderEditDialog={
        hasUpdatePermission
          ? (id, dialogProps) => (
              <EditActivityDialog
                title="Edit Activity"
                confirmButtonText="Save"
                handleClose={dialogProps.onClose}
                activity={getItem(id)}
                practiceReview={props.practiceReview}
              />
            )
          : undefined
      }
      renderDeleteDialog={
        hasUpdatePermission
          ? (id, dialogProps) => {
              const item = getItem(id);
              return (
                <ConfirmationDialog
                  open={true}
                  title="Delete Custom Activity?"
                  body={<DialogContentText>Are you sure you want to delete the activity: {item!.activityName}?</DialogContentText>}
                  confirm={() => deleteMutation({ variables: { id: item!.id! } }).then(dialogProps.onClose)}
                  cancel={dialogProps.onClose}
                  loading={deleting}
                />
              );
            }
          : undefined
      }
      extraRowActions={(activity: Activity) =>
        activity.activityEmails.some((ae) => ae.canBeResentToFirm) ? (
          <LoadingButton variant="outlined" size="small" onClick={() => resendEmail(activity)} loading={resendEmailMutation.loading}>
            Resend Email
          </LoadingButton>
        ) : (
          <></>
        )
      }
      displayWithoutContainer
      removeDisabledActions
      disableSelectionOnClick
      maxHeight={900}
    />
  );
};

export default ActivityLog;
