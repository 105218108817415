import React, { memo } from "react";
import { Typography } from "@mui/material";

import {
  getCounterStyle,
  checklistExpanderWidth,
  checklistIndentSize,
  hangingIndentForNumberingWidth,
  cpaRefWidth,
  questionPadding,
  checklistStyles
} from "../styles/common";
import { QuestionHeader, QuestionHeaderTemplate } from "checklists";
import { makeStyles } from "../makeStyles";

const useStyles = makeStyles<Props>()((theme, props) => {
  return {
    ...checklistStyles(theme),
    indentQuestionHeader: {
      paddingLeft: `calc((${checklistIndentSize} * ${
        props.questionHeader.depth - 1
      }) + ${hangingIndentForNumberingWidth} + ${checklistExpanderWidth})`
    },
    numberedQuestionHeaderText: {
      "&::before": {
        content: `counter(section-${props.questionHeader.parentId}, ${getCounterStyle(props.questionHeader.depth)}) "."`
      }
    },
    questionHeaderCounter: {
      counterReset: `section-${props.questionHeader.id}`,
      counterIncrement: `section-${props.questionHeader.parentId}`
    },
    questionHeaderHeaderContainer: {
      display: "flex",
      marginBottom: theme.spacing(1),
      paddingRight: questionPadding // Align with questions
    },
    questionHeaderText: {
      flexGrow: 1,
      textIndent: `-${hangingIndentForNumberingWidth}`,
      "&::before": {
        content: `counter(section-${props.questionHeader.parentId}, ${getCounterStyle(props.questionHeader.depth)}) "."`,
        marginRight: theme.spacing(1),
        textAlign: "right",
        display: "inline-block",
        fontSize: theme.typography.body1.fontSize,
        fontVariantNumeric: "tabular-nums",
        width: `calc(${hangingIndentForNumberingWidth} - ${theme.spacing(1)})`
      }
    },
    questionHeaderTextBody: {
      display: "inline"
    },
    cpaRef: {
      width: cpaRefWidth,
      flexShrink: 0
    }
  };
});

interface Props {
  questionHeader: QuestionHeader | QuestionHeaderTemplate;
  children: React.ReactNode;
  adornments: React.ReactNode;
  className?: string;
}

const QuestionHeaderDisplay: React.FunctionComponent<Props> = (props) => {
  const { classes, cx } = useStyles(props);

  return props.questionHeader.hidden ? (
    <div className={classes.questionHeaderCounter} />
  ) : (
    <div className={classes.questionHeaderCounter}>
      <div className={cx(classes.questionHeaderHeaderContainer, props.className)}>
        <div
          className={cx(
            classes.questionHeaderText,
            classes.numberedItemText,
            classes.numberedQuestionHeaderText,
            classes.indentQuestionHeader
          )}>
          <Typography variant="body1" className={classes.questionHeaderTextBody}>
            {props.questionHeader.text}
          </Typography>
        </div>
        <div className={classes.cpaRef}>
          <Typography variant="body1">{props.questionHeader.cpaRef}</Typography>
        </div>
        <div className={classes.adornments}>{props.adornments}</div>
      </div>
      {props.children}
    </div>
  );
};

const MemoizedQuestionHeaderDisplay = memo(QuestionHeaderDisplay);
export { MemoizedQuestionHeaderDisplay as QuestionHeaderDisplay };
