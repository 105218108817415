import React from "react";
import { Button, Dialog, DialogActions, DialogContent, FormControl, Grid, MenuItem, Typography } from "@mui/material";
import ClosableDialogTitle from "common/ClosableDialogTitle";
import { useMutation, useQuery } from "@apollo/client";
import { staticDataStyles } from "styles/common";
import { Activity, ActivityInput, CustomActivity } from "./models";
import * as Yup from "yup";
import { Field as FormikField, Form as FormikForm, Formik } from "formik";
import { TextField as FmuiTextField } from "formik-mui";
import { Validations } from "common/validations/common-yup-validations";
import { AllUsersQuery, useCurrentUser, User } from "users";
import { PracticeReview } from "practice-reviews";
import { FetchPrActivitiesQuery, AddActivityMutation, EditActivityMutation, FetchCustomActivitiesQuery } from "./queries";
import { LoadingButton } from "@mui/lab";
import { makeStyles } from "../makeStyles";
import _ from "lodash";

const useStyles = makeStyles()((theme) => ({
  ...staticDataStyles(theme),
  description: {
    marginBottom: theme.spacing(3)
  }
}));

interface EditActivityDialogProps {
  handleClose: () => void;
  title: string;
  confirmButtonText: string;
  practiceReview: PracticeReview;
  activity?: Activity;
}

const EditActivityDialog = (props: EditActivityDialogProps) => {
  const currentUser = useCurrentUser();
  const { classes } = useStyles();

  const userQuery = useQuery<{ users: User[] }>(AllUsersQuery);
  const users = _.sortBy(userQuery.data?.users ?? [], (u) => u.name);

  const customActivityQuery = useQuery<{ customActivities: CustomActivity[] }>(FetchCustomActivitiesQuery);
  const customActivities = customActivityQuery.data?.customActivities ?? [];

  const validationSchema = Yup.object().shape({
    activityName: Validations.requiredText()
  });

  const [addActivity, { loading: loadingAdd }] = useMutation<{ activities: { add: Activity } }, { activity: ActivityInput }>(
    AddActivityMutation,
    {
      refetchQueries: [{ query: FetchPrActivitiesQuery, variables: { prId: props.practiceReview.id } }]
    }
  );
  const [editActivity, { loading: loadingEdit }] = useMutation<{ activities: { update: Activity } }, { activity: ActivityInput }>(
    EditActivityMutation
  );
  const mutate = Boolean(props.activity) ? editActivity : addActivity;
  const loading = Boolean(props.activity) ? loadingEdit : loadingAdd;

  const submitForm = async (values: ActivityInput) => {
    let activity: ActivityInput = {
      id: props.activity?.id,
      practiceReviewId: props.practiceReview.id,
      ...values,
      // In order for the (none) item to work in a controlled component we must give it a value
      // We remove the value here to keep the input inline with the db model
      assignedToUserId: values.assignedToUserId ? Number(values.assignedToUserId) : undefined
    };
    await mutate({
      variables: { activity }
    });
    props.handleClose();
  };

  return (
    <Dialog open={true} onClose={props.handleClose} fullWidth={true} scroll="paper" maxWidth="sm">
      <ClosableDialogTitle onClose={props.handleClose}>{props.title}</ClosableDialogTitle>
      <Formik
        initialValues={{
          activityName: props.activity?.activityName ?? "",
          assignedToUserId: props.activity?.assignedToUser?.id ?? 0,
          outcomeOrComments: props.activity?.outcomeOrComments ?? ""
        }}
        onSubmit={submitForm}
        validationSchema={validationSchema}>
        {(formikProps) => (
          <FormikForm>
            <DialogContent>
              <Typography className={classes.description} paragraph>
                Activities will be monitored through the Practice Review processes.
              </Typography>
              <Grid container item direction="column" spacing={2}>
                <Grid item>
                  <div className={classes.stackedContainer}>
                    <Typography className={classes.label}>Assigned By</Typography>
                    <Typography>
                      {Boolean(props.activity) ? props.activity?.assignedByUser?.name ?? "System" : currentUser.user.name}
                    </Typography>
                  </div>
                </Grid>
                <Grid item>
                  <FormControl variant="outlined" fullWidth size="small">
                    <FormikField component={FmuiTextField} select name="activityName" label="Process" fullWidth required>
                      {_.orderBy(customActivities, (a) => a.id).map((a) => (
                        <MenuItem value={a.customActivityName}>{a.customActivityName}</MenuItem>
                      ))}
                    </FormikField>
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl variant="outlined" fullWidth size="small">
                    <FormikField component={FmuiTextField} select name="assignedToUserId" label="Assigned To">
                      <MenuItem value={0}>(none)</MenuItem>
                      {users.map((user) => (
                        <MenuItem value={user.id} key={user.id}>
                          {user.name}
                        </MenuItem>
                      ))}
                    </FormikField>
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormikField component={FmuiTextField} name="outcomeOrComments" label="Comments" multiline rows={3} fullWidth />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={props.handleClose}>Cancel</Button>
              <LoadingButton color="primary" variant="contained" loading={loading} onClick={() => formikProps.submitForm()}>
                {props.confirmButtonText}
              </LoadingButton>
            </DialogActions>
          </FormikForm>
        )}
      </Formik>
    </Dialog>
  );
};

export default EditActivityDialog;
