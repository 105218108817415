import React, { useEffect, useState } from "react";
import { PracticeReview, PRBaseStatusCode, PrScreenQuery } from "practice-reviews";
import { Box, Button, Card, CardContent, Stack, TextField, Tooltip, Typography } from "@mui/material";
import { datagridStyles, staticDataStyles, tableStyles } from "styles/common";
import { formatDate, formatDateTime } from "util/formats";
import { gql, useMutation } from "@apollo/client";
import { useNotifications } from "notifications";
import ReadOnlyNotes from "../chrome/ReadOnlyNotes";
import _ from "lodash";
import { useUnsavedChanges } from "../UnsavedChangesProvider";
import { LoadingButton } from "@mui/lab";
import { makeStyles } from "makeStyles";
import { ChangeMeetingDialog } from "../committee-meetings/ChangeMeetingDialog";
import { useCurrentUser, Permissions } from "users";
import { DataGridWithHeader } from "common/DataGridWithHeader";
import { GridCellParams } from "@mui/x-data-grid-pro";
import StackedStaticDataDisplay from "../common/StackedStaticDataDisplay";

const useStyles = makeStyles()((theme) => ({
  ...staticDataStyles(theme),
  ...tableStyles(theme),
  ...datagridStyles(theme),
  root: {},
  refreshContainer: {
    display: "flex",
    alignItems: "center",
    marginLeft: "auto"
  },
  refreshButton: {
    marginLeft: theme.spacing(2)
  },
  labeledField: {
    marginBottom: theme.spacing(2)
  },
  practiceReviewContainer: {
    backgroundColor: theme.palette.grey[50]
  },
  sectionHeader: {
    marginBottom: theme.spacing(1)
  },
  actionContainer: {
    marginTop: theme.spacing(1),
    display: "flex",
    justifyContent: "flex-end"
  }
}));

interface Props {
  practiceReview: PracticeReview;
  reasonPrMeetingCannotBeChanged: string | null;
}

export const PrOverview: React.FunctionComponent<Props> = (props) => {
  const { classes, cx, theme } = useStyles();
  const { userHasPermission } = useCurrentUser();
  const notifications = useNotifications();
  const { unsavedChanges, changesSaved } = useUnsavedChanges();

  const practiceReview = props.practiceReview;

  const [changingMeeting, setChangingMeeting] = useState(false);

  const [prNotes, setPrNotes] = useState("");
  useEffect(() => {
    setPrNotes(practiceReview.prNotes ?? "");
  }, [practiceReview.prNotes]);

  const [savePrNotesMutate, savePrNotesMutation] = useMutation<
    { practiceReview: { savePrNotes: PracticeReview } },
    { practiceReviewId: number; prNotes: string | null }
  >(gql`
    mutation SavePrNotes($practiceReviewId: Int!, $prNotes: String) {
      practiceReview {
        savePrNotes(practiceReviewId: $practiceReviewId, prNotes: $prNotes) {
          id
          prNotes
        }
      }
    }
  `);

  async function savePrNotes() {
    const normalizedPrNotes = prNotes.trim() === "" ? null : prNotes.trim();
    const result = await savePrNotesMutate({
      variables: { practiceReviewId: practiceReview.id, prNotes: normalizedPrNotes }
    });

    if (result.data?.practiceReview.savePrNotes?.id) {
      notifications.success("Saved PR notes.");
    }
  }

  const [refreshPrAndFirmMutate, refreshPrAndFirmMutation] = useMutation<
    {
      practiceReview: { refreshFromDataSource: PracticeReview };
    },
    { practiceReviewId: number }
  >(
    gql`
      mutation RefreshPrAndFirm($practiceReviewId: Int!) {
        practiceReview {
          refreshFromDataSource(practiceReviewId: $practiceReviewId) {
            id
          }
        }
      }
    `,
    {
      variables: { practiceReviewId: practiceReview.id },
      onCompleted: (result) => {
        if (result?.practiceReview?.refreshFromDataSource?.id != null) {
          notifications.success("Refreshed PR and firm from iMIS.");
        }
      },
      refetchQueries: [{ query: PrScreenQuery, variables: { id: practiceReview.id } }]
    }
  );

  const notesHeight = "50em";

  return (
    <div className={classes.root}>
      <Stack spacing={5}>
        <Stack direction="row" alignItems="center">
          <div className={classes.refreshContainer}>
            <Typography variant="body1" className={classes.inlineLabel}>
              Last Refreshed:
            </Typography>
            <Typography variant="body1">{formatDateTime(practiceReview.firm.lastRefreshedFromDataSource)}</Typography>
            {userHasPermission(Permissions.CammsRefreshFirm) && (
              <LoadingButton
                variant="outlined"
                color="primary"
                loading={refreshPrAndFirmMutation.loading}
                onClick={() => refreshPrAndFirmMutate()}
                className={classes.refreshButton}>
                Refresh from iMIS
              </LoadingButton>
            )}
          </div>
        </Stack>

        <Card variant="outlined" className={classes.practiceReviewContainer}>
          <CardContent>
            <Typography variant="h3" className={classes.sectionHeader} gutterBottom>
              Current Practice Review ({practiceReview.reviewYear})
            </Typography>

            <Stack spacing={2}>
              <Box
                sx={{
                  display: "grid",
                  rowGap: theme.spacing(1),
                  columnGap: theme.spacing(5),
                  gridTemplateRows: "auto auto",
                  gridTemplateColumns: "auto auto auto",
                  gridAutoFlow: "column"
                }}>
                <StackedStaticDataDisplay label="Start Date" value={formatDate(practiceReview.startDate)} />
                <StackedStaticDataDisplay label="End Date" value={formatDate(practiceReview.endDate)} />
                <StackedStaticDataDisplay label="Estimated Time" value={`${practiceReview.estimate.finalEstimate}h`} />
                <StackedStaticDataDisplay
                  label="Actual Time (to date)"
                  value={practiceReview.actualTimeToDate !== null ? `${practiceReview.actualTimeToDate}h` : "--"}
                />
                <Stack>
                  <Typography variant="body1" className={classes.label}>
                    Committee Meeting
                  </Typography>

                  <Stack direction="row" spacing={1} alignItems="flex-start">
                    <Typography variant="body1">{practiceReview.committeeMeeting?.name ?? "--"}</Typography>
                    {userHasPermission(Permissions.PrChangeMeeting) && (
                      <Tooltip title={props.reasonPrMeetingCannotBeChanged ?? ""}>
                        <span>
                          <Button
                            color="primary"
                            sx={{ ml: 1, position: "relative", top: "-0.2em" }}
                            size="small"
                            onClick={() => setChangingMeeting(true)}
                            disabled={Boolean(props.reasonPrMeetingCannotBeChanged)}>
                            {practiceReview.committeeMeeting ? "Change" : "Assign"}
                          </Button>
                        </span>
                      </Tooltip>
                    )}
                  </Stack>
                </Stack>
                <StackedStaticDataDisplay label="Lead Reviewer" value={practiceReview.leadReviewer?.user.name ?? "--"} />
              </Box>
              <StackedStaticDataDisplay
                label="Last Review Conclusion"
                value={
                  practiceReview.previousReview?.status.baseStatusCode === PRBaseStatusCode.Completed &&
                  practiceReview.previousReview?.mostAuthoritativeDecision?.complianceZone
                    ? `Zone ${practiceReview.previousReview.mostAuthoritativeDecision.complianceZone.zoneCode}`
                    : "--"
                }
              />
            </Stack>
          </CardContent>
        </Card>

        <Stack>
          <Typography variant="h3" className={classes.sectionHeader}>
            PR Notes
          </Typography>
          {userHasPermission(Permissions.PrUpdatePrNotes) ? (
            <>
              <TextField
                multiline
                fullWidth
                margin="none"
                value={prNotes}
                onChange={(e) => {
                  setPrNotes(e.target.value);
                  unsavedChanges();
                }}
              />
              <div className={classes.actionContainer}>
                <LoadingButton
                  variant="outlined"
                  color="primary"
                  loading={savePrNotesMutation.loading}
                  onClick={async () => {
                    if (userHasPermission(Permissions.PrUpdatePrNotes)) {
                      await savePrNotes();
                      changesSaved();
                    }
                  }}>
                  Save
                </LoadingButton>
              </div>
            </>
          ) : (
            <Typography>{prNotes}</Typography>
          )}
        </Stack>

        <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", columnGap: theme.spacing(5) }}>
          {userHasPermission(Permissions.ViewNotesFromNoticeProcess) && (
            <Box sx={{ flexGrow: 0, flexShrink: 0 }}>
              <ReadOnlyNotes header="Notes from Notice Process" value={practiceReview.upcomingReviewNotice?.notes} height={notesHeight} />
            </Box>
          )}

          <Box sx={{ flexGrow: 0, flexShrink: 0 }}>
            <ReadOnlyNotes header="Notes from Estimate Time" value={practiceReview.estimate.notes} height={notesHeight} />
          </Box>

          {userHasPermission(Permissions.ViewSchedulingAndReviewers) && (
            <Box sx={{ flexGrow: 0, flexShrink: 0 }}>
              <ReadOnlyNotes header="Notes from Scheduling" value={practiceReview.notesFromSchedulingPhase} height={notesHeight} />
            </Box>
          )}
        </Box>
      </Stack>

      {changingMeeting && <ChangeMeetingDialog practiceReviews={[practiceReview]} onClose={() => setChangingMeeting(false)} />}
    </div>
  );
};
