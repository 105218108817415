import React, { memo, useState } from "react";
import { QuestionTemplate, QuestionHeaderTemplate, MasterChecklistNode } from "checklists";
import { QuestionHeaderDisplay } from "checklists/QuestionHeaderDisplay";
import { MasterChecklistQuestionDisplay } from "./MasterChecklistQuestionDisplay";
import { MasterChecklistActions } from "./MasterChecklistActions";
import EditMasterChecklistSectionDialog from "./dialogs/EditMasterChecklistSectionDialog";
import { useMutation } from "@apollo/client";
import { DeleteSubsectionMutation } from "./MasterChecklistSectionDisplay";
import { ConfirmationDialog } from "common/ConfirmationDialog";
import { DialogContentText } from "@mui/material";

import { MasterChecklistQuery } from "./MasterChecklistScreen";
import { makeStyles } from "../makeStyles";

const useStyles = makeStyles<Props>()(() => ({
  masterChecklistQuestionHeaderHeaderContainer: {
    paddingRight: "0 !important" // align with section controls
  }
}));

interface Props {
  questionHeader: QuestionHeaderTemplate;
  siblings: MasterChecklistNode[];
}

const MasterChecklistQuestionHeader: React.FunctionComponent<Props> = (props) => {
  const { classes } = useStyles(props);

  const [deleteMutate, { loading }] = useMutation<{ masterChecklistQuestionContainers: { deleteSection: boolean } }, { id: number }>(
    DeleteSubsectionMutation,
    { refetchQueries: [{ query: MasterChecklistQuery }] }
  );

  const [isHovered, setIsHovered] = useState(false);

  return (
    <div onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <QuestionHeaderDisplay
        className={classes.masterChecklistQuestionHeaderHeaderContainer}
        questionHeader={props.questionHeader}
        adornments={
          <MasterChecklistActions
            node={props.questionHeader}
            hidden={!isHovered}
            siblings={props.siblings}
            renderEditDialog={(node, dialogProps) => (
              <EditMasterChecklistSectionDialog
                title="Edit Question Header"
                confirmButtonText="Save"
                handleClose={dialogProps.onClose}
                isHeader={true}
                data={node as QuestionHeaderTemplate}
              />
            )}
            renderDeleteDialog={(node, dialogProps) => (
              <ConfirmationDialog
                title="Delete Question Header?"
                body={<DialogContentText>Are you sure you want to delete question header: {node.text}?</DialogContentText>}
                cancel={dialogProps.onClose}
                confirm={() => deleteMutate({ variables: { id: node.id } }).then(dialogProps.onClose)}
                loading={loading}
                open={true}
              />
            )}
            isQuestionContainer
          />
        }>
        {props.questionHeader.children.map((child) => (
          <MasterChecklistQuestionDisplay key={child.id} siblings={props.questionHeader.children} question={child as QuestionTemplate} />
        ))}
      </QuestionHeaderDisplay>
    </div>
  );
};

const MemoizedMasterChecklistQuestionHeader = memo(MasterChecklistQuestionHeader);
export { MemoizedMasterChecklistQuestionHeader as MasterChecklistQuestionHeader };
