import React from "react";
import { Button, ButtonGroup, FormControl, Typography } from "@mui/material";

import { staticDataStyles } from "styles/common";
import { InaPriority } from "inas";

import { makeStyles } from "../makeStyles";

const useStyles = makeStyles()((theme) => ({
  ...staticDataStyles(theme),
  priorityLabel: {
    marginBottom: theme.spacing(0.5)
  },
  unselectedPriorityButton: {
    backgroundColor: theme.palette.common.white
  },
  selectedPriorityButton: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark
    }
  }
}));

interface Props {
  priority: InaPriority;
  setPriority: (newPriority: InaPriority) => void;
}

const PrioritySelector = (props: Props) => {
  const { classes, cx } = useStyles();

  function renderButton(forPriority: InaPriority) {
    return (
      <Button
        className={cx({
          [classes.selectedPriorityButton]: props.priority === forPriority
        })}
        variant={props.priority === forPriority ? "contained" : "outlined"}
        disableElevation
        onClick={() => {
          props.setPriority(forPriority);
        }}>
        {forPriority}
      </Button>
    );
  }

  return (
    <FormControl fullWidth>
      <Typography variant="body1" className={cx(classes.label, classes.priorityLabel)}>
        Priority
      </Typography>
      <ButtonGroup fullWidth>
        {renderButton(InaPriority.High)}
        {renderButton(InaPriority.Normal)}
        {renderButton(InaPriority.Low)}
      </ButtonGroup>
    </FormControl>
  );
};

export default PrioritySelector;
