import { gql } from "@apollo/client";

export const FetchAppRolesQuery = gql`
  query FetchAppRoles {
    appRoles {
      id
      identifier
      displayName
      description
      assignees {
        principalId
        principalDisplayName
      }
    }
  }
`;
