import React from "react";
import NumberFormat from "react-number-format";

export interface Props {
  name: string;
  places: number;
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
}

// Adapted from https://codesandbox.io/s/formik-material-ui-number-format-vz318?file=/src/index.tsx
function FixedPlacesNumberFormat(props: Props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: other.name,
            value: values.value
          }
        });
      }}
      fixedDecimalScale
      decimalScale={props.places}
      allowNegative={false}
      style={{ textAlign: "right" }}
    />
  );
}

export default FixedPlacesNumberFormat;
