import { useQuery } from "@apollo/client";
import { ScreenHeader } from "common/ScreenHeader";
import gql from "graphql-tag";
import React from "react";
import CrudTable from "../common/CrudTable";
import { UserRole } from "../users";
import EditWorkflowInaDialog from "./EditWorkflowInaDialog";
import { InaType } from "./models";

const InaTypesQuery = gql`
  query FetchInaTypes {
    inaTypes {
      id
      typeCode
      friendlyName
      assignedTo {
        id
        name
      }
      assignedToNonAssurance {
        id
        name
      }
      reassignableToRoles {
        id
        name
      }
    }
  }
`;

const UserRolesQuery = gql`
  query FetchUserRoles {
    userRoles {
      id
      name
    }
  }
`;

const WorkflowInasScreen: React.FunctionComponent = () => {
  const inaTypesQuery = useQuery<{ inaTypes: InaType[] }>(InaTypesQuery);
  const inaTypes = inaTypesQuery.data?.inaTypes ?? [];

  const userRolesQuery = useQuery<{ userRoles: UserRole[] }>(UserRolesQuery);
  const userRoles = userRolesQuery.data?.userRoles ?? [];

  const getInaType = (id: number) => inaTypes.find((it) => it.id === id);

  return (
    <>
      <ScreenHeader title="Workflow INAs" />
      <CrudTable
        rows={inaTypes}
        columnDefinitions={[
          {
            field: "friendlyName",
            headerName: "Description",
            flex: 1
          },
          {
            field: "assignedTo",
            headerName: "Assign to (Assurance)",
            valueFormatter: (params) => (params.value as UserRole)?.name,
            width: 200
          },
          {
            field: "assignedToNonAssurance",
            headerName: "Assign to (Non-assurance)",
            valueFormatter: (params) => (params.value as UserRole)?.name,
            width: 200
          }
        ]}
        storageKey={`Ina Types`}
        noDataMessage="No INA types"
        loading={inaTypesQuery.loading}
        renderEditDialog={(id, props) => (
          <EditWorkflowInaDialog handleClose={props.onClose} inaType={getInaType(id)!} userRoles={userRoles} />
        )}
      />
    </>
  );
};

export default WorkflowInasScreen;
