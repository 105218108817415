import React, { useState } from "react";
import { IconButton, Stack, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import { Reviewer } from "practice-reviews";
import { YesNoIcon } from "common/YesNoIcon";
import { tableStyles } from "styles/common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment as fasComment } from "@fortawesome/free-solid-svg-icons/faComment";
import RichTooltip from "common/RichTooltip";

import { useCurrentUser } from "../../users";
import { makeStyles } from "makeStyles";

const useStyles = makeStyles()((theme) => ({
  ...tableStyles(theme),
  leadIndicator: {
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2)
  },
  notesIcon: {
    color: theme.palette.primary.main
  },
  noFilesAssigned: {
    color: theme.palette.warning.main
  },
  editorTooltipContent: {
    whiteSpace: "pre-line"
  },
  submitToLeadIconCell: {
    paddingLeft: theme.spacing(0.5),
    fontSize: theme.typography.body1.fontSize
  }
}));

interface Props {
  reviewers: Reviewer[];
}

export const ReviewerTable: React.FunctionComponent<Props> = (props) => {
  const { classes, cx, theme } = useStyles();

  const [openReviewerNotesPopoverReviewerId, setOpenReviewerNotesPopoverReviewerId] = useState<number | null>(null);
  const [openResubmissionNotesPopoverReviewerId, setOpenResubmissionNotesPopoverReviewerId] = useState<number | null>(null);
  const showResubmissionNotes = props.reviewers.some((reviewer: Reviewer) => reviewer.resubmissionNotes != null);

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell className={classes.submitToLeadIconCell}></TableCell>
          <TableCell>Completed</TableCell>
          <TableCell>Reviewer</TableCell>
          <TableCell align="center">Notes</TableCell>
          {showResubmissionNotes && (
            <TableCell align="center">
              Resubmission
              <br />
              Notes
            </TableCell>
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        {props.reviewers.map((reviewer) => {
          return (
            <TableRow key={reviewer.userId}>
              <TableCell className={classes.submitToLeadIconCell}>
                {reviewer.isLead ? (
                  <YesNoIcon
                    yes={
                      reviewer.clients.filter((client) => client.checklistCompletion.someQuestionsIncomplete).length === 0 &&
                      reviewer.clients.length !== 0
                    }
                  />
                ) : (
                  <Stack direction="row" spacing={2}>
                    <YesNoIcon yes={reviewer.reviewClientsSubmitted} />
                    {!reviewer.reviewClientsSubmitted && (
                      <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>
                        {reviewer.reviewClientsHaveBeenUnsubmitted ? "Submission withdrawn" : "Unsubmitted"}
                      </Typography>
                    )}
                  </Stack>
                )}
              </TableCell>
              <TableCell className={cx({ [classes.noFilesAssigned]: reviewer.clients.length === 0 })}>
                {reviewer.clients.length === 0
                  ? "No files"
                  : `${reviewer.clients.filter((client) => !client.checklistCompletion.someQuestionsIncomplete).length} of ${
                      reviewer.clients.length
                    }`}
              </TableCell>
              <TableCell>
                {reviewer.user.name}
                {reviewer.isLead && <span className={classes.leadIndicator}>Lead</span>}
              </TableCell>
              <TableCell align="center">
                {reviewer.reviewerNotes && (
                  <RichTooltip
                    placement="right"
                    open={openReviewerNotesPopoverReviewerId === reviewer.userId}
                    content={
                      <div className={classes.editorTooltipContent}>
                        <Typography variant="body2">
                          <div dangerouslySetInnerHTML={{ __html: reviewer.reviewerNotes }} />
                        </Typography>
                      </div>
                    }
                    onClose={() => setOpenReviewerNotesPopoverReviewerId(null)}>
                    <Tooltip
                      title={
                        <div className={classes.editorTooltipContent}>
                          <div dangerouslySetInnerHTML={{ __html: reviewer.reviewerNotes }} />
                        </div>
                      }
                      placement="right">
                      <IconButton color="primary" onClick={() => setOpenReviewerNotesPopoverReviewerId(reviewer.userId)} size="small">
                        <FontAwesomeIcon icon={fasComment} />
                      </IconButton>
                    </Tooltip>
                  </RichTooltip>
                )}
              </TableCell>
              {showResubmissionNotes && (
                <TableCell align="center">
                  {reviewer.resubmissionNotes && (
                    <RichTooltip
                      placement="right"
                      open={openResubmissionNotesPopoverReviewerId === reviewer.userId}
                      content={<Typography variant="body2">{reviewer.resubmissionNotes}</Typography>}
                      onClose={() => setOpenResubmissionNotesPopoverReviewerId(null)}>
                      <Tooltip title={reviewer.resubmissionNotes} placement="right">
                        <IconButton color="primary" onClick={() => setOpenResubmissionNotesPopoverReviewerId(reviewer.userId)} size="small">
                          <FontAwesomeIcon icon={fasComment} />
                        </IconButton>
                      </Tooltip>
                    </RichTooltip>
                  )}
                </TableCell>
              )}
            </TableRow>
          );
        })}
        {props.reviewers.length > 1 && (
          <TableRow className={classes.total}>
            <TableCell className={classes.submitToLeadIconCell}>
              <YesNoIcon yes={props.reviewers.every((r) => r.isLead || r.reviewClientsSubmitted)} />
            </TableCell>
            <TableCell>{`${
              props.reviewers.flatMap((r) => r.clients).filter((client) => !client.checklistCompletion.someQuestionsIncomplete).length
            } of ${props.reviewers.flatMap((r) => r.clients).length}`}</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>

            {showResubmissionNotes && <TableCell></TableCell>}
          </TableRow>
        )}
        {props.reviewers.length === 0 && (
          <TableRow className={classes.total}>
            <TableCell colSpan={4} className={classes.spanningRow}>
              No reviewers have been scheduled.
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};
