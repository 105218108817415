import React from "react";
import { CircularProgress, Container, Typography } from "@mui/material";
import { makeStyles } from "../makeStyles";

interface ILoadingScreenProps {
  mode: "auth" | "init";
}

export const LoadingScreen = (props: ILoadingScreenProps) => {
  const useStyles = makeStyles()((theme) => {
    return {
      rootContainer: {
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
      },
      rootContainerChildren: {
        margin: theme.spacing(2)
      }
    };
  });

  const { classes } = useStyles();

  return (
    <Container className={classes.rootContainer}>
      <CircularProgress className={classes.rootContainerChildren} size="5rem" />
      {props.mode === "auth" ? (
        <Typography variant="h1" className={classes.rootContainerChildren}>
          Authenticating...
        </Typography>
      ) : (
        <Typography variant="h1" className={classes.rootContainerChildren}>
          Loading...
        </Typography>
      )}
    </Container>
  );
};
