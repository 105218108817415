import React from "react";
import { FirmPartner, PREstimate } from "practice-reviews";
import { Typography } from "@mui/material";
import { datagridStyles, tableStyles } from "styles/common";
import { makeStyles } from "makeStyles";
import { GridColDef, GridValueFormatterParams } from "@mui/x-data-grid-pro";
import { DataGridWithHeader } from "../../common/DataGridWithHeader";

const partnerGridHeaderHeight = 72;
const useStyles = makeStyles<Props>()((theme, props) => ({
  ...tableStyles(theme),
  ...datagridStyles(theme, partnerGridHeaderHeight)
}));

interface Props {
  partners: FirmPartner[];
  estimate: PREstimate;
}

export const FirmPartnerTable: React.FunctionComponent<Props> = (props) => {
  const { classes, cx } = useStyles(props);

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Registered Name",
      flex: 1,
      valueGetter: (params) =>
        `${(params.row as FirmPartner).name}${
          (params.row as FirmPartner).designation ? `, ${(params.row as FirmPartner).designation}` : ""
        }`
    },
    {
      field: "isOther",
      headerName: "Partner",
      type: "boolean",
      width: 100,
      headerClassName: classes.wrapHeader,
      valueGetter: (params) => !(params.row as FirmPartner).isOther
    },
    {
      field: "withFirmSince",
      headerName: "With Firm Since",
      headerClassName: classes.wrapHeader,
      width: 100
    },
    {
      field: "yearDesignationObtained",
      headerName: "Designation Obtained",
      headerClassName: classes.wrapHeader,
      width: 120
    },
    {
      field: "compilation",
      headerName: "Compilation",
      type: "boolean",
      width: 110
    },
    {
      field: "audit",
      headerName: "Audit",
      type: "boolean",
      width: 110
    },
    {
      field: "review",
      headerName: "Review",
      type: "boolean",
      width: 110
    },
    {
      field: "timeEstimate",
      headerName: "Time Estimate",
      width: 100,
      valueFormatter: (params: GridValueFormatterParams) => (params.value as number | undefined)?.toFixed(2) ?? "--",
      align: "right",
      cellClassName: classes.number,
      headerClassName: cx(classes.wrapHeader, classes.lastColumnHeader)
    }
  ];

  const partnersWithTimeEstimates = props.partners.map((partner) => ({
    ...partner,
    timeEstimate: props.estimate.partners.find((partnerEstimate) => partnerEstimate.firmPartnerId === partner.id)?.timeEstimate
  }));

  const reviewYear = props.partners[0]?.reviewYear;

  return (
    <div>
      <Typography variant="h3">{`Engagement Responsibility${reviewYear ? ` (${reviewYear})` : ""}`}</Typography>
      <DataGridWithHeader
        itemType="Engagement responsibility"
        columns={columns}
        rows={partnersWithTimeEstimates}
        displayWithoutContainer
        noDataMessage="None."
        className={cx(classes.hideBooleanXs)}
      />
    </div>
  );
};
