import { Button, Checkbox, Dialog, DialogActions, DialogContent, FormControlLabel, Stack, Typography } from "@mui/material";
import { Formik, Field as FormikField } from "formik";
import React from "react";
import ClosableDialogTitle from "../common/ClosableDialogTitle";
import { EmailTemplate } from "./models";
import * as Yup from "yup";
import { gql, useMutation } from "@apollo/client";
import { TextField as FmuiTextField } from "formik-mui";
import { LoadingButton } from "@mui/lab";
import { useNotifications } from "../notifications";
import { MergeFieldType } from "common/HtmlMergeFields/models";
import QuillRichTextEditor, { SetFontsForPurpose } from "common/QuillRichTextEditor";

const UpdateEmailTemplateMutation = gql`
  mutation UpdateEmailTemplate($emailTemplate: EmailTemplateInput) {
    emailTemplates {
      update(emailTemplate: $emailTemplate) {
        id
        templateName
        emailSubject
        emailBodyHtml
      }
    }
  }
`;

interface Props {
  handleClose: () => void;
  id: number;
  emailTemplate: EmailTemplate;
}

const EditEmailTemplateDialog: React.FunctionComponent<Props> = (props) => {
  const notifications = useNotifications();

  const pafBodyContentRetriever = React.useRef<{ getContentAsHtml: () => string | null }>({ getContentAsHtml: () => null });

  const validationSchema = Yup.object().shape({
    templateName: Yup.string().required("Please enter a name."),
    emailSubject: Yup.string().required("Please enter a subject."),
    emailBodyHtml: Yup.string().required("Please enter a body.")
  });

  const [updateMutation, { loading: updating }] = useMutation<{ emailTemplates: EmailTemplate }, { emailTemplate: EmailTemplate }>(
    UpdateEmailTemplateMutation
  );

  return (
    <Dialog open={true} onClose={props.handleClose} fullWidth={true} scroll="paper" maxWidth="md">
      <ClosableDialogTitle onClose={props.handleClose}>Editing Email Template: {props.emailTemplate.templateName}</ClosableDialogTitle>
      <Formik
        initialValues={{
          id: props.id,
          templateName: props.emailTemplate.templateName,
          emailSubject: props.emailTemplate.emailSubject,
          emailBodyHtml: props.emailTemplate.emailBodyHtml
        }}
        onSubmit={async (values) => {
          let emailTemplate = {
            id: props.id,
            templateName: values.templateName,
            emailSubject: values.emailSubject,
            emailBodyHtml: values.emailBodyHtml
          } as EmailTemplate;

          const result = await updateMutation({
            variables: { emailTemplate }
          });

          if ((result.errors?.length ?? 0) === 0) {
            notifications.success("Updated email template.");
          }

          props.handleClose();
        }}
        validationSchema={validationSchema}>
        {(formikProps) => (
          <>
            <DialogContent dividers>
              <Stack>
                <Typography paragraph>This template determines the contents of emails of this type.</Typography>
                <FormikField component={FmuiTextField} name="templateName" label="Template Name" fullWidth required />
                <FormikField component={FmuiTextField} name="emailSubject" label="Subject" fullWidth required />

                <QuillRichTextEditor
                  name="emailBodyHtml"
                  setFontsForPurpose={SetFontsForPurpose.ForEmail}
                  html={formikProps.values.emailBodyHtml}
                  setFieldValue={formikProps.setFieldValue}
                  showMergeFields
                  mergeFieldType={props.emailTemplate.isMeetingBased ? MergeFieldType.HtmlForMeeting : MergeFieldType.HtmlForReview}
                  colors
                  links
                  cpaalogo
                  fontSize
                />
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button onClick={props.handleClose}>Cancel</Button>
              <LoadingButton
                color="primary"
                variant="contained"
                loading={updating}
                onClick={() => {
                  formikProps.submitForm();
                }}>
                Save
              </LoadingButton>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default EditEmailTemplateDialog;
