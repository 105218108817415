import React, { useState } from "react";
import { PracticeReview, ToggleIsDeficiencyReportPostedMutation } from "practice-reviews";
import { Typography, Button, Switch, FormControlLabel } from "@mui/material";

import { makeStyles } from "../../makeStyles";
import { documentListStyles, summaryChecklistStyles } from "styles/common";
import { DeficiencyReportDialog } from "deficiency-report/DeficiencyReportDialog";
import { MicrosoftWordLink } from "../../common/MicrosoftWordLink";
import { useMutation } from "@apollo/client";
import { showLinkAndActionsGenerateButton } from "practice-reviews/utilities";
import { useCurrentUser } from "users";

const useStyles = makeStyles()((theme) => ({
  ...documentListStyles(theme),
  ...summaryChecklistStyles(theme)
}));

interface Props {
  practiceReview: PracticeReview;
  buttonSize?: "small" | "medium" | "large";
  documentClass?: string;
  actionClass?: string;
  useMissingStyling?: boolean;
  userCanPostToPortal: boolean;
}

export const DeficiencyReportLinkAndActions: React.FunctionComponent<Props> = (props) => {
  const { classes, cx } = useStyles();
  const currentUser = useCurrentUser();
  const url = props.practiceReview.deficiencyReportUrl;

  const [deficiencyReportDialogOpen, setDeficiencyReportDialogOpen] = useState(false);

  const [toggleIsDeficiencyReportPostedMutate, toggleIsDeficiencyReportPostedMutation] = useMutation<
    {
      practiceReview: {
        toggleIsDeficiencyReportPosted: Partial<PracticeReview>;
        __typename?: string;
      };
    },
    {
      practiceReviewId: number;
    }
  >(ToggleIsDeficiencyReportPostedMutation);

  async function toggleIsDeficiencyReportPosted() {
    await toggleIsDeficiencyReportPostedMutate({
      variables: {
        practiceReviewId: props.practiceReview.id
      },
      optimisticResponse: {
        practiceReview: {
          toggleIsDeficiencyReportPosted: {
            ...props.practiceReview,
            isDeficiencyReportPosted: !props.practiceReview.isDeficiencyReportPosted
          },
          __typename: ""
        }
      }
    });
  }

  return (
    <>
      <div className={classes.documentRow}>
        <div className={cx(classes.documentName, props.documentClass)}>
          {!url ? (
            <Typography variant="body1" className={cx({ [classes.missingForm]: props.useMissingStyling })}>
              Deficiency Report
            </Typography>
          ) : (
            <MicrosoftWordLink href={url}>Deficiency Report</MicrosoftWordLink>
          )}
        </div>
        {showLinkAndActionsGenerateButton(props.practiceReview, currentUser, false) && (
          <div className={cx(classes.documentAction, props.actionClass)}>
            <span>
              <Button variant="outlined" onClick={() => setDeficiencyReportDialogOpen(true)} size={props.buttonSize}>
                {url ? "Regenerate" : "Generate"}
              </Button>
            </span>
          </div>
        )}
        {url && props.userCanPostToPortal && (
          <div className={classes.checklistItemSecondAction}>
            <FormControlLabel
              control={<Switch checked={props.practiceReview.isDeficiencyReportPosted} onClick={() => toggleIsDeficiencyReportPosted()} />}
              label="Available in PRS Portal"
            />
          </div>
        )}
      </div>

      {deficiencyReportDialogOpen && (
        <DeficiencyReportDialog
          onClose={() => setDeficiencyReportDialogOpen(false)}
          practiceReview={props.practiceReview}
          regenerating={Boolean(url)}
        />
      )}
    </>
  );
};
