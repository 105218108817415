import { Button, Checkbox, Dialog, DialogActions, DialogContent, FormControlLabel, Stack } from "@mui/material";
import ClosableDialogTitle from "common/ClosableDialogTitle";
import { PracticeReview } from "practice-reviews";
import React, { useState } from "react";
import { Formik, Field as FormikField, FormikHelpers } from "formik";
import { TextField as FmuiTextField } from "formik-mui";
import { useMutation } from "@apollo/client";
import { useNotifications } from "notifications";
import { GetInasForUserQuery } from "inas";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import { CancelPendingExemptionMutation, EndExemptionMutation, FetchExemptionsQuery } from "./queries";
import { ExemptionStatus } from ".";
import { DateTime } from "luxon";
import PrsDatePickerField from "../common/FormikFields/PrsDatePickerField";
import StackedStaticDataDisplay from "../common/StackedStaticDataDisplay";
import { standardDateFormat } from "../util/formats";

interface Props {
  practiceReview: PracticeReview;
  onClose: () => void;
}

interface FormValues {
  reason: string;
  newDate: DateTime | null;
  endDate: DateTime;
}

export const EndExemptionDialog: React.FunctionComponent<Props> = (props) => {
  const notifications = useNotifications();

  const [selectNewDate, setSelectNewDate] = useState(false);

  const [cancelPendingExemptionMutate, cancelPendingExemptionMutation] = useMutation<
    { practiceReview: { cancelPendingExemption: PracticeReview } },
    { practiceReviewId: number; endDate: string; reason: string; selectNewDate: boolean; newDate: string | null }
  >(CancelPendingExemptionMutation, {
    refetchQueries: [{ query: GetInasForUserQuery }, { query: FetchExemptionsQuery }]
  });

  async function cancelPendingExemption(values: FormValues, actions: FormikHelpers<FormValues>) {
    const result = await cancelPendingExemptionMutate({
      variables: {
        practiceReviewId: props.practiceReview.id,
        endDate: values.endDate.toISODate(),
        reason: values.reason.trim(),
        selectNewDate: selectNewDate,
        newDate: values.newDate ? values.newDate.toISODate() : null
      }
    });

    if (result.data?.practiceReview.cancelPendingExemption?.id) {
      notifications.success("Cancelled pending exemption.");
    }

    actions.setSubmitting(false);
    props.onClose();
  }

  const [endExemptionMutate, endExemptionMutation] = useMutation<
    { practiceReview: { endExemption: PracticeReview } },
    { practiceReviewId: number; endDate: string; reason: string; selectNewDate: boolean; newDate: string | null }
  >(EndExemptionMutation, {
    refetchQueries: [{ query: GetInasForUserQuery }, { query: FetchExemptionsQuery }]
  });

  async function endExemption(values: FormValues, actions: FormikHelpers<FormValues>) {
    const result = await endExemptionMutate({
      variables: {
        practiceReviewId: props.practiceReview.id,
        endDate: values.endDate.toISODate(),
        reason: values.reason.trim(),
        selectNewDate: selectNewDate,
        newDate: values.newDate ? values.newDate.toISODate() : null
      }
    });

    if (result.data?.practiceReview.endExemption?.id) {
      notifications.success("Ended exemption.");
    }

    actions.setSubmitting(false);
    props.onClose();
  }

  const initialFormValues: FormValues = {
    reason: "",
    newDate:
      props.practiceReview.startDate && DateTime.fromISO(props.practiceReview.startDate) >= DateTime.now().startOf("day")
        ? DateTime.fromISO(props.practiceReview.startDate)
        : null,
    endDate: DateTime.now().startOf("day")
  };

  const validationSchema = Yup.object({
    reason: Yup.string().required("Enter the reason."),
    newDate:
      props.practiceReview.prStage > 0 && selectNewDate
        ? Yup.mixed().nullable().required("Enter the new review date.")
        : Yup.mixed().nullable()
  });

  const exemptionPending = props.practiceReview.exemption?.status === ExemptionStatus.Pending;

  return (
    <Dialog open={true} onClose={props.onClose} fullWidth maxWidth="sm">
      <Formik
        initialValues={initialFormValues}
        validationSchema={validationSchema}
        onSubmit={exemptionPending ? cancelPendingExemption : endExemption}>
        {(formikProps) => (
          <>
            <ClosableDialogTitle onClose={props.onClose}>
              {exemptionPending ? "Cancel Pending Exemption" : "End Exemption"}
            </ClosableDialogTitle>
            <DialogContent>
              <Stack>
                <Stack direction="row" spacing={3} sx={{ mb: 2 }}>
                  <StackedStaticDataDisplay label="PR No." value={props.practiceReview.prNumber} />
                  <StackedStaticDataDisplay label="Entity No." value={props.practiceReview.firm.entityNumber.toString()} />
                  <StackedStaticDataDisplay label="Firm" value={props.practiceReview.firm.name} />
                </Stack>
                <StackedStaticDataDisplay
                  sx={{ mb: 1 }}
                  label="Exemption Started"
                  value={
                    props.practiceReview.exemption!.startedOn
                      ? DateTime.fromISO(props.practiceReview.exemption!.startedOn).toFormat(standardDateFormat)
                      : "--"
                  }
                />
                <FormikField
                  component={PrsDatePickerField}
                  name="endDate"
                  label="Exemption Ended"
                  required
                  maxDate={DateTime.local()}
                  minDate={
                    props.practiceReview.exemption?.startedOn ? DateTime.fromISO(props.practiceReview.exemption.startedOn) : undefined
                  }
                />
                <FormikField
                  component={FmuiTextField}
                  name="reason"
                  multiline
                  required
                  label={exemptionPending ? "Reason for Cancelling Exemption" : "Reason for Ending Exemption"}
                  fullWidth
                />
                {props.practiceReview.prStage > 0 && (
                  <Stack>
                    <FormControlLabel
                      control={<Checkbox name="selectNewDate" onClick={() => setSelectNewDate(!selectNewDate)} checked={selectNewDate} />}
                      label="Select New Tentative Review Date"
                    />
                    {selectNewDate && (
                      <FormikField
                        component={PrsDatePickerField}
                        name="newDate"
                        label="New Tentative Review Date"
                        required
                        fullWidth
                        minDate={DateTime.local()}
                      />
                    )}
                  </Stack>
                )}
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => props.onClose()}
                disabled={exemptionPending ? cancelPendingExemptionMutation.loading : endExemptionMutation.loading}>
                Cancel
              </Button>
              <LoadingButton
                color="primary"
                variant="contained"
                onClick={() => formikProps.submitForm()}
                loading={exemptionPending ? cancelPendingExemptionMutation.loading : endExemptionMutation.loading}>
                OK
              </LoadingButton>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};
