import React from "react";
import { FormControlLabel, FormLabel, Radio, TextField } from "@mui/material";
import { YesNoNa } from "decisions";
import { FormikProps } from "formik";
import _ from "lodash";
import { useUnsavedChanges } from "../UnsavedChangesProvider";
import { makeStyles } from "../makeStyles";
import { DecisionFormValues } from "./DecisionStageTab";

const useStyles = makeStyles()(() => ({
  radioContainer: {
    display: "grid",
    gridTemplateColumns: "6em 6em 6em"
  }
}));

interface Props {
  formik: FormikProps<DecisionFormValues>;
  label: string;
  fieldName: string;
  requireExplanationForNo?: boolean;
  disabled?: boolean;
}

export const YesNoNaQuestion: React.FunctionComponent<Props> = (props) => {
  const { classes } = useStyles();
  const { unsavedChanges } = useUnsavedChanges();

  const { formik, fieldName } = props;

  function handleRadioChange(e: React.ChangeEvent<HTMLInputElement>) {
    formik.setValues({ ...formik.values, [e.target.name]: e.target.value });
    unsavedChanges();
  }

  return (
    <div>
      <FormLabel>{props.label}</FormLabel>
      <div className={classes.radioContainer}>
        <FormControlLabel
          control={
            <Radio
              name={fieldName}
              checked={(formik.values as any)[fieldName] === YesNoNa.Yes}
              value={YesNoNa.Yes}
              onChange={handleRadioChange}
              disabled={props.disabled}
            />
          }
          label="Yes"
        />
        <FormControlLabel
          control={
            <Radio
              name={fieldName}
              checked={(formik.values as any)[fieldName] === YesNoNa.No}
              value={YesNoNa.No}
              onChange={handleRadioChange}
              disabled={props.disabled}
            />
          }
          label="No"
        />
        {(formik.values as any)[fieldName] === YesNoNa.Na && (
          <FormControlLabel
            control={
              <Radio
                name={fieldName}
                checked={(formik.values as any)[fieldName] === YesNoNa.Na}
                value={YesNoNa.Na}
                onChange={handleRadioChange}
                disabled={props.disabled}
              />
            }
            label="N/A"
          />
        )}
      </div>
      {props.requireExplanationForNo && (formik.values as any)[fieldName] === YesNoNa.No && (
        <TextField
          fullWidth
          required
          label="Explanation"
          margin="none"
          sx={{ mt: 1 }}
          value={(formik.values as any)[`${fieldName}Explanation`] || ""}
          onChange={(e) => {
            formik.setFieldValue(`${fieldName}Explanation`, e.target.value);
            unsavedChanges();
          }}
          disabled={props.disabled}
          error={(formik.touched as any)[`${fieldName}Explanation`] && (formik.errors as any)[`${fieldName}Explanation`] !== undefined}
          helperText={(formik.touched as any)[`${fieldName}Explanation`] && (formik.errors as any)[`${fieldName}Explanation`]}
          onBlur={() => formik.setFieldTouched(`${fieldName}Explanation`, true)}
        />
      )}
    </div>
  );
};
