import React, { useEffect } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { PrChecklist } from "./PrChecklist";
import {
  AnswerFieldsFragment,
  ChecklistSectionFieldsFragment,
  QuestionContainerFieldsFragment,
  QuestionFieldsFragment,
  QuestionHeaderFieldsFragment
} from "./queries";
import { QuestionContainer } from "./models";
import { LevelOfAdjustment } from "../level-of-adjustment";

const FirmChecklistQuery = gql`
  ${QuestionContainerFieldsFragment}
  ${QuestionFieldsFragment}
  ${AnswerFieldsFragment}
  ${ChecklistSectionFieldsFragment}
  ${QuestionHeaderFieldsFragment}
  query FetchFirmChecklist($practiceReviewId: Int!) {
    firmChecklistQuestionContainers(practiceReviewId: $practiceReviewId) {
      ...QuestionContainerFields
      questions {
        ...QuestionFields
        answer(reviewClientId: null) {
          ...AnswerFields
        }
      }
      ...ChecklistSectionFields
      ...QuestionHeaderFields
    }
  }
`;

interface Props {
  practiceReviewId: number;
  levelOfAdjustment: LevelOfAdjustment;
}

export const FirmChecklist: React.FunctionComponent<Props> = (props) => {
  const [runChecklistQuery, checklistQuery] = useLazyQuery<
    { firmChecklistQuestionContainers: QuestionContainer[] },
    { practiceReviewId: number }
  >(FirmChecklistQuery, {
    fetchPolicy: "network-only",
    variables: {
      practiceReviewId: props.practiceReviewId
    }
  });
  const checklistQuestionContainers = checklistQuery.data?.firmChecklistQuestionContainers ?? [];

  useEffect(() => {
    const awaitedRunChecklistQuery = async () => await runChecklistQuery();
    awaitedRunChecklistQuery();
  }, [runChecklistQuery]);

  return (
    <PrChecklist
      checklistQuestionContainers={checklistQuestionContainers}
      practiceReviewId={props.practiceReviewId}
      levelOfAdjustment={props.levelOfAdjustment}
      reviewClientId={null}
      checklistLoading={checklistQuery.loading}
      showLevelOfAdjustment={true}
    />
  );
};
