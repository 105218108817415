import React, { useState } from "react";
import { AttachedDocument, DocType, PracticeReview, RemoveAttachedDocumentFromPracticeReviewMutation } from "practice-reviews";
import { Grid, Link, Typography, Button, DialogContentText } from "@mui/material";

import { AxiosResponse } from "axios";
import { useAxios } from "auth/SecureAxios";
import { useNotifications } from "notifications";
import { useAppConfig } from "util/AppConfig";
import { gql, useApolloClient, useMutation } from "@apollo/client";
import _, { forEach } from "lodash";
import { ConfirmationDialog } from "common/ConfirmationDialog";
import { LoadingButton } from "@mui/lab";
import { makeStyles } from "../makeStyles";
import { PrReportLinkAndActions } from "./report-links/PrReportLinkAndActions";
import { documentListStyles } from "../styles/common";
import { DeficiencyReportLinkAndActions } from "./report-links/DeficiencyReportLinkAndActions";
import { useCurrentUser, Permissions } from "../users";
import { ChecklistNotesReportLinkAndActions } from "./report-links/ChecklistNotesReportLinkAndActions";
import { MicrosoftWordLink } from "../common/MicrosoftWordLink";
import { getOpenableUrl } from "../util/utilities";

interface Props {
  practiceReview: PracticeReview;
}

export const ReportsAndQuestionnaires: React.FunctionComponent<Props> = (props) => {
  const useStyles = makeStyles()((theme) => ({
    ...documentListStyles(theme),
    tabContent: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      "& > :first-child": {
        flexGrow: 1
      },
      "& > :last-child": {
        alignSelf: "flex-start"
      }
    },
    additionalFilesHeader: {
      display: "flex",
      "& > :not(:last-child)": {
        marginRight: theme.spacing(3)
      }
    }
  }));

  const { classes } = useStyles();
  const notifications = useNotifications();
  const appConfig = useAppConfig();
  const apolloClient = useApolloClient();
  const { secureAxios } = useAxios();

  const [attachingDocumentType, setAttachingDocumentType] = useState<DocType | null>(null);
  const [removingDocument, setRemovingDocument] = useState<AttachedDocument | null>(null);

  const { practiceReview } = props;

  async function documentsAttached(documents: FileList, documentType: DocType) {
    if (documentType === "AdditionalFile") {
      let validationErrorExists = false;

      forEach(documents, (document) => {
        if (practiceReview.attachedDocuments.some((ad) => ad.fileName === document.name)) {
          notifications.error(`The practice review already has a file named ${document.name}.`);
          validationErrorExists = true;
        }

        var invalidSharePointFileNameErrorMessage = validateFileNameForSharePoint(document.name);
        if (invalidSharePointFileNameErrorMessage !== null) {
          notifications.error(invalidSharePointFileNameErrorMessage);
          validationErrorExists = true;
        }

        const fileNameResemblesReservedDocumentName = checkFileNameResemblesReservedDocumentName(document.name);
        if (fileNameResemblesReservedDocumentName) {
          notifications.error(fileNameResemblesReservedDocumentName);
          validationErrorExists = true;
        }
      });

      if (validationErrorExists) {
        return;
      }
    }

    setAttachingDocumentType(documentType);

    const formData = new FormData();
    formData.append("PracticeReviewId", props.practiceReview.id.toString());
    formData.append("DocumentTypeCode", documentType.toString());

    forEach(documents, (document) => {
      formData.append("Documents", document);
    });

    let postResult: AxiosResponse<PracticeReview>;
    const postRequestEndpoint = `${appConfig.apiEndpoint}/api/practice-review-document/upload`;
    try {
      postResult = await secureAxios.post(postRequestEndpoint, formData, {});
      if (postResult === undefined || postResult?.status === 401) {
        postResult = await secureAxios.post(postRequestEndpoint, formData, {});
      }
    } catch (e: any) {
      setAttachingDocumentType(null);
      notifications.serverError(e.message);
      return;
    }

    if (postResult?.status !== 200) {
      notifications.serverError(new Error(postResult?.statusText));
    } else {
      const updatedPr: PracticeReview = postResult.data;
      await updatePracticeReviewInCache(updatedPr);
      notifications.success(`Attached document${documents.length > 1 ? "s" : ""}.`);
    }

    setAttachingDocumentType(null);
  }

  function validateFileNameForSharePoint(filename: string) {
    let error: string | null = null;

    if (filename.length >= 128) {
      error = "The name is too long. File names must be under 128 characters.";
    }

    if (filename.indexOf("..") !== -1) {
      error = "The name contains consecutive '.' characters. Only one period in a row is allowed.";
    }

    if (filename.slice(0, 1) === ".") {
      error = "The name starts with a '.' character, which is not allowed.";
    }

    if (filename.slice(filename.length - 1, filename.length) === ".") {
      error = "The name ends with a '.' character, which is not allowed.";
    }

    if (/["#%&*:<>?\\/{|}~]/.test(filename)) {
      error = "The name contains a character that is not allowed.";
    }

    return error ? `The file could not be attached because its file name was invalid: ${error}` : null;
  }

  function checkFileNameResemblesReservedDocumentName(filename: string) {
    const reservedDocumentNames = [
      "Checklist Notes Report",
      "Committee Meeting Minutes",
      "Confidential Client List",
      "PD Declaration Form",
      "Decision Letter",
      "Deficiency Report",
      "Signed Deficiency Report",
      "Directed PD Letter",
      "Director's Presentation Format",
      "Firm Activity Log with Full Detail",
      "Firm Response Original",
      "Firm Response Redacted",
      "Schedule Notification Letter",
      "Office Listing Report",
      "Office Listing Report With Firm Names",
      "Practice Review Report",
      "Signed Draft PR Report",
      "Presentation Format",
      "Planning Question",
      "Standard Motions Summary",
      "Standard Motions Summary With Firm Names",
      "Student Question",
      "Tax Management Question",
      "Exemption Letter",
      "Signed Exemption Letter"
    ];

    const documentNamesThisFilenameResembles = reservedDocumentNames
      .map((n) => n.toLowerCase())
      .filter((n) => filename.toLowerCase().indexOf(n) !== -1);
    if (!documentNamesThisFilenameResembles?.length) {
      return null;
    }
    const totalTerms = documentNamesThisFilenameResembles.length;
    var prohibitedTextString = documentNamesThisFilenameResembles.reduce(function (prevVal, currVal, idx) {
      const quotedString = `"${currVal}"`;

      return idx == 0
        ? quotedString
        : totalTerms > 2 && idx < totalTerms - 1
        ? prevVal + ", " + quotedString
        : prevVal + " or " + quotedString;
    }, "");
    return `This Additional Files section is intended only for attaching *miscellaneous* files.

        The file you have selected cannot be attached using this function, because its filename contains the text ${prohibitedTextString}, which is too similar to the reserved name of a document that has a defined purpose in this system. 
        This is not allowed because that file could be confused with, or even overwrite or be overwritten by, the other file.

        The most common reason to see this message is that there may be a different area of the screen dedicated to uploading the specific type of document, which must be used instead so that the file can play its intended role. 

        Please look over the rest of this screen for a spot dedicated to a ${prohibitedTextString} file.

        If you cannot find such a part of the screen, and/or are certain this file should be attached as an uncategorized miscellaneous file instead, please rename the file to not include the text ${prohibitedTextString} in its filename, then try again.`;
  }

  async function updatePracticeReviewInCache(updatedPr: PracticeReview) {
    const cacheId = `PracticeReview:${updatedPr.id}`;

    await apolloClient.refetchQueries({
      updateCache(cache) {
        cache.modify({
          id: cacheId,
          fields: {
            isMissingSchedulingQuestionnaire() {
              return updatedPr.isMissingSchedulingQuestionnaire;
            },
            schedulingQuestionnaireUrl() {
              return updatedPr.schedulingQuestionnaireUrl;
            },
            isMissingTaxQuestionnaire() {
              return updatedPr.isMissingTaxQuestionnaire;
            },
            taxQuestionnaireUrl() {
              return updatedPr.taxQuestionnaireUrl;
            },
            isMissingConfidentialClientList() {
              return updatedPr.isMissingConfidentialClientList;
            },
            confidentialClientListUrl() {
              return updatedPr.confidentialClientListUrl;
            },
            attachedDocuments() {
              return updatedPr.attachedDocuments.map((ad) => ({ ...ad, __typename: "AttachedDocument" }));
            }
          }
        });
      }
    });
  }

  const [removeAttachedDocumentMutate, removeAttachedDocumentMutation] = useMutation<
    { practiceReview: { removeAttachedDocument: PracticeReview } },
    { practiceReviewId: number; attachedDocumentId: number; documentType: DocType }
  >(RemoveAttachedDocumentFromPracticeReviewMutation);

  async function removeAttachedDocument(document: AttachedDocument) {
    const result = await removeAttachedDocumentMutate({
      variables: { practiceReviewId: practiceReview.id, attachedDocumentId: document.id, documentType: document.type }
    });

    if (result.data?.practiceReview.removeAttachedDocument?.id) {
      await updatePracticeReviewInCache(result.data.practiceReview.removeAttachedDocument);
      notifications.success("Removed document.");
    }

    setRemovingDocument(null);
  }

  const FormLinkAndActions: React.FunctionComponent<{
    name: string;
    required: boolean;
    showIfExistsAnyway?: boolean;
    isMissing: boolean;
    showActions: boolean;
    url: string | null;
    documentType: DocType;
  }> = (componentProps) => {
    const { name, required, showIfExistsAnyway, isMissing, url, documentType, showActions } = componentProps;

    const formDocument = practiceReview.attachedDocuments.find((ad) => ad.type === documentType);

    return required || (showIfExistsAnyway && url) ? (
      <div className={classes.documentRow}>
        <div className={classes.documentRow}>
          {isMissing ? (
            <Typography variant="body1" className={classes.missingForm}>
              {name}
            </Typography>
          ) : (
            <Link href={getOpenableUrl(url!)} target="_blank">
              <Typography variant="body1">{name}</Typography>
            </Link>
          )}
        </div>
        {showActions && (
          <div className={classes.documentAction}>
            {!url ? (
              <LoadingButton
                variant="outlined"
                size="small"
                component="label"
                loading={attachingDocumentType === documentType}
                disabled={attachingDocumentType !== null}>
                Attach
                <input type="file" hidden accept="*/*" onChange={(e) => documentsAttached(e.target.files!, documentType)} />
              </LoadingButton>
            ) : (
              <Button
                variant="outlined"
                size="small"
                color="error"
                onClick={() => setRemovingDocument(formDocument!)}
                disabled={attachingDocumentType !== null || removeAttachedDocumentMutation.loading}
                className="removeButton">
                Remove
              </Button>
            )}
          </div>
        )}
      </div>
    ) : null;
  };

  const DocumentLinkAndActions: React.FunctionComponent<{ document: AttachedDocument; showActions: boolean }> = (componentProps) => {
    const { document, showActions } = componentProps;
    return (
      <div className={classes.documentRow}>
        <div className={classes.documentName}>
          <Link href={getOpenableUrl(document.url)} target="_blank">
            <Typography variant="body1">{document.fileName}</Typography>
          </Link>
        </div>
        <div className={classes.documentAction}>
          {showActions && (
            <Button
              variant="outlined"
              size="small"
              color="error"
              onClick={() => setRemovingDocument(document)}
              disabled={attachingDocumentType !== null || removeAttachedDocumentMutation.loading}
              className="removeButton">
              Remove
            </Button>
          )}
        </div>
      </div>
    );
  };

  const additionalFiles = _.orderBy(
    practiceReview.attachedDocuments.filter((ad) => ad.type === "AdditionalFile"),
    (ad) => ad.id
  );

  const { userHasPermission } = useCurrentUser();

  const userCanUpdatePr = !practiceReview.hasBeenReturned || userHasPermission(Permissions.ReturnedPrUpdate);
  const userCanUpdateFiles = userHasPermission(Permissions.PrUpdateFiles) || userCanUpdatePr;

  const signedDeficiencyReportUrl = practiceReview.attachedDocuments.filter((ad) => ad.type === DocType.SignedDeficiencyReport)[0]?.url;
  const signedDraftPrReportUrl = practiceReview.attachedDocuments.filter((ad) => ad.type === DocType.SignedDraftPracticeReviewReport)[0]
    ?.url;
  const decisionLetterDocType =
    practiceReview.mostAuthoritativeDecision?.isComply ?? true ? DocType.DecisionComplyLetter : DocType.DecisionNonComplyLetter;
  const decisionLetterDocument = practiceReview.attachedDocuments.filter((ad) => ad.type === decisionLetterDocType)[0];
  const reviewerEvaluationForm = practiceReview.attachedDocuments.filter((ad) => ad.type === DocType.ReviewerEvaluationForm)[0];

  return (
    <div className={classes.tabContent}>
      <Grid container spacing={5}>
        <Grid item xs={12} md={4}>
          <Typography variant="h3" gutterBottom>
            Reports
          </Typography>
          <div className={classes.documentList}>
            <div className={classes.documentRow}>
              <DeficiencyReportLinkAndActions practiceReview={practiceReview} useMissingStyling userCanPostToPortal={false} />
            </div>
            <div className={classes.documentRow}>
              <ChecklistNotesReportLinkAndActions practiceReview={practiceReview} />
            </div>
            <div className={classes.documentRow}>
              <PrReportLinkAndActions practiceReview={practiceReview} useMissingStyling />
            </div>
            <div className={classes.documentRow}>
              <div className={classes.documentName}>
                {!practiceReview.decisionLetterUrl ? (
                  <Typography variant="body1" className={classes.missingForm}>
                    Decision Letter
                  </Typography>
                ) : (
                  <Link href={getOpenableUrl(practiceReview.decisionLetterUrl)} target="_blank">
                    <Typography variant="body1">Decision Letter</Typography>
                  </Link>
                )}
              </div>
            </div>
            <div className={classes.documentRow}>
              <div className={classes.documentName}>
                {practiceReview.finalReportPackageUrl ? (
                  <Link href={getOpenableUrl(practiceReview.finalReportPackageUrl)} target="_blank">
                    <Typography variant="body1">Final Report Package</Typography>
                  </Link>
                ) : (
                  <Typography variant="body1" className={classes.missingForm}>
                    Final Report Package
                  </Typography>
                )}
              </div>
            </div>
            <div className={classes.documentRow}>
              <div className={classes.documentName}>
                {practiceReview.directedPdLetterUrl ? (
                  <Link href={getOpenableUrl(practiceReview.directedPdLetterUrl)} target="_blank">
                    <Typography variant="body1">Directed PD Letter</Typography>
                  </Link>
                ) : (
                  <Typography variant="body1" className={classes.missingForm}>
                    Directed PD Letter
                  </Typography>
                )}
              </div>
            </div>
            <div className={classes.documentRow}>
              <div className={classes.documentName}>
                {practiceReview.pdDeclarationFormUrl ? (
                  <MicrosoftWordLink href={practiceReview.pdDeclarationFormUrl}>
                    Professional Development Declaration Form
                  </MicrosoftWordLink>
                ) : (
                  <Typography variant="body1" className={classes.missingForm}>
                    Professional Development Declaration Form
                  </Typography>
                )}
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="h3">Questionnaires and Forms</Typography>
          <div className={classes.documentList}>
            <FormLinkAndActions
              name="Planning Questionnaire Part 1"
              required={true}
              isMissing={practiceReview.isMissingSchedulingQuestionnaire}
              url={practiceReview.schedulingQuestionnaireUrl}
              documentType={DocType.SchedulingQuestionnaire}
              showActions={userCanUpdateFiles}
            />
            <FormLinkAndActions
              name="Planning Questionnaire Part 2"
              required={true}
              isMissing={practiceReview.isMissingTaxQuestionnaire}
              url={practiceReview.taxQuestionnaireUrl}
              documentType={DocType.TaxQuestionnaire}
              showActions={userCanUpdateFiles}
            />
            <FormLinkAndActions
              name="Confidential Client List"
              required
              showIfExistsAnyway
              isMissing={practiceReview.isMissingConfidentialClientList}
              url={practiceReview.confidentialClientListUrl}
              documentType={DocType.ConfidentialClientList}
              showActions={userCanUpdateFiles}
            />
            <FormLinkAndActions
              name="Deficiency Report (Signed)"
              required={true}
              isMissing={!Boolean(signedDeficiencyReportUrl)}
              url={signedDeficiencyReportUrl}
              documentType={DocType.SignedDeficiencyReport}
              showActions={userCanUpdateFiles}
            />
            <FormLinkAndActions
              name="Draft Practice Review Report (Signed)"
              required={true}
              isMissing={!Boolean(signedDraftPrReportUrl)}
              url={signedDraftPrReportUrl}
              documentType={DocType.SignedDraftPracticeReviewReport}
              showActions={userCanUpdateFiles}
            />
            <div className={classes.documentRow}>
              <div className={classes.documentName}>
                {reviewerEvaluationForm ? (
                  <Link href={getOpenableUrl(reviewerEvaluationForm.url)} target="_blank">
                    <Typography variant="body1">Reviewer Evaluation Form</Typography>
                  </Link>
                ) : (
                  <Typography variant="body1" className={classes.missingForm}>
                    Reviewer Evaluation Form
                  </Typography>
                )}
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={4}>
          <div className={classes.additionalFilesHeader}>
            <Typography variant="h3">Additional Files</Typography>
            {userCanUpdateFiles && (
              <LoadingButton
                variant="outlined"
                size="small"
                component="label"
                loading={attachingDocumentType === "AdditionalFile"}
                disabled={attachingDocumentType !== null}>
                Attach
                <input
                  type="file"
                  hidden
                  accept="*/*"
                  multiple
                  onChange={(e) => documentsAttached(e.target.files!, DocType.AdditionalFile)}
                />
              </LoadingButton>
            )}
          </div>
          <div className={classes.documentList}>
            {additionalFiles.map((d) => (
              <DocumentLinkAndActions key={d.id} document={d} showActions={userCanUpdateFiles} />
            ))}
          </div>
        </Grid>
      </Grid>
      <Link href={practiceReview.sharePointUrl} target="_blank">
        <Typography variant="h3">View all documents in SharePoint</Typography>
      </Link>

      {removingDocument !== null && (
        <ConfirmationDialog
          open={true}
          body={
            <DialogContentText>
              {`Do you want to remove the ${
                removingDocument?.type === "AdditionalFile" ? `file "${removingDocument?.fileName}"` : removingDocument?.typeFriendlyName
              }?`}
            </DialogContentText>
          }
          title="Remove file?"
          cancel={() => setRemovingDocument(null)}
          confirm={() => removeAttachedDocument(removingDocument)}
          loading={removeAttachedDocumentMutation.loading}
        />
      )}
    </div>
  );
};
