import React from "react";
import { Link } from "@mui/material";
import { getRouteForPracticeReview } from "practice-reviews/PracticeReviewScreen";
import { PracticeReview } from "practice-reviews";
import { Link as RouterLink } from "react-router-dom";

const PrLink: React.FunctionComponent<{
  practiceReview: PracticeReview;
}> = ({ practiceReview }) => (
  <Link to={getRouteForPracticeReview(practiceReview)} component={RouterLink}>
    {practiceReview.prNumber}
  </Link>
);

export default PrLink;
