import React, { memo, useCallback, useContext, useMemo, useState } from "react";
import { ChecklistNode, ChecklistNodeTreePath, isQuestion, Question, QuestionContainer, QuestionHeader } from "checklists";
import { PrQuestion } from "./PrQuestion";
import { QuestionHeaderDisplay } from "./QuestionHeaderDisplay";
import { ChecklistActionType, nodeMatches } from "./checklistReducer";
import { PrChecklistDispatchContext } from "./PrChecklist";
import { PracticeReviewContext } from "practice-reviews/PracticeReviewScreen";
import { useCurrentUser, Permissions } from "users";
import { makeStyles } from "../makeStyles";
import { Button, DialogContentText } from "@mui/material";
import { CompletionMarker } from "./CompletionMarker";
import { ConfirmationDialog } from "common/ConfirmationDialog";

const useStyles = makeStyles<Props>()((theme) => {
  return {
    naButton: {
      marginLeft: "auto",
      padding: "0 12px",
      fontSize: "0.75em",
      flexShrink: 0
    },
    completionMarker: {
      marginLeft: theme.spacing(3),
      position: "relative",
      left: "-0.5rem",
      flexShrink: 0
    }
  };
});
interface Props {
  questionHeader: QuestionHeader;
  tabSectionId: number;
  currentQuestionTreePath?: ChecklistNodeTreePath;
  findingInvalidAnswers: boolean;
}

const PrQuestionHeader: React.FunctionComponent<Props> = (props) => {
  const { classes } = useStyles(props);
  const { dispatch } = useContext(PrChecklistDispatchContext);
  const { practiceReview } = useContext(PracticeReviewContext);

  const [confirmingMarkAllNa, setConfirmingMarkAllNa] = useState(false);

  const allAnswersAreNa = useCallback((node: ChecklistNode): boolean => {
    if (isQuestion(node)) {
      return node.answer?.isNa ?? false;
    } else {
      return (node as QuestionContainer).children.every((c) => allAnswersAreNa(c));
    }
  }, []);

  const allAnswersInSectionAreNa = useMemo(() => allAnswersAreNa(props.questionHeader), [props.questionHeader, allAnswersAreNa]);

  const someAnswersAreNotNa = useCallback((node: ChecklistNode): boolean => {
    if (isQuestion(node)) {
      const question = node as Question;
      return (question.answer?.isYes ?? false) || (question.answer?.isReportable ?? false) || (question.answer?.isNonReportable ?? false);
    } else {
      return (node as QuestionContainer).children.some((c) => someAnswersAreNotNa(c));
    }
  }, []);

  const handleMarkAllNa = useCallback(() => {
    const someAnswersInSectionAreAnsweredAndNotNa = someAnswersAreNotNa(props.questionHeader);

    if (!someAnswersInSectionAreAnsweredAndNotNa) {
      dispatch({ type: ChecklistActionType.MarkAllNa, sectionTreePath: props.questionHeader.treePath, tabSectionId: props.tabSectionId });
    } else {
      setConfirmingMarkAllNa(true);
    }
  }, [props.questionHeader, props.tabSectionId, dispatch, someAnswersAreNotNa]);

  const dispatchMarkAllNa = useCallback(() => {
    dispatch({ type: ChecklistActionType.MarkAllNa, sectionTreePath: props.questionHeader.treePath, tabSectionId: props.tabSectionId });
    setConfirmingMarkAllNa(false);
  }, [props.questionHeader.treePath, props.tabSectionId, dispatch]);

  const dispatchUnmarkAllNa = useCallback(
    () =>
      dispatch({ type: ChecklistActionType.UnmarkAllNa, sectionTreePath: props.questionHeader.treePath, tabSectionId: props.tabSectionId }),
    [props.questionHeader.treePath, props.tabSectionId, dispatch]
  );
  const { userHasPermission } = useCurrentUser();
  const canEdit = (practiceReview && !practiceReview.hasBeenReturned) || userHasPermission(Permissions.ReturnedPrUpdate);
  return (
    <QuestionHeaderDisplay
      questionHeader={props.questionHeader}
      adornments={
        <>
          {allAnswersInSectionAreNa ? (
            <Button
              variant="contained"
              className={classes.naButton}
              hidden={!canEdit}
              onClick={(e) => {
                dispatchUnmarkAllNa();
                (e.target as HTMLButtonElement).blur();
              }}>
              Unmark All N/A
            </Button>
          ) : (
            <Button
              variant="contained"
              className={classes.naButton}
              hidden={!canEdit}
              onClick={(e) => {
                handleMarkAllNa();
                (e.target as HTMLButtonElement).blur();
              }}>
              Mark All N/A
            </Button>
          )}
          <CompletionMarker section={props.questionHeader} className={classes.completionMarker} />
          {confirmingMarkAllNa && (
            <ConfirmationDialog
              open={true}
              body={<DialogContentText>Section has answers. Discard them?</DialogContentText>}
              title="Discard answers?"
              noDanger
              cancel={() => setConfirmingMarkAllNa(false)}
              confirm={dispatchMarkAllNa}
            />
          )}
        </>
      }>
      {props.questionHeader.children.map((child) => (
        <PrQuestion
          key={child.id}
          question={child as Question}
          tabSectionId={props.tabSectionId}
          isCurrentQuestion={props.currentQuestionTreePath !== undefined && nodeMatches(child, props.currentQuestionTreePath, child.depth)}
          findingInvalidAnswers={props.findingInvalidAnswers}
        />
      ))}
    </QuestionHeaderDisplay>
  );
};

const MemoizedPrQuestionHeader = memo(PrQuestionHeader);
export { MemoizedPrQuestionHeader as PrQuestionHeader };
