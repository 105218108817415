import {
  Box,
  Card,
  CardContent,
  Checkbox,
  Collapse,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography
} from "@mui/material";
import { Autocomplete } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ReviewClientChecklist } from "checklists/ReviewClientChecklist";
import CollapseToggle from "common/CollapseToggle";
import InlineStaticDataDisplay from "common/InlineStaticDataDisplay";
import StackedStaticDataDisplay from "common/StackedStaticDataDisplay";
import { makeStyles } from "makeStyles";
import { PracticeReview, ReviewClient } from "practice-reviews";
import { expanderStyles } from "styles/common";
import { Permissions, useCurrentUser } from "users";
import { formatCurrency, formatDate } from "../util/formats";
import { getRouteForPracticeReview, PracticeReviewTabs } from "practice-reviews/PracticeReviewScreen";
import { getPrScreenHeaderHeight } from "practice-reviews/PrScreenHeader";
import { useDimensions } from "react-dimensions-hook";
import _ from "lodash";
import { YesNoIcon } from "../common/YesNoIcon";

const useStyles = makeStyles()((theme) => ({
  ...expanderStyles(theme),
  root: {
    paddingTop: theme.spacing(3)
  },
  fileSelectorContainer: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    alignItems: "center"
  },
  partnerName: {
    color: theme.palette.text.secondary
  },
  fileDetailsWrapper: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: 0,
    background: theme.palette.common.white
  },
  stickyFileDetailsWrapper: {
    position: "sticky",
    top: getPrScreenHeaderHeight(theme),
    zIndex: 2 // above MUI floating labels
  },
  detailsHeader: {
    display: "flex",
    alignItems: "center"
  },
  detailItems: {
    marginTop: theme.spacing(1),
    display: "grid",
    rowGap: theme.spacing(2),
    columnGap: theme.spacing(3),
    justifyContent: "start"
  },
  clientDetailItems: {
    gridTemplateColumns: "minmax(6em, auto) minmax(6em, auto) minmax(6em, auto) minmax(6em, auto) repeat(auto-fill, 12ch)"
  },
  firstRow: {
    display: "contents",
    "& > *": {
      gridRow: "1/1"
    }
  },
  secondRow: {
    display: "contents",
    "& > *": {
      gridRow: "2/2"
    }
  },
  thirdRow: {
    display: "contents",
    "& > *": {
      gridRow: "3/3"
    }
  }
}));

interface Props {
  practiceReview: PracticeReview;
  reviewClientId?: number;
}

export const ReviewClientChecklists: React.FunctionComponent<Props> = (props) => {
  const { classes, cx, theme } = useStyles();
  const history = useHistory();
  const { user, userIsLeadReviewer, userHasPermission } = useCurrentUser();
  const [detailsExpanded, setDetailsExpanded] = useState(true);
  const { ref: reviewClientDetailsRef, dimensions: reviewClientDetailsDimensions } = useDimensions();

  const [selectedReviewClient, setSelectedReviewClient] = useState<ReviewClient | null>(null);
  useEffect(
    () => setSelectedReviewClient(props.practiceReview.reviewClients.find((rc) => rc.id === props.reviewClientId) ?? null),
    [props.reviewClientId, props.practiceReview.reviewClients]
  );

  const reviewerHasSubmittedReviewClients =
    !userIsLeadReviewer(props.practiceReview) &&
    props.practiceReview.reviewClients.filter((rc) => rc.id === props.reviewClientId)?.[0]?.reviewedByUserId === user.id &&
    props.practiceReview.otherReviewers.filter((r) => r.userId === user.id)?.[0]?.reviewClientsSubmitted;

  const capitalizedReviewClientDescriptor = "Client";

  return (
    <div className={classes.root}>
      <Grid container className={classes.fileSelectorContainer} spacing={3}>
        <Grid item xs={12} lg={4} xl={3}>
          <Autocomplete
            options={_.orderBy(
              userIsLeadReviewer(props.practiceReview) || userHasPermission(Permissions.PracticeReviewViewAll)
                ? props.practiceReview.reviewClients
                : props.practiceReview.reviewClients.filter((c) => c.reviewedByUserId === user.id),
              (rc) => rc.name
            )}
            getOptionLabel={(rc) => `${rc.name}${rc.refNum ? ` (${rc.refNum})` : ""}`}
            value={selectedReviewClient}
            onChange={(e, newReviewClient) => {
              if (newReviewClient) {
                history.replace(getRouteForPracticeReview(props.practiceReview, PracticeReviewTabs.ClientChecklists, newReviewClient));
              }
            }}
            renderInput={(params) => <TextField {...params} label={capitalizedReviewClientDescriptor} margin="none" />}
          />
        </Grid>
        {selectedReviewClient && (
          <Grid item xs={12} lg={4} xl={3}>
            <InlineStaticDataDisplay label="Partner" value={selectedReviewClient.partnerName} />
          </Grid>
        )}
      </Grid>

      {selectedReviewClient && (
        <Box
          ref={reviewClientDetailsRef}
          className={cx(classes.fileDetailsWrapper, { [classes.stickyFileDetailsWrapper]: detailsExpanded })}>
          <Card variant="outlined">
            <CardContent sx={{ paddingTop: 1, paddingBottom: 1, paddingLeft: 2, paddingRight: 2, ":last-child": { paddingBottom: 1 } }}>
              <div className={classes.detailsHeader}>
                <CollapseToggle
                  size="small"
                  className={classes.expander}
                  expanded={detailsExpanded}
                  toggle={() => setDetailsExpanded(!detailsExpanded)}
                />
                <Typography variant="h3" onClick={() => setDetailsExpanded(!detailsExpanded)}>
                  {`${capitalizedReviewClientDescriptor} Details: ${selectedReviewClient.name}`}
                </Typography>
              </div>
              <Collapse in={detailsExpanded} timeout="auto" unmountOnExit>
                <div className={cx(classes.detailItems, classes.clientDetailItems)}>
                  <StackedStaticDataDisplay label="Nature of Business" nowrap value={selectedReviewClient.clientFile!.businessNature} />
                  <StackedStaticDataDisplay
                    label="Fiscal Year End"
                    nowrap
                    value={formatDate(selectedReviewClient.clientFile!.fiscalYearEnd)}
                  />
                  <StackedStaticDataDisplay
                    label="Report Date"
                    value={selectedReviewClient.clientFile!.reportDate ? formatDate(selectedReviewClient.clientFile!.reportDate) : "--"}
                  />
                  <StackedStaticDataDisplay label="Engagement Type" nowrap value={selectedReviewClient.engagementType?.name ?? "--"} />
                  {selectedReviewClient.clientFile!.isHoursNotFees ? (
                    <StackedStaticDataDisplay
                      label="Hours"
                      value={selectedReviewClient.clientFile!.feesOrHoursAmount?.toString() ?? "--"}
                    />
                  ) : (
                    <StackedStaticDataDisplay
                      label="Fees"
                      nowrap
                      value={
                        selectedReviewClient.clientFile!.feesOrHoursAmount !== undefined &&
                        selectedReviewClient.clientFile!.feesOrHoursAmount !== null
                          ? formatCurrency(selectedReviewClient.clientFile!.feesOrHoursAmount)!
                          : "--"
                      }
                    />
                  )}
                  <StackedStaticDataDisplay
                    label="Materiality"
                    nowrap
                    value={formatCurrency(selectedReviewClient.clientFile!.materiality) ?? "--"}
                  />
                  <StackedStaticDataDisplay label="Assets" nowrap value={formatCurrency(selectedReviewClient.clientFile!.assets) ?? "--"} />
                  <StackedStaticDataDisplay
                    label="Liabilities"
                    nowrap
                    value={formatCurrency(selectedReviewClient.clientFile!.liabilities) ?? "--"}
                  />
                  <StackedStaticDataDisplay
                    label="Revenue"
                    nowrap
                    value={formatCurrency(selectedReviewClient.clientFile!.revenue) ?? "--"}
                  />
                  <StackedStaticDataDisplay
                    label="Net Income"
                    value={formatCurrency(selectedReviewClient.clientFile!.netIncomeBeforeTaxes) ?? "--"}
                  />
                </div>
              </Collapse>
            </CardContent>
          </Card>
        </Box>
      )}

      {selectedReviewClient && (
        <ReviewClientChecklist
          key={selectedReviewClient.id}
          practiceReviewId={props.practiceReview.id}
          levelOfAdjustment={props.practiceReview.levelOfAdjustment}
          reviewClient={selectedReviewClient}
          reviewerHasSubmittedReviewClients={reviewerHasSubmittedReviewClients}
          checklistActionsVerticalOffset={detailsExpanded ? reviewClientDetailsDimensions.height : 0}
        />
      )}
    </div>
  );
};
