import { Stack, Typography } from "@mui/material";

import React from "react";
import { Firm } from ".";
import { makeStyles } from "../makeStyles";
import { staticDataStyles } from "../styles/common";

const useStyles = makeStyles()((theme) => ({
  ...staticDataStyles(theme),
  status: {
    fontWeight: 500
  },
  inactive: {
    color: theme.palette.error.main
  }
}));

interface Props {
  firm: Firm;
}

const FirmStatus: React.FunctionComponent<Props> = (props) => {
  const firm = props.firm;
  const { classes, cx } = useStyles();

  return (
    <Stack direction="row">
      <Typography variant="body1" className={classes.inlineLabel}>
        Firm Status:
      </Typography>
      <Typography variant="body1" className={cx(classes.status, { [classes.inactive]: firm.firmStatus === "Inactive" })}>
        {firm.firmStatus}
        {firm.subStatus && <span>, {firm.subStatus}</span>}
      </Typography>
    </Stack>
  );
};

export default FirmStatus;
