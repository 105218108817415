import React from "react";

import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeStyles } from "../makeStyles";

const useStyles = makeStyles<Props>()((theme) => {
  return {
    checkedItem: {
      color: theme.palette.success.main
    }
  };
});

interface Props {
  yes: boolean;
}

export const YesNoIcon: React.FunctionComponent<Props> = (props) => {
  const { classes, cx } = useStyles(props);

  return <FontAwesomeIcon icon={props.yes ? faCheck : faTimes} className={cx({ [classes.checkedItem]: props.yes })} />;
};
