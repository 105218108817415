import React from "react";
import { useMutation, gql } from "@apollo/client";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

import { ScreenHeader } from "common/ScreenHeader";
import { Skeleton } from "@mui/material";
import { ImportPracticeReviewResults } from "./models";
import { Helmet } from "react-helmet";
import { LoadingButton } from "@mui/lab";
import { makeStyles } from "../makeStyles";

const useStyles = makeStyles()((theme) => ({
  root: {},
  header: {
    marginBottom: theme.spacing(3)
  },
  gridContainer: {
    marginTop: theme.spacing(1),
    width: 300
  },
  table: {}
}));

export const ImportPRs: React.FunctionComponent = () => {
  const { classes } = useStyles();

  const importPrMutation = gql`
    mutation {
      practiceReview {
        import {
          new
          updated
          failed
        }
      }
    }
  `;

  const [importPracticeReviews, { loading: mLoading, data }] = useMutation(importPrMutation);

  const importResults = data?.practiceReview?.import as ImportPracticeReviewResults;

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Import Reviews - PRS Online</title>
      </Helmet>
      <ScreenHeader title="Import Reviews" />
      <LoadingButton
        onClick={() => importPracticeReviews()}
        variant="contained"
        color="primary"
        size="medium"
        disabled={mLoading}
        loading={mLoading}>
        Import Reviews
      </LoadingButton>
      {mLoading ? (
        <div className={classes.gridContainer}>
          <Skeleton variant="rectangular" width="300px" height="14rem" />
        </div>
      ) : (
        importResults && (
          <div className={classes.gridContainer}>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Status</TableCell>
                    <TableCell align="right">Count</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      New
                    </TableCell>
                    <TableCell align="right">{importResults?.new ?? 0}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Updated
                    </TableCell>
                    <TableCell align="right">{importResults?.updated ?? 0}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Failed
                    </TableCell>
                    <TableCell align="right">{importResults?.failed ?? 0}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )
      )}
    </div>
  );
};
