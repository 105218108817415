import React from "react";
import { Button, Dialog, DialogActions, DialogContent, Grid, TextField, Typography } from "@mui/material";
import ClosableDialogTitle from "common/ClosableDialogTitle";
import { gql, useMutation } from "@apollo/client";
import { StatutoryHoliday } from "./models";
import * as Yup from "yup";
import { Field as FormikField, Form as FormikForm, Formik } from "formik";
import { DateTime } from "luxon";
import PrsDatePickerField from "common/FormikFields/PrsDatePickerField";
import { StatutoryHolidaysQuery } from "./StatHolidaysScreen";
import { DateRules, Validations } from "common/validations/common-yup-validations";
import { useCalendar } from "util/CalendarProvider";
import { Autocomplete, AutocompleteRenderInputParams } from "formik-mui";
import { LoadingButton } from "@mui/lab";

const AddStatHolidayMutation = gql`
  mutation AddStatHoliday($holiday: StatutoryHolidayInput!) {
    statutoryHolidays {
      add(holiday: $holiday) {
        id
        holidayName
        holidayDate
      }
    }
  }
`;

const EditStatHolidayMutation = gql`
  mutation EditStatHoliday($holiday: StatutoryHolidayInput!) {
    statutoryHolidays {
      update(holiday: $holiday) {
        id
        holidayName
        holidayDate
      }
    }
  }
`;

interface EditHolidayDialogProps {
  handleClose: () => void;
  year: number;
  title: string;
  confirmButtonText: string;
  holiday?: StatutoryHoliday;
}

interface FormValues {
  name: string;
  date: DateTime | null;
}

const EditHolidayDialog = (props: EditHolidayDialogProps) => {
  const { holidays } = useCalendar();

  const validationSchema = Yup.object().shape({
    name: Validations.requiredText(),
    date: Validations.requiredDate()
      .test(DateRules.isWithinYear(props.year))
      .test(DateRules.isNotOverlappingHoliday(holidays, DateTime.fromISO(props.holiday?.holidayDate ?? "")))
  });

  const [addStatHoliday, { loading: loadingAdd }] = useMutation<
    { statutoryHolidays: { add: StatutoryHoliday } },
    { holiday: StatutoryHoliday }
  >(AddStatHolidayMutation, {
    refetchQueries: [{ query: StatutoryHolidaysQuery }]
  });

  const [editStatHoliday, { loading: loadingEdit }] = useMutation<
    { statutoryHolidays: { add: StatutoryHoliday } },
    { holiday: StatutoryHoliday }
  >(EditStatHolidayMutation);

  const mutate = Boolean(props.holiday) ? editStatHoliday : addStatHoliday;
  const loading = Boolean(props.holiday) ? loadingEdit : loadingAdd;

  const dateTimeYear = DateTime.local(props.year);
  const initialValues: FormValues = {
    name: props.holiday?.holidayName ?? "",
    date: props.holiday ? DateTime.fromISO(props.holiday.holidayDate) : dateTimeYear.startOf("year")
  };

  return (
    <Dialog open={true} onClose={props.handleClose} fullWidth={true} scroll="paper" maxWidth="sm">
      <ClosableDialogTitle onClose={props.handleClose}>{props.title}</ClosableDialogTitle>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values) => {
          let holiday: StatutoryHoliday = {
            id: props.holiday?.id ?? 0,
            holidayDate: values.date!.toISODate(),
            holidayName: values.name
          };
          await mutate({
            variables: { holiday }
          });
          props.handleClose();
        }}
        validationSchema={validationSchema}>
        {(formikProps) => (
          <FormikForm>
            <DialogContent>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Typography paragraph>
                    Non-working days affect the ability to select days in all calendar and date pickers used throughout the app. Days listed
                    as non-working will be disabled.
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <FormikField
                    component={Autocomplete}
                    name="name"
                    // The autocomplete element doesn't update the formik value onBlur without the user pressing enter so we must do it manually
                    onClose={(e: React.ChangeEvent<HTMLInputElement>, reason: string) =>
                      reason === "blur" && formikProps.setFieldValue("name", e.target.value)
                    }
                    freeSolo
                    options={Array.from(new Set(holidays?.map((h) => h.holidayName)))}
                    renderInput={(params: AutocompleteRenderInputParams) => (
                      <TextField
                        {...params}
                        label="Name"
                        error={formikProps.touched.name && !!formikProps.errors.name}
                        helperText={formikProps.touched.name && formikProps.errors.name}
                        variant="outlined"
                        required
                      />
                    )}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={8}>
                  <FormikField
                    component={PrsDatePickerField}
                    name="date"
                    label="Date"
                    required
                    minDate={dateTimeYear.startOf("year")}
                    maxDate={dateTimeYear.endOf("year")}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={props.handleClose}>Cancel</Button>
              <LoadingButton color="primary" variant="contained" loading={loading} onClick={() => formikProps.submitForm()}>
                {props.confirmButtonText}
              </LoadingButton>
            </DialogActions>
          </FormikForm>
        )}
      </Formik>
    </Dialog>
  );
};

export default EditHolidayDialog;
