import React from "react";
import { PracticeReview } from "practice-reviews";
import { Link, Typography } from "@mui/material";
import { makeStyles } from "../../makeStyles";
import { useHistory } from "react-router-dom";
import { getRouteForPracticeReview, PracticeReviewTabs } from "../PracticeReviewScreen";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons/faLink";

interface Props {
  header: string;
  practiceReview?: PracticeReview;
  linkDestination?: PracticeReviewTabs;
}

export const SummaryTabHeader: React.FunctionComponent<Props> = (props) => {
  const useStyles = makeStyles()((theme) => ({
    sectionHeader: {
      marginBottom: theme.spacing(1)
    },
    headerLink: {
      paddingLeft: theme.spacing(1)
    }
  }));
  const { classes } = useStyles();
  const history = useHistory();
  return (
    <Typography variant="h3" className={classes.sectionHeader}>
      {props.header}
      {props.practiceReview && props.linkDestination && (
        <Link
          component="button"
          className={classes.headerLink}
          onClick={() => {
            history.push(getRouteForPracticeReview(props.practiceReview as PracticeReview, props.linkDestination));
          }}>
          <FontAwesomeIcon icon={faLink} />
        </Link>
      )}
    </Typography>
  );
};
