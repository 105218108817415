import { useMutation, useQuery } from "@apollo/client";
import React from "react";
import EditCustomReportDialog from "./EditCustomReportDialog";
import CrudTable from "../common/CrudTable";
import { CustomReport } from "./models";
import { ConfirmationDialog } from "common/ConfirmationDialog";
import { Helmet } from "react-helmet";
import { DialogContentText } from "@mui/material";
import { ScreenHeader } from "../common/ScreenHeader";
import { DeleteCustomReportMutation, FetchCustomReportsQuery } from ".";

const CustomReportsScreen: React.FunctionComponent = () => {
  const [deleteMutate, { loading: deleting }] = useMutation<{ customReport: { delete: boolean } }, { id: number }>(
    DeleteCustomReportMutation,
    {
      refetchQueries: [{ query: FetchCustomReportsQuery }]
    }
  );

  const customReportsQuery = useQuery<{ customReports: CustomReport[] }>(FetchCustomReportsQuery);
  const customReports = customReportsQuery.data?.customReports || [];

  return (
    <>
      <Helmet>
        <title>Custom Reports - PRS Online</title>
      </Helmet>
      <ScreenHeader title="Custom Reports" />
      <CrudTable
        title=""
        rows={customReports}
        loading={customReportsQuery.loading}
        columnDefinitions={[
          {
            field: "reportName",
            headerName: "Report Name",
            flex: 1.5
          },
          {
            field: "url",
            headerName: "Url",
            flex: 3
          }
        ]}
        storageKey={`Custom Report`}
        noDataMessage="No reports created"
        renderAddDialog={(props) => (
          <EditCustomReportDialog title="Add Custom Report" confirmButtonText="Add" handleClose={props.onClose} />
        )}
        renderEditDialog={(id, props) => (
          <EditCustomReportDialog
            title="Edit Custom Report"
            confirmButtonText="Save"
            handleClose={props.onClose}
            data={customReports.find((r) => r.id === id)}
          />
        )}
        renderDeleteDialog={(id, props) => {
          let report = customReports.find((r) => r.id === id);
          return (
            <ConfirmationDialog
              open={true}
              title="Delete Custom Report?"
              body={<DialogContentText>`Are you sure you want to delete the custom report: ${report?.reportName}?`</DialogContentText>}
              confirm={() => deleteMutate({ variables: { id: id } }).then(props.onClose)}
              cancel={props.onClose}
              loading={deleting}
            />
          );
        }}
      />
    </>
  );
};

export default CustomReportsScreen;
