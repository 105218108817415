import { gql } from "@apollo/client";

export const FetchCustomReportsQuery = gql`
  query FetchCustomReports {
    customReports {
      id
      reportName
      url
      category
    }
  }
`;

export const DeleteCustomReportMutation = gql`
  mutation DeleteCustomReport($id: Int) {
    customReports {
      delete(id: $id)
    }
  }
`;
