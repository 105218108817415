import React, { useState } from "react";
import { Tab, Tabs, Tooltip } from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import { PracticeReview } from "../practice-reviews";
import { DecisionStageTab } from "./DecisionStageTab";
import { makeStyles } from "../makeStyles";
import {
  getCommitteeDecisionInaccessibleReason,
  getDirectorDecisionInaccessibleReason,
  getManagerDecisionInaccessibleReason
} from "./decisionLogic";
import { DecisionTypeCode } from "./models";
import { Permissions, useCurrentUser } from "users";

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      "& .MuiTabPanel-root": {
        flexGrow: 1
      }
    },
    tabs: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      "& .MuiTab-wrapper": {
        alignItems: "flex-start",
        textAlign: "left"
      },
      "& .MuiTab-root": {
        minWidth: "14rem",
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
      }
    },
    tabLabel: {
      display: "flex",
      alignItems: "center"
    },
    disableableTab: {
      pointerEvents: "auto !important" as any
    }
  };
});

interface Props {
  practiceReview: PracticeReview;
  subtab: DecisionTypeCode | undefined;
  setActiveDecisionTab: (newTab: DecisionTypeCode) => void;
}

export const DecisionTab: React.FunctionComponent<Props> = (props) => {
  const { classes } = useStyles();

  const mostAuthoritativeDecision =
    props.practiceReview.decisions.find((d) => d.decisionType.typeCode === DecisionTypeCode.Committee) ??
    props.practiceReview.decisions.find((d) => d.decisionType.typeCode === DecisionTypeCode.Director) ??
    props.practiceReview.decisions.find((d) => d.decisionType.typeCode === DecisionTypeCode.Manager) ??
    props.practiceReview.decisions.find((d) => d.decisionType.typeCode === DecisionTypeCode.Reviewer);

  const [activeTab, setActiveTab] = useState(
    props.subtab ??
      (props.practiceReview.hasBeenReturned && mostAuthoritativeDecision
        ? mostAuthoritativeDecision.decisionType.typeCode
        : DecisionTypeCode.Reviewer)
  );
  const managerTabInaccessibleReason = getManagerDecisionInaccessibleReason(props.practiceReview);
  const directorTabInaccessibleReason = getDirectorDecisionInaccessibleReason(props.practiceReview);
  const committeeTabInaccessibleReason = getCommitteeDecisionInaccessibleReason(props.practiceReview);

  const { userHasPermission } = useCurrentUser();

  return (
    <div className={classes.root}>
      <Tabs
        className={classes.tabs}
        value={activeTab}
        indicatorColor="primary"
        textColor="primary"
        onChange={(e, newTab: DecisionTypeCode) => {
          setActiveTab(newTab);
          props.setActiveDecisionTab(newTab);
        }}>
        <Tab label="Reviewer Decision" value={DecisionTypeCode.Reviewer} />
        {userHasPermission(Permissions.PrManagerDecisionView) && (
          <Tab
            value={DecisionTypeCode.Manager}
            disabled={Boolean(managerTabInaccessibleReason)}
            className={classes.disableableTab}
            label={
              <Tooltip title={managerTabInaccessibleReason ?? ""}>
                <span>Manager Approval</span>
              </Tooltip>
            }
          />
        )}
        {userHasPermission(Permissions.PrDirectorDecisionView) && (
          <Tab
            value={DecisionTypeCode.Director}
            disabled={Boolean(directorTabInaccessibleReason)}
            className={classes.disableableTab}
            label={
              <Tooltip title={directorTabInaccessibleReason ?? ""}>
                <span>
                  Director Approval / <br />
                  Committee Presentation
                </span>
              </Tooltip>
            }
          />
        )}
        <Tab
          value={DecisionTypeCode.Committee}
          disabled={Boolean(committeeTabInaccessibleReason)}
          className={classes.disableableTab}
          label={
            <Tooltip title={committeeTabInaccessibleReason ?? ""}>
              <span>Committee Decision</span>
            </Tooltip>
          }
        />
      </Tabs>
      <TabContext value={activeTab}>
        <TabPanel value={DecisionTypeCode.Reviewer}>
          <DecisionStageTab practiceReview={props.practiceReview} decisionTypeCode={DecisionTypeCode.Reviewer} />
        </TabPanel>
        {userHasPermission(Permissions.PrManagerDecisionView) && (
          <TabPanel value={DecisionTypeCode.Manager}>
            <DecisionStageTab practiceReview={props.practiceReview} decisionTypeCode={DecisionTypeCode.Manager} />
          </TabPanel>
        )}
        {userHasPermission(Permissions.PrDirectorDecisionView) && (
          <TabPanel value={DecisionTypeCode.Director}>
            <DecisionStageTab practiceReview={props.practiceReview} decisionTypeCode={DecisionTypeCode.Director} />
          </TabPanel>
        )}
        <TabPanel value={DecisionTypeCode.Committee}>
          <DecisionStageTab practiceReview={props.practiceReview} decisionTypeCode={DecisionTypeCode.Committee} />
        </TabPanel>
      </TabContext>
    </div>
  );
};
