import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  Link,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { PracticeReview, PRPhase, PRStatus } from "./models";
import { formatDate } from "util/formats";
import { datagridStyles, fieldStyles } from "../styles/common";
import { Ina } from "../inas";
import { Helmet } from "react-helmet";
import { ScreenHeader } from "../common/ScreenHeader";
import { makeStyles } from "../makeStyles";
import { AxiosResponse } from "axios";
import { useAxios } from "auth/SecureAxios";
import { useAppConfig } from "util/AppConfig";
import { useNotifications } from "../notifications";
import fileDownload from "js-file-download";
import { DateTime } from "luxon";
import { LoadingButton } from "@mui/lab";
import PrLink from "../common/PrLink";
import useLocalStorage from "../util/useLocalStorage";
import { PrsDatePicker } from "../common/PrsDatePicker";
import { DataGridPro, GridSortCellParams } from "@mui/x-data-grid-pro";
import { Link as RouterLink } from "react-router-dom";
import { getRouteForPracticeReview } from "practice-reviews/PracticeReviewScreen";

const useStyles = makeStyles()((theme) => ({
  ...datagridStyles(theme),
  ...fieldStyles(theme)
}));

const PracticeReviewSearch = gql`
  query PracticeReviewSearch($options: PrSearchQueryOptionsInput, $skip: Int!, $take: Int!) {
    practiceReviewSearch(options: $options, skip: $skip, take: $take) {
      id
      prNumber
      reviewType
      startDate
      firm {
        id
        entityNumber
        name
        phoneNumber
        city
      }
      contactPhone
      status {
        id
        statusName
      }
      phase {
        id
        name
      }
      leadReviewer {
        userId
        practiceReviewId
        user {
          id
          name
        }
      }
      startDate
      endDate
      committeeMeeting {
        id
        meetingDate
        location
      }
      inas {
        id
        assignedToUser {
          id
          name
        }
        createdDate
        type {
          id
          friendlyName
        }
        inaBatch {
          id
          assignedTo
        }
      }
    }
  }
`;

const PrStatusesQuery = gql`
  query PRStatuses {
    prStatuses {
      id
      statusName
    }
  }
`;

const PrPhasesQuery = gql`
  query PRPhases {
    prPhases {
      id
      name
    }
  }
`;

interface QueryOptions {
  prNumberBegin: string;
  prNumberEnd: string;
  entityNumber: number | null;
  firmNameQuery: string;
  locationQuery: string;
  contactNameQuery: string;
  phoneNumberQuery: string;
  statusId: number | null;
  phaseId: number | null;
  fromDate: string | null;
  toDate: string | null;
  isNonAssurance: boolean | null;
  sortOn: string | null;
  sortDescending: boolean;
}

const ANY_SELECTION_ID = -1;

const initialQueryOptions: QueryOptions = {
  firmNameQuery: "",
  entityNumber: null,
  prNumberBegin: "",
  prNumberEnd: "",
  locationQuery: "",
  contactNameQuery: "",
  phoneNumberQuery: "",
  fromDate: null,
  toDate: null,
  statusId: ANY_SELECTION_ID,
  phaseId: ANY_SELECTION_ID,
  isNonAssurance: null,
  sortOn: null,
  sortDescending: false
};

const AdvancedPrSearchScreen: React.FunctionComponent = () => {
  const appConfig = useAppConfig();
  const { fileDownloadAxios } = useAxios();
  const notifications = useNotifications();
  const { classes } = useStyles();

  const [showingResults, setShowingResults] = useState(false);
  const [exportingSearch, setExportingSearch] = useState<boolean>(false);
  const [queryOptions, setQueryOptions] = useLocalStorage<QueryOptions>("Advanced PR Search options", getEmptyQueryOptions());

  const [runPrQuery, prQuery] = useLazyQuery<
    { practiceReviewSearch: PracticeReview[] },
    { options: QueryOptions; skip: number; take: number }
  >(PracticeReviewSearch, {
    notifyOnNetworkStatusChange: true
  });
  const practiceReviews = prQuery.data?.practiceReviewSearch ?? [];

  function noFiltersEntered() {
    return (
      queryOptions.firmNameQuery === "" &&
      queryOptions.prNumberBegin.trim() === "" &&
      queryOptions.prNumberEnd.trim() === "" &&
      queryOptions.entityNumber === null &&
      queryOptions.locationQuery.trim() === "" &&
      queryOptions.contactNameQuery.trim() === "" &&
      queryOptions.phoneNumberQuery.trim() === "" &&
      queryOptions.fromDate === null &&
      queryOptions.toDate === null &&
      queryOptions.statusId === ANY_SELECTION_ID &&
      queryOptions.phaseId === ANY_SELECTION_ID &&
      queryOptions.isNonAssurance === null
    );
  }

  const statusesQuery = useQuery<{ prStatuses: PRStatus[] }>(PrStatusesQuery);
  const statuses = statusesQuery.data?.prStatuses ?? [];

  const phasesQuery = useQuery<{ prPhases: PRPhase[] }>(PrPhasesQuery);
  const phases = phasesQuery.data?.prPhases.filter((p) => p.id !== 0) ?? [];

  function getEmptyQueryOptions(): QueryOptions {
    return {
      ...initialQueryOptions,
      firmNameQuery: "",
      entityNumber: null,
      prNumberBegin: "",
      prNumberEnd: "",
      locationQuery: "",
      contactNameQuery: "",
      phoneNumberQuery: "",
      fromDate: null,
      toDate: null,
      statusId: ANY_SELECTION_ID,
      phaseId: ANY_SELECTION_ID,
      isNonAssurance: null
    };
  }

  async function search() {
    let options = queryOptions as any;
    delete options["isPprpReview"]; // this is a hack I am unhappy about; it used to be a search criterion so may still exist in folks's local storage but graphQL will complain 🙄
    await runPrQuery({
      variables: {
        options: {
          ...options,
          statusId: queryOptions.statusId === ANY_SELECTION_ID ? null : queryOptions.statusId,
          phaseId: queryOptions.phaseId === ANY_SELECTION_ID ? null : queryOptions.phaseId
        },
        skip: 0,
        take: 50
      }
    });
    setShowingResults(true);
  }

  async function prQueryExport() {
    setExportingSearch(true);

    let postResult: AxiosResponse<string | ArrayBuffer | ArrayBufferView | Blob>;
    const postRequestEndpoint = `${appConfig.apiEndpoint}/api/advanced-pr-search/export`;
    const postRequestOptions = {
      ...queryOptions,
      statusId: queryOptions.statusId === ANY_SELECTION_ID ? null : queryOptions.statusId,
      phaseId: queryOptions.phaseId === ANY_SELECTION_ID ? null : queryOptions.phaseId
    };
    try {
      postResult = await fileDownloadAxios.post(postRequestEndpoint, postRequestOptions);
      if (postResult === undefined || postResult?.status === 401) {
        postResult = await fileDownloadAxios.post(postRequestEndpoint, postRequestOptions);
      }
      if (postResult?.status === 200) {
        fileDownload(postResult.data, `PR Search Export - ${DateTime.now().toFormat("yyyy-MM-dd")}.xlsx`);
      } else {
        notifications.serverError(new Error(postResult?.statusText));
      }
    } catch (e: any) {
      notifications.serverError(e.message);
      return;
    }

    setExportingSearch(false);
  }

  useEffect(() => {
    if (!noFiltersEntered()) {
      search();
    }
  }, []);

  function handleEnterKey(e: React.KeyboardEvent<any>) {
    if (e.key === "Enter") {
      search();
    }
  }

  return (
    <Stack sx={{ height: "100%" }}>
      <Helmet>
        <title>Advanced PR Search</title>
      </Helmet>
      <ScreenHeader title="Advanced PR Search" />
      <Paper sx={{ p: 3, flex: 1 }}>
        <Stack>
          <Typography variant="h3">Search Filters</Typography>
          <Grid item container columnSpacing={2} xs={12} lg={10} xl={8}>
            <Grid item xs={6}>
              <TextField
                label="Firm Name"
                fullWidth
                value={queryOptions.firmNameQuery}
                onChange={(e: React.ChangeEvent<any>) => {
                  setQueryOptions({ ...queryOptions, firmNameQuery: e.target.value });
                }}
                onKeyPress={handleEnterKey}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="Entity Number"
                type="number"
                className={classes.noNumberSpinners}
                fullWidth
                value={queryOptions.entityNumber || ""}
                onChange={(e: React.ChangeEvent<any>) => {
                  setQueryOptions({ ...queryOptions, entityNumber: e.target.value.trim() === "" ? null : Number(e.target.value) });
                }}
                onKeyPress={handleEnterKey}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="Location"
                fullWidth
                value={queryOptions.locationQuery}
                onChange={(e: React.ChangeEvent<any>) => {
                  setQueryOptions({ ...queryOptions, locationQuery: e.target.value });
                }}
                onKeyPress={handleEnterKey}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="Contact Name"
                fullWidth
                value={queryOptions.contactNameQuery}
                onChange={(e: React.ChangeEvent<any>) => {
                  setQueryOptions({ ...queryOptions, contactNameQuery: e.target.value });
                }}
                onKeyPress={handleEnterKey}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="Phone Number"
                fullWidth
                value={queryOptions.phoneNumberQuery}
                onChange={(e: React.ChangeEvent<any>) => {
                  setQueryOptions({ ...queryOptions, phoneNumberQuery: e.target.value });
                }}
                onKeyPress={handleEnterKey}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="From PR No."
                fullWidth
                value={queryOptions.prNumberBegin}
                onChange={(e: React.ChangeEvent<any>) => {
                  setQueryOptions({ ...queryOptions, prNumberBegin: e.target.value });
                }}
                onKeyPress={handleEnterKey}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="To PR No."
                fullWidth
                value={queryOptions.prNumberEnd}
                onChange={(e: React.ChangeEvent<any>) => {
                  setQueryOptions({ ...queryOptions, prNumberEnd: e.target.value });
                }}
                onKeyPress={handleEnterKey}
              />
            </Grid>
            <Grid item xs={3}>
              <PrsDatePicker
                setValue={(newDate) => setQueryOptions({ ...queryOptions, fromDate: newDate?.startOf("day").toISODate() ?? null })}
                value={queryOptions.fromDate ? DateTime.fromISO(queryOptions.fromDate) : null}
                label="From Review Date"
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <PrsDatePicker
                setValue={(newDate) => setQueryOptions({ ...queryOptions, toDate: newDate?.startOf("day").toISODate() ?? null })}
                value={queryOptions.toDate ? DateTime.fromISO(queryOptions.toDate) : null}
                label="To Review Date"
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth margin="normal" size="small">
                <InputLabel id="status-label">Status</InputLabel>
                <Select
                  labelId="status-label"
                  label="Status"
                  value={queryOptions.statusId}
                  onChange={(e) => setQueryOptions({ ...queryOptions, statusId: Number(e.target.value) })}>
                  {statusesQuery.loading ? (
                    <p>Please wait...</p>
                  ) : (
                    [
                      <MenuItem key={ANY_SELECTION_ID} value={ANY_SELECTION_ID}>
                        Any
                      </MenuItem>,
                      ...statuses.map((status) => (
                        <MenuItem key={status.id} value={status.id}>
                          {status.statusName}
                        </MenuItem>
                      ))
                    ]
                  )}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth margin="normal" size="small">
                <InputLabel id="review-type-label">Type</InputLabel>
                <Select
                  labelId="review-type-label"
                  label="Type"
                  value={
                    queryOptions.isNonAssurance === true ? "Non-assurance" : queryOptions.isNonAssurance === false ? "Assurance" : "Any"
                  }
                  onChange={(e) => {
                    const isNonAssurance = e.target.value === "Non-assurance" ? true : e.target.value === "Assurance" ? false : null;
                    setQueryOptions({ ...queryOptions, isNonAssurance });
                  }}>
                  <MenuItem value="Any">Any</MenuItem>
                  <MenuItem value="Assurance">Assurance</MenuItem>
                  <MenuItem value="Non-assurance">Non-assurance</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth margin="normal" size="small">
                <InputLabel id="phase-label">Phase</InputLabel>
                <Select
                  labelId="phase-label"
                  label="Phase"
                  value={queryOptions.phaseId}
                  onChange={(e) => setQueryOptions({ ...queryOptions, phaseId: Number(e.target.value) })}>
                  {phasesQuery.loading ? (
                    <p>Please wait...</p>
                  ) : (
                    [
                      <MenuItem key={ANY_SELECTION_ID} value={ANY_SELECTION_ID}>
                        Any
                      </MenuItem>,
                      ...phases.map((phase) => (
                        <MenuItem key={phase.id} value={phase.id}>
                          {phase.name}
                        </MenuItem>
                      ))
                    ]
                  )}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Stack direction="row" spacing={2} sx={{ mt: 3, mb: 3 }}>
            <Button onClick={() => search()} variant="contained" color="primary" disabled={noFiltersEntered()}>
              Search
            </Button>
            <Button
              onClick={() => {
                setQueryOptions(getEmptyQueryOptions());
                setShowingResults(false);
              }}
              variant="outlined">
              Reset Filters
            </Button>
            {showingResults && (
              <LoadingButton
                loading={exportingSearch}
                onClick={prQueryExport}
                color="primary"
                variant="outlined"
                sx={{ ml: "auto !important" }}
                disabled={practiceReviews.length === 0}>
                Export Results
              </LoadingButton>
            )}
          </Stack>

          {showingResults && (
            <Box sx={{ height: "500px", overflow: "auto" }}>
              <DataGridPro
                rows={practiceReviews}
                loading={prQuery.loading}
                components={{
                  LoadingOverlay: () => <LinearProgress />,
                  NoRowsOverlay: () => <div className={classes.noDataMessage}>No matching PRs found.</div>
                }}
                hideFooter
                onRowsScrollEnd={(params) => {
                  if (!prQuery.loading && prQuery.fetchMore) {
                    prQuery.fetchMore({ variables: { skip: practiceReviews.length } });
                  }
                }}
                columns={[
                  {
                    field: "prNumber",
                    headerName: "PR No.",
                    sortable: true,
                    width: 100
                  },
                  {
                    field: "entityNumber",
                    headerName: "Entity No.",
                    headerClassName: classes.wrapHeader,
                    valueGetter: (params: { row: PracticeReview }) => params.row.firm.entityNumber,
                    sortComparator: (v1: any, v2: any, param1: GridSortCellParams, param2: GridSortCellParams) => {
                      const pr1 = param1.api.getRow(param1.id)!;
                      const entityNumber1 = pr1.firm.entityNumber;
                      const pr2 = param1.api.getRow(param2.id)!;
                      const entityNumber2 = pr2.firm.entityNumber;

                      return entityNumber1 - entityNumber2;
                    },
                    sortable: true,
                    width: 100
                  },
                  {
                    field: "firmName",
                    headerName: "Firm Name",
                    sortable: true,
                    valueGetter: (params) => params.row.firm.name,
                    renderCell: (params) => (
                      <Link to={getRouteForPracticeReview(params.row)} component={RouterLink}>
                        {params.row.firm.name}
                      </Link>
                    ),

                    width: 200
                  },
                  {
                    field: "firmPhoneNumber",
                    headerName: "Firm Phone",
                    valueGetter: (params) => params.row.firm.phoneNumber,
                    sortable: true,
                    width: 100
                  },
                  {
                    field: "contactPhone",
                    headerName: "PR Contact Phone",
                    headerClassName: classes.wrapHeader,
                    sortable: true,
                    width: 100
                  },
                  {
                    field: "status",
                    headerName: "Status",
                    valueGetter: (params) => params.row.status.statusName,
                    sortable: true,
                    width: 100
                  },
                  {
                    field: "reviewType",
                    headerName: "Type",
                    sortable: true,
                    width: 120
                  },
                  {
                    field: "phase",
                    headerName: "Phase",
                    valueGetter: (params) => params.row.phase.name,
                    sortable: true,
                    width: 140
                  },
                  {
                    field: "currentActivity",
                    headerName: "Current Activity",
                    valueGetter: (params) =>
                      params.row.inas.length > 0
                        ? params.row.inas.reduce((max: Ina, curr: Ina) =>
                            new Date(max.createdDate) >= new Date(curr.createdDate) ? max : curr
                          ).type.friendlyName
                        : "",
                    sortable: true,
                    width: 200
                  },
                  {
                    field: "user",
                    headerName: "User",
                    valueGetter: (params) => {
                      const lastIna: Ina | null =
                        params.row.inas.length > 0
                          ? [...params.row.inas]
                              .sort((a: Ina, b: Ina) => b.id - a.id)
                              .reduce((max: Ina, curr: Ina) => (new Date(max.createdDate) >= new Date(curr.createdDate) ? max : curr))
                          : null;

                      if (!lastIna) {
                        return "";
                      }

                      switch (lastIna.type.friendlyName) {
                        case "Perform Review":
                          return params.row.leadReviewer?.user?.name ?? "";
                        case "Custom Activity":
                          return lastIna?.assignedToUser?.name ?? "";
                        default:
                          return lastIna?.inaBatch?.assignedTo ?? "";
                      }
                    },
                    sortable: true,
                    width: 160
                  },
                  {
                    field: "startDate",
                    headerName: "PR Date",
                    valueFormatter: (params) => formatDate(params.value as string),
                    sortable: true,
                    width: 120
                  },
                  {
                    field: "location",
                    headerName: "Location",
                    valueGetter: (params) => params.row.firm.city,
                    sortable: true,
                    width: 120
                  },
                  {
                    field: "leadReviewer",
                    headerName: "Lead Reviewer",
                    valueGetter: (params) => params.row.leadReviewer?.user?.name ?? "",
                    sortable: true,
                    width: 160
                  },
                  {
                    field: "meeting",
                    headerName: "Meeting",
                    valueGetter: (params) =>
                      params.row.committeeMeeting
                        ? `${formatDate(params.row.committeeMeeting.meetingDate)} - ${params.row.committeeMeeting.location}`
                        : "",
                    sortable: true,
                    width: 200
                  }
                ]}
                sortModel={
                  queryOptions.sortOn
                    ? [
                        {
                          field: queryOptions.sortOn,
                          sort: queryOptions.sortDescending ? "desc" : "asc"
                        }
                      ]
                    : []
                }
                onSortModelChange={(newSortModel) => {
                  if (newSortModel.length === 0 || !newSortModel[0].sort) {
                    setQueryOptions({
                      ...queryOptions,
                      sortOn: null,
                      sortDescending: false
                    });

                    return;
                  }

                  setQueryOptions({
                    ...queryOptions,
                    sortOn: newSortModel[0].field,
                    sortDescending: newSortModel[0].sort === "desc"
                  });

                  search();
                }}
              />
            </Box>
          )}
        </Stack>
      </Paper>
    </Stack>
  );
};

export default AdvancedPrSearchScreen;
