import React from "react";
import { Button, Dialog, DialogActions, DialogContent, Grid, Typography } from "@mui/material";
import ClosableDialogTitle from "common/ClosableDialogTitle";
import { gql, useMutation } from "@apollo/client";
import * as Yup from "yup";
import { Field as FormikField, Form as FormikForm, Formik } from "formik";
import { TextField as FmuiTextField } from "formik-mui";
import { RetentionPolicy } from "./models";
import { Validations } from "common/validations/common-yup-validations";
import { LoadingButton } from "@mui/lab";

const UpdateRetentionPolicyMutation = gql`
  mutation EditRetentionPolicy($policy: RetentionPolicyInput) {
    retentionPolicy {
      update(policy: $policy) {
        id
        dataItemName
        expirationMarginInMonths
      }
    }
  }
`;

interface EditRetentionPolicyDialogProps {
  handleClose: () => void;
  title: string;
  confirmButtonText: string;
  data: RetentionPolicy;
}

const EditRetentionPolicyDialog = (props: EditRetentionPolicyDialogProps) => {
  const validationSchema = Yup.object().shape({
    expirationMarginInMonths: Validations.requiredNonNegativeNumber()
  });

  const [mutate, { loading }] = useMutation<{ retentionPolicy: { update: RetentionPolicy } }, { policy: RetentionPolicy }>(
    UpdateRetentionPolicyMutation
  );

  return (
    <Dialog open={true} onClose={props.handleClose} fullWidth={true} scroll="paper" maxWidth="sm">
      <ClosableDialogTitle onClose={props.handleClose}>{props.title}</ClosableDialogTitle>
      <Formik
        initialValues={{ ...props.data }}
        onSubmit={async (values) => {
          const policy: RetentionPolicy = {
            id: props.data.id,
            expirationMarginInMonths: values.expirationMarginInMonths
          };

          await mutate({
            variables: { policy }
          });

          props.handleClose();
        }}
        validationSchema={validationSchema}>
        {(formikProps) => (
          <FormikForm>
            <DialogContent>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Typography paragraph>{props.data.dataItemName}</Typography>
                </Grid>
                <Grid item>
                  <FormikField
                    type="number"
                    inputProps={{ min: 0 }}
                    component={FmuiTextField}
                    name="expirationMarginInMonths"
                    label="Expiration Margin In Months"
                    fullWidth
                    required
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={props.handleClose}>Cancel</Button>
              <LoadingButton color="primary" variant="contained" loading={loading} onClick={() => formikProps.submitForm()}>
                {props.confirmButtonText}
              </LoadingButton>
            </DialogActions>
          </FormikForm>
        )}
      </Formik>
    </Dialog>
  );
};

export default EditRetentionPolicyDialog;
