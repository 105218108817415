import { useQuery } from "@apollo/client";
import { ScreenHeader } from "common/ScreenHeader";
import gql from "graphql-tag";
import React from "react";
import EditTimedEventMarginDialog from "timed-event-margins/EditTimedEventMarginDialog";
import CrudTable from "../common/CrudTable";
import { TimedEventMargin } from "./models";
import { Helmet } from "react-helmet";

export const GetTimedEventMarginQuery = gql`
  query TimedEventMarginQuery {
    timedEventMargin {
      id
      typeName
      friendlyName
      marginDescription
      marginInDays
    }
  }
`;

const TimedEventMarginsScreen: React.FunctionComponent = () => {
  const query = useQuery<{ timedEventMargin: TimedEventMargin[] }>(GetTimedEventMarginQuery);
  const margins = query.data?.timedEventMargin || [];

  return (
    <>
      <Helmet>
        <title>Timed Event Margins - PRS Online</title>
      </Helmet>
      <ScreenHeader title="Timed Event Margins" />
      <CrudTable
        rows={margins}
        loading={query.loading}
        columnDefinitions={[
          {
            field: "friendlyName",
            headerName: "Event Type",
            flex: 3,
            sortable: true
          },
          {
            field: "marginDescription",
            headerName: "Margin Description",
            flex: 2
          },
          {
            field: "marginInDays",
            headerName: "Margin in Days",
            flex: 1
          }
        ]}
        storageKey={`Timed Event Margin`}
        renderEditDialog={(id, props) => (
          <EditTimedEventMarginDialog
            title="Edit Timed Event Margin"
            handleClose={props.onClose}
            confirmButtonText="Save"
            timedEventMargin={query.data?.timedEventMargin?.find((d) => d.id === id)!}
          />
        )}
        sortModel={[
          {
            field: "friendlyName",
            sort: "asc"
          }
        ]}
      />
    </>
  );
};

export default TimedEventMarginsScreen;
