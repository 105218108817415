import { Theme, Button } from "@mui/material";
import { AdjustmentQuestion, AdjustmentQuestionAnswer } from "./models";
import { useEffect, useState } from "react";
import { answerButtonsStyles } from "styles/common";
import { makeStyles } from "makeStyles";

interface Props {
  setAnswer: (id: number, value: AdjustmentQuestionAnswer) => void;
  adjustmentQuestion: AdjustmentQuestion;
}

const useStyles = makeStyles()((theme) => ({
  buttons: {
    flexShrink: 0
  },
  threeButtonContainer: {
    gridTemplateColumns: "1fr 1fr 1fr"
  },
  ...answerButtonsStyles(theme)
}));

export const AdjustmentQuestionButtonGroup = (props: Props) => {
  const [answer, setAnswer] = useState<AdjustmentQuestionAnswer | null>(null);
  useEffect(() => setAnswer(props.adjustmentQuestion.answer), [props.adjustmentQuestion.answer]);

  const { classes, cx } = useStyles();

  const isYes = answer === AdjustmentQuestionAnswer.Yes;
  const isNo = answer === AdjustmentQuestionAnswer.No;
  const isNa = answer === AdjustmentQuestionAnswer.NA;

  return (
    <div className={classes.buttons}>
      <div className={cx(classes.buttonContainer, classes.threeButtonContainer)}>
        <Button
          className={cx(classes.answerButton, {
            [classes.unselectedAnswerButton]: !isYes,
            [classes.selectedAnswerButton]: isYes,
            [classes.yes]: isYes
          })}
          disableElevation
          variant={isYes ? "contained" : "outlined"}
          onClick={() => {
            setAnswer(AdjustmentQuestionAnswer.Yes);
            props.setAnswer(props.adjustmentQuestion.id, AdjustmentQuestionAnswer.Yes);
          }}>
          YES
        </Button>
        <Button
          className={cx(classes.answerButton, {
            [classes.unselectedAnswerButton]: !isNo,
            [classes.selectedAnswerButton]: isNo,
            [classes.no]: isNo
          })}
          disableElevation
          variant={isNo ? "contained" : "outlined"}
          onClick={() => {
            setAnswer(AdjustmentQuestionAnswer.No);
            props.setAnswer(props.adjustmentQuestion.id, AdjustmentQuestionAnswer.No);
          }}>
          NO
        </Button>
        <Button
          className={cx(classes.answerButton, {
            [classes.unselectedAnswerButton]: !isNa,
            [classes.selectedAnswerButton]: isNa,
            [classes.na]: isNa
          })}
          disableElevation
          variant={isNa ? "contained" : "outlined"}
          onClick={() => {
            props.setAnswer(props.adjustmentQuestion.id, AdjustmentQuestionAnswer.NA);
            setAnswer(AdjustmentQuestionAnswer.NA);
          }}>
          N/A
        </Button>
      </div>
    </div>
  );
};
