import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Ina, InaTypeCode } from "./models";
import _ from "lodash";
import { Button, Grid, Link, Stack, Typography } from "@mui/material";
import { PracticeReviewInaPanel } from "./PracticeReviewInaPanel";
import { AppRoute } from "Routes";
import { Skeleton } from "@mui/material";
import { getRouteForPracticeReview, PracticeReviewTabs } from "practice-reviews/PracticeReviewScreen";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons/faEnvelope";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons/faMapMarkerAlt";
import { faPhone } from "@fortawesome/free-solid-svg-icons/faPhone";
import { faUserTie } from "@fortawesome/free-solid-svg-icons/faUserTie";
import { datagridStyles, tableStyles, inaPanelStyles } from "styles/common";
import { dataGridDateValueFormatter, formatDate, formatDateTime, standardDateFormat } from "util/formats";
import { Helmet } from "react-helmet";
import { GetInasForUserQuery } from ".";
import { makeStyles } from "makeStyles";
import { useInasUser } from "InasUserProvider";
import { ScreenHeader } from "common/ScreenHeader";
import { useCurrentUser, Permissions } from "users";
import { DecisionTypeCode } from "decisions";
import { GridColDef, GridSortCellParams } from "@mui/x-data-grid-pro";
import { MeetingInaPanel } from "./MeetingInaPanel";
import { CommitteeMeeting } from "committee-meetings";
import { Link as RouterLink } from "react-router-dom";
import { DocType, PracticeReview } from "../practice-reviews";
import { EndExemptionDialog } from "../exemptions/EndExemptionDialog";
import { DateTime } from "luxon";
import InlineStaticDataDisplay from "common/InlineStaticDataDisplay";
import { useNotifications } from "../notifications";
import { LoadingButton } from "@mui/lab";

const useStyles = makeStyles()((theme) => ({
  ...datagridStyles(theme),
  ...tableStyles(theme),
  ...inaPanelStyles(theme),
  inaPanel: {
    "&:not(:last-child)": {
      marginBottom: theme.spacing(5)
    }
  },
  rowDetails: {
    "& > :not(:first-child)": {
      marginTop: theme.spacing(1)
    },
    "& svg": {
      marginRight: theme.spacing(1),
      color: theme.palette.text.secondary
    },
    "& label": {
      color: theme.palette.text.secondary,
      marginRight: theme.spacing(1)
    }
  },
  detailRow: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
    position: "relative",
    top: "-1px", // Shift row up 1px to cover bottom border of parent row
    display: "flex",
    "& > :not(:first-child)": {
      marginLeft: theme.spacing(5)
    }
  }
}));

export const InaScreen: React.FunctionComponent = () => {
  const { classes } = useStyles();
  const { inasUser } = useInasUser();
  const { user, userHasPermission } = useCurrentUser();
  const notifications = useNotifications();

  const [endingExemptionForPr, setEndingExemptionForPr] = useState<PracticeReview | null>(null);
  const [sendingRegistrarPackageForTaskId, setSendingRegistrarPackageForTaskId] = useState<number | null>(null);

  const inaQuery = useQuery<{ inas: Ina[] }>(GetInasForUserQuery, {
    variables: {
      userId: inasUser?.id
    },
    fetchPolicy: "cache-and-network",
    pollInterval: 300000 // refresh the INAs every 5 minutes
  });
  const inas = inaQuery.data?.inas ?? [];
  const inasByType = _.orderBy(
    _.groupBy(inas, (ina) => ina.type.id),
    (g) => g[0].type.friendlyName
  );

  function buildPrInaPanel(
    panelInas: Ina[],
    getNavigationRouteForIna?: (ina: Ina) => string,
    options?: {
      groupByMeeting?: boolean;
      meetingActions?: React.ReactNode;
      meetingPrActions?: React.ReactNode;
      meetingPrActionsWidth?: number;
      completable?: boolean;
      showOverdue?: boolean;
      showScheduledColumn?: boolean;
      showReviewLocationColumn?: boolean;
      showMissingFormsColumn?: boolean;
      showSystemEstimateColumn?: boolean;
      showFinalEstimateColumn?: boolean;
      showLeadReviewerColumn?: boolean;
      showOtherReviewersColumn?: boolean;
      showPhaseColumn?: boolean;
      showIncreasedRiskColumn?: boolean;
      showDecisionColumn?: boolean;
      showMeetingColumn?: boolean;
      showRequiresResponseColumn?: boolean;
      showExemptionStartDateColumn?: boolean;
      isPerformReview?: boolean;
      extraColumns?: GridColDef[];
      headerActions?: (selectedInas: Ina[]) => React.ReactElement;
      extraActions?: (ina: Ina) => React.ReactElement;
      actionsWidth?: number;
      getRowDetails?: (ina: Ina) => React.ReactElement;
      getDetailPanelHeight?: (ina: Ina) => number;
      hideInaTable?: boolean;
      getFixedNavigationRoute?: () => string;
      getNavigationRouteForMeeting?: (meeting: CommitteeMeeting) => string;
    }
  ) {
    options = options || {};
    const sampleIna = panelInas[0];

    return options.groupByMeeting ? (
      <MeetingInaPanel
        key={`meeting-${sampleIna.type.id}`}
        className={classes.inaPanel}
        title={sampleIna.type.friendlyName}
        inas={options.hideInaTable ? [] : panelInas}
        getNavigationRouteForIna={getNavigationRouteForIna}
        getNavigationRouteForMeeting={options.getNavigationRouteForMeeting}
        completable={options.completable}
        meetingActions={options.meetingActions}
        prActions={options.meetingPrActions}
        prActionsWidth={options.meetingPrActionsWidth}
      />
    ) : (
      <PracticeReviewInaPanel
        key={`${sampleIna.type.id}`}
        className={classes.inaPanel}
        title={sampleIna.type.friendlyName}
        inas={options.hideInaTable ? [] : panelInas}
        getNavigationRoute={getNavigationRouteForIna}
        getFixedNavigationRoute={options.getFixedNavigationRoute}
        completable={options.completable}
        showOverdue={options.showOverdue}
        showScheduledColumn={options.showScheduledColumn}
        showReviewLocationColumn={options.showReviewLocationColumn}
        showMissingFormsColumn={options.showMissingFormsColumn}
        showSystemEstimateColumn={options.showSystemEstimateColumn}
        showFinalEstimateColumn={options.showFinalEstimateColumn}
        showLeadReviewerColumn={options.showLeadReviewerColumn}
        showOtherReviewersColumn={options.showOtherReviewersColumn}
        showPhaseColumn={options.showPhaseColumn}
        showIncreasedRiskColumn={options.showIncreasedRiskColumn}
        showDecisionColumn={options.showDecisionColumn}
        showMeetingColumn={options.showMeetingColumn}
        showRequiresResponseColumn={options.showRequiresResponseColumn}
        showExemptionStartDateColumn={options.showExemptionStartDateColumn}
        isPerformReview={options.isPerformReview}
        extraColumns={options.extraColumns}
        headerActions={options.headerActions}
        extraActions={options.extraActions}
        actionsWidth={options.actionsWidth}
        getRowDetails={options.getRowDetails ? (ina) => options!.getRowDetails!(ina) : undefined}
        getDetailPanelHeight={options.getDetailPanelHeight}
      />
    );
  }

  function renderPrContactInfo(ina: Ina) {
    const address1 = ina.practiceReview!.firm.address1;
    const address2 = ina.practiceReview!.firm.address2;
    const city = ina.practiceReview!.firm.city;

    return (
      <Stack direction="row" className={classes.detailRow} alignItems="baseline">
        <Stack direction="row" spacing={1} alignItems="center">
          <FontAwesomeIcon icon={faUserTie} />
          <Typography variant="body1">{ina.practiceReview!.contactName}</Typography>
        </Stack>
        <Stack direction="row" spacing={1} alignItems="center">
          <FontAwesomeIcon icon={faPhone} />
          <Typography variant="body1">{ina.practiceReview!.contactPhone}</Typography>
        </Stack>
        <a href={ina.practiceReview!.contactEmailUri}>
          <Stack direction="row" spacing={1} alignItems="center">
            <FontAwesomeIcon icon={faEnvelope} />
            <Typography variant="body1">{ina.practiceReview!.contactEmail}</Typography>
          </Stack>
        </a>
        <Stack direction="row" spacing={1} alignItems="center">
          <FontAwesomeIcon icon={faMapMarkerAlt} />
          <Typography variant="body1">{`${address1}, ${address2 ? `${address2}, ` : ""}${city}`}</Typography>
        </Stack>
      </Stack>
    );
  }

  function getExtraActionsForPendingExemptionIna(ina: Ina) {
    return userHasPermission(Permissions.ExemptionConfirm) ? (
      <>
        <Button
          variant="outlined"
          size="small"
          className={classes.actionTextButton}
          onClick={() => setEndingExemptionForPr(ina.practiceReview)}>
          End Exemption
        </Button>
      </>
    ) : (
      <></>
    );
  }

  const actionsWidthForNoncompletableExemptionInas = 220;
  const actionsWidthForCompletableExemptionInas = 290;

  return (
    <div>
      <Helmet>
        <title>INAs - PRS Online</title>
      </Helmet>

      {inasUser && <ScreenHeader title={`${inasUser.name}'s INAs`} />}

      {inaQuery.loading && !inaQuery.data ? (
        <Grid container direction="column" spacing={3}>
          {[...Array(4)].map((x, index) => (
            <Grid item key={index} xs={12}>
              <Skeleton variant="rectangular" width="100%" height="10rem" />
            </Grid>
          ))}
        </Grid>
      ) : inas.length > 0 ? (
        _.map(inasByType, (inaGroup) => {
          const sampleIna = inaGroup[0];
          switch (sampleIna.type.typeCode) {
            case InaTypeCode.GenerateNotices:
              return buildPrInaPanel(inaGroup, (ina) => AppRoute.GenerateUpcomingReviewNotices);

            case InaTypeCode.ApproveNotices:
              return buildPrInaPanel(inaGroup, (ina) => AppRoute.ApproveUpcomingReviewNotices);

            case InaTypeCode.WaitingForForms:
              return buildPrInaPanel(
                inaGroup,
                (ina) => getRouteForPracticeReview(ina.practiceReview, PracticeReviewTabs.ReportsQuestionnaires),
                {
                  showMissingFormsColumn: true,
                  completable: true
                }
              );

            case InaTypeCode.ReviewForms:
              return buildPrInaPanel(
                inaGroup,
                (ina) => getRouteForPracticeReview(ina.practiceReview, PracticeReviewTabs.ReportsQuestionnaires),
                {
                  showMissingFormsColumn: true,
                  completable: true
                }
              );

            case InaTypeCode.EstimateTime:
              return buildPrInaPanel(inaGroup, (ina) => getRouteForPracticeReview(ina.practiceReview, PracticeReviewTabs.EstimateTime), {
                showReviewLocationColumn: true,
                showSystemEstimateColumn: true
              });

            case InaTypeCode.ScheduleReview:
              return buildPrInaPanel(
                inaGroup,
                (ina) => getRouteForPracticeReview(ina.practiceReview, PracticeReviewTabs.SchedulingReviewers),
                {
                  showScheduledColumn: true,
                  showFinalEstimateColumn: true,
                  showIncreasedRiskColumn: true,
                  getRowDetails: (ina) => renderPrContactInfo(ina)
                }
              );

            case InaTypeCode.PerformReview:
              return buildPrInaPanel(inaGroup, (ina) => getRouteForPracticeReview(ina.practiceReview, PracticeReviewTabs.Summary), {
                showLeadReviewerColumn: true,
                showOtherReviewersColumn: true,
                showOverdue: true,
                isPerformReview: true,
                extraColumns: [
                  {
                    field: "practiceReview.dueDate",
                    headerName: "Due Date",
                    width: 100,
                    valueGetter: (params: { row: Ina }) =>
                      params.row.dueDate ? DateTime.fromISO(params.row.dueDate).toFormat(standardDateFormat) : "--"
                  }
                ],
                getRowDetails: (ina) => (
                  <>
                    {renderPrContactInfo(ina)}
                    <Stack direction="row" className={classes.detailRow} alignItems="baseline">
                      <InlineStaticDataDisplay label="Review Ends" value={formatDate(ina.practiceReview!.endDate)} />
                      <InlineStaticDataDisplay label="INA Started" value={formatDate(ina.createdDate)} />
                    </Stack>
                  </>
                ),
                getDetailPanelHeight: () => 112
              });

            case InaTypeCode.WaitingForReviewPeriod:
              return buildPrInaPanel(inaGroup, undefined, { showLeadReviewerColumn: true });

            case InaTypeCode.SendClientFileRequestNotification:
              return buildPrInaPanel(inaGroup, (ina) => getRouteForPracticeReview(ina.practiceReview, PracticeReviewTabs.Overview), {
                showScheduledColumn: true,
                completable: true
              });

            case InaTypeCode.ClientFilesNotReceived:
              return buildPrInaPanel(inaGroup, (ina) => getRouteForPracticeReview(ina.practiceReview, PracticeReviewTabs.Overview), {
                showScheduledColumn: true,
                completable: true
              });

            case InaTypeCode.RescheduleReview:
              return buildPrInaPanel(
                inaGroup,
                (ina) => getRouteForPracticeReview(ina.practiceReview, PracticeReviewTabs.SchedulingReviewers),
                {
                  showScheduledColumn: true,
                  showFinalEstimateColumn: true,
                  showIncreasedRiskColumn: true,
                  getRowDetails: (ina) => (
                    <>
                      {renderPrContactInfo(ina)}
                      <div className={classes.detailRow}>
                        <Typography variant="body1">
                          <label>Rescheduling Reason:</label>
                          {ina.practiceReview!.mostRecentRescheduleReason}
                        </Typography>
                      </div>
                    </>
                  ),
                  getDetailPanelHeight: (ina) => 112
                }
              );

            case InaTypeCode.ReviewSubmittedFiles:
              const prInas = inaGroup.filter((ina) => Boolean(ina.practiceReview));

              return (
                <React.Fragment key="review-submitted-files">
                  {prInas.length > 0 &&
                    buildPrInaPanel(
                      prInas,
                      (ina) => getRouteForPracticeReview(ina.practiceReview, PracticeReviewTabs.ReportsQuestionnaires),
                      {
                        completable: true,
                        showPhaseColumn: true
                      }
                    )}
                </React.Fragment>
              );

            case InaTypeCode.PRNotReturned:
              return buildPrInaPanel(inaGroup, (ina) => getRouteForPracticeReview(ina.practiceReview, PracticeReviewTabs.Summary), {
                showLeadReviewerColumn: true
              });

            case InaTypeCode.PrepareDraftReport:
              return buildPrInaPanel(
                inaGroup,
                (ina) => getRouteForPracticeReview(ina.practiceReview, PracticeReviewTabs.ReportsQuestionnaires),
                {
                  completable: true,
                  showDecisionColumn: true,
                  showLeadReviewerColumn: true,
                  showMeetingColumn: true
                }
              );

            case InaTypeCode.EnterManagerDecision:
              return buildPrInaPanel(
                inaGroup,
                (ina) => getRouteForPracticeReview(ina.practiceReview, PracticeReviewTabs.Decision, undefined, DecisionTypeCode.Manager),
                {
                  showLeadReviewerColumn: true,
                  showDecisionColumn: true,
                  showMeetingColumn: true,
                  showReviewLocationColumn: true
                }
              );

            case InaTypeCode.EnterDirectorDecision:
              return buildPrInaPanel(
                inaGroup,
                (ina) => getRouteForPracticeReview(ina.practiceReview, PracticeReviewTabs.Decision, undefined, DecisionTypeCode.Director),
                {
                  showLeadReviewerColumn: true,
                  showDecisionColumn: true,
                  showMeetingColumn: true,
                  showReviewLocationColumn: true
                }
              );

            case InaTypeCode.DiscussDecisionChangeWithReviewer:
              return buildPrInaPanel(
                inaGroup,
                (ina) => getRouteForPracticeReview(ina.practiceReview, PracticeReviewTabs.Decision, undefined, DecisionTypeCode.Reviewer),
                {
                  showLeadReviewerColumn: true,
                  completable: true
                }
              );

            case InaTypeCode.WaitingForFirmResponse:
              return buildPrInaPanel(inaGroup, (ina) => getRouteForPracticeReview(ina.practiceReview, PracticeReviewTabs.FirmResponse), {
                showLeadReviewerColumn: true,
                showMeetingColumn: true,
                showRequiresResponseColumn: true,
                completable: true
              });

            case InaTypeCode.RedactFirmResponse:
              return buildPrInaPanel(inaGroup, (ina) => getRouteForPracticeReview(ina.practiceReview, PracticeReviewTabs.FirmResponse), {
                showMeetingColumn: true,
                showRequiresResponseColumn: true,
                completable: true
              });

            case InaTypeCode.ReviewFirmResponse:
              return buildPrInaPanel(inaGroup, (ina) => getRouteForPracticeReview(ina.practiceReview, PracticeReviewTabs.FirmResponse), {
                showMeetingColumn: true,
                showRequiresResponseColumn: true,
                completable: true
              });

            case InaTypeCode.ReviewOnHold:
              return buildPrInaPanel(inaGroup, (ina) => getRouteForPracticeReview(ina.practiceReview, PracticeReviewTabs.Overview), {
                showScheduledColumn: true,
                extraColumns: [
                  {
                    field: "practiceReview.held.heldBy",
                    valueGetter: (params: { row: Ina }) => params.row.practiceReview!.held?.heldBy,
                    headerName: "Held by",
                    width: 140
                  }
                ],
                getRowDetails: (ina) => (
                  <div className={classes.detailRow}>
                    <Typography variant="body1">
                      <label>Reason Held:</label>
                      {ina.practiceReview!.held?.reason}
                    </Typography>
                    <Typography variant="body1">
                      <label>Date Held:</label>
                      {formatDateTime(ina.practiceReview!.held?.date)}
                    </Typography>
                  </div>
                )
              });

            case InaTypeCode.FirmStatusChanged:
            case InaTypeCode.FirmAddressChanged:
              return buildPrInaPanel(inaGroup, (ina) => getRouteForPracticeReview(ina.practiceReview, PracticeReviewTabs.FirmDetails), {
                showPhaseColumn: true,
                completable: true
              });

            case InaTypeCode.WaitingForCommitteeMeeting:
              return buildPrInaPanel(
                inaGroup,
                (ina) => getRouteForPracticeReview(ina.practiceReview, PracticeReviewTabs.ReportsQuestionnaires),
                {
                  groupByMeeting: true,
                  getNavigationRouteForMeeting: (meeting) => AppRoute.CommitteeMeetings
                }
              );

            case InaTypeCode.WaitingToEnterCommitteeDecisions:
            case InaTypeCode.EnterCommitteeDecision:
              return buildPrInaPanel(inaGroup, (ina) => getRouteForPracticeReview(ina.practiceReview, PracticeReviewTabs.Decision), {
                groupByMeeting: true,
                getNavigationRouteForMeeting: (meeting) => `${AppRoute.EnterCommitteeDecisions}?meetingId=${meeting.id}`
              });

            case InaTypeCode.ReviewFinalReports:
              return buildPrInaPanel(
                inaGroup,
                (ina) => getRouteForPracticeReview(ina.practiceReview, PracticeReviewTabs.ReportsQuestionnaires),
                {
                  groupByMeeting: true,
                  getNavigationRouteForMeeting: (meeting) => `${AppRoute.ReviewCommitteeDecisions}?meetingId=${meeting.id}`
                }
              );

            case InaTypeCode.ReviewDraftExemptionLetter:
              return buildPrInaPanel(inaGroup, (ina) => getRouteForPracticeReview(ina.practiceReview, PracticeReviewTabs.Exemption), {
                showExemptionStartDateColumn: true,
                showLeadReviewerColumn: true,
                headerActions: () =>
                  userHasPermission(Permissions.ExemptionReviewLetter) ? (
                    <Button size="small" variant="outlined" component={RouterLink} to={AppRoute.ReviewExemptionLetters}>
                      Review Letters
                    </Button>
                  ) : (
                    <></>
                  ),
                extraActions: (ina: Ina) => getExtraActionsForPendingExemptionIna(ina),
                actionsWidth: actionsWidthForNoncompletableExemptionInas
              });

            case InaTypeCode.ApproveExemptionLetterAndSendNotification:
              return buildPrInaPanel(inaGroup, (ina) => getRouteForPracticeReview(ina.practiceReview, PracticeReviewTabs.Exemption), {
                showExemptionStartDateColumn: true,
                showLeadReviewerColumn: true,
                headerActions: () =>
                  userHasPermission(Permissions.ExemptionConfirm) ? (
                    <Button size="small" variant="outlined" component={RouterLink} to={AppRoute.ApproveExemptionLetters}>
                      Approve Letters
                    </Button>
                  ) : (
                    <></>
                  ),
                extraActions: (ina: Ina) => getExtraActionsForPendingExemptionIna(ina),
                actionsWidth: actionsWidthForNoncompletableExemptionInas
              });

            case InaTypeCode.ConfirmAcknowledgedExemption:
              return buildPrInaPanel(inaGroup, (ina) => getRouteForPracticeReview(ina.practiceReview, PracticeReviewTabs.Exemption), {
                showExemptionStartDateColumn: true,
                showLeadReviewerColumn: true,
                extraActions: (ina: Ina) => getExtraActionsForPendingExemptionIna(ina),
                actionsWidth: actionsWidthForNoncompletableExemptionInas
              });

            case InaTypeCode.WaitingForExemptionResponse:
              return buildPrInaPanel(inaGroup, (ina) => getRouteForPracticeReview(ina.practiceReview, PracticeReviewTabs.Exemption), {
                showExemptionStartDateColumn: true,
                showLeadReviewerColumn: true,
                completable: true,
                extraActions: (ina: Ina) => getExtraActionsForPendingExemptionIna(ina),
                actionsWidth: actionsWidthForCompletableExemptionInas
              });

            case InaTypeCode.ReviewPrWithActiveExemption:
              return buildPrInaPanel(inaGroup, (ina) => getRouteForPracticeReview(ina.practiceReview, PracticeReviewTabs.Exemption), {
                showExemptionStartDateColumn: true,
                completable: true,
                extraColumns: [
                  {
                    field: "newPrForExemptionFirm",
                    headerName: "New PR",
                    width: 70,
                    renderCell: (params: { row: Ina }) => (
                      <Link
                        to={getRouteForPracticeReview(
                          params.row.practiceReview!.exemption?.newPracticeReviewForFirm ?? null,
                          PracticeReviewTabs.Overview
                        )}
                        component={RouterLink}>
                        {params.row.practiceReview!.exemption?.newPracticeReviewForFirm?.prNumber ?? ""}
                      </Link>
                    ),
                    sortComparator: (v1: any, v2: any, param1: GridSortCellParams, param2: GridSortCellParams) =>
                      param1.api
                        .getRow(param1.id)!
                        .practiceReview.prNumber.localeCompare(param2.api.getRow(param2.id)!.practiceReview.prNumber)
                  }
                ],
                extraActions: (ina: Ina) => getExtraActionsForPendingExemptionIna(ina),
                actionsWidth: actionsWidthForCompletableExemptionInas
              });

            case InaTypeCode.PDWaitingForDeclaration:
              return buildPrInaPanel(inaGroup, (ina) => getRouteForPracticeReview(ina.practiceReview, PracticeReviewTabs.DirectedPD), {
                extraColumns: [
                  {
                    field: "pdDeclarationDueDate",
                    headerName: "PD Due Date",
                    valueFormatter: dataGridDateValueFormatter,
                    valueGetter: (params: { row: Ina }) => params.row.practiceReview!.mostAuthoritativeDecision?.directedPdDueDate ?? "--"
                  }
                ],
                getRowDetails: (ina) => {
                  const decision = ina.practiceReview!.mostAuthoritativeDecision!;
                  const assignedPdCoursesText = decision?.assignedPdCourses
                    .filter((c) => c.isDirected && !c.isCompleted && !c.isExempt)!
                    .map((c) => c.pdCourse.name)
                    .join(", ");
                  return (
                    <div className={classes.detailRow}>
                      <InlineStaticDataDisplay label="Incomplete Courses" value={assignedPdCoursesText} />
                    </div>
                  );
                },
                completable: true
              });

            case InaTypeCode.CustomActivity:
              return buildPrInaPanel(
                inaGroup,
                (ina) => getRouteForPracticeReview(ina.practiceReview, PracticeReviewTabs.ReportsQuestionnaires),
                {
                  showLeadReviewerColumn: true,
                  showPhaseColumn: true,
                  completable: true,
                  extraColumns: [
                    {
                      field: "activity.activityName",
                      headerName: "Activity",
                      width: 200,
                      valueGetter: (params: { row: Ina }) => params.row.activity?.activityName ?? "--"
                    },
                    {
                      field: "activity.assignedByUser",
                      headerName: "Assigned By",
                      width: 160,
                      valueGetter: (params: { row: Ina }) => params.row.activity?.assignedByUser?.name ?? "--"
                    }
                  ],
                  getRowDetails: (ina) => (
                    <div className={classes.detailRow}>
                      <InlineStaticDataDisplay label="Comments" value={ina.activity?.outcomeOrComments ?? "--"} />
                    </div>
                  )
                }
              );

            default:
              return buildPrInaPanel(inaGroup, (ina) => getRouteForPracticeReview(ina.practiceReview, PracticeReviewTabs.Overview));
          }
        })
      ) : (
        <Typography variant="body1">{`${!inasUser || user.id === inasUser.id ? "You" : "They"} have no outstanding INAs.`}</Typography>
      )}

      {endingExemptionForPr && <EndExemptionDialog practiceReview={endingExemptionForPr} onClose={() => setEndingExemptionForPr(null)} />}
    </div>
  );
};
