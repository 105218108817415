import { useQuery } from "@apollo/client";
import { Theme, Box } from "@mui/material";
import { Activity } from "activities/models";
import { DataGridWithHeader } from "common/DataGridWithHeader";
import { PrsDatePicker } from "common/PrsDatePicker";
import { ScreenHeader } from "common/ScreenHeader";
import gql from "graphql-tag";
import { DateTime } from "luxon";
import React from "react";
import { Helmet } from "react-helmet";
import { formatDate } from "util/formats";
import { makeActivityLog } from "activities/ActivityLog";
import { PersonalActivityLogFragment } from "./queries";
import { makeStyles } from "../makeStyles";
import PrLink from "../common/PrLink";

export const FetchPersonalActivityLogQuery = gql`
  query FetchPersonalActivityLog($startDate: Date, $endDate: Date) {
    personalActivityLog(startDate: $startDate, endDate: $endDate) {
      id
      activityName
      dateInitiated
      assignedByUser {
        name
        id
      }
      assignedToUser {
        name
        id
      }
      completedDate
      completedByUser {
        name
        id
      }
      outcomeOrComments
      isCustomActivity
      practiceReview {
        id
        prNumber
        firm {
          id
          name
        }
      }
      activityLogBatchPr {
        id
        prNumber
        firmName
        isError
        errorMessage
      }
    }
  }
`;

const useStyles = makeStyles()((theme: Theme) => ({
  spacedRight: {
    marginRight: theme.spacing(1)
  },
  subRow: {
    paddingLeft: theme.spacing(3),
    flexDirection: "column"
  },
  subRowBox: {
    display: "flex"
  },
  errorText: {
    color: theme.palette.error.main
  },
  firmName: {
    marginLeft: theme.spacing(3)
  },
  prErrorMessage: {
    marginLeft: theme.spacing(2)
  },
  failSpacingTop: {
    marginTop: theme.spacing(3),
    color: theme.palette.error.main
  }
}));

const PersonalActivityLogScreen: React.FunctionComponent = () => {
  const { classes, cx, theme } = useStyles();

  const [startDate, setStartDate] = React.useState<DateTime | null>(DateTime.local().minus({ days: 7 }));
  const [endDate, setEndDate] = React.useState<DateTime | null>(null);

  const query = useQuery<{ personalActivityLog: Activity[] }, { startDate: string | null; endDate: string | null }>(
    FetchPersonalActivityLogQuery,
    {
      variables: { startDate: startDate?.toISODate() ?? null, endDate: endDate?.toISODate() ?? null }
    }
  );

  const activities = query.data?.personalActivityLog ? makeActivityLog(query.data.personalActivityLog) : [];

  return (
    <>
      <Helmet>
        <title>My Activity Log - PRS Online</title>
      </Helmet>
      <ScreenHeader title="My Activity Log" />

      <DataGridWithHeader
        title=""
        noDataMessage="No activities found."
        maxHeight={800}
        columns={[
          {
            field: "prNumber",
            headerName: "PR",
            valueGetter: (params) => {
              return (params.row as Activity).practiceReview?.prNumber ?? "";
            },
            renderCell: (params) =>
              Boolean((params.row as Activity).practiceReview) ? (
                <PrLink practiceReview={(params.row as Activity).practiceReview!} />
              ) : null,
            width: 80
          },
          {
            field: "firmName",
            headerName: "Firm",
            flex: 85,
            valueGetter: (params) => {
              return (params.row as Activity).practiceReview?.firm.name ?? "";
            }
          },
          {
            field: "activityName",
            headerName: "Process",
            flex: 35,
            valueFormatter: (params) => {
              return params.value ?? "--";
            }
          },
          {
            field: "dateInitiated",
            headerName: "Date Initiated",
            width: 120,
            valueFormatter: (params) => formatDate(params.value as string)
          },
          {
            field: "assignedByUser",
            headerName: "Assigned By",
            width: 160,
            valueGetter: (params) => {
              return (params.row as Activity).assignedByUser?.name ?? "System";
            }
          },
          {
            field: "assignedToUser",
            headerName: "Assigned To",
            width: 160,
            valueGetter: (params) => {
              return (params.row as Activity).assignedToUser?.name ?? "--";
            }
          },
          {
            field: "completedDate",
            headerName: "Date Completed",
            width: 140,
            valueFormatter: (params) => formatDate(params.value as string)
          },
          {
            field: "completedByUser",
            headerName: "Completed By",
            width: 160,
            valueGetter: (params) => {
              return (params.row as Activity).completedByUser?.name ?? "--";
            }
          }
        ]}
        headerActions={
          <>
            <PrsDatePicker
              setValue={setStartDate}
              value={startDate}
              label="Start Date"
              pickerProps={{ className: classes.spacedRight }}
              margin="none"
              allowNonWorkingDays
            />
            <PrsDatePicker setValue={setEndDate} value={endDate} label="End Date" margin="none" allowNonWorkingDays />
          </>
        }
        rows={activities}
        getRowId={(row) => `${row.__typename}-${row.id}`}
        loading={query.loading}
        getDetailPanelContent={(params) => {
          const activity = params.row as Activity;
          return ((params.row as Activity).activityLogBatchPr ?? []).length > 0 ? (
            <Box sx={{ p: 2, pl: 10, backgroundColor: theme.palette.highlight, height: 300, overflowY: "auto" }}>
              <div className={classes.subRowBox}>
                Files Affected:
                <div className={classes.subRow}>
                  {activity.activityLogBatchPr?.some((pr) => !pr.isError) && <div>Succeeded</div>}
                  {activity.activityLogBatchPr?.map((pr) =>
                    pr.isError !== true ? (
                      <div className={classes.subRowBox}>
                        <div>{pr.prNumber}</div>
                        <div className={classes.firmName}>{pr.firmName}</div>
                      </div>
                    ) : null
                  )}

                  {activity.activityLogBatchPr?.some((pr) => pr.isError) && <div className={classes.failSpacingTop}>Failed</div>}
                  {activity.activityLogBatchPr?.map((pr) =>
                    pr.isError === true ? (
                      <div className={cx(classes.errorText, classes.subRowBox)}>
                        <div>{pr.prNumber ?? "unknown"}</div>
                        <div className={classes.firmName}>{pr.firmName ?? "unknown"}</div>
                        <div className={classes.prErrorMessage}>{pr.errorMessage}</div>
                      </div>
                    ) : null
                  )}
                </div>
              </div>
            </Box>
          ) : null;
        }}
        getDetailPanelHeight={(params) => 300}
        itemType="My Activity"
      />
    </>
  );
};

export default PersonalActivityLogScreen;
