import { gql } from "@apollo/client";

export const PdCoursesQuery = gql`
  query FetchPdCourses {
    pdCourses(activeOnly: false) {
      id
      name
      productCode
      isActive
      topicName
      topicShortName
    }
  }
`;

export const SignOffChecklistItemsQuery = gql`
  query FetchSignOffChecklistItems($practiceReviewId: Int!, $signOffChecklistTypeCode: SignOffChecklistTypeCode) {
    signOffChecklistItems(practiceReviewId: $practiceReviewId, signOffChecklistTypeCode: $signOffChecklistTypeCode) {
      id
      questionText
      sortOrder
      displayTemplateName
      isChecked
    }
  }
`;

export const DecisionFieldsFragment = gql`
  fragment DecisionFields on Decision {
    id
    decisionType {
      id
      typeCode
    }

    isComply
    nonComplyFinancial
    nonComplyAudit
    nonComplyReview
    nonComplyQualityManagement
    nonComplyTax
    nonComplyOther
    nonComplyOtherSpecified

    complianceZone {
      id
      zoneCode
      requiresFollowUpScope
    }
    followUpDate
    nonComplyZoneNote

    cpabParticipating
    cpdCompliance
    cpdComplianceExplanation
    pliCompliance
    pliComplianceExplanation

    assignedPdCourses {
      id
      isDirected
      isCompleted
      isExempt
      exemptReason
      pdCourse {
        id
        name
        productCode
        topicName
        topicShortName
        isActive
      }
    }
    directedPdDueDate

    motionStandard {
      id
    }
    motionPd {
      id
    }
    motionCpab {
      id
    }
    motionCic {
      id
    }

    descriptionOfIntendedAction
    additionalCommentsForMinutes
    additionalCommentsForDecisionLetter

    reviewerMemo
    managerMemo
    directorsHighlights
    directorsSummaryOfFirmResponse

    isComplete
    signedOff
    signedOffByUser {
      id
      name
    }
    signedOffDate
    hasOutstandingDiscussChanges
    discussChangesCompleted

    requiresFullScopeFollowUp
    fullScopeFollowUpComments

    monitoringRequired
    recommendationsToCommittee
  }
`;

export const SaveDecisionMutation = gql`
  mutation SaveDecision($decision: DecisionInput!, $discussDecisionChange: Boolean!, $cancelDecisionChange: Boolean!) {
    decision {
      saveDecision(decision: $decision, discussDecisionChange: $discussDecisionChange, cancelDecisionChange: $cancelDecisionChange) {
        ...DecisionFields
      }
    }
  }
  ${DecisionFieldsFragment}
`;

export const ToggleSignOffChecklistItemMutation = gql`
  mutation ToggleSignOffChecklistItem($signOffChecklistItemId: Int!) {
    decision {
      toggleSignOffChecklistItem(signOffChecklistItemId: $signOffChecklistItemId) {
        id
        isChecked
      }
    }
  }
`;

export const SignOffDecisionMutation = gql`
  mutation SignOffReviewerDecision($decisionId: Int!) {
    decision {
      signOffDecision(decisionId: $decisionId) {
        id
        signedOff
        signedOffDate
        signedOffByUser {
          id
          name
        }
      }
    }
  }
`;

export const GetComplianceZonesQuery = gql`
  query GetComplianceZones {
    complianceZones {
      id
      name
      zoneCode
      isNonComplyZone
      maySupplyFollowUpDate
      requiresNote
      requiresResponse
      requiresFollowUpScope
    }
  }
`;

export const FetchActiveMotionsQuery = gql`
  query GetMotionParagraphs {
    motionParagraphs(activeOnly: true) {
      id
      motionType {
        id
        name
        typeCode
      }
      shortDescription
      motionCode
      isActive
      isComply
      isNonComply
      sortOrder
    }
  }
`;

export const TablePrMutation = gql`
  mutation TablePr($practiceReviewId: Int!, $committeeMeetingId: Int!, $reason: String!) {
    practiceReview {
      table(practiceReviewId: $practiceReviewId, committeeMeetingId: $committeeMeetingId, reason: $reason) {
        id
        committeeMeeting {
          id
          name
          isOpen
        }
        mostRecentlyTabled {
          practiceReviewId
          committeeMeetingId
          committeeMeeting {
            id
            name
          }
        }
      }
    }
  }
`;

export const ToggleFinalReportReviewedMutation = gql`
  mutation ToggleFinalReportReviewed($practiceReviewId: Int!, $role: CommitteeDecisionReviewRole!) {
    practiceReview {
      toggleFinalReportReviewed(practiceReviewId: $practiceReviewId, role: $role) {
        id
        isFinalReportReviewedByCoordinator
        isFinalReportReviewedByDirector
      }
    }
  }
`;

export const FetchDecisionAdditionalParagraphsQuery = gql`
  query FetchDecisionAdditionalParagraphs($activeOnly: Boolean) {
    decisionAdditionalParagraphs(activeOnly: $activeOnly) {
      id
      name
      isActive
      paragraphForMinutes
      paragraphForDecisionLetter
    }
  }
`;

export const AddDecisionAdditionalParagraphMutation = gql`
  mutation AddDecisionAdditionalParagraph($decisionAdditionalParagraph: DecisionAdditionalParagraphInput) {
    decisionAdditionalParagraph {
      add(decisionAdditionalParagraph: $decisionAdditionalParagraph) {
        id
        name
        isActive
        paragraphForMinutes
        paragraphForDecisionLetter
      }
    }
  }
`;

export const UpdateDecisionAdditionalParagraphMutation = gql`
  mutation UpdateDecisionAdditionalParagraph($decisionAdditionalParagraph: DecisionAdditionalParagraphInput) {
    decisionAdditionalParagraph {
      update(decisionAdditionalParagraph: $decisionAdditionalParagraph) {
        id
        name
        isActive
        paragraphForMinutes
        paragraphForDecisionLetter
      }
    }
  }
`;

export const DeleteDecisionAdditionalParagraphMutation = gql`
  mutation DeleteDecisionAdditionalParagraph($id: Int!) {
    decisionAdditionalParagraph {
      delete(id: $id) {
        id
      }
    }
  }
`;
