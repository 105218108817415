import React from "react";
import { Button, Dialog, DialogActions, DialogContent, Grid, Typography } from "@mui/material";

import ClosableDialogTitle from "common/ClosableDialogTitle";
import { gql, useMutation } from "@apollo/client";
import { staticDataStyles } from "styles/common";
import * as Yup from "yup";
import { Field as FormikField, Form as FormikForm, Formik } from "formik";
import { TextField as FmuiTextField } from "formik-mui";
import { TimedEventMargin } from "./models";
import { Validations } from "common/validations/common-yup-validations";

import { LoadingButton } from "@mui/lab";
import { makeStyles } from "../makeStyles";

const UpdateTimedEventMarginMutation = gql`
  mutation EditTimedEvent($event: TimedEventMarginInput) {
    timedEventMargin {
      update(timedEventMargin: $event) {
        id
        typeName
        friendlyName
        marginDescription
        marginInDays
      }
    }
  }
`;

const useStyles = makeStyles()((theme) => ({
  ...staticDataStyles(theme),
  // Because of negative margin on the input label, this ensures equal spacing
  lastLabel: {
    marginBottom: theme.spacing(1)
  }
}));
interface EditTimedEventMarginDialogProps {
  handleClose: () => void;
  title: string;
  confirmButtonText: string;
  timedEventMargin: TimedEventMargin;
}

const EditTimedEventMarginDialog = (props: EditTimedEventMarginDialogProps) => {
  const { classes, cx } = useStyles();

  const validationSchema = Yup.object().shape({
    marginInDays: Validations.requiredNonNegativeNumber()
  });

  const [mutate, { loading }] = useMutation<{ timedEventMargin: { update: TimedEventMargin } }, { event: TimedEventMargin }>(
    UpdateTimedEventMarginMutation
  );

  return (
    <Dialog open={true} onClose={props.handleClose} fullWidth={true} scroll="paper" maxWidth="sm">
      <ClosableDialogTitle onClose={props.handleClose}>{props.title}</ClosableDialogTitle>
      <Formik
        initialValues={{ ...props.timedEventMargin }}
        onSubmit={async (values) => {
          var event = {
            ...values
          };

          await mutate({
            variables: { event }
          });

          props.handleClose();
        }}
        validationSchema={validationSchema}>
        {(formikProps) => (
          <FormikForm>
            <DialogContent>
              <Typography paragraph>
                Timed event margins control the time at which certain timing-based workflow events occur, such as how many days after which
                to send reminders.
              </Typography>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <div className={classes.stackedContainer}>
                    <Typography className={classes.label}>Timed Event Type</Typography>
                    <Typography>{props.timedEventMargin.friendlyName}</Typography>
                  </div>
                </Grid>
                <Grid item>
                  <div className={cx(classes.stackedContainer, classes.lastLabel)}>
                    <Typography className={classes.label}>Margin Description</Typography>
                    <Typography>{props.timedEventMargin.marginDescription}</Typography>
                  </div>
                </Grid>
                <Grid item>
                  <FormikField
                    type="number"
                    inputProps={{ min: 0 }}
                    component={FmuiTextField}
                    name="marginInDays"
                    label="Margin in Days"
                    fullWidth
                    required
                  />
                </Grid>
              </Grid>
              <DialogActions>
                <Button onClick={props.handleClose}>Cancel</Button>
                <LoadingButton color="primary" variant="contained" loading={loading} onClick={() => formikProps.submitForm()}>
                  {props.confirmButtonText}
                </LoadingButton>
              </DialogActions>
            </DialogContent>
          </FormikForm>
        )}
      </Formik>
    </Dialog>
  );
};

export default EditTimedEventMarginDialog;
