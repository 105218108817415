import { gql } from "@apollo/client";

export const UpdateDirectedPdCoursesMutation = gql`
  mutation UpdateDirectedPdCoursesMutation($practiceReviewId: Int!, $updatedCourses: [AssignedPdInput!]!) {
    pdCourses {
      updateDirectedPdCourses(practiceReviewId: $practiceReviewId, updatedCourses: $updatedCourses) {
        id
        isDirected
        isCompleted
        isExempt
        exemptReason
      }
    }
  }
`;
