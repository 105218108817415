import { gql, useMutation, useQuery } from "@apollo/client";
import { DialogContentText } from "@mui/material";
import { GridValueFormatterParams, GridValueGetterParams } from "@mui/x-data-grid-pro";
import _ from "lodash";
import React from "react";
import { Helmet } from "react-helmet";
import { ConfirmationDialog } from "../common/ConfirmationDialog";
import CrudTable from "../common/CrudTable";
import EditEngagementTypeTemplateDialog from "./EditEngagementTypeTemplateDialog";
import { EngagementTypeTemplate } from "./models";
import { DeleteEngagementTypeTemplateMutation, FetchEngagementTypeTemplatesQuery } from "./queries";

const EngagementTypeTemplateScreen: React.FunctionComponent = () => {
  const query = useQuery<{ engagementTypeTemplates: EngagementTypeTemplate[] }>(FetchEngagementTypeTemplatesQuery);
  const engagementTypes = query.data?.engagementTypeTemplates ?? [];

  const [deleteMutation, { loading: deleting }] = useMutation<{ engagementTypeTemplates: { add: EngagementTypeTemplate } }, { id: number }>(
    DeleteEngagementTypeTemplateMutation,
    {
      refetchQueries: [{ query: FetchEngagementTypeTemplatesQuery }]
    }
  );

  const getGroupName: (params: GridValueGetterParams) => string = (params) => params.row.engagementGroup.name;

  return (
    <>
      <Helmet>
        <title>Engagement Types - PRS Online</title>
      </Helmet>
      <CrudTable
        storageKey="engagementTypeTemplates"
        title="Engagement Types"
        columnDefinitions={[
          {
            field: "engagementGroup",
            headerName: "Group",
            valueGetter: getGroupName,
            sortComparator: (value1, value2, params1, params2) => {
              const groupName1 = params1.api.getRow(params1.id)!.engagementGroup?.name;
              const groupName2 = params2.api.getRow(params2.id)!.engagementGroup?.name;
              if (groupName1 !== groupName2) {
                return groupName1.localeCompare(groupName2);
              } else {
                return params1.api.getRow(params1.id)!.name.localeCompare(params2.api.getRow(params2.id)!.name);
              }
            },
            flex: 10,
            sortable: true
          },
          {
            field: "name",
            headerName: "Template",
            flex: 30,
            sortable: true
          },
          {
            field: "isPublic",
            type: "boolean",
            headerName: "Public Company",
            width: 150,
            sortable: true
          },
          {
            field: "isActive",
            type: "boolean",
            headerName: "Active",
            width: 100,
            sortable: true
          }
        ]}
        rows={_.orderBy(engagementTypes, [(et) => et.isActive, (et) => et.engagementGroup.name, (et) => et.name], ["desc", "asc", "asc"])}
        loading={query.loading}
        renderAddDialog={(dialogProps) => (
          <EditEngagementTypeTemplateDialog
            handleClose={dialogProps.onClose}
            title="Add Engagement Type Template"
            existingNames={engagementTypes.map((t) => t.name) ?? []}
            adding={true}
          />
        )}
        renderEditDialog={(id, dialogProps) => {
          const engagementTypeTemplate = engagementTypes.find((template) => template.id === id);
          if (!engagementTypeTemplate) {
            return null;
          }
          return (
            <EditEngagementTypeTemplateDialog
              handleClose={dialogProps.onClose}
              title={`Editing Template: ${engagementTypeTemplate.name}`}
              id={id}
              name={engagementTypeTemplate.name}
              isPublic={engagementTypeTemplate.isPublic}
              isActive={engagementTypeTemplate.isActive}
              engagementGroup={engagementTypeTemplate.engagementGroup}
              existingNames={engagementTypes.map((t) => t.name).filter((n) => n !== engagementTypeTemplate.name) ?? []}
              questionContainerTemplates={engagementTypeTemplate.questionContainerTemplates}
              adding={false}
            />
          );
        }}
        renderDeleteDialog={(id, dialogProps) => (
          <ConfirmationDialog
            open={true}
            title="Delete Engagement Type Template"
            body={
              <DialogContentText>
                {`Are you sure you want to delete the template: ${engagementTypes.find((t) => t.id === id)!.name}?`}
              </DialogContentText>
            }
            confirm={() => deleteMutation({ variables: { id: id } }).then(dialogProps.onClose)}
            cancel={dialogProps.onClose}
            loading={deleting}
          />
        )}
        disableDeleteReason={(row) => (row.isActive ? "Cannot delete active templates" : undefined)}
      />
    </>
  );
};

export default EngagementTypeTemplateScreen;
