import { useQuery } from "@apollo/client";

import React from "react";
import { ScreenHeader } from "../common/ScreenHeader";
import CrudTable from "../common/CrudTable";
import { makeStyles } from "../makeStyles";
import { MotionParagraph } from "../decisions";
import EditMotionDialog from "./EditMotionDialog";
import { FetchMotionsQuery } from "./queries";
import { GridSortItem } from "@mui/x-data-grid-pro";
import DeleteMotionDialog from "./DeleteMotionDialog";
import { Helmet } from "react-helmet";

const useStyles = makeStyles()(() => ({
  forceScroll: {
    minHeight: "101vh"
  },
  searchBar: {
    width: "30em"
  }
}));

const MotionsScreen: React.FunctionComponent = (props) => {
  const { classes } = useStyles();

  const motionParagraphQuery = useQuery<{ motionParagraphs: MotionParagraph[] }>(FetchMotionsQuery);
  const motionParagraphs = motionParagraphQuery.data?.motionParagraphs ?? [];
  const [sortModel, setSortModel] = React.useState<GridSortItem[]>([
    {
      field: "type",
      sort: "asc"
    },
    {
      field: "sortOrder",
      sort: "asc"
    }
  ]);

  return (
    <div className={classes.forceScroll}>
      <Helmet>
        <title>Motions - PRS Online</title>
      </Helmet>
      <ScreenHeader title="Motions" />
      <CrudTable
        columnDefinitions={[
          {
            field: "type",
            headerName: "Type",
            valueGetter: (params) => (params.row as MotionParagraph).motionType.name,
            sortable: true
          },
          {
            field: "motionCode",
            headerName: "Code",
            sortable: true
          },
          {
            field: "shortDescription",
            headerName: "Short Description",
            flex: 1,
            sortable: true
          },
          {
            field: "sortOrder",
            headerName: "Motion Sort Order",
            minWidth: 175,
            sortable: true
          },
          {
            field: "isActive",
            headerName: "Active",
            type: "boolean",
            sortable: true
          },
          {
            field: "isComply",
            headerName: "Comply",
            type: "boolean",
            sortable: true
          },
          {
            field: "isNonComply",
            headerName: "Non-comply",
            type: "boolean",
            sortable: true
          }
        ]}
        rows={motionParagraphs}
        loading={motionParagraphQuery.loading}
        noDataMessage="No motions created."
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model)}
        renderAddDialog={(props) => (
          <EditMotionDialog
            title="Add a Motion"
            confirmButtonText="Add"
            handleClose={props.onClose}
            existingMotions={motionParagraphs.filter((m) => m.isActive) || []}
          />
        )}
        renderEditDialog={(id, dialogProps) => (
          <EditMotionDialog
            title="Edit a Motion"
            confirmButtonText="Save"
            handleClose={dialogProps.onClose}
            motion={motionParagraphs.find((m) => m.id === id)}
            existingMotions={motionParagraphs.filter((m) => m.isActive) || []}
          />
        )}
        renderDeleteDialog={(id, dialogProps) => (
          <DeleteMotionDialog motion={motionParagraphs.find((m) => m.id === id) as MotionParagraph} handleClose={dialogProps.onClose} />
        )}
        disableDeleteReason={(motion) => (motion.isInUse ? "Cannot delete motion that is in use" : undefined)}
      />
    </div>
  );
};

export default MotionsScreen;
