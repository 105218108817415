import React from "react";
import { Button, Chip, Dialog, DialogActions, DialogContent, FormControl, Grid, Stack, Typography } from "@mui/material";
import ClosableDialogTitle from "../common/ClosableDialogTitle";
import { EditUserMutation, User, UserInput } from ".";
import { Field as FormikField, Form as FormikForm, Formik } from "formik";
import { TextField as FmuiTextField } from "formik-mui";
import { LoadingButton } from "@mui/lab";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";
import StackedStaticDataDisplay from "common/StackedStaticDataDisplay";
import _ from "lodash";
import { makeStyles } from "../makeStyles";
import { fieldStyles } from "styles/common";

const useStyles = makeStyles()((theme) => ({
  ...fieldStyles(theme)
}));

interface Props {
  handleClose: () => void;
  confirmButtonText: string;
  user: User;
}

interface FormValues {
  designation: string;
  entityNumber: number | null;
}

const EditUserDialog = (props: Props) => {
  const { classes } = useStyles();

  const [editUserMutate, editUserMutation] = useMutation<{ users: { edit: User } }, { user: UserInput }>(EditUserMutation);

  const initialValues: FormValues = {
    designation: props.user.designation ?? "",
    entityNumber: props.user.entityNumber
  };

  const validationSchema = Yup.object({
    designation: Yup.string(),
    entityNumber: Yup.number().nullable(true)
  });

  return (
    <Dialog open={true} fullWidth={true} scroll="paper" maxWidth="sm">
      <ClosableDialogTitle onClose={props.handleClose}>Edit User</ClosableDialogTitle>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, actions) => {
          await editUserMutate({
            variables: {
              user: {
                ...values,
                id: props.user.id,
                designation: values.designation.trim() !== "" ? values.designation.trim() : null,
                entityNumber: values.entityNumber !== null ? parseInt(values.entityNumber.toString()) : null
              }
            }
          });
          props.handleClose();
        }}
        validationSchema={validationSchema}>
        {(formikProps) => {
          return (
            <FormikForm>
              <DialogContent>
                <Stack spacing={1}>
                  {props.user.lastName ? (
                    <>
                      <StackedStaticDataDisplay value={props.user.firstName} label="First Name" />
                      <StackedStaticDataDisplay value={props.user.lastName} label="Last Name" />
                    </>
                  ) : (
                    <StackedStaticDataDisplay value={props.user.name ?? props.user.firstName} label="Display Name" />
                  )}

                  {props.user.email ? (
                    <StackedStaticDataDisplay value={props.user.email} label="Email" />
                  ) : (
                    <StackedStaticDataDisplay value={props.user.userPrincipalName ?? ""} label="Account Name" />
                  )}

                  <FormControl>
                    <FormikField name="designation" label="Designation" component={FmuiTextField} fullWidth />
                  </FormControl>
                  <FormControl>
                    <FormikField
                      name="entityNumber"
                      label="Entity Number"
                      type="number"
                      className={classes.noNumberSpinners}
                      component={FmuiTextField}
                      fullWidth
                    />
                  </FormControl>
                  {props.user.groups.length > 0 && (
                    <Stack spacing={1}>
                      <Typography variant="h4">Groups</Typography>
                      <Stack direction="row" flexWrap="wrap">
                        {_.orderBy(props.user.groups, (name) => name).map((group) => (
                          <Chip label={group} sx={{ mr: 1, mb: 1 }} />
                        ))}
                      </Stack>
                    </Stack>
                  )}
                </Stack>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => props.handleClose()}>Cancel</Button>
                <LoadingButton
                  loading={editUserMutation.loading}
                  color="primary"
                  variant="contained"
                  onClick={() => formikProps.submitForm()}>
                  {props.confirmButtonText}
                </LoadingButton>
              </DialogActions>
            </FormikForm>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default EditUserDialog;
