import { useMutation, useQuery } from "@apollo/client";
import { ScreenHeader } from "common/ScreenHeader";
import gql from "graphql-tag";
import CrudTable from "../common/CrudTable";
import EditSystemEstimatedTimeRuleDialog from "./EditSystemEstimatedTimeRuleDialog";
import { SystemEstimatedTimeRule } from "./models";
import { Helmet } from "react-helmet";
import { ConfirmationDialog } from "common/ConfirmationDialog";
import { DialogContentText } from "@mui/material";

import { datagridStyles } from "styles/common";
import { makeStyles } from "../makeStyles";

export const GetSystemEstimatedTimeRulesQuery = gql`
  query GetSystemEstimatedTimeRules {
    systemEstimatedTimeRules {
      minimumPartnersForRule
      approvedToSign_DidntAudit_DidntReview
      audited_Reviewed
      audited_DidntReview
      didntAudit_Reviewed
      overallCompilation
      overallQam
      overallTimeToCompileReport
    }
  }
`;

const DeleteSystemEstimatedTimeMutation = gql`
  mutation DeleteSystemEstimatedTimeRule($minimumPartnersForRule: Int) {
    systemEstimatedTimeRules {
      delete(minimumPartnersForRule: $minimumPartnersForRule) {
        minimumPartnersForRule
        approvedToSign_DidntAudit_DidntReview
        audited_Reviewed
        audited_DidntReview
        didntAudit_Reviewed
        overallCompilation
        overallQam
        overallTimeToCompileReport
      }
    }
  }
`;

const useStyles = makeStyles()((theme) => ({
  ...datagridStyles(theme)
}));

const SystemEstimatedTimeRulesScreen: React.FunctionComponent = () => {
  const { classes } = useStyles();
  const [deleteMutation, { loading: deleting }] = useMutation<
    { systemEstimatedTimeRules: { delete: SystemEstimatedTimeRule } },
    { minimumPartnersForRule: number }
  >(DeleteSystemEstimatedTimeMutation, {
    refetchQueries: [{ query: GetSystemEstimatedTimeRulesQuery }]
  });

  const query = useQuery<{ systemEstimatedTimeRules: SystemEstimatedTimeRule[] }>(GetSystemEstimatedTimeRulesQuery);
  const rules = query.data?.systemEstimatedTimeRules ?? [];

  return (
    <>
      <Helmet>
        <title>System Estimated Time Rules - PRS Online</title>
      </Helmet>
      <ScreenHeader title="System Estimated Time Rules" />
      <CrudTable
        rows={rules}
        getRowId={(r) => r.minimumPartnersForRule}
        headerHeight={100}
        columnDefinitions={[
          {
            field: "minimumPartnersForRule",
            headerName: "Minimum Partners For Rule",
            headerClassName: classes.wrapHeader,
            sortable: true,
            flex: 1
          },
          {
            field: "approvedToSign_DidntAudit_DidntReview",
            headerName: "For Each Partner With Signing Approval Who Did Not Sign Anything",
            headerClassName: classes.wrapHeader,
            flex: 1
          },
          {
            field: "audited_Reviewed",
            headerName: "For Each Partner Who Signed A Review AND an Audit",
            headerClassName: classes.wrapHeader,
            flex: 1
          },
          {
            field: "audited_DidntReview",
            headerName: "For Each Partner Who Signed an Audit but NOT a Review",
            headerClassName: classes.wrapHeader,
            flex: 1
          },
          {
            field: "didntAudit_Reviewed",
            headerName: "For Each Partner Who Signed a Review but NOT an Audit",
            headerClassName: classes.wrapHeader,
            flex: 1
          },
          {
            field: "overallCompilation",
            headerName: "Add for Compilation",
            headerClassName: classes.wrapHeader,
            flex: 1
          },
          {
            field: "overallQam",
            headerName: "Add for QAM",
            headerClassName: classes.wrapHeader,
            flex: 1
          },
          {
            field: "overallTimeToCompileReport",
            headerName: "Add for Compiling Report and Reviewing With Firm",
            headerClassName: classes.wrapHeader,
            flex: 1
          }
        ]}
        storageKey={`System Estimated Time Rules`}
        sortModel={[
          {
            field: "minimumPartnersForRule",
            sort: "asc"
          }
        ]}
        renderAddDialog={(props) => (
          <EditSystemEstimatedTimeRuleDialog
            title="Add Rule"
            handleClose={props.onClose}
            confirmButtonText="Add"
            existingRules={rules.map((r) => r.minimumPartnersForRule!)}
          />
        )}
        renderEditDialog={(minimumPartnersForRule, props) => (
          <EditSystemEstimatedTimeRuleDialog
            title="Edit Rule"
            rule={rules.find((r) => r.minimumPartnersForRule === minimumPartnersForRule)}
            handleClose={props.onClose}
            confirmButtonText="Save"
            existingRules={rules.filter((r) => minimumPartnersForRule !== r.minimumPartnersForRule).map((r) => r.minimumPartnersForRule!)}
          />
        )}
        renderDeleteDialog={(minimumPartnersForRule, props) => (
          <ConfirmationDialog
            open={true}
            title="Delete Rule"
            body={
              <DialogContentText>{`Are you sure you want to delete the rule for ${minimumPartnersForRule} minimum partners?`}</DialogContentText>
            }
            confirm={() => deleteMutation({ variables: { minimumPartnersForRule } }).then(props.onClose)}
            cancel={props.onClose}
            loading={deleting}
          />
        )}
        disableDeleteReason={(row) => (row.minimumPartnersForRule === 1 ? "Cannot delete rule for 1 minimum partner" : undefined)}
      />
    </>
  );
};

export default SystemEstimatedTimeRulesScreen;
