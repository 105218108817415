import { useMutation, gql } from "@apollo/client";
import { LoadingButton } from "@mui/lab";
import { Dialog, DialogContent, Typography, DialogActions, Button } from "@mui/material";
import ClosableDialogTitle from "../common/ClosableDialogTitle";
import { PracticeReview } from "./models";

export const ChangePrIsNonAssuranceMutation = gql`
  mutation ChangePracticeReviewIsNonAssurance($practiceReviewId: Int!, $isNonAssurance: Boolean!) {
    practiceReview {
      changeIsNonAssurance(practiceReviewId: $practiceReviewId, isNonAssurance: $isNonAssurance) {
        id
        reviewType
        isNonAssurance
      }
    }
  }
`;

export const ChangePrIsNonAssuranceDialog = (props: { onClose: () => void; practiceReview: PracticeReview }) => {
  const [changePrIsNonAssuranceMutate, changePrIsNonAssuranceMutation] = useMutation<
    { practiceReview: { changeIsNonAssurance: PracticeReview } },
    { practiceReviewId: number; isNonAssurance: boolean }
  >(ChangePrIsNonAssuranceMutation);

  return (
    <Dialog
      open={true}
      onClose={() => {
        props.onClose();
      }}>
      <ClosableDialogTitle onClose={props.onClose}>Change Practice Review Type</ClosableDialogTitle>
      <DialogContent>
        <Typography>
          {`Are you sure you want to change the practice review type to ${
            props.practiceReview.isNonAssurance ? "Assurance" : "Non-assurance"
          }?`}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            props.onClose();
          }}>
          Cancel
        </Button>
        <LoadingButton
          loading={changePrIsNonAssuranceMutation.loading}
          onClick={async () => {
            await changePrIsNonAssuranceMutate({
              variables: { practiceReviewId: props.practiceReview.id, isNonAssurance: !props.practiceReview.isNonAssurance }
            });
            props.onClose();
          }}
          color="primary"
          variant="contained">
          OK
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
