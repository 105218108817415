import React from "react";
import { Button, Dialog, DialogActions, DialogContent, Grid } from "@mui/material";
import ClosableDialogTitle from "common/ClosableDialogTitle";
import { Field as FormikField, Form as FormikForm, Formik } from "formik";
import { TextField as FmuiTextField, CheckboxWithLabel as FmuiCheckboxWithLabel } from "formik-mui";
import { LoadingButton } from "@mui/lab";
import { gql } from "@apollo/client";

import * as Yup from "yup";
import { useMutation } from "@apollo/client";
import { PdCourse, PdCourseInput } from ".";
import { FetchPdCoursesQuery } from "./PdCoursesScreen";

const AddPdCoursesMutation = gql`
  mutation UpdatePdCourse($pdCourse: PdCourseInput) {
    pdCourses {
      add(pdCourse: $pdCourse) {
        name
        shortName
        productCode
        isActive
        topicName
        topicShortName
      }
    }
  }
`;

const EditPdCoursesMutation = gql`
  mutation UpdatePdCourse($pdCourse: PdCourseInput) {
    pdCourses {
      update(pdCourse: $pdCourse) {
        id
        name
        shortName
        productCode
        isActive
        topicName
        topicShortName
      }
    }
  }
`;

interface Props {
  handleClose: () => void;
  title: string;
  confirmButtonText: string;
  course?: PdCourse;
}

interface FormValues {
  name: string;
  shortName: string;
  productCode: string | null;
  isActive: boolean;
  topicName: string;
  topicShortName: string;
}

export const EditPdCoursesDialog = (props: Props) => {
  const [addPdCourseMutate, addPdCourseMutation] = useMutation<{ PdCourses: { update: PdCourse } }, { pdCourse: PdCourseInput }>(
    AddPdCoursesMutation,
    { refetchQueries: [FetchPdCoursesQuery] }
  );

  const [editPdCourseMutate, editPdCourseMutation] = useMutation<{ PdCourses: { update: PdCourse } }, { pdCourse: PdCourseInput }>(
    EditPdCoursesMutation,
    { refetchQueries: [FetchPdCoursesQuery] }
  );

  const editing = props.course !== undefined;
  const initialValues: FormValues = {
    name: props.course?.name ?? "",
    shortName: props.course?.shortName ?? "",
    productCode: props.course?.productCode ?? "",
    topicName: props.course?.topicName ?? "",
    topicShortName: props.course?.topicShortName ?? "",
    isActive: props.course?.isActive ?? true
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Enter the Name of the recommendation."),
    shortName: Yup.string().required("Enter the Short Name of the recommendation"),
    productCode: Yup.string().required("Enter the Product Code of the recommendation."),
    topicName: Yup.string().required("Enter the Topic Name of the recommendation"),
    topicShortName: Yup.string().required("Enter the Topic Short Name of the recommendation")
  });

  return (
    <Dialog open={true} fullWidth={true} scroll="paper" maxWidth="sm">
      <ClosableDialogTitle onClose={props.handleClose}>{props.title}</ClosableDialogTitle>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, actions) => {
          if (editing) {
            editPdCourseMutate({
              variables: {
                pdCourse: {
                  ...values,
                  id: props.course?.id ?? 0
                }
              }
            });
          } else {
            addPdCourseMutate({
              variables: {
                pdCourse: {
                  ...values
                }
              }
            });
          }

          props.handleClose();
        }}
        validationSchema={validationSchema}>
        {(formikProps) => {
          return (
            <FormikForm>
              <DialogContent>
                <Grid direction="column" item container rowSpacing={1}>
                  <Grid item>
                    <FormikField name={`name`} label="Name" multiline required component={FmuiTextField} fullWidth />
                  </Grid>
                  <Grid item>
                    <FormikField name={`shortName`} label="Short Name" required component={FmuiTextField} fullWidth />
                  </Grid>
                  <Grid item>
                    <FormikField name={`productCode`} label="Product Code" component={FmuiTextField} fullWidth />
                  </Grid>
                  <Grid item>
                    <FormikField name={`topicName`} label="Topic Name" component={FmuiTextField} fullWidth />
                  </Grid>
                  <Grid item>
                    <FormikField name={`topicShortName`} label="Topic Short Name" component={FmuiTextField} fullWidth />
                  </Grid>

                  <Grid item></Grid>
                  <Grid item>
                    <FormikField
                      name={`isActive`}
                      type="checkbox"
                      component={FmuiCheckboxWithLabel}
                      fullWidth
                      Label={{ label: "Active" }}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => props.handleClose()}>Cancel</Button>
                <LoadingButton
                  loading={editPdCourseMutation.loading || addPdCourseMutation.loading}
                  color="primary"
                  variant="contained"
                  onClick={() => formikProps.submitForm()}>
                  {props.confirmButtonText}
                </LoadingButton>
              </DialogActions>
            </FormikForm>
          );
        }}
      </Formik>
    </Dialog>
  );
};
