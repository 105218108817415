import { useMutation } from "@apollo/client";
import { ConfirmationDialog } from "../common/ConfirmationDialog";
import { useNotifications } from "../notifications";
import { DialogContentText } from "@mui/material";
import { DeleteMotionsMutation, FetchMotionsQuery } from "./queries";
import { MotionParagraph } from "../decisions";

interface Props {
  handleClose: () => void;
  motion: MotionParagraph;
}

const DeleteMotionDialog = (props: Props) => {
  const notifications = useNotifications();
  const [mutate, { loading: deleting }] = useMutation<{ motionParagraph: { delete: MotionParagraph } }, { id: number }>(
    DeleteMotionsMutation,
    {
      refetchQueries: [{ query: FetchMotionsQuery }]
    }
  );

  return (
    <>
      <ConfirmationDialog
        open={true}
        title="Delete Motion?"
        body={<DialogContentText>Are you sure you want to delete the motion?</DialogContentText>}
        confirmButtonText="Delete"
        confirm={async () => {
          await mutate({
            variables: { id: props.motion.id }
          });
          notifications.success("Motion deleted.");
          props.handleClose();
        }}
        loading={deleting}
        cancel={props.handleClose}
      />
    </>
  );
};

export default DeleteMotionDialog;
