import React, { useRef } from "react";
import { PracticeReview, SaveSummaryNotesMutation } from "practice-reviews";
import { Grid, Stack, Typography } from "@mui/material";
import { makeStyles } from "../../makeStyles";
import { useMutation } from "@apollo/client";
import { useCurrentUser } from "../../users";
import { useNotifications } from "../../notifications";
import { LoadingButton } from "@mui/lab";
import { useUnsavedChanges } from "../../UnsavedChangesProvider";
import RichTextEditor from "../../common/RichTextEditor";
import { htmlHasContent } from "../../util/utilities";

interface Props {
  practiceReview: PracticeReview;
}

export const SummaryNotes: React.FunctionComponent<Props> = (props) => {
  const notifications = useNotifications();
  const practiceReview = props.practiceReview;
  const { user, userIsLeadReviewer } = useCurrentUser();
  const { changesSaved } = useUnsavedChanges();

  const notesContentRetriever = useRef<{ getContentAsHtml: () => string | null }>({ getContentAsHtml: () => null });

  async function saveNotes() {
    let html = notesContentRetriever.current.getContentAsHtml();
    if (!htmlHasContent(html)) {
      html = null;
    }

    const result = await saveMutate({
      variables: { practiceReviewId: props.practiceReview.id, notes: html }
    });

    if (result.data?.practiceReview.saveSummaryNotes?.id) {
      notifications.success("Saved notes.");
    }
  }

  const [saveMutate, saveMutation] = useMutation<
    { practiceReview: { saveSummaryNotes: PracticeReview } },
    { practiceReviewId: number; notes: string | null }
  >(SaveSummaryNotesMutation);

  const myNotes = userIsLeadReviewer(practiceReview)
    ? practiceReview.leadReviewer?.reviewerNotes
    : practiceReview.otherReviewers.find((r) => r.userId === user.id)?.reviewerNotes;

  return (
    <Stack spacing={1}>
      <Typography variant="h3">My Notes</Typography>
      <RichTextEditor
        minHeight="20em"
        html={myNotes ?? null}
        passContentRetriever={(getContentAsHtml) => {
          notesContentRetriever.current = { getContentAsHtml };
        }}
        reportUnsavedChanges
      />

      <LoadingButton
        sx={{ alignSelf: "flex-end" }}
        variant="outlined"
        loading={saveMutation.loading}
        onClick={async () => {
          await saveNotes();
          changesSaved();
        }}>
        Save
      </LoadingButton>
    </Stack>
  );
};
