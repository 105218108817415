import React from "react";
import { Button, Dialog, DialogActions, DialogContent, Grid, Typography } from "@mui/material";
import ClosableDialogTitle from "common/ClosableDialogTitle";
import { gql, useMutation } from "@apollo/client";
import { CustomReport } from "./models";
import * as Yup from "yup";
import { Field as FormikField, Form as FormikForm, Formik } from "formik";
import { TextField as FmuiTextField } from "formik-mui";
import { Validations } from "common/validations/common-yup-validations";
import { LoadingButton } from "@mui/lab";
import { FetchCustomReportsQuery } from ".";

const AddCustomReportMutation = gql`
  mutation AddCustomReport($report: CustomReportInput!) {
    customReports {
      add(report: $report) {
        id
        reportName
        url
      }
    }
  }
`;

const EditCustomReportMutation = gql`
  mutation EditCustomReport($report: CustomReportInput!) {
    customReports {
      update(report: $report) {
        id
        reportName
        url
      }
    }
  }
`;

type EditableCustomReport = Pick<CustomReport, "id" | "reportName" | "url">;

interface EditCustomReportDialogProps {
  handleClose: () => void;
  title: string;
  confirmButtonText: string;
  data?: EditableCustomReport;
}

const EditCustomReportDialog = (props: EditCustomReportDialogProps) => {
  const validationSchema = Yup.object().shape({
    name: Validations.requiredText(),
    url: Validations.requiredText()
  });

  const [addCustomReport, { loading: loadingAdd }] = useMutation<{ customReport: { add: CustomReport } }, { report: EditableCustomReport }>(
    AddCustomReportMutation,
    {
      refetchQueries: [{ query: FetchCustomReportsQuery }]
    }
  );

  const [editCustomReport, { loading: loadingEdit }] = useMutation<
    { customReport: { update: CustomReport } },
    { report: EditableCustomReport }
  >(EditCustomReportMutation);

  const mutate = Boolean(props.data?.reportName) ? editCustomReport : addCustomReport;
  const loading = Boolean(props.data?.reportName) ? loadingEdit : loadingAdd;

  return (
    <Dialog open={true} onClose={props.handleClose} fullWidth={true} scroll="paper" maxWidth="sm">
      <ClosableDialogTitle onClose={props.handleClose}>{props.title}</ClosableDialogTitle>
      <Formik
        initialValues={{ name: props.data?.reportName ?? "", url: props.data?.url ?? "" }}
        onSubmit={async (values) => {
          let report: EditableCustomReport = {
            id: props.data?.id,
            reportName: values.name,
            url: values.url
          };
          await mutate({
            variables: { report }
          });
          props.handleClose();
        }}
        validationSchema={validationSchema}>
        {(formikProps) => (
          <FormikForm>
            <DialogContent>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Typography paragraph>Items added here will show up under the 'Reports' navigation menu.</Typography>
                </Grid>
                <Grid item xs={8}>
                  <FormikField component={FmuiTextField} name="name" label="Name" fullWidth required />
                </Grid>
                <Grid item xs={8}>
                  <FormikField component={FmuiTextField} name="url" label="Url" fullWidth required />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={props.handleClose}>Cancel</Button>
              <LoadingButton color="primary" variant="contained" loading={loading} onClick={() => formikProps.submitForm()}>
                {props.confirmButtonText}
              </LoadingButton>
            </DialogActions>
          </FormikForm>
        )}
      </Formik>
    </Dialog>
  );
};

export default EditCustomReportDialog;
