import { convertFromHTML, ContentState, Modifier, SelectionState } from "draft-js";

/**
 * Given a string of HTML, generates a draft-js ContentState with equivalent content, using
 * temporary special characters (Braille Pattern Blank, Left Single Quote, and Right Single Quote) to ensure
 * empty <p> and <br>, and spaces that would otherwise be clobbered by draft-js in the process are instead
 * preserved in the result.
 *
 * @param html The HTML string to make into a draft-js contentState (if falsy, "" is used)
 * @returns A ContentState representing the given HTML, including vulnerable line breaks
 */
export function generateCleanContentStateFromHtml(html: string | null) {
  // Braille Pattern Blank: a whitespace character that doesn't display, but also doesn't get nuked by a
  // stubborn draft-js. (We will only be using it temporarily and then taking it out again anyway, so it
  // could theoretically be any character not likely to appear in the actual content whether visible or
  // not, but it just felt right to me to use a non-display character.)
  const bpbHtmlEncoded = "&#10240;";
  const bpbActualChar = "⠀";

  // Left quotes are explicitly removed from the content so we know they won't exist. Unfortunately, draft-js
  // will try to nuke non-breaking spaces so replacing them now and adding them later lets the client have all
  // the formatting they could want
  const lsqHtmlEncoded = "&#8216;";
  const lsqActualChar = "‘";

  // Right single quotes are also explicitly removed so we can use them to replace tabs that would otherwise not
  // survive being added to draft-js.
  const rsqHtmlEncoded = "&#xE6;";
  const rsqActualChar = "æ";

  // To make sure we're inserting the right number of tabs, we get what the base tab-increment from the body
  // if it exists.
  const baseTabIndentation = parseFloat(
    html
      ?.match(/tab-interval:\d*\.\d/)
      ?.pop()
      ?.match(/\d*\.\d/)
      ?.pop() ?? "36.0"
  );
  // The main issue is that tab indentation is stored in distance rather than number of tabs, so this
  // replacer is needed to calculate the number of tab characters we need to insert.
  const tabReplacer = (match: string, ...args: any[]) => {
    let tabs = 0;
    // 1. Get the distance of the indentation,
    let indentation = match.match(/\d*\.\d/)?.pop();
    if (indentation) {
      // 2. Get the number of tab characters by dividing by the base incrementation,
      tabs = Math.floor(parseFloat(indentation) / baseTabIndentation);
    }
    // 3. Insert the fake tab characters needed to get to that indentation level.
    return ">".padEnd(1 + rsqHtmlEncoded.length * tabs, rsqHtmlEncoded);
  };
  // We'll shoehorn in the BPB character in these cases where a lack of content would otherwise cause
  // them to be lost:
  const htmlFromProps = html
    ?.replace(/<p><br><\/p>/gim, `<p>${bpbHtmlEncoded}</p>`) // empty paragraphs (editor inserts these with line breaks in them)
    ?.replace(/<br>[\s\r\n]*<br>/gim, `<br>${bpbHtmlEncoded}<br>`) // consecutive line breaks
    ?.replace(/<br>\s*<\//gim, `<br>${bpbHtmlEncoded}</`) // line breaks at the end of a block
    ?.replace(/&nbsp;/g, lsqHtmlEncoded) // non-breaking spaces
    ?.replace(/ (?=<\/span>)| (?= )/g, lsqHtmlEncoded) // spaces pasted from MS-Word are put into spans
    ?.replace(/ style='text-indent:\d*.\dpt'>/g, tabReplacer); // Tabs are stored as text-indentation styles on indented p tags

  // this is where we would experience the loss:
  const contentBlocksAndMap = convertFromHTML(htmlFromProps ?? "");

  // Get the initial result, which still contains the desired lines but also, at the moment, the temp chars.
  // (If we didn't care about that, we could stop after this, but its presence does impact the editing
  // experience, for one thing because it does have width and thus takes up a caret position)
  let contentState = html
    ? ContentState.createFromBlockArray(contentBlocksAndMap.contentBlocks, contentBlocksAndMap.entityMap)
    : ContentState.createFromText("");

  // Now that we've called convertFromHTML without losing empty lines, we can remove our cheat characters
  // from the result. Which is much more complicated than it was to insert it...
  // First we'll identify blocks that need it cleansed:
  const blocksWithSpecialChar = contentBlocksAndMap.contentBlocks.filter(
    (f) => f.getText().includes(bpbActualChar) || f.getText().includes(lsqActualChar) || f.getText().includes(rsqActualChar)
  );

  // Let's iterate through those blocks backwards, so that any char indices they have won't go stale as we remove a
  // character from their content.
  // (This is more of a problem for the style ranges inside the blocks, but might as well go back to front here too)
  for (let candidateBlockIndex = blocksWithSpecialChar.length - 1; candidateBlockIndex >= 0; candidateBlockIndex--) {
    const candidateBlock = blocksWithSpecialChar[candidateBlockIndex];

    // The thing is, these blocks are based on structure, not styling, so they may have multiple different styles
    // within them.  So if we just take the block's text, remove the special chars, and call Modifier.replaceText,
    // we actually lose those inline styles.  So instead, we need to:
    //
    // 1. Identify the style ranges within the block,
    const styleRangeIndexPairs: { startIndex: number; endIndex: number }[] = [];
    candidateBlock.findStyleRanges(
      () => true,
      (startIndex, endIndex) => styleRangeIndexPairs.push({ startIndex: startIndex, endIndex: endIndex })
    );

    // (iterating through them backwards so their char indices don't go stale as we remove a character from them)
    for (let styleIndex = styleRangeIndexPairs.length - 1; styleIndex >= 0; styleIndex--) {
      // 2. get the text within each range and check if it contains the character,
      const styleRangeIndexPair = styleRangeIndexPairs[styleIndex];
      const styleRangeText = candidateBlock.getText().substring(styleRangeIndexPair.startIndex, styleRangeIndexPair.endIndex);

      if (styleRangeText.includes(bpbActualChar) || styleRangeText.includes(lsqActualChar) || styleRangeText.includes(rsqActualChar)) {
        // 3. grab the inline style for the range, and the text with the characters removed,
        const inlineStyleForRange = candidateBlock.getInlineStyleAt(styleRangeIndexPair.startIndex);
        const replacementText = styleRangeText
          .replace(new RegExp(bpbActualChar, "gim"), "")
          .replace(new RegExp(lsqActualChar, "gim"), " ")
          .replace(new RegExp(rsqActualChar, "g"), "\t");

        // 4. make a SelectionState representing just the text of this range within the block,
        const selection = SelectionState.createEmpty(candidateBlock.getKey()).merge({
          anchorKey: candidateBlock.getKey(),
          anchorOffset: styleRangeIndexPair.startIndex,
          focusKey: candidateBlock.getKey(),
          focusOffset: styleRangeIndexPair.endIndex
        });

        // 5. get a new contentState from Modifier.replaceText, passing in the inline style, for that selection.
        contentState = Modifier.replaceText(contentState, selection, replacementText, inlineStyleForRange);
      }
    }
  }

  return contentState;
}
