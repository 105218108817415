import { EngagementGroup } from "engagement-type-templates/models";
import { UpcomingReviewNotice } from "scheduling";
import { User } from "users";
import { Activity } from "activities/models";
import { ChecklistCompletion } from "checklists";
import { Decision } from "decisions";
import { LevelOfAdjustment } from "level-of-adjustment/models";
import { CommitteeMeeting, CommitteeMeetingTabledReview } from "../committee-meetings";
import { FirmResponse } from "../firm-response/models";
import { Exemption } from "../exemptions";
import { Ina } from "../inas";

export interface FirmEntityType {
  typeShortName: string;
  typeDescription: string;
}

export interface Firm {
  id: number;
  name: string;
  entityNumber: number;
  firmStatus: string | null;
  subStatus: string | null;
  entityType: FirmEntityType;

  practiceLocale: string | null;
  zone: string | null;
  isCpab: boolean;

  address1: string;
  address2: string | null;
  city: string;
  province: string | null;
  country: string | null;
  postalCode: string | null;
  phoneNumber: string | null;
  faxNumber: string | null;

  designatedPartnerFirstName: string | null;
  designatedPartnerLastName: string | null;
  designatedPartnerTitle: string | null;
  designatedPartnerDesignation: string | null;

  hoDesignatedPartnerFirstName: string | null;
  hoDesignatedPartnerLastName: string | null;
  hoDesignatedPartnerTitle: string | null;
  hoDesignatedPartnerDesignation: string | null;
  hoDesignatedPartnerEmail: string | null;

  latestPartners: FirmPartner[];
  latestClienteleProfile: ClienteleProfile | null;

  lastRefreshedFromDataSource: string;

  practiceReviews: PracticeReview[];
  latestPracticeReview: PracticeReview;

  historicalPrs: HistoricalPr[];
}

export interface FirmPartner {
  id: number;
  reviewYear: number;
  name: string;
  designation: string;
  yearDesignationObtained: number;
  isOther: boolean;
  withFirmSince: number;
  function: string;
  compilation: boolean;
  audit: boolean;
  review: boolean;
}

export interface ClienteleProfile {
  firmId: number;
  reviewYear: number;

  publicCompaniesHours: number;
  publicCompaniesClients: number;
  offeringDocumentsHours: number;
  offeringDocumentsClients: number;
  otherEntitiesUnderIfrsHours: number;
  otherEntitiesUnderIfrsClients: number;
  notForProfitHours: number;
  notForProfitClients: number;
  publicSectorHours: number;
  publicSectorClients: number;
  otherAuditHours: number;
  otherAuditClients: number;

  reviewYearEndHours: number;
  reviewYearEndClients: number;
  reviewEntitiesUnderIfrsHours: number;
  reviewEntitiesUnderIfrsClients: number;
  reviewInterimHours: number;
  reviewInterimClients: number;

  businessValuationsHours: number;
  businessValuationsClients: number;
  insolvencyHours: number;
  insolvencyClients: number;
  trustAccountingHours: number;
  trustAccountingClients: number;
  compilationHours: number;
  compilationClients: number;
  handlingOfTrustFundsHours: number;
  handlingOfTrustFundsClients: number;
  accountingServicesHours: number;
  accountingServicesClients: number;
  taxServicesHours: number;
  taxServicesClients: number;
  forensicHours: number;
  forensicClients: number;
  otherServicesHours: number;
  otherServicesClients: number;

  totalHours: number;
  totalClients: number;
}

export interface FirmProfile {
  firmId: number;
  reviewYear: number;

  fullTimePartners: number;
  designatedAccountantsFt: number;
  designatedAccountantsPt: number;
  candidatesFt: number;
  candidatesPt: number;
  otherFt: number;
  otherPt: number;
}

export enum PRBaseStatusCode {
  Any = "Any",
  InProgress = "InProgress",
  Hold = "Hold",
  ExemptionPending = "ExemptionPending",
  Exempt = "Exempt",
  Removed = "Removed",
  Completed = "Completed"
}

export interface PRStatus {
  id: number;
  baseStatusCode: PRBaseStatusCode;
  statusName: string;
}

export enum PRPhaseCode {
  Any = 0,
  Scheduling = 1,
  PerformPracticeReview = 2,
  DirectorApproval = 3,
  CommitteeMeeting = 4,
  Completed = 5
}

export interface PRPhase {
  id: PRPhaseCode;
  name: string;
}

export interface PREstimate {
  id: number;
  date: string;
  isFinalized: boolean;

  compilationTotal: number;
  otherQamTotal: number;
  finalizeReviewTotal: number;
  totalSystemEstimate: number;
  overrideEstimate: number | null;
  finalEstimate: number;

  notes: string | null;

  partners: PRPartnerEstimate[];
}

export interface PRPartnerEstimate {
  id: number;
  firmPartnerId: number;
  timeEstimate: number;
}

export interface AttachedDocument {
  id: number;
  type: DocType;
  typeAsString: string;
  typeFriendlyName: string;
  fileName: string;
  url: string;
}

export interface PracticeClient {
  id: number;
  clientName: string;
  toReview: boolean;
}

export interface EngagementType {
  id: number;
  name: string;
  engagementGroup: EngagementGroup;
}

export interface ReviewClient {
  id: number;
  name: string;
  partnerName: string;
  refNum: string;
  engagementTypeId: number;
  engagementType: EngagementType;
  reviewedByUserId: number;
  reviewer: User | null;
  checklistCompletion: ChecklistCompletion;

  clientFile: ClientFile | null;
}

export interface ClientFile {
  businessNature: string;
  fiscalYearEnd: string;
  cpabReview: boolean;
  competitiveBid: boolean;
  isHoursNotFees: boolean;
  feesOrHoursAmount: number;
  materiality: number | null;
  assets: number;
  liabilities: number;
  revenue: number;
  netIncomeBeforeTaxes: number;
  reportDate: string | null;
}

export interface ReviewClientInput {
  id?: number;
  practiceReviewId: number;
  name: string;
  partnerName: string;
  engagementTypeId: number;
  refNum: string;
  reviewedByUserId: number;
  clientFile: ClientFile | null;
}

export interface EngagementType {
  id: number;
  name: string;
  engagementGroup: EngagementGroup;
}

export interface TimeEntry {
  id: number;
  enteredDate: string;
  enteredHours: number;
  isAdmin: boolean;
  user: User;
  practiceReview: PracticeReview;
  __typename?: string;
}
export interface TimeEntryInput {
  id?: number;
  enteredDate: string;
  enteredHours: number;
  userId: number;
  practiceReviewId: number;
}

export interface PracticeReview {
  id: number;
  prNumber: string;
  prNumberPrefix: number;
  prStage: number;
  reviewYear: number;
  datesHaveBeenConfirmed: boolean;
  startDate: string;
  endDate: string;
  isNonAssurance: boolean;
  isDeskReview: boolean;
  reviewType: "Non-assurance" | "Assurance";
  hasIncreasedRisk: boolean;

  contactName: string;
  contactEmail: string;
  contactEmailUri: string;
  hasValidContactEmail: boolean;
  contactPhone: string;
  contactTitle: string;
  contactDesignation: string;

  firm: Firm;
  status: PRStatus;
  phase: PRPhase;

  schedulingQuestionnaireUrl: string | null;
  isMissingSchedulingQuestionnaire: boolean;
  isSchedulingQuestionnaireReviewedByLead: boolean;

  taxQuestionnaireUrl: string | null;
  isMissingTaxQuestionnaire: boolean;
  isTaxQuestionnaireReviewedByLead: boolean;

  confidentialClientListUrl: string | null;
  isMissingConfidentialClientList: boolean;

  deficiencyReportUrl: string | null;
  practiceReviewReportUrl: string | null;
  checklistNotesReportUrl: string | null;

  firmProfileForReviewYear: FirmProfile;

  hasBeenEstimated: boolean;
  estimate: PREstimate;
  mostRecentRescheduleReason: string | null;

  previousReview?: PracticeReview;

  prNotes: string | null;
  notesFromSchedulingPhase: string | null;

  leadReviewer: Reviewer | null;
  otherReviewers: Reviewer[];

  actualTimeToDate: number | null;
  timeEntries: TimeEntry[];

  upcomingReviewNotice: UpcomingReviewNotice | null;

  committeeMeeting: CommitteeMeeting | null;
  committeeMeetingIsConfirmed: boolean;

  attachedDocuments: AttachedDocument[];
  sharePointUrl: string;

  practiceClients: PracticeClient[];

  reviewClients: ReviewClient[];

  engagementTypes: EngagementType[];

  firmChecklistCompletion: ChecklistCompletion;

  held: {
    heldBy: string;
    reason: string;
    date: string;
  } | null;

  levelOfAdjustment: LevelOfAdjustment;

  activities: Activity[];
  inas: Ina[];

  finalReportPackageUrl: string | null;
  isFinalReportReviewedByCoordinator: boolean;
  isFinalReportReviewedByDirector: boolean;

  hasDecisionLetter: boolean;
  decisionLetterUrl: string | null;

  pdDeclarationFormUrl: string | null;
  directedPdLetterUrl: string | null;

  decisions: Decision[];
  mostAuthoritativeDecision: Decision | null;

  isDeficiencyReportPosted: boolean;

  hasBeenReturned: boolean;
  isReturnedComplete: boolean;
  reasonReturnedIncomplete: string | null;

  requiresResponse: boolean;
  firmResponse: FirmResponse;

  committeeTab: string | null;
  tabledFromPrevious: CommitteeMeetingTabledReview | null;
  tabledToNext: CommitteeMeetingTabledReview | null;
  mostRecentlyTabled: CommitteeMeetingTabledReview | null;

  exemption: Exemption | null;
  exemptionLetterUrl: string | null;
  signedExemptionLetterUrl: string | null;
}

export interface HistoryItem {
  __typename?: string;
  id?: number;
  isComply: boolean | null;
  nonComplyFinancial: boolean;
  nonComplyAudit: boolean;
  nonComplyReview: boolean;
  nonComplyQualityManagement: boolean;
  nonComplyTax: boolean;
  nonComplyOther: boolean;
  nonComplyOtherSpecified: string | null;
}

export interface HistoricalPr extends HistoryItem {
  reviewYear: number;
  prNumber: string;
  otherComments: string | null;
}

export interface DataGridHistoryItem extends HistoryItem {
  _gridId: string;
}

export function isDecision(historyItem: HistoryItem): historyItem is Decision {
  return historyItem.__typename === "Decision";
}

export function isHistoricalPr(historyItem: HistoryItem): historyItem is HistoricalPr {
  return historyItem.__typename === "HistoricalPr";
}

export interface ImportPracticeReviewResults {
  new: number;
  updated: number;
  failed: number;
}

export enum DocType {
  ChecklistNotesReport = "ChecklistNotesReport",
  CommitteeMeetingMinutes = "CommitteeMeetingMinutes",
  ConfidentialClientList = "ConfidentialClientList",
  DecisionComplyLetter = "DecisionComplyLetter",
  DecisionNonComplyLetter = "DecisionNonComplyLetter",
  DecisionTabledLetter = "DecisionTabledLetter",
  DeficiencyReport = "DeficiencyReport",
  SignedDeficiencyReport = "SignedDeficiencyReport",
  DirectedPDLetter = "DirectedPDLetter",
  DirectorPresentationFormat = "DirectorPresentationFormat",
  FirmActivityLogFullDetails = "FirmActivityLogFullDetails",
  FirmResponseOriginal = "FirmResponseOriginal",
  FirmResponseRedacted = "FirmResponseRedacted",
  MailedScheduleNotification = "MailedScheduleNotification",
  OfficeListingReport = "OfficeListingReport",
  OfficeListingReportWithFirm = "OfficeListingReportWithFirm",
  PDDeclarationForm = "PDDeclarationForm",
  PracticeReviewReport = "PracticeReviewReport",
  SignedDraftPracticeReviewReport = "SignedDraftPracticeReviewReport",
  ReviewerEvaluationForm = "ReviewerEvaluationForm",
  PresentationFormat = "PresentationFormat",
  SchedulingQuestionnaire = "SchedulingQuestionnaire",
  StandardMotionsSummary = "StandardMotionsSummary",
  StandardMotionsWithNamesSummary = "StandardMotionsWithNamesSummary",
  StudentQuestionnaire = "StudentQuestionnaire",
  TaxQuestionnaire = "TaxQuestionnaire",
  ExemptionLetter = "ExemptionLetter",
  SignedExemptionLetter = "SignedExemptionLetter",
  AdditionalFile = "AdditionalFile"
}

export interface Reviewer {
  practiceReviewId: number;
  userId: number;
  isLead: boolean;
  reviewClientsSubmitted: boolean;
  reviewClientsHaveBeenUnsubmitted: boolean;
  reviewerNotes: string;
  resubmissionNotes: string;
  user: User;
  clients: ReviewClient[];
}

export interface PrReportParameters {
  sortOrder: SortOrderForClientFiles;
  filterFromReferenceLetter: string | null;
  filterToReferenceLetter: string | null;
  filterClientId: number | null;
  filterPartnerName: string | null;
  filterReviewerUserId: number | null;
  filterEngagementTypeId: number | null;
  includeDecision: boolean;
}

export enum SortOrderForClientFiles {
  ClientName = "ClientName",
  Partner = "Partner",
  EngagementType = "EngagementType",
  Reviewer = "Reviewer",
  ReferenceLetter = "ReferenceLetter",
  EngagementGroup = "EngagementGroup"
}
