import React, { useState } from "react";
import { Ina, InaTypeCode } from "./models";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons/faEllipsisV";

interface Props {
  inas: Ina[];
  setInasToReassign: (inas: Ina[]) => void;
  setInasToSetPriorityOf: (inas: Ina[]) => void;
  disableReassignment?: (ina: Ina) => boolean;
}

export const InaMenu: React.FunctionComponent<Props> = (props) => {
  const { inas } = props;
  const [menuAnchorElement, setMenuAnchorElement] = useState<HTMLElement | null>(null);

  return (
    <div>
      <IconButton
        onClick={(e) => {
          setMenuAnchorElement(e.currentTarget);
          e.stopPropagation();
        }}
        size="small">
        <FontAwesomeIcon icon={faEllipsisV} />
      </IconButton>
      <Menu
        id={`ina-menu-${inas[0].id}`}
        anchorEl={menuAnchorElement}
        keepMounted
        open={!!menuAnchorElement}
        onClose={() => setMenuAnchorElement(null)}>
        <MenuItem
          onClick={() => {
            setMenuAnchorElement(null);
            props.setInasToReassign(inas);
          }}
          disabled={props.disableReassignment ? props.disableReassignment(inas[0]) : false}>
          Reassign
        </MenuItem>
        <MenuItem
          onClick={() => {
            setMenuAnchorElement(null);
            props.setInasToSetPriorityOf(inas);
          }}>
          Set priority
        </MenuItem>
      </Menu>
    </div>
  );
};
