import { gql, useMutation, useQuery } from "@apollo/client";
import { TextField, Chip } from "@mui/material";

import React from "react";
import { ScreenHeader } from "../common/ScreenHeader";
import CrudTable from "../common/CrudTable";
import { PdCourse, QueryOptions } from "./models";
import { debounce } from "lodash";
import { makeStyles } from "../makeStyles";
import HorizontalRuleRoundedIcon from "@mui/icons-material/HorizontalRuleRounded";
import { lighten } from "@mui/material";

import { EditPdCoursesDialog } from "./EditPdCoursesDialog";
import { ConfirmationDialog } from "common/ConfirmationDialog";
import { DialogContentText } from "@mui/material";

export const FetchPdCoursesQuery = gql`
  query FetchPdCourses($query: String, $after: Int, $count: Int, $sortOn: String, $sortDescending: Boolean) {
    pdCourses(query: $query, after: $after, count: $count, sortOn: $sortOn, sortDescending: $sortDescending) {
      id
      name
      shortName
      productCode
      isActive
      topicName
      topicShortName
    }
  }
`;

const DeletePdCourseMutation = gql`
  mutation deletePdCourse($pdCourseId: Int!) {
    pdCourses {
      delete(pdCourseId: $pdCourseId) {
        id
      }
    }
  }
`;

const GET_ASSIGNED_PD_COURSE_IDS = gql`
  query GetAssignedPdCourseIds {
    getAssignedPdCourseIds
  }
`;
interface AssignedPdCourseIdsResponse {
  getAssignedPdCourseIds: number[];
}
const useStyles = makeStyles()((theme) => ({
  forceScroll: {
    minHeight: "101vh"
  },
  searchBar: {
    width: "30em"
  },
  active: {
    backgroundColor: "rgba(185, 246, 202, 0.376)", // Change this to your desired background color
    color: "#008941" // Change this to your desired text color
  },
  inactive: {
    backgroundColor: lighten("#717073", 0.95),
    color: "#717073"
  },
  tableActions: {
    "& > *": {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1)
    }
  }
}));

const pageSize = 60;

const PdCoursesScreen: React.FunctionComponent = (props) => {
  const { classes } = useStyles();

  const onPageChange = (newPage: number) => {
    setPage(newPage);
    setQueryOptions({
      ...queryOptions,
      after: (newPage - 1) * pageSize
    });
  };

  const [page, setPage] = React.useState<number>(1);
  const [querySearchBarValue, setQuerySearchBarValue] = React.useState<string>("");
  const [queryOptions, setQueryOptions] = React.useState<QueryOptions>({
    query: "",
    after: 0,
    count: pageSize,
    sortOn: "",
    sortDescending: false
  });

  const debouncedSetQuery = debounce((value) => {
    onPageChange(1);
    setQueryOptions({ ...queryOptions, query: value, after: 0 });
  }, 500);

  const deleteQuery = (newQuery: string) => {
    setQuerySearchBarValue(newQuery);
    debouncedSetQuery(newQuery);
  };

  const queryData = useQuery<{ pdCourses: PdCourse[] }>(FetchPdCoursesQuery, {
    variables: queryOptions
  });
  const isAssignedData = useQuery<AssignedPdCourseIdsResponse>(GET_ASSIGNED_PD_COURSE_IDS);

  const [deletePdCourseMutate, deletePdCourseMutation] = useMutation<{ pdCourses: { delete: PdCourse } }, { pdCourseId: number }>(
    DeletePdCourseMutation,
    { refetchQueries: [FetchPdCoursesQuery] }
  );

  const courses = !queryData.loading ? queryData.data?.pdCourses! : [];
  return (
    <div className={classes.forceScroll}>
      <ScreenHeader title="Recommendations" />
      <CrudTable
        columnDefinitions={[
          {
            field: "name",
            headerName: "Name",
            flex: 40,
            sortable: true
          },
          {
            field: "productCode",
            headerName: "Product Code",
            flex: 10,
            sortable: true
          },
          {
            field: "shortName",
            headerName: "Short Name",
            flex: 30,
            sortable: true
          },
          {
            field: "topicName",
            headerName: "Topic Name",
            flex: 20,
            sortable: true
          },
          {
            field: "topicShortName",
            headerName: "Topic Short Name",
            flex: 20,
            sortable: true
          },
          {
            field: "isActive",
            headerName: "Active",
            renderCell: (params) =>
              params.row.isActive ? <Chip label="Active" className={classes.active} /> : <HorizontalRuleRoundedIcon color="disabled" />,
            flex: 10,
            sortable: true,
            align: "center", // Centering content
            headerAlign: "center" // Optionally, centering header content
          }
        ]}
        rows={courses}
        loading={queryData.loading}
        storageKey="Recommendations"
        sortModel={
          queryOptions.sortOn
            ? [
                {
                  field: queryOptions.sortOn,
                  sort: queryOptions.sortDescending ? "desc" : "asc"
                }
              ]
            : []
        }
        onSortModelChange={(newSortModel) => {
          onPageChange(1);

          if (newSortModel.length === 0 || !newSortModel[0].sort) {
            setQueryOptions({
              ...queryOptions,
              after: 0,
              sortOn: "",
              sortDescending: false
            });

            return;
          }

          setQueryOptions({
            ...queryOptions,
            after: 0,
            sortOn: newSortModel[0].field,
            sortDescending: newSortModel[0].sort === "desc"
          });
        }}
        headerActions={
          <div className={classes.searchBar}>
            <TextField label="Search" value={querySearchBarValue} onChange={(e) => deleteQuery(e.target.value)} fullWidth margin="none" />
          </div>
        }
        showPagination={true}
        onPageChange={onPageChange}
        currentPage={page}
        pageSize={pageSize}
        renderAddDialog={(props) => <EditPdCoursesDialog title="Add Recommendation" handleClose={props.onClose} confirmButtonText="Add" />}
        renderEditDialog={(id, props) => {
          return (
            <EditPdCoursesDialog
              course={courses.find((m) => m.id === id)}
              title="Edit Recommendation"
              handleClose={props.onClose}
              confirmButtonText="Save"
            />
          );
        }}
        disableDeleteReason={(c) =>
          isAssignedData.data?.getAssignedPdCourseIds.includes(c.id) ? "Recommendation is currently assigned" : ""
        }
        renderDeleteDialog={(id, props) => (
          <ConfirmationDialog
            title="Delete Recommendation"
            open={true}
            body={<DialogContentText>Are you sure you want to remove {courses.find((m) => m.id === id)?.shortName}?</DialogContentText>}
            cancel={props.onClose}
            loading={deletePdCourseMutation.loading}
            confirm={() => {
              console.log("Delete");
              deletePdCourseMutate({ variables: { pdCourseId: id } });

              props.onClose();
            }}
            confirmButtonText="Delete"
          />
        )}
      />
    </div>
  );
};

export default PdCoursesScreen;
