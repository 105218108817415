import React, { useRef, useState } from "react";
import { PracticeReview, PRBaseStatusCode, PRPhaseCode } from "practice-reviews";
import { Grid, Typography, TextField, Button, Chip, Tooltip } from "@mui/material";
import { TextField as FmuiTextField, Autocomplete as FmuiAutocomplete } from "formik-mui";
import ReadOnlyNotes from "../../chrome/ReadOnlyNotes";
import { Formik, Field as FormikField, FormikHelpers, FormikErrors } from "formik";
import { actionStyles, staticDataStyles } from "../../styles/common";
import { useCurrentUser, User, Permissions } from "users";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/client";
import { DateTime } from "luxon";
import RichTextEditor from "common/RichTextEditor";
import { useNotifications } from "notifications";
import PrsDatePickerField from "../../common/FormikFields/PrsDatePickerField";
import FinalizeScheduleDialog from "./FinalizeScheduleDialog";
import SchedulingHeader from "./SchedulingHeader";
import { SaveScheduleMutation } from "scheduling";
import { formatDate, formatDateTime, formatTime, standardTimeFormat } from "../../util/formats";
import { useUnsavedChanges } from "../../UnsavedChangesProvider";
import InlineStaticDataDisplay from "../../common/InlineStaticDataDisplay";
import { LoadingButton } from "@mui/lab";
import { makeStyles } from "makeStyles";
import StackedStaticDataDisplay from "common/StackedStaticDataDisplay";
import _ from "lodash";

const useStyles = makeStyles()((theme) => ({
  ...staticDataStyles(theme),
  ...actionStyles(theme),
  root: {
    "& > :not(:first-child)": {
      marginTop: theme.spacing(3)
    }
  },
  header: {
    display: "flex",
    alignItems: "center"
  },
  reviewerChip: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1)
  },
  textFieldWarning: {
    color: theme.palette.warning.dark
  }
}));

interface Props {
  practiceReview: PracticeReview;
}

interface FormValues {
  notes: string;
  leadReviewer: User | null;
  otherReviewers: User[];
  finalize: boolean;
  startDate: DateTime;
  startTime: string;
  endDate: DateTime;
}

const AvailableReviewersQuery = gql`
  query GetAvailableReviewers {
    availableReviewers {
      id
      name
      lastName
      firstName
    }
  }
`;

export const SchedulingAndReviewers: React.FunctionComponent<Props> = (props) => {
  const { classes } = useStyles();
  const notifications = useNotifications();
  const { unsavedChanges, changesSaved } = useUnsavedChanges();

  const { practiceReview } = props;
  const [confirmingFinalize, setConfirmingFinalize] = useState(false);

  const availableReviewersQuery = useQuery<{ availableReviewers: User[] }>(AvailableReviewersQuery);
  const availableReviewers = availableReviewersQuery.data?.availableReviewers || [];
  const orderedReviewers = _.orderBy(availableReviewers, [(r) => r.lastName, (r) => r.firstName]);

  const initialFormValues: FormValues = {
    notes: props.practiceReview.notesFromSchedulingPhase ?? "",
    leadReviewer: props.practiceReview.leadReviewer?.user ?? null,
    otherReviewers: props.practiceReview.otherReviewers.map((or) => or.user),
    startDate: DateTime.fromISO(props.practiceReview.startDate),
    startTime: formatTime(DateTime.fromISO(props.practiceReview.startDate).toString()),
    endDate: DateTime.fromISO(props.practiceReview.endDate),
    finalize: false
  };

  const scheduleValidation = (values: FormValues) => {
    const errors: FormikErrors<FormValues> = {};

    if (values.finalize) {
      if (values.leadReviewer == null) {
        errors.leadReviewer = "A lead reviewer is required to finalize a schedule.";
      }

      if (values.startDate == null) {
        errors.startDate = "A start date is required to finalize a schedule.";
      } else if (values.startDate < DateTime.local().startOf("day")) {
        errors.startDate = "Reviews cannot be scheduled in the past.";
      }
      if (values.startDate != null && values.endDate < values.startDate.startOf("day")) {
        errors.endDate = "End date must be after start date.";
      }
    }

    if (values.startTime) {
      const t = DateTime.fromFormat(values.startTime, standardTimeFormat);
      if (!t.isValid) errors.startTime = "Start times must be in the format HH:mm AM.";
    }

    return errors;
  };

  const buildDateStringFromDateAndTime = (date: DateTime, timeAsString: string) => {
    const time = DateTime.fromFormat(timeAsString, standardTimeFormat);
    if (time.isValid) return date.set({ hour: time.hour, minute: time.minute }).toString();
    else return date.set({ hour: 0, minute: 0 }).toString();
  };

  async function saveOrFinalize(values: FormValues, actions: FormikHelpers<FormValues>) {
    if (values.finalize) {
      setConfirmingFinalize(true);
    } else {
      const notesHtml = notesContentRetriever.current.getContentAsHtml();

      const result = await saveMutate({
        variables: {
          practiceReviewId: props.practiceReview.id,
          startDate: buildDateStringFromDateAndTime(values.startDate, values.startTime),
          endDate: values.endDate?.toISODate(),
          schedulingNotes: notesHtml,
          leadReviewer: values.leadReviewer ? values.leadReviewer.id : null,
          otherReviewers: values.otherReviewers.map((r) => r.id),
          finalize: values.finalize
        }
      });

      if (result.data?.practiceReview.saveSchedule?.id) {
        notifications.success("Schedule saved.");
        changesSaved();
      }
    }
    actions.setSubmitting(false);
  }

  const [saveMutate, saveMutation] = useMutation<
    { practiceReview: { saveSchedule: PracticeReview } },
    {
      practiceReviewId: number;
      schedulingNotes: string | null;
      startDate: string | null;
      endDate: string | null;
      leadReviewer: number | null;
      otherReviewers: number[] | null;
      finalize: boolean;
    }
  >(SaveScheduleMutation);

  const { userHasPermission } = useCurrentUser();
  const canEditScheduling = userHasPermission(Permissions.UpdateScheduling);
  const canEditReviewers = userHasPermission(Permissions.UpdateReviewers);

  const rescheduling = props.practiceReview.datesHaveBeenConfirmed;
  const scheduleLocked =
    props.practiceReview.phase.id !== PRPhaseCode.Scheduling || props.practiceReview.status.baseStatusCode !== PRBaseStatusCode.InProgress;

  const notesContentRetriever = useRef<{ getContentAsHtml: () => string | null }>({ getContentAsHtml: () => null });

  return (
    <div className={classes.root}>
      {!availableReviewersQuery.loading && !availableReviewersQuery.error && userHasPermission(Permissions.ViewSchedulingAndReviewers) && (
        <Formik initialValues={initialFormValues} onSubmit={saveOrFinalize} validate={scheduleValidation}>
          {(formikProps) => {
            const showSameLeadReviewerAsPreviousWarning =
              formikProps.values.leadReviewer &&
              formikProps.values.leadReviewer?.id === practiceReview.previousReview?.leadReviewer?.user.id &&
              practiceReview.prNumberPrefix !== practiceReview.previousReview.prNumberPrefix;

            const notesHaveBeenChanged = initialFormValues.notes.trim() === (notesContentRetriever.current.getContentAsHtml() ?? "").trim();

            return (
              <>
                <SchedulingHeader practiceReview={practiceReview} />

                <Grid container spacing={5}>
                  <Grid container item spacing={5}>
                    <Grid item xs={12} lg={4}>
                      <ReadOnlyNotes header="PR Notes" value={practiceReview.prNotes} plainText={true} />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <ReadOnlyNotes header="Notes from Estimate Time" value={practiceReview.estimate?.notes} />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <Typography variant="h3" sx={{ mb: 1 }}>
                        Scheduling Notes
                      </Typography>
                      <RichTextEditor
                        minHeight="12em"
                        html={formikProps.values.notes}
                        passContentRetriever={(getContentAsHtml) => {
                          notesContentRetriever.current = { getContentAsHtml };
                        }}
                        hideToolbar
                        readOnly={formikProps.isSubmitting || !canEditScheduling}
                        reportUnsavedChanges
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Typography variant="h3" sx={{ mb: 2 }}>
                      Review Dates
                    </Typography>
                    <Grid container item xs={12} spacing={2}>
                      <Grid item xs={12}>
                        <InlineStaticDataDisplay label="Estimated Hours" value={`${practiceReview.estimate.finalEstimate}h`} />
                      </Grid>
                      {scheduleLocked || !canEditScheduling ? (
                        <>
                          <Grid item xs={12} md={6}>
                            <StackedStaticDataDisplay label="Start Date" value={formatDateTime(practiceReview.startDate)} />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <StackedStaticDataDisplay label="End Date" value={formatDate(practiceReview.endDate)} />
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid container item spacing={2}>
                            <Grid item xs={12} md={6} xl={4}>
                              <FormikField
                                component={PrsDatePickerField}
                                name="startDate"
                                label="Start"
                                fullWidth
                                showTime={false}
                                minDate={DateTime.local()}
                                disabled={formikProps.isSubmitting}
                                onChange={(val: DateTime) => {
                                  formikProps.setFieldValue("startDate", val);
                                  formikProps.setFieldValue("endDate", val);
                                  unsavedChanges();
                                }}
                              />
                            </Grid>
                            <Grid item xs={12} md={6} xl={4}>
                              <FormikField
                                sx={{ maxWidth: "6em" }}
                                component={FmuiTextField}
                                name="startTime"
                                label="Start Time"
                                fullWidth
                                disabled={formikProps.isSubmitting}
                                onChange={(e: React.ChangeEvent<any>) => {
                                  formikProps.setFieldValue("startTime", e.target.value);
                                  unsavedChanges();
                                }}
                                value={formikProps.values.startTime}
                              />
                            </Grid>
                          </Grid>
                          <Grid item xs={12} md={6} xl={4}>
                            <FormikField
                              component={PrsDatePickerField}
                              name="endDate"
                              label="End"
                              fullWidth
                              minDate={DateTime.local()}
                              disabled={formikProps.isSubmitting}
                              onChange={(val: DateTime) => {
                                formikProps.setFieldValue("endDate", val);
                                unsavedChanges();
                              }}
                            />
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Typography variant="h3" sx={{ mb: 2 }}>
                      Reviewers
                    </Typography>
                    <Grid container spacing={5}>
                      {scheduleLocked || !canEditReviewers ? (
                        <Grid item xs={12} md={6}>
                          <Tooltip title="Lead Reviewer">
                            <Chip
                              color="primary"
                              variant="outlined"
                              className={classes.reviewerChip}
                              label={practiceReview.leadReviewer?.user.name ?? "Unknown"}
                            />
                          </Tooltip>
                          {practiceReview.otherReviewers.map((r) => (
                            <Chip key={r.user.id} className={classes.reviewerChip} variant="outlined" label={r.user.name} />
                          ))}
                        </Grid>
                      ) : (
                        <Grid item container xs={12} md={8} spacing={3}>
                          <Grid item xs={12}>
                            <FormikField
                              name="leadReviewer"
                              value={formikProps.values.leadReviewer}
                              component={FmuiAutocomplete}
                              disabled={formikProps.isSubmitting || scheduleLocked}
                              clearOnBlur
                              fullWidth
                              options={orderedReviewers}
                              getOptionLabel={(option: User) => option.name}
                              onChange={(e: React.ChangeEvent<{}>, val: User) => {
                                formikProps.setFieldValue("leadReviewer", val);
                                formikProps.setFieldValue(
                                  "otherReviewers",
                                  formikProps.values.otherReviewers.filter((user) => user.id !== val?.id)
                                );
                                unsavedChanges();
                              }}
                              getOptionSelected={(opt: User, val: User) => val.id === opt.id}
                              renderInput={(params: any) => (
                                <TextField
                                  FormHelperTextProps={{
                                    className: showSameLeadReviewerAsPreviousWarning ? classes.textFieldWarning : ""
                                  }}
                                  helperText={
                                    showSameLeadReviewerAsPreviousWarning
                                      ? "Same lead reviewer as firm's previous review."
                                      : formikProps.errors.leadReviewer
                                  }
                                  label="Lead Reviewer"
                                  {...params}
                                  variant="outlined"
                                  error={!!formikProps.errors.leadReviewer}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormikField
                              name="otherReviewers"
                              component={FmuiAutocomplete}
                              value={formikProps.values.otherReviewers}
                              clearOnBlur
                              multiple
                              fullWidth
                              options={orderedReviewers}
                              disabled={formikProps.isSubmitting || scheduleLocked}
                              onChange={(e: React.ChangeEvent<{}>, val: User[]) => {
                                formikProps.setFieldValue("otherReviewers", val);
                                unsavedChanges();
                              }}
                              getOptionLabel={(option: User) => option.name}
                              getOptionSelected={(opt: User, val: User) => val.id === opt.id}
                              getOptionDisabled={(opt: User) => formikProps.values.leadReviewer?.id === opt.id}
                              renderInput={(params: any) => <TextField label="Other Reviewers" {...params} />}
                            />
                          </Grid>
                        </Grid>
                      )}
                      <Grid item xs={12} md={4}>
                        {practiceReview.previousReview ? (
                          <>
                            <Typography variant="body1" className={classes.label}>
                              {`Previous Reviewer${practiceReview.previousReview!.otherReviewers.length > 0 ? "s" : ""} (${
                                practiceReview.previousReview!.reviewYear
                              })`}
                            </Typography>
                            <Tooltip title="Lead Reviewer">
                              <Chip
                                color="primary"
                                variant="outlined"
                                className={classes.reviewerChip}
                                label={practiceReview.previousReview?.leadReviewer?.user.name ?? "Unknown"}
                              />
                            </Tooltip>
                            {practiceReview.previousReview?.otherReviewers.map((r) => (
                              <Chip className={classes.reviewerChip} variant="outlined" label={r.user.name} />
                            ))}
                          </>
                        ) : (
                          <>
                            <Typography variant="body1" className={classes.label}>
                              Previous Reviewers
                            </Typography>
                            <Typography>No previous reviews for firm.</Typography>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container justifyContent="flex-end" item xs={12} lg={12} spacing={2}>
                    <Grid item className={classes.actions}>
                      {(canEditScheduling || canEditReviewers) && (
                        <LoadingButton
                          variant="outlined"
                          loading={saveMutation.loading}
                          disabled={formikProps.isSubmitting || (scheduleLocked && notesHaveBeenChanged)}
                          onClick={async () => {
                            formikProps.setFieldValue("finalize", false, false);
                            //by using Promise.resolve(), we ensure the setFieldValue has applied
                            //before the submitForm's Validation is applied. https://github.com/formium/formik/issues/529#issuecomment-400832225
                            await Promise.resolve();
                            formikProps.submitForm();
                          }}>
                          {scheduleLocked ? "Save notes" : "Save"}
                        </LoadingButton>
                      )}
                      {canEditScheduling && (
                        <Button
                          color="primary"
                          variant="outlined"
                          disabled={formikProps.isSubmitting || scheduleLocked}
                          onClick={async () => {
                            formikProps.setFieldValue("finalize", true, false);
                            //by using Promise.resolve(), we ensure the setFieldValue has applied
                            //before the submitForm's Validation is applied. https://github.com/formium/formik/issues/529#issuecomment-400832225
                            await Promise.resolve();
                            formikProps.submitForm();
                          }}>
                          {rescheduling ? "Reschedule" : "Finalize Schedule"}
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                <FinalizeScheduleDialog
                  isReschedule={rescheduling}
                  isEstimateFinalized={practiceReview.estimate.isFinalized}
                  practiceReviewId={practiceReview.id}
                  startDate={buildDateStringFromDateAndTime(formikProps.values.startDate, formikProps.values.startTime)}
                  endDate={formikProps.values.endDate?.toISODate()}
                  schedulingNotes={notesContentRetriever.current.getContentAsHtml()}
                  leadReviewer={formikProps.values.leadReviewer ? formikProps.values.leadReviewer.id : null}
                  otherReviewers={formikProps.values.otherReviewers.map((r) => r.id)}
                  open={confirmingFinalize}
                  handleClose={() => setConfirmingFinalize(false)}
                />
              </>
            );
          }}
        </Formik>
      )}
    </div>
  );
};
