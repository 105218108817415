import { useQuery } from "@apollo/client";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Select,
  Stack,
  Switch,
  TextField,
  Typography
} from "@mui/material";
import { GridSortCellParams, GridColDef } from "@mui/x-data-grid-pro";
import { ScreenHeader } from "common/ScreenHeader";
import { DateTime } from "luxon";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Exemption, ExemptionStatus } from ".";
import { DataGridWithHeader } from "common/DataGridWithHeader";
import { makeStyles } from "makeStyles";
import { datagridStyles } from "styles/common";
import { standardDateFormat } from "util/formats";
import { PrsDatePicker } from "common/PrsDatePicker";
import { useCurrentUser, Permissions } from "users";
import { FetchExemptionsQuery } from "./queries";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { getRouteForPracticeReview, PracticeReviewTabs } from "../practice-reviews/PracticeReviewScreen";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons/faArrowRight";
import { EndExemptionDialog } from "./EndExemptionDialog";
import { PracticeReview } from "practice-reviews";

const useStyles = makeStyles()((theme) => ({
  ...datagridStyles(theme)
}));

const ActiveExemptionsScreen: React.FunctionComponent = () => {
  const { classes } = useStyles();
  const { userHasPermission } = useCurrentUser();
  const history = useHistory();

  const [endingExemptionForPr, setEndingExemptionForPr] = useState<PracticeReview | null>(null);

  const emptyFilters = {
    reviewType: "Any",
    status: null,
    anniversaryMonth: -1,
    fromStartedOnDate: null,
    toStartedOnDate: null,
    fromEndedOnDate: null,
    toEndedOnDate: null,
    prNumber: "",
    ended: false
  };
  const [filters, setFilters] = useState<{
    reviewType: string;
    status: ExemptionStatus | null;
    anniversaryMonth: number;
    fromStartedOnDate: DateTime | null;
    toStartedOnDate: DateTime | null;
    fromEndedOnDate: DateTime | null;
    toEndedOnDate: DateTime | null;
    prNumber: string;
    ended: boolean;
  }>(emptyFilters);

  const exemptionQuery = useQuery<{ exemptions: Exemption[] }, { ended: boolean }>(FetchExemptionsQuery, {
    variables: { ended: filters.ended }
  });
  const exemptions = exemptionQuery.data?.exemptions ?? [];
  const filteredExemptions = exemptions.filter((exemption) => {
    // Note: active vs. ended exemptions are filtered server-side
    const matchReviewTypeFilter = filters.reviewType === "Any" || exemption.practiceReview.reviewType === filters.reviewType;
    const startedOnDate = exemption.startedOn ? DateTime.fromISO(exemption.startedOn) : null;
    const endedOnDate = exemption.endedOn ? DateTime.fromISO(exemption.endedOn) : null;

    const matchesStatusFilter = filters.status === null || exemption.status === filters.status;
    const matchesMonthFilter =
      filters.anniversaryMonth === -1 || (startedOnDate !== null && startedOnDate.month === filters.anniversaryMonth);
    const matchesStartDateFilter =
      (filters.fromStartedOnDate === null || (startedOnDate !== null && startedOnDate >= filters.fromStartedOnDate)) &&
      (filters.toStartedOnDate === null || (startedOnDate && startedOnDate <= filters.toStartedOnDate));
    const matchesEndDateFilter =
      (filters.fromEndedOnDate === null || (endedOnDate !== null && endedOnDate >= filters.fromEndedOnDate)) &&
      (filters.toEndedOnDate === null || (endedOnDate && endedOnDate <= filters.toEndedOnDate));
    const matchesPrNumberFilter = filters.prNumber.trim() === "" || exemption.practiceReview.prNumber.indexOf(filters.prNumber) !== -1;

    return (
      matchReviewTypeFilter &&
      matchesStatusFilter &&
      matchesMonthFilter &&
      matchesStartDateFilter &&
      matchesEndDateFilter &&
      matchesPrNumberFilter
    );
  });

  return (
    <>
      <Helmet>
        <title>Exemptions - PRS Online</title>
      </Helmet>

      <ScreenHeader title="Exemptions" />

      <Paper sx={{ p: 2 }}>
        <Stack spacing={3}>
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography variant="h4">Filters</Typography>
            <FormControl margin="none" size="small" sx={{ width: "14em" }}>
              <InputLabel id="exemption-type-label">Review Type</InputLabel>
              <Select
                labelId="exemption-type-label"
                label="Review Type"
                value={filters.reviewType}
                onChange={(e) => setFilters({ ...filters, reviewType: e.target.value })}>
                <MenuItem value={"Any"}>Any</MenuItem>
                <MenuItem value={"Assurance"}>Assurance</MenuItem>
                <MenuItem value={"Non-assurance"}>Non-Assurance</MenuItem>
              </Select>
            </FormControl>
            <FormControl margin="none" size="small" sx={{ width: "14em" }}>
              <InputLabel id="exemption-status-label">Exemption Status</InputLabel>
              <Select
                labelId="exemption-status-label"
                label="Exemption Status"
                value={filters.status ?? "Any"}
                onChange={(e) => setFilters({ ...filters, status: e.target.value !== "Any" ? (e.target.value as ExemptionStatus) : null })}>
                <MenuItem value={"Any"}>Any</MenuItem> <MenuItem value={ExemptionStatus.Pending}>Pending</MenuItem>
                <MenuItem value={ExemptionStatus.PendingRenewal}>Pending Renewal</MenuItem>
                <MenuItem value={ExemptionStatus.Exempt}>Exempt</MenuItem>
              </Select>
            </FormControl>
            <FormControl margin="none" size="small" sx={{ width: "14em" }}>
              <InputLabel id="anniversary-month-label">Anniversary Month</InputLabel>
              <Select
                labelId="anniversary-month-label"
                label="Anniversary Month"
                value={filters.anniversaryMonth}
                onChange={(e) => setFilters({ ...filters, anniversaryMonth: Number(e.target.value) })}>
                <MenuItem value={-1}>Any</MenuItem> <MenuItem value={1}>January</MenuItem>
                <MenuItem value={2}>February</MenuItem> <MenuItem value={3}>March</MenuItem>
                <MenuItem value={4}>April</MenuItem> <MenuItem value={5}>May</MenuItem> <MenuItem value={6}>June</MenuItem>
                <MenuItem value={7}>July</MenuItem> <MenuItem value={8}>August</MenuItem> <MenuItem value={9}>September</MenuItem>
                <MenuItem value={10}>October</MenuItem> <MenuItem value={11}>November</MenuItem> <MenuItem value={12}>December</MenuItem>
              </Select>
            </FormControl>

            {filters.ended ? (
              <>
                <PrsDatePicker
                  setValue={(newDate) => setFilters({ ...filters, fromEndedOnDate: newDate?.startOf("day") ?? null })}
                  value={filters.fromEndedOnDate}
                  label="From End Date"
                  allowNonWorkingDays
                />

                <PrsDatePicker
                  setValue={(newDate) => setFilters({ ...filters, toEndedOnDate: newDate?.startOf("day") ?? null })}
                  value={filters.toEndedOnDate}
                  label="To End Date"
                  allowNonWorkingDays
                />
              </>
            ) : (
              <>
                <PrsDatePicker
                  setValue={(newDate) => setFilters({ ...filters, fromStartedOnDate: newDate?.startOf("day") ?? null })}
                  value={filters.fromStartedOnDate}
                  label="From Start Date"
                  allowNonWorkingDays
                />

                <PrsDatePicker
                  setValue={(newDate) => setFilters({ ...filters, toStartedOnDate: newDate?.startOf("day") ?? null })}
                  value={filters.toStartedOnDate}
                  label="To Start Date"
                  allowNonWorkingDays
                />
              </>
            )}

            <TextField value={filters.prNumber} label="PR No." onChange={(e) => setFilters({ ...filters, prNumber: e.target.value })} />

            <FormControlLabel
              control={
                <Switch
                  checked={filters.ended}
                  onClick={() =>
                    setFilters({
                      ...filters,
                      ended: !filters.ended,
                      fromStartedOnDate: null,
                      toStartedOnDate: null,
                      fromEndedOnDate: null,
                      toEndedOnDate: null
                    })
                  }
                />
              }
              label="Show Ended Exemptions"
            />

            <Box sx={{ flex: 1 }} />

            <Button variant="outlined" onClick={() => setFilters(emptyFilters)}>
              Clear
            </Button>
          </Stack>

          <DataGridWithHeader
            itemType="Active exemptions"
            columns={
              [
                {
                  field: "entityNumber",
                  headerName: "Entity No.",
                  valueGetter: ({ row }) => row.practiceReview.firm.entityNumber,
                  width: 80
                },
                {
                  field: "firmName",
                  headerName: "Firm",
                  valueGetter: ({ row }) => row.practiceReview.firm.name,
                  renderCell: ({ row }) => (
                    <Link to={getRouteForPracticeReview(row.practiceReview, PracticeReviewTabs.Exemption)} component={RouterLink}>
                      {row.practiceReview.firm.name}
                    </Link>
                  ),
                  flex: 2
                },
                {
                  field: "reviewType",
                  headerName: "Type",
                  width: 110,
                  valueGetter: ({ row }) => row.practiceReview.reviewType
                },
                {
                  field: "status",
                  headerName: "Exemption Status",
                  valueGetter: ({ row }) => (row.status === ExemptionStatus.PendingRenewal ? "Pending Renewal" : row.status.toString()),
                  width: 140,
                  hide: filters.ended
                },
                {
                  field: "startedReason",
                  headerName: "Reason Started",
                  flex: 1
                },
                {
                  field: "exemptionLetterSent",
                  headerName: "Letter Sent",
                  type: "date",
                  valueGetter: ({ row }) => (row.exemptionLetterSent ? row.exemptionLetterSent! : null),
                  valueFormatter: (params) => (params.value ? DateTime.fromISO(params.value).toFormat(standardDateFormat) : "--"),
                  width: 90,
                  hide: filters.ended
                },
                {
                  field: "exemptionLetterAcknowledged",
                  headerName: "Letter Acknowledged",
                  headerClassName: classes.wrapHeader,
                  type: "date",
                  valueGetter: ({ row }) => (row.exemptionLetterAcknowledged ? row.exemptionLetterAcknowledged! : null),
                  valueFormatter: (params) => (params.value ? DateTime.fromISO(params.value).toFormat(standardDateFormat) : "--"),
                  width: 110,
                  hide: filters.ended
                },
                {
                  field: "startedOn",
                  headerName: "Start Date",
                  type: "date",
                  valueGetter: ({ row }) => (row.startedOn ? row.startedOn! : null),
                  valueFormatter: (params) => (params.value ? DateTime.fromISO(params.value).toFormat(standardDateFormat) : "--"),
                  width: 90
                },
                {
                  field: "approvedBy",
                  headerName: "Approved By",
                  valueGetter: ({ row }) => row.approvedByUser?.name ?? "--",
                  width: 120,
                  hide: filters.ended
                },
                {
                  field: "currentActivity",
                  headerName: "Current Activity",
                  valueGetter: ({ row }) => row.practiceReview.inas.filter((ina) => !ina.isComplete)[0]?.type.friendlyName ?? "--",
                  flex: 1,
                  hide: filters.ended
                },
                {
                  field: "endedReason",
                  headerName: "Reason Ended",
                  flex: 1,
                  hide: !filters.ended
                },
                {
                  field: "endedOn",
                  headerName: "End Date",
                  type: "date",
                  valueGetter: ({ row }) => (row.endedOn ? row.endedOn! : null),
                  valueFormatter: (params) => (params.value ? DateTime.fromISO(params.value).toFormat(standardDateFormat) : "--"),
                  width: 90,
                  hide: !filters.ended
                },
                {
                  field: "endedBy",
                  headerName: "Ended By",
                  valueGetter: ({ row }) => row.endedByUser?.name ?? "--",
                  width: 120,
                  hide: !filters.ended
                },
                {
                  field: "prNumber",
                  headerName: "Last PR No.",
                  width: 100,
                  valueGetter: ({ row }) => row.practiceReview.prNumber,
                  disableColumnMenu: true,
                  sortComparator: (v1, v2, param1: GridSortCellParams, param2: GridSortCellParams) =>
                    param1.api
                      .getRow(param1.id)!
                      .practiceReview.prNumber.localeCompare(param2.api.getRow(param2.id)!.practiceReview.prNumber)
                },
                {
                  field: "actions",
                  headerName: "Actions",
                  width: 180,
                  renderCell: ({ row }) => {
                    const exemption = row;

                    return (
                      <Stack direction="row" spacing={1}>
                        {!filters.ended && userHasPermission(Permissions.ExemptionConfirm) && (
                          <Button
                            variant="outlined"
                            size="small"
                            sx={{ pt: 0, pb: 0 }}
                            onClick={() => setEndingExemptionForPr(exemption.practiceReview)}>
                            End Exemption
                          </Button>
                        )}
                        <IconButton
                          color="primary"
                          size="small"
                          title="Go"
                          onClick={() => history.push(getRouteForPracticeReview(exemption.practiceReview, PracticeReviewTabs.Exemption))}>
                          <FontAwesomeIcon icon={faArrowRight} />
                        </IconButton>
                      </Stack>
                    );
                  }
                }
              ] as GridColDef<Exemption>[]
            }
            rows={filteredExemptions}
            displayWithoutContainer
            loading={exemptionQuery.loading}
            noDataMessage="No exemptions."
          />
        </Stack>
      </Paper>

      {endingExemptionForPr && <EndExemptionDialog practiceReview={endingExemptionForPr} onClose={() => setEndingExemptionForPr(null)} />}
    </>
  );
};

export default ActiveExemptionsScreen;
