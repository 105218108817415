import React from "react";
import { useCalendar } from "util/CalendarProvider";
import { DatePicker, DateTimePicker } from "@mui/lab";
import { DateTime } from "luxon";
import { formatDate, standardDateFormat, standardDateTimeFormat } from "util/formats";
import { TextField, TextFieldProps } from "@mui/material";
import { StatutoryHoliday } from "../stat-holidays";

interface Props {
  value: DateTime | null;
  setValue: (newValue: DateTime | null) => void;
  pickerProps?: Partial<React.ComponentProps<typeof DatePicker>>;
  required?: boolean;
  allowNonWorkingDays?: boolean;
  fullWidth?: boolean;
  label?: string;
  showTime?: boolean;
  errorText?: string;
  onBlur?: () => any;
  margin?: "dense" | "normal" | "none";
}

export function dateDisabled(date: DateTime, disabledDates: StatutoryHoliday[], allowNonWorkingDays: boolean | undefined) {
  if (disabledDates && !(allowNonWorkingDays ?? false)) {
    return disabledDates.some((disabledDate) => disabledDate.holidayDate === date.startOf("day").toISODate()) /*||
      date.weekday === 6 || // GB 2023-02-24: A blanket allow of Saturdays and Sundays for now as it just causes Kirsten difficulties trying to test things. We might never turn this back on, but if we do, we should feature flag it.
      date.weekday === 7 */;
  }
  return false;
}

export const PrsDatePicker = (props: Props) => {
  const { value, setValue } = props;
  const { holidays } = useCalendar();

  const originalValue = React.useRef<DateTime | null>(props.value);
  // We use formatDate to only compare dates, not times
  const disabledDates =
    holidays?.filter((h) => formatDate(h.holidayDate.toString()) !== formatDate(originalValue.current?.toString() ?? null)) ?? [];

  const renderInput = (inputProps: TextFieldProps) => {
    return (
      <TextField
        {...inputProps}
        required={props.required}
        onBlur={props.onBlur}
        className={props.pickerProps?.className}
        fullWidth={props.fullWidth}
        error={Boolean(props.errorText)}
        helperText={props.errorText}
        margin={props.margin}
      />
    );
  };

  return props.showTime ? (
    <DateTimePicker
      {...(props.pickerProps as any)}
      renderInput={renderInput}
      inputFormat={standardDateTimeFormat}
      allowSameDateSelection
      onChange={setValue}
      shouldDisableDate={(day: DateTime) => dateDisabled(day, disabledDates, props.allowNonWorkingDays)}
      value={value?.toString() ?? null}
    />
  ) : (
    <DatePicker
      {...(props.pickerProps as any)}
      label={props.label}
      renderInput={renderInput}
      inputFormat={standardDateFormat}
      allowSameDateSelection
      onChange={setValue}
      shouldDisableDate={(day: DateTime) => dateDisabled(day, disabledDates, props.allowNonWorkingDays)}
      value={value?.toString() ?? null}
      clearable
    />
  );
};
