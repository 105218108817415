import React from "react";
import { PracticeReview } from "practice-reviews";
import { makeStyles } from "../../makeStyles";
import { YesNoIcon } from "../../common/YesNoIcon";
import { summaryChecklistStyles } from "../../styles/common";
import { Typography } from "@mui/material";
import { SummaryTabHeader } from "./SummaryTabHeader";
import { useCurrentUser } from "../../users";

interface Props {
  practiceReview: PracticeReview;
  userIsNonLeadReviewer: boolean;
}

export const TimeEntryPreview: React.FunctionComponent<Props> = (props) => {
  const useStyles = makeStyles()((theme) => ({
    ...summaryChecklistStyles(theme)
  }));
  const { classes } = useStyles();
  const { user } = useCurrentUser();

  const { practiceReview, userIsNonLeadReviewer } = props;

  const timeEntries = !userIsNonLeadReviewer
    ? practiceReview.timeEntries
    : practiceReview.timeEntries.filter((te) => te.user.id === user.id);

  return (
    <div>
      <SummaryTabHeader header="Time Entry" />
      <div className={classes.checklist}>
        <div className={classes.checklistRow}>
          <div className={classes.checklistItemState}>
            <YesNoIcon yes={timeEntries.length !== 0} />
          </div>
          <div className={classes.checklistItem}>
            <Typography variant="body1">{!props.userIsNonLeadReviewer ? "Total Hours" : "My Hours"}</Typography>
          </div>
          <Typography variant="body1" className={classes.checklistItemDetail}>
            {`${timeEntries.reduce((total, entry) => total + entry.enteredHours, 0)}h`}
          </Typography>
        </div>
      </div>
    </div>
  );
};
