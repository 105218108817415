import { useState, useEffect } from "react";
import { Alert, Box, Card, Collapse, LinearProgress, Stack, Typography, DialogContentText, Link } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {
  BinderJob,
  QueueJobTypeCode,
  DismissBinderJobMutation,
  RequeueBinderJobMutation,
  FetchBinderJobsQuery,
  jobIsStale,
  FetchBinderJobByMeetingIdQuery,
  JobProgress
} from ".";
import { DateTime } from "luxon";
import { formatDate } from "util/formats";
import { makeStyles } from "../makeStyles";
import { ApolloClient, useMutation } from "@apollo/client";
import { useNotifications } from "../notifications";
import { ConfirmationDialog } from "common/ConfirmationDialog";
import { useInterval } from "util/utilities";
import { SignalRPubSub } from "../util/SignalRPubSub";

interface Props {
  job: BinderJob;
}

const useStyles = makeStyles()((theme) => ({
  binderCard: {
    position: "relative"
  },
  dismissButton: {
    position: "absolute",
    right: theme.spacing(0.5),
    top: theme.spacing(0.5)
  },
  requeueButton: {
    position: "absolute",
    right: theme.spacing(10),
    top: theme.spacing(0.5)
  }
}));

export function subscribeToDocumentGenerationProgress(signalrPubSub: SignalRPubSub, apolloClient: ApolloClient<object>, loading: boolean) {
  if (!signalrPubSub || !signalrPubSub.subscribe) {
    return;
  }

  signalrPubSub.subscribe("documentGenerationProgress", (messageContents) => {
    if (loading) {
      // ignore messages that are received while loading or refetching the initial query
      return;
    }

    const jobProgress = JSON.parse(messageContents as any) as JobProgress;
    const cacheId = `JobProgress:${jobProgress.id}`;
    var cachedJobProgress = (apolloClient.cache as any).data.data[cacheId];

    if (cachedJobProgress !== undefined) {
      // only bother to update if this has more current information (never want them to see it going backwards because of a race condition)
      if (jobProgress.stepsCompleted >= cachedJobProgress.stepsCompleted) {
        apolloClient.cache.modify({
          id: cacheId,
          fields: {
            progressMessage() {
              return jobProgress.progressMessage;
            },
            stepsCompleted() {
              return jobProgress.stepsCompleted;
            },
            stepsNotStarted() {
              return jobProgress.stepsNotStarted;
            },
            stepsRunning() {
              return jobProgress.stepsRunning;
            },
            stepsWithErrors() {
              return jobProgress.stepsWithErrors;
            },
            totalSteps() {
              return jobProgress.totalSteps;
            },
            errorMessages() {
              return jobProgress.errorMessages;
            },
            resultData() {
              return jobProgress.resultData;
            },
            allStepsComplete() {
              return jobProgress.allStepsComplete;
            },
            lastUpdated() {
              return jobProgress.lastUpdated;
            }
          }
        });
      }
    }
  });
}

const BinderJobCard = (props: Props) => {
  const { classes } = useStyles();
  const notifications = useNotifications();
  const [confirmingDismissBinderJob, setConfirmingDismissBinderJob] = useState<number | null>(null);
  const [confirmingRequeueBinderJob, setConfirmingRequeueBinderJob] = useState<number | null>(null);
  const [binderErrorJobId, setBinderErrorJobId] = useState<number | null>(null);
  const [startedAgo, setStartedAgo] = useState<string | null>(null);
  const [stale, setStale] = useState<boolean | null>(null);

  function refreshTimedElements(job: BinderJob) {
    setStartedAgo(DateTime.fromISO(job.dateCreated).toRelative());
    setStale(jobIsStale(job));
  }

  useInterval(() => {
    refreshTimedElements(props.job);
  }, 1000);

  const [dismissMutate, dismissMutation] = useMutation<{ queueJob: { dismiss: BinderJob } }, { queueJobId: number }>(
    DismissBinderJobMutation,
    {
      refetchQueries: [
        {
          query: FetchBinderJobsQuery
        },
        { query: FetchBinderJobByMeetingIdQuery, variables: { meetingId: props.job.committeeMeetingId } }
      ]
    }
  );

  async function dismissBinderJob(queueJobId: number) {
    await dismissMutate({ variables: { queueJobId } });
    setConfirmingDismissBinderJob(null);
  }

  const [requeueMutate, requeueMutation] = useMutation<{ queueJob: { requeue: BinderJob } }, { queueJobId: number }>(
    RequeueBinderJobMutation,
    {
      refetchQueries: [
        {
          query: FetchBinderJobsQuery
        },
        { query: FetchBinderJobByMeetingIdQuery, variables: { meetingId: props.job.committeeMeetingId } }
      ]
    }
  );

  async function requeueBinderJob(queueJobId: number) {
    const result = await requeueMutate({ variables: { queueJobId } });
    setConfirmingRequeueBinderJob(null);
    if (result.data?.queueJob.requeue.id) {
      notifications.success("Re-queued binder job.");
    }
  }

  const completedProgress = (props.job.progress.stepsCompleted / props.job.progress.totalSteps) * 100;
  const errorsArray = (props.job.progress.errorMessages ?? "")
    .replace(/\r\n\r\n/gm, "\n") // multiple lines snuck in
    .trim()
    .split("\n");

  const stepsToRestart = props.job.progress.stepsNotStarted + props.job.progress.stepsRunning;

  // you can hide a job that is finished or stale
  const canDismiss = props.job.isComplete || props.job.progress.allStepsComplete || stale;

  // if it's a generate and it's stale or has errors, it can also be retried
  const canRetry =
    canDismiss && props.job.queueJobTypeCode === QueueJobTypeCode.GenerateBinder && (stale || props.job.progress.stepsWithErrors > 0);

  return (
    <>
      <Card key={props.job.id} sx={{ padding: 2 }} className={classes.binderCard}>
        <Typography variant="h3">
          {props.job.queueJobTypeCode === QueueJobTypeCode.GenerateBinder
            ? "Generating binder for "
            : props.job.queueJobTypeCode === QueueJobTypeCode.AssembleBinder
            ? "Assembling binder for "
            : ""}
          {formatDate(props.job.committeeMeeting.meetingDate)} - {props.job.committeeMeeting.location}
        </Typography>
        {canRetry && (
          <LoadingButton
            className={classes.requeueButton}
            color="primary"
            variant="text"
            onClick={() => setConfirmingRequeueBinderJob(props.job.id)}
            loading={requeueMutation.loading}
            title="Send incomplete and/or failed steps for processing again">
            Retry
          </LoadingButton>
        )}
        {canDismiss && (
          <LoadingButton
            className={classes.dismissButton}
            color="primary"
            variant="text"
            onClick={() => setConfirmingDismissBinderJob(props.job.id)}
            loading={dismissMutation.loading}
            title="Hide this binder job from display">
            Dismiss
          </LoadingButton>
        )}

        <Typography variant="caption">Started {startedAgo}</Typography>
        <Stack direction="row" alignItems="center" sx={{ mt: 2 }}>
          <Box sx={{ flexGrow: 1, mr: 1 }}>
            <LinearProgress variant="buffer" value={completedProgress} valueBuffer={0} />
          </Box>
          <Typography sx={{ minWidth: "2em" }} variant="body2" color="text.secondary">{`${Math.round(completedProgress)}%`}</Typography>
        </Stack>
        {props.job.progress && (
          <div>
            <Typography variant="caption">
              <span>
                {props.job.progress.stepsCompleted + props.job.progress.stepsRunning > 0 &&
                  props.job.progress.stepsCompleted < props.job.progress.totalSteps &&
                  `Step ${props.job.progress.stepsCompleted + 1} of ${props.job.progress.totalSteps}: `}
                {props.job.progress?.progressMessage}
                {props.job.progress.stepsCompleted + props.job.progress.stepsRunning > 0 &&
                  props.job.progress.stepsCompleted < props.job.progress.totalSteps &&
                  "..."}
              </span>
            </Typography>
            {props.job.progress.errorMessages && (
              <Alert color="error" variant={binderErrorJobId === props.job.id ? "outlined" : "standard"} sx={{ margin: 1 }}>
                <Box
                  sx={{ cursor: "pointer" }}
                  onClick={() => setBinderErrorJobId(binderErrorJobId === props.job.id ? null : props.job.id)}>
                  {`${errorsArray.length} error${errorsArray.length > 1 ? "s were" : " was"} encountered during ${
                    props.job.queueJobTypeCode === QueueJobTypeCode.GenerateBinder
                      ? "generation"
                      : props.job.queueJobTypeCode === QueueJobTypeCode.AssembleBinder
                      ? "assembly"
                      : "job"
                  }`}
                </Box>

                <Collapse in={binderErrorJobId === props.job.id}>
                  <Typography variant="body2" sx={{ marginTop: 1 }}>
                    {errorsArray.map((error) => {
                      return (
                        <Alert sx={{ margin: 2 }} color="error">
                          {error}
                        </Alert>
                      );
                    })}
                  </Typography>
                </Collapse>
              </Alert>
            )}
          </div>
        )}
        {(props.job.isComplete || props.job.progress.stepsCompleted === props.job.progress.totalSteps) && // because signalR will only update progress, not job
          props.job.queueJobTypeCode === QueueJobTypeCode.AssembleBinder &&
          props.job.progress.resultData && (
            <Alert color="info" variant="outlined" sx={{ margin: 1 }}>
              {`Created file: `}
              <Link href={`${props.job.committeeMeeting.sharePointUrl}/${props.job.progress.resultData}`}>
                {props.job.progress.resultData}
              </Link>
            </Alert>
          )}
      </Card>
      {confirmingDismissBinderJob && (
        <ConfirmationDialog
          open={true}
          title="Dismiss Binder Job?"
          body={
            <DialogContentText>
              This will hide this job from <strong>all</strong> users! Are you sure?
            </DialogContentText>
          }
          confirm={() => dismissBinderJob(confirmingDismissBinderJob)}
          cancel={() => setConfirmingDismissBinderJob(null)}
          loading={dismissMutation.loading}
        />
      )}
      {confirmingRequeueBinderJob && (
        <ConfirmationDialog
          open={true}
          title="Retry Binder Job?"
          noDanger
          body={
            <DialogContentText>
              {props.job.progress.stepsWithErrors ? (
                <p>
                  {`${props.job.progress.stepsWithErrors}`} step{props.job.progress.stepsWithErrors > 1 ? "s" : ""} with errors will be
                  retried.
                </p>
              ) : null}
              {stepsToRestart ? (
                <p>
                  {`${stepsToRestart}`} step{stepsToRestart > 1 ? "s" : ""} will be restarted.
                </p>
              ) : null}
              <p>Continue?</p>
            </DialogContentText>
          }
          confirm={() => requeueBinderJob(confirmingRequeueBinderJob)}
          cancel={() => setConfirmingRequeueBinderJob(null)}
          loading={requeueMutation.loading}
        />
      )}
    </>
  );
};

export default BinderJobCard;
