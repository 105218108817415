import { PdCourse } from "../pd-courses/models";
import { HistoryItem, PracticeReview } from "../practice-reviews";
import { User } from "../users";

export enum DecisionTypeCode {
  Reviewer = "Reviewer",
  Manager = "Manager",
  Director = "Director",
  Committee = "Committee"
}

export interface DecisionType {
  id: number;
  typeCode: DecisionTypeCode;
  name: string;
}

export interface Decision extends HistoryItem {
  decisionType: DecisionType;

  cpabParticipating: YesNoNa | null;
  cpdCompliance: YesNoNa | null;
  cpdComplianceExplanation: string | null;
  pliCompliance: YesNoNa | null;
  pliComplianceExplanation: string | null;

  complianceZone: ComplianceZone | null;
  followUpDate: string | null;
  nonComplyZoneNote: string | null;

  assignedPdCourses: AssignedPdCourse[];
  directedPdDueDate: string | null;

  motionStandard: MotionParagraph | null;
  motionPd: MotionParagraph | null;
  motionCpab: MotionParagraph | null;
  motionCic: MotionParagraph | null;

  descriptionOfIntendedAction: string | null;
  additionalCommentsForMinutes: string | null;
  additionalCommentsForDecisionLetter: string | null;

  reviewerMemo: string | null;
  managerMemo: string | null;
  directorsHighlights: string | null;
  directorsSummaryOfFirmResponse: string | null;

  isComplete: boolean;

  signedOff: boolean;
  signedOffDate: string | null;
  signedOffByUser: User | null;

  hasOutstandingDiscussChanges: boolean;
  discussChangesCompleted: boolean;

  requiresFullScopeFollowUp: boolean | null;
  fullScopeFollowUpComments: string | null;

  monitoringRequired: boolean;
  recommendationsToCommittee: string | null;

  practiceReview?: PracticeReview;
}

export interface DecisionInput {
  id?: number;
  practiceReviewId: number;
  decisionTypeCode: DecisionTypeCode;

  isComply: boolean | null;
  nonComplyFinancial: boolean;
  nonComplyAudit: boolean;
  nonComplyReview: boolean;
  nonComplyQualityManagement: boolean;
  nonComplyTax: boolean;
  nonComplyOther: boolean;
  nonComplyOtherSpecified: string | null;

  complianceZoneId: number | null;
  followUpDate: string | null;
  nonComplyZoneNote: string | null;

  cpabParticipating: YesNoNa | null;
  cpdCompliance: YesNoNa | null;
  cpdComplianceExplanation: string | null;
  pliCompliance: YesNoNa | null;
  pliComplianceExplanation: string | null;

  recommendedPdCourseIds: number[];
  directedPdCourses: {
    courseId: number;
    isCompleted: boolean;
  }[];
  directedPdDueDate: string | null;

  motionStandardId: number | null;
  motionPdId: number | null;
  motionCpabId: number | null;
  motionCicId: number | null;

  descriptionOfIntendedAction: string | null;
  additionalCommentsForMinutes: string | null;
  additionalCommentsForDecisionLetter: string | null;

  reviewerMemo: string | null;
  managerMemo: string | null;
  directorsHighlights: string | null;
  directorsSummaryOfFirmResponse: string | null;

  requiresFullScopeFollowUp: boolean | null;
  fullScopeFollowUpComments: string | null;

  monitoringRequired: boolean;
  recommendationsToCommittee: string | null;
}

export interface AssignedPdCourse {
  id: number;
  pdCourse: PdCourse;
  isDirected: boolean;
  mustBeTakenBy: string | null;
  isCompleted: boolean;
  isExempt: boolean;
  exemptReason: string | null;
}

export interface ComplianceZone {
  id: number;
  name: string;
  zoneCode: string;
  isNonComplyZone: boolean;
  requiresNote: boolean;
  requiresResponse: boolean;
  maySupplyFollowUpDate: boolean;
  requiresFollowUpScope: boolean;
}

export enum YesNoNa {
  Yes = "Yes",
  No = "No",
  Na = "Na"
}

export interface SignOffChecklistItem {
  id: number;
  decisionType: DecisionType;
  questionText: string;
  sortOrder: number;
  displayTemplateName: string | null;
  isChecked: boolean;
}

export enum MotionTypeCode {
  Standard = "Standard",
  PD = "PD",
  CPAB = "CPAB",
  CIC = "CIC"
}

export interface MotionType {
  id: number;
  name: string;
  typeCode: MotionTypeCode;
}

export interface MotionParagraph {
  id: number;
  motionType: MotionType;
  shortDescription: string;
  motionCode: string;
  isActive: boolean;
  isComply: boolean;
  isNonComply: boolean;
  isInUse: boolean;
  sortOrder: number;
  paragraphForMinutes: string;
  paragraphForDecisionLetter: string;
}

export interface MotionParagraphInput {
  id?: number;
  motionTypeId: number;
  shortDescription: string;
  motionCode: string;
  isActive: boolean;
  isComply: boolean;
  isNonComply: boolean;
  sortOrder: number;
  paragraphForMinutes: string;
  paragraphForDecisionLetter: string;
}

export interface DecisionAdditionalParagraph {
  id: number;
  name: string;
  isActive: boolean;
  paragraphForMinutes: string;
  paragraphForDecisionLetter: string;
}

export interface DecisionAdditionalParagraphInput {
  id?: number;
  name: string;
  isActive: boolean;
  paragraphForMinutes: string;
  paragraphForDecisionLetter: string;
}
