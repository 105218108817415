import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, TextField } from "@mui/material";

import React, { useState } from "react";
import { makeStyles } from "../makeStyles";
import ClosableDialogTitle from "./ClosableDialogTitle";

const useStyles = makeStyles()((theme) => ({
  confirmationField: {
    marginTop: theme.spacing(2)
  }
}));

interface Props {
  title: string;
  body: React.ReactNode;
  requireStringToBeTyped?: string;
  confirmButtonText?: string;
  confirmButtonDisabled?: boolean;
  cancelButtonText?: string;
  cancelButtonVariant?: "text" | "outlined" | "contained";
  open: boolean;
  confirm: () => void;
  cancel: () => void;
  loading?: boolean;
  noDanger?: boolean;
}

export const ConfirmationDialog: React.FunctionComponent<Props> = (props) => {
  const { classes } = useStyles();
  const [confirmationString, setConfirmationString] = useState("");

  return (
    <Dialog open={props.open} onClose={props.cancel} maxWidth="sm" fullWidth scroll="paper">
      <ClosableDialogTitle onClose={props.cancel}>{props.title}</ClosableDialogTitle>
      <DialogContent>
        {props.body}
        {props.requireStringToBeTyped && (
          <TextField
            className={classes.confirmationField}
            value={confirmationString}
            onChange={(e) => setConfirmationString(e.target.value)}
            fullWidth
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.cancel} disabled={props.loading} variant={props.cancelButtonVariant}>
          {props.cancelButtonText || "Cancel"}
        </Button>
        <LoadingButton
          color={props.noDanger ? "primary" : "error"}
          variant="contained"
          onClick={props.confirm}
          disabled={
            props.confirmButtonDisabled
              ? true
              : props.requireStringToBeTyped
              ? confirmationString !== props.requireStringToBeTyped
              : undefined
          }
          loading={props.loading ?? false}>
          {props.confirmButtonText || "OK"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
