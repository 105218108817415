import React, { useState, useEffect, useContext, createContext } from "react";
import { useAppConfig } from "./AppConfig";
import { SignalRPubSub } from "./SignalRPubSub";

export const SignalRContext = createContext<SignalRPubSub>(undefined as any);

export const SignalRProvider: React.FunctionComponent = (props) => {
  const [signalRPubSubInstance, setSignalRPubSubInstance] = useState<SignalRPubSub>(undefined as any);
  const { azureSignalRConnectionString } = useAppConfig();

  useEffect(() => {
    if (!azureSignalRConnectionString) return;
    const signalRPubSub = new SignalRPubSub(azureSignalRConnectionString);
    signalRPubSub.start();
    setSignalRPubSubInstance(signalRPubSub);
  }, [azureSignalRConnectionString]);

  return <SignalRContext.Provider value={signalRPubSubInstance}>{props.children}</SignalRContext.Provider>;
};

export function useSignalR() {
  return useContext(SignalRContext);
}
