import { PubSubEngine } from "graphql-subscriptions";
import { SignalRClient } from "./SignalRClient";

// forked from @aaronpowell/graphql-signalr-subscriptions 0.3.0 because the @microsoft/signalr import was wrong
type SubscriptionInfo = {
  onMessage: (...args: unknown[]) => void;
  triggerName: string;
};

export class SignalRPubSub extends PubSubEngine {
  private client: SignalRClient;
  private handlerMap = new Map<number, SubscriptionInfo>();
  constructor(signalRConnectionString: string, hubName: string | undefined = undefined) {
    super();
    this.client = SignalRClient.fromConnectionString(signalRConnectionString, hubName);
  }
  public start() {
    return this.client.start();
  }
  publish<T>(triggerName: string, payload: T): Promise<void> {
    return this.client.send(triggerName, payload);
  }
  subscribe(triggerName: string, onMessage: (...args: unknown[]) => void): Promise<number> {
    this.client.on(triggerName, onMessage);

    const id = Date.now() * Math.random();
    this.handlerMap.set(id, { onMessage, triggerName });

    return Promise.resolve(id);
  }
  unsubscribe(subId: number) {
    const info = this.handlerMap.get(subId);
    if (info) {
      this.client.off(info.triggerName, info.onMessage);
      this.handlerMap.delete(subId);
    }
  }
}
