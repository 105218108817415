import { useMutation } from "@apollo/client";
import { AssignedPdCourse, DecisionTypeCode } from "decisions";
import { PracticeReview } from "practice-reviews";
import React, { useEffect, useState } from "react";
import { Switch, Checkbox, Grid, Card, Typography, FormControlLabel, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { UpdateDirectedPdCoursesMutation } from "pd-courses/queries";
import { useNotifications } from "notifications";
import { makeStyles } from "makeStyles";
import { datagridStyles } from "styles/common";
import { optionalScreenWidthLimit } from "styles/theme";
import { DateTime } from "luxon";
import { standardDateFormat } from "util/formats";
import StackedStaticDataDisplay from "../common/StackedStaticDataDisplay";

interface Props {
  practiceReview: PracticeReview;
}

const useStyles = makeStyles()((theme) => ({
  ...datagridStyles(theme),
  cardContainer: {
    padding: theme.spacing(3),
    borderLeft: `${theme.spacing(1)} solid transparent`,
    maxWidth: optionalScreenWidthLimit
  },
  exemptCard: {
    borderLeftColor: theme.palette.cpaLightGrey.main,
    color: theme.palette.text.secondary
  },
  completedCard: {
    borderLeftColor: theme.palette.cpaAccentGreen.main
  },
  topicNameAndCodeLabels: {
    color: theme.palette.text.secondary
  },
  exemptSwitchLabel: {
    color: theme.palette.text.primary
  }
}));

export const DirectedPdTab = (props: Props) => {
  const { classes, cx } = useStyles();
  const { practiceReview } = props;
  const { success } = useNotifications();

  const [directedPdCourses, setDirectedPdCourses] = useState<AssignedPdCourse[] | null>(null);

  const setCourseToExempt = (assignedPdCourseId: number, toggle: boolean = false, reason: string | null = null) => {
    let updatedCourse = { ...directedPdCourses!.find((apd) => apd.id === assignedPdCourseId)! };
    if (toggle) updatedCourse.isExempt = !updatedCourse.isExempt;
    if (updatedCourse.isExempt) {
      updatedCourse.exemptReason = reason;
    } else {
      updatedCourse.exemptReason = null;
    }
    setDirectedPdCourses(sortDirectedPdCourses([...directedPdCourses!.filter((apd) => apd.id !== assignedPdCourseId), updatedCourse]));
  };

  const toggleCourseComplete = (assignedPdCourseId: number) => {
    let updatedCourse = { ...directedPdCourses!.find((apd) => apd.id === assignedPdCourseId)! };
    updatedCourse.isCompleted = !updatedCourse.isCompleted;
    setDirectedPdCourses(sortDirectedPdCourses([...directedPdCourses!.filter((apd) => apd.id !== assignedPdCourseId), updatedCourse]));
  };

  const sortDirectedPdCourses = (assignedCourses: AssignedPdCourse[]) => {
    return assignedCourses.sort(
      (c1, c2) =>
        (c1.pdCourse.topicName ?? "").localeCompare(c2.pdCourse.topicName) ||
        (c1.pdCourse.productCode ?? "").localeCompare(c2.pdCourse.productCode ?? "")
    );
  };

  const decision = practiceReview.decisions.find((d) => d.decisionType.typeCode === DecisionTypeCode.Committee) || null;

  useEffect(() => {
    const sortedCourses = sortDirectedPdCourses([...(decision?.assignedPdCourses.filter((c) => c.isDirected) ?? [])]);
    setDirectedPdCourses(sortedCourses);
  }, [props.practiceReview]);

  const [pdCoursesMutate, pdCoursesMutation] = useMutation<
    { practiceReview: { updateDirectedPdCourses: AssignedPdCourse[] } },
    { practiceReviewId: number; updatedCourses: Partial<AssignedPdCourse>[]; pdCoursesDueDate?: string | null }
  >(UpdateDirectedPdCoursesMutation);

  const saveActiveDirectedPdCourses = async () => {
    await pdCoursesMutate({
      variables: {
        practiceReviewId: practiceReview.id,
        updatedCourses: directedPdCourses!.map((apd) => ({
          id: apd.id,
          isCompleted: apd.isCompleted,
          isExempt: apd.isExempt,
          exemptReason: apd.exemptReason
        }))
      }
    });
    if (!pdCoursesMutation.error) {
      success("Saved PD courses.");
    }
  };

  const saveable = !directedPdCourses?.some((apd) => apd.isExempt && !apd.exemptReason);

  return (
    <>
      <Grid justifyContent="flex-end" spacing={2} container>
        <Grid item xs={12}>
          {decision?.directedPdDueDate && (
            <StackedStaticDataDisplay
              label="Date Directed PD to Be Taken By"
              value={DateTime.fromISO(decision.directedPdDueDate).toFormat(standardDateFormat)}
            />
          )}
        </Grid>
        <Grid item container xs={12} spacing={2}>
          {directedPdCourses?.map((directedCourse) => (
            <Grid key={directedCourse.id} item xs={12}>
              <Card
                variant="outlined"
                className={cx(
                  classes.cardContainer,
                  { [classes.exemptCard]: directedCourse.isExempt },
                  { [classes.completedCard]: directedCourse.isCompleted }
                )}>
                <Grid container>
                  <Grid item container spacing={2}>
                    <Grid xs={12} item>
                      <Typography variant="h3">{directedCourse.pdCourse.name}</Typography>
                    </Grid>
                    <Grid xs={12} item>
                      <Typography variant="subtitle2" className={classes.topicNameAndCodeLabels}>
                        {directedCourse.pdCourse.topicName} | {directedCourse.pdCourse.productCode}
                      </Typography>
                    </Grid>
                    <Grid xs={12} item>
                      <FormControlLabel
                        disabled={directedCourse.isExempt}
                        checked={directedCourse.isCompleted}
                        onClick={() => {
                          toggleCourseComplete(directedCourse.id);
                        }}
                        control={<Checkbox />}
                        label="Complete"
                      />
                      <FormControlLabel
                        disabled={directedCourse.isCompleted}
                        className={classes.exemptSwitchLabel}
                        checked={directedCourse.isExempt}
                        onClick={() => {
                          setCourseToExempt(directedCourse.id, true);
                        }}
                        control={<Switch />}
                        label="Exempt"
                      />
                    </Grid>
                  </Grid>
                  {directedCourse.isExempt && (
                    <Grid flexGrow={1} item>
                      <TextField
                        autoFocus
                        fullWidth
                        multiline
                        minRows={3}
                        label={"Exemption Reason"}
                        error={!directedCourse.exemptReason}
                        helperText={!!directedCourse.exemptReason ? "" : "Exemption Reason Required"}
                        value={directedCourse.exemptReason}
                        onChange={(e) => {
                          setCourseToExempt(directedCourse.id, false, e.target.value);
                        }}
                      />
                    </Grid>
                  )}
                </Grid>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Grid item>
          <LoadingButton
            variant="outlined"
            disabled={!saveable}
            onClick={async () => {
              await saveActiveDirectedPdCourses();
            }}
            loading={pdCoursesMutation.loading}>
            Save
          </LoadingButton>
        </Grid>
      </Grid>
    </>
  );
};
