import { PaginationItem } from "@mui/lab";
import React from "react";
import { makeStyles } from "../makeStyles";

interface Props {
  page: number;
  onPageChange: (newPage: number) => void;
  maxPage?: number;
}

const useStyles = makeStyles()(() => ({
  paginationWrapper: {
    textAlign: "right"
  }
}));

const DataGridPagination: React.FunctionComponent<Props> = (props) => {
  const { classes } = useStyles();

  return (
    <div className={classes.paginationWrapper}>
      <PaginationItem type="previous" onClick={(e) => props.onPageChange(props.page - 1)} disabled={props.page <= 1} />
      <PaginationItem selected page={props.page} />
      <PaginationItem
        type="next"
        onClick={(e) => props.onPageChange(props.page + 1)}
        disabled={props.page >= (props.maxPage ?? Infinity)}
      />
    </div>
  );
};

export default DataGridPagination;
