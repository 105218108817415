import { EnhancedMergeField, MergeFieldBehavior } from "./models";

export function getAsposeMergeFieldTextForInsert(field: EnhancedMergeField): string {
  let ensuredType = ensureMergeFieldBehaviorTypeRepresentation(field.type);
  switch (ensuredType) {
    case MergeFieldBehavior.List:
      return `<<foreach [in ${field.field}]>> <</foreach>>`;
    case MergeFieldBehavior.Boolean:
      return `<<if [${field.field}]>> <</if>>`;
    case MergeFieldBehavior.Document:
      return `<<doc [${field.field}] -build>>`;
    case MergeFieldBehavior.RichTextHtml:
      return `<<[${field.field}] -html>>`;
    case MergeFieldBehavior.Object:
      return "";
    default:
      return `<<[${field.field}]>>`;
  }
}

export function getMergeFieldTypeDisplayText(fieldType: any): any {
  switch (fieldType) {
    case "Boolean":
      return "true or false";
    case "List":
      return "list";
    case "Document":
      return "mergeable sub-document";
    case "RichTextHtml":
      return "field with formatting";
    case "Object":
      return "category";
    case "Field":
      return "field";
    default:
      return fieldType;
  }
}

export function ensureMergeFieldBehaviorTypeRepresentation(fieldType: any) {
  // This data doesn't come from graphQL, because graphQL can't handle nesting of arbitrary depth.
  // So it comes from a bespoke API call. But that means it's not subject to the graphQL-specific
  // serialization config... thus this enum comes as an int, not as its string representation.
  // I didn't want to mess with the global serialization config at this stage, and ASP.NET Core MVC
  // seemed uninterested in obeying the attribute approach, so I just wrote this piece of code to
  // properly handle whatever form it comes in.  Sorry for the confusion.
  if (fieldType === 0 || fieldType === "0") {
    return MergeFieldBehavior.Field;
  }
  if (fieldType === 1 || fieldType === "1") {
    return MergeFieldBehavior.Boolean;
  }
  if (fieldType === 2 || fieldType === "2") {
    return MergeFieldBehavior.List;
  }
  if (fieldType === 3 || fieldType === "3") {
    return MergeFieldBehavior.Document;
  }
  if (fieldType === 4 || fieldType === "4") {
    return MergeFieldBehavior.RichTextHtml;
  }
  if (fieldType === 5 || fieldType === "5") {
    return MergeFieldBehavior.Object;
  }

  return fieldType;
}
