import { gql } from "@apollo/client";

export const MasterChecklistFieldsFragment = gql`
  fragment MasterChecklistFields on QuestionContainerTemplate {
    id
    parentId
    sortOrder
    text
    questions {
      id
      parentId
      sortOrder
      cpaRef
      text
      standardParagraphs {
        id
        cpaRef
        title
        text
        recommendation
        isSignificantByDefault
        isRemedialByDefault
      }
    }
  }
`;

export const QuestionTemplateFieldsFragment = gql`
  fragment QuestionTemplateFields on QuestionTemplate {
    id
    parentId
    sortOrder
    cpaRef
    text
    standardParagraphs {
      id
      cpaRef
      title
      text
      recommendation
      isSignificantByDefault
      isRemedialByDefault
    }
  }
`;

export const ChecklistSectionTemplateFieldsFragment = gql`
  fragment ChecklistSectionTemplateFields on ChecklistSectionTemplate {
    shortDescription
  }
`;

export const QuestionHeaderTemplateFieldsFragment = gql`
  fragment QuestionHeaderTemplateFields on QuestionHeaderTemplate {
    cpaRef
  }
`;

export const SectionHeaderToPrintFieldsFragment = gql`
  fragment SectionHeaderToPrintFields on SectionHeaderToPrint {
    id
    name
    sortOrder
    isFirm
    isClient
  }
`;
