import React, { memo, useCallback, useState } from "react";
import { IconButton, Menu, MenuItem } from "@mui/material";

import { isQuestionTemplate, MasterChecklistNode, QuestionContainerTemplate } from "checklists";
import { RenderDialogProps } from "common/CrudTable";
import { questionPadding } from "styles/common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons/faArrowDown";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons/faArrowUp";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons/faEllipsisV";
import { gql, useApolloClient, useMutation } from "@apollo/client";
import { ReorderMasterChecklistInput } from "./models";
import EditMasterChecklistQuestionDialog from "./dialogs/EditMasterChecklistQuestionDialog";
import EditMasterChecklistSectionDialog from "./dialogs/EditMasterChecklistSectionDialog";
import _ from "lodash";
import { makeStyles } from "../makeStyles";

enum Directions {
  Up = -1,
  Down = 1
}

const ReorderChecklistMutation = gql`
  mutation ReorderMasterChecklist($containers: [ReorderMasterChecklistInput], $questions: [ReorderQuestionTemplateInput]) {
    masterChecklistQuestionContainers {
      reorder(containers: $containers, questions: $questions)
    }
  }
`;

function useStyles<T extends MasterChecklistNode>(props: Props<T>) {
  return makeStyles<Props<T>>()((theme, props) => ({
    buttons: {
      paddingRight: questionPadding,
      flexShrink: 0,
      display: "flex",
      visibility: props.hidden ? "hidden" : undefined,
      "& > :first-child": {
        marginRight: theme.spacing(1)
      },
      "& > :last-child": {
        marginLeft: theme.spacing(3)
      }
    }
  }))(props);
}

interface Props<T extends MasterChecklistNode> {
  node: T;
  renderEditDialog?: (node: T, props: RenderDialogProps) => React.ReactNode;
  renderDeleteDialog?: (node: T, props: RenderDialogProps) => React.ReactNode;
  isQuestionContainer?: boolean;
  isSection?: boolean;
  hidden: boolean;
  siblings: MasterChecklistNode[];
}

function MasterChecklistActions<T extends MasterChecklistNode>(props: Props<T>) {
  const apolloClient = useApolloClient();
  const { classes } = useStyles(props);

  const [reorderMutation] = useMutation<
    { masterChecklistQuestionContainers: { reorder: boolean } },
    { containers: ReorderMasterChecklistInput[]; questions: ReorderMasterChecklistInput[] }
  >(ReorderChecklistMutation);

  const debouncedReorderMutation = useCallback(_.debounce(reorderMutation, 3000), [reorderMutation]);

  const [menuAnchor, setMenuAnchor] = useState<HTMLButtonElement | null>(null);

  const [addQuestionDialogOpen, setAddQuestionDialogOpen] = useState(false);
  const [addSectionDialogOpen, setAddSectionDialogOpen] = useState(false);
  const [addQuestionHeaderDialogOpen, setAddQuestionHeaderDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const rowIndex = props.siblings.findIndex((node) => node.id === props.node.id);

  function moveRow(direction: Directions) {
    let siblings = [...props.siblings];

    let siblingNode = siblings[rowIndex + direction];
    siblings[rowIndex] = siblingNode;
    siblings[rowIndex + direction] = props.node;

    let updatedContainers: ReorderMasterChecklistInput[] = [];
    let updatedQuestions: ReorderMasterChecklistInput[] = [];
    siblings.forEach((current, index) => {
      // Sort order should be 1-based
      let updated = { id: current.id, sortOrder: index + 1 };
      apolloClient.cache.modify({
        id: apolloClient.cache.identify(current as any),
        fields: {
          sortOrder: () => index + 1
        }
      });
      if (isQuestionTemplate(current)) {
        updatedQuestions.push(updated);
      } else {
        updatedContainers.push(updated);
      }
    });
    debouncedReorderMutation({ variables: { containers: updatedContainers, questions: updatedQuestions } });
  }

  function editClicked() {
    setEditDialogOpen(true);
    setMenuAnchor(null);
  }

  function deleteClicked() {
    setDeleteDialogOpen(true);
    setMenuAnchor(null);
  }

  function addQuestionClicked() {
    setAddQuestionDialogOpen(true);
    setMenuAnchor(null);
  }

  function addSectionClicked() {
    setAddSectionDialogOpen(true);
    setMenuAnchor(null);
  }

  function addQuestionHeaderClicked() {
    setAddQuestionHeaderDialogOpen(true);
    setMenuAnchor(null);
  }

  return (
    <>
      <div className={classes.buttons}>
        <IconButton size="small" onClick={() => moveRow(Directions.Up)} disabled={rowIndex === 0}>
          <FontAwesomeIcon icon={faArrowUp} />
        </IconButton>
        <IconButton size="small" onClick={() => moveRow(Directions.Down)} disabled={rowIndex === props.siblings.length - 1}>
          <FontAwesomeIcon icon={faArrowDown} />
        </IconButton>
        <IconButton size="small" onClick={(e) => setMenuAnchor(e.currentTarget)}>
          <FontAwesomeIcon icon={faEllipsisV} />
        </IconButton>
        {Boolean(menuAnchor) && (
          <Menu anchorEl={menuAnchor} keepMounted open={Boolean(menuAnchor)} onClose={() => setMenuAnchor(null)}>
            <MenuItem key="Edit" onClick={editClicked}>
              Edit
            </MenuItem>
            {props.isQuestionContainer && (
              <MenuItem key="Add Question" onClick={addQuestionClicked}>
                Add Question
              </MenuItem>
            )}
            {props.isSection && [
              <MenuItem key="Add Section" onClick={addSectionClicked}>
                Add Section
              </MenuItem>,
              <MenuItem key="Add Question Header" onClick={addQuestionHeaderClicked}>
                Add Question Header
              </MenuItem>
            ]}
            <MenuItem onClick={deleteClicked}>Delete</MenuItem>
          </Menu>
        )}
      </div>
      {addQuestionDialogOpen && (
        <EditMasterChecklistQuestionDialog
          title="Add Question"
          confirmButtonText="Add"
          handleClose={() => setAddQuestionDialogOpen(false)}
          container={props.node as QuestionContainerTemplate}
        />
      )}
      {addSectionDialogOpen && (
        <EditMasterChecklistSectionDialog
          title="Add Section"
          confirmButtonText="Add"
          handleClose={() => setAddSectionDialogOpen(false)}
          container={props.node as QuestionContainerTemplate}
          isHeader={false}
        />
      )}
      {addQuestionHeaderDialogOpen && (
        <EditMasterChecklistSectionDialog
          title="Add Question Header"
          confirmButtonText="Add"
          handleClose={() => setAddQuestionHeaderDialogOpen(false)}
          container={props.node as QuestionContainerTemplate}
          isHeader={true}
        />
      )}
      {editDialogOpen && props.renderEditDialog?.(props.node, { onClose: () => setEditDialogOpen(false) })}
      {deleteDialogOpen && props.renderDeleteDialog?.(props.node, { onClose: () => setDeleteDialogOpen(false) })}
    </>
  );
}

const MemoizedMasterChecklistActions = memo(MasterChecklistActions);
export { MemoizedMasterChecklistActions as MasterChecklistActions };
