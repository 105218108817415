import React from "react";
import { IconButton, Typography } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { makeStyles } from "../makeStyles";

const useStyles = makeStyles()((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(0),
    top: theme.spacing(0),
    color: theme.palette.grey[500]
  }
}));

interface Props {
  onClose?: () => void;
}

const ClosableDialogTitle: React.FunctionComponent<Props> = (props) => {
  const { classes } = useStyles();

  return (
    <DialogTitle variant="h3" {...({ component: "h3" } as any)}>
      {props.children}
      <IconButton className={classes.closeButton} onClick={props.onClose ? () => props.onClose!() : undefined}>
        <FontAwesomeIcon icon={faTimes} />
      </IconButton>
    </DialogTitle>
  );
};

export default ClosableDialogTitle;
