import _ from "lodash";
import {
  Checklist,
  ChecklistNode,
  ChecklistNodeTreePath,
  ChecklistSection,
  isQuestion,
  isQuestionTemplate,
  isSection,
  isSectionTemplate,
  Question,
  QuestionContainer,
  QuestionHeader
} from "./models";

let absoluteSortOrderCounter = 1;

export function buildChecklist(checklistQuestionContainers: QuestionContainer[], isMasterChecklist?: boolean): Checklist {
  const checklistQuestionContainersByParentId = _.groupBy(checklistQuestionContainers, (qc) => qc.parentId ?? 0);

  if (!checklistQuestionContainersByParentId[0]) {
    return {
      tabSections: [],
      questionsByTabAndAbsoluteSortOrder: _.fromPairs(
        ([] as ChecklistSection[]).map((section) => {
          const questionsByAbsoluteSortOrder = _.keyBy([] as Question[], (q) => q.absoluteSortOrder);

          return [section.id, questionsByAbsoluteSortOrder];
        })
      )
    };
  }

  const tabSections = checklistQuestionContainersByParentId[0];
  const orderedTabSections = _.orderBy(tabSections, (s) => s.sortOrder);

  const connectedTabSections = orderedTabSections.map((section) => {
    const childrenOfSection = checklistQuestionContainersByParentId[section.id];
    // If a container doesn't have children, we can return it directly
    if (childrenOfSection === undefined) return { ...section, children: [] } as ChecklistSection;
    return connectSection(section as ChecklistSection, childrenOfSection, checklistQuestionContainersByParentId);
  });

  // For the master checklist, we don't need to traverse it like the PR checklists, so we don't need the
  // absolute sort order on the questions.
  const tabSectionsWithAbsoluteSortOrderApplied = isMasterChecklist
    ? connectedTabSections
    : connectedTabSections.map((tabSection) => {
        absoluteSortOrderCounter = 1;
        return applyAbsoluteSortOrder(tabSection) as ChecklistSection;
      });

  const questionsByTabAndAbsoluteSortOrder = _.fromPairs(
    tabSectionsWithAbsoluteSortOrderApplied.map((section) => {
      const sectionQuestions = getQuestionsInQuestionContainer(section);
      const orderedQuestions = _.orderBy(sectionQuestions, (q) => q.absoluteSortOrder);
      const questionsByAbsoluteSortOrder = _.keyBy(orderedQuestions, (q) => q.absoluteSortOrder);

      return [section.id, questionsByAbsoluteSortOrder];
    })
  );

  return { tabSections: tabSectionsWithAbsoluteSortOrderApplied, questionsByTabAndAbsoluteSortOrder };
}

function connectSection(
  section: ChecklistSection,
  children: QuestionContainer[],
  checklistQuestionContainersByParentId: { [key: string]: QuestionContainer[] }
): ChecklistSection {
  const initialExpansionDepth = 1;

  function connectChildren(
    questionContainer: QuestionContainer,
    childQuestionContainers: QuestionContainer[],
    depth: number,
    treePath: ChecklistNodeTreePath
  ): ChecklistSection | QuestionHeader {
    const childQuestionsWithDepth: ChecklistNode[] = [
      ...(questionContainer.questions ?? []).map((q) => {
        const childTreePath = addNodeToTreePath(treePath, depth + 1, q);
        return {
          ...q,
          depth: depth + 1,
          treePath: childTreePath
        };
      })
    ];

    const connectedChildQuestionContainers = childQuestionContainers.map((child) => {
      const grandchildren = checklistQuestionContainersByParentId[child.id] || [];
      const childTreePath = addNodeToTreePath(treePath, depth + 1, child);

      return connectChildren(child, grandchildren, depth + 1, childTreePath);
    });

    const orderedChildren = _.orderBy([...childQuestionsWithDepth, ...connectedChildQuestionContainers], (child) => child.sortOrder);

    return isSection(questionContainer) || isSectionTemplate(questionContainer)
      ? {
          ...questionContainer,
          depth: depth,
          treePath: treePath,
          children: orderedChildren,
          expanded: depth <= initialExpansionDepth
        }
      : {
          ...questionContainer,
          depth: depth,
          treePath: treePath,
          children: orderedChildren
        };
  }

  const rootSectionTreePath = addNodeToTreePath({}, 0, section);
  const sectionConnectedToChildren = connectChildren(section, children, 0, rootSectionTreePath);

  return sectionConnectedToChildren as ChecklistSection;
}

function getQuestionsInQuestionContainer(questionContainer: QuestionContainer) {
  const childQuestions: Question[] = questionContainer.children
    .filter((c) => isQuestion(c) || isQuestionTemplate(c))
    .map((c) => c as Question);
  const descendantQuestions: Question[] = questionContainer.children
    .filter((c) => !isQuestion(c) && !isQuestionTemplate(c))
    .reduce((questions, qc) => questions.concat(getQuestionsInQuestionContainer(qc as QuestionContainer)), [] as Question[]);

  return childQuestions.concat(descendantQuestions);
}

function addNodeToTreePath(treePath: ChecklistNodeTreePath, depth: number, node: ChecklistNode): ChecklistNodeTreePath {
  return {
    ...treePath,
    [depth]: { id: node.id, __typename: node.__typename }
  };
}

function applyAbsoluteSortOrder(checklistNode: ChecklistNode): ChecklistNode {
  if (isQuestion(checklistNode)) {
    return { ...checklistNode, absoluteSortOrder: absoluteSortOrderCounter++ };
  } else {
    return { ...checklistNode, children: checklistNode.children.map((c) => applyAbsoluteSortOrder(c)) };
  }
}
