import { useMutation, useQuery } from "@apollo/client";
import { ScreenHeader } from "common/ScreenHeader";
import _ from "lodash";
import React, { useState } from "react";
import CrudTable from "../common/CrudTable";
import { Helmet } from "react-helmet";
import { AppRole, AppRoleAssignee, FetchAppRolesQuery } from ".";
import { datagridStyles } from "../styles/common";
import { makeStyles } from "../makeStyles";
import { ConfirmationDialog } from "common/ConfirmationDialog";
import { DialogContentText } from "@mui/material";
import { GridSortModel, GridValueFormatterParams } from "@mui/x-data-grid-pro";

const useStyles = makeStyles()((theme) => ({
  ...datagridStyles(theme)
}));

const AppRolesScreen: React.FunctionComponent = () => {
  const { classes, cx } = useStyles();

  const [sortModel, setSortModel] = useState<GridSortModel>([{ field: "identifier", sort: "asc" }]);

  const appRolesQuery = useQuery<{ appRoles: AppRole[] }, {}>(FetchAppRolesQuery, {
    variables: {}
  });

  const appRoles = appRolesQuery.data?.appRoles ? _.orderBy(appRolesQuery.data.appRoles, (ar) => ar.identifier) : [];
  const assigneesFormatter = (params: GridValueFormatterParams) =>
    params.value?.length
      ? /*(
      <ul>
        {params.value.map((v: AppRoleAssignee) => (
          <li>{v.principalDisplayName}</li>
        ))}
      </ul>
    )*/ _.orderBy(params.value, (ar) => ar.principalDisplayName)
          .map((v: AppRoleAssignee) => v.principalDisplayName)
          .join(", ")
      : //params.value.map((v: AppRoleAssignee) => v.principalDisplayName).join(", ")
        "--";
  return (
    <>
      <Helmet>
        <title>App Role Assignments - PRS Online</title>
      </Helmet>

      <ScreenHeader title="App Role Assignments" />
      <CrudTable
        sortModel={sortModel}
        onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
        rows={appRoles}
        loading={appRolesQuery.loading}
        columnDefinitions={[
          {
            field: "identifier",
            headerName: "Identifier",
            flex: 1
          },
          {
            field: "displayName",
            headerName: "Display Name",
            flex: 1
          },
          {
            field: "description",
            headerName: "Description",
            flex: 3
          },
          {
            field: "assignees",
            headerName: "Assignees",
            flex: 2,
            valueFormatter: assigneesFormatter
          }
        ]}
        storageKey="App Role Assignments"
        noDataMessage="No app roles."
      />
    </>
  );
};

export default AppRolesScreen;
