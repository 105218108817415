import React from "react";
import { Button, Dialog, DialogActions, DialogContent, Grid, TextField } from "@mui/material";

import ClosableDialogTitle from "common/ClosableDialogTitle";
import { Reference, useApolloClient, useMutation, useQuery } from "@apollo/client";
import { staticDataStyles } from "styles/common";
import * as Yup from "yup";
import { Form as FormikForm, Formik } from "formik";
import { User, AllUsersQuery, useCurrentUser, UsersWhoMayHaveInasReassignedToThemQuery } from "users";
import StackedStaticDataDisplay from "common/StackedStaticDataDisplay";
import { GetInasForUserQuery, Ina, InaPriority, ReassignInasMutation } from "inas";
import _ from "lodash";
import { Autocomplete } from "@mui/material";
import { useNotifications } from "../notifications";
import PrioritySelector from "./PrioritySelector";
import PrList from "./PrList";
import { LoadingButton } from "@mui/lab";
import { makeStyles } from "../makeStyles";

interface Props {
  inas: Ina[];
  handleClose: (completedReassignment: boolean) => void;
}

interface FormValues {
  userId: number | null;
  priority: InaPriority;
  isRetain: boolean;
}
const useStyles = makeStyles()((theme) => ({
  ...staticDataStyles(theme)
}));

const ReassignInasDialog = (props: Props) => {
  const notifications = useNotifications();
  const apolloClient = useApolloClient();
  const { user } = useCurrentUser();
  const { classes } = useStyles();

  const validationSchema = Yup.object({
    userId: Yup.number().required("Select a person to reassign to."),
    priority: Yup.string().required("Select a priority.")
  });

  const userQuery = useQuery<{ usersWhoMayHaveInasReassignedToThem: User[] }>(UsersWhoMayHaveInasReassignedToThemQuery);
  const users = _.sortBy(userQuery.data?.usersWhoMayHaveInasReassignedToThem ?? [], (u) => u.name);
  const priorities: string[] = [];

  priorities.push("Retain");
  priorities.push(InaPriority.High);
  priorities.push(InaPriority.Normal);
  priorities.push(InaPriority.Low);

  const [reassignMutate, reassignMutation] = useMutation<
    { ina: { reassign: Ina[] } },
    { inaIds: number[]; userId: number; priority: InaPriority; isRetain: boolean }
  >(ReassignInasMutation, { refetchQueries: [{ query: GetInasForUserQuery }] });

  async function reassignInas(values: FormValues) {
    const mutationResult = await reassignMutate({
      variables: {
        inaIds: props.inas.map((ina) => ina.id),
        userId: values.userId!,
        priority: values.priority,
        isRetain: values.isRetain
      }
    });

    if ((mutationResult.data?.ina.reassign.length ?? 0) > 0) {
      // Immediately remove the INAs from the cache
      const inaCacheIds = mutationResult.data?.ina.reassign.map((ina) => apolloClient.cache.identify(ina as any)) ?? [];
      apolloClient.cache.modify({
        fields: {
          inas(existingInas: Reference[]) {
            return existingInas.filter((inaRef) => !inaCacheIds.includes(inaRef.__ref));
          }
        }
      });
      notifications.success(`Reassigned INA${props.inas.length > 1 ? "s" : ""}.`);
      props.handleClose(true);
    }
  }

  const uniqueUsersInasAssignedTo = _.uniqBy(
    props.inas.map((ina) => ina.assignedToUser),
    (u) => u.id
  );

  const initialValues: FormValues = {
    userId: props.inas.every((ina) => ina.assignedToUser.id === user.id) ? null : user.id,
    priority: _.uniq(props.inas.map((ina) => ina.priority)).length > 1 ? InaPriority.Normal : props.inas[0].priority,
    isRetain: true
  };

  return (
    <Dialog open={true} onClose={() => props.handleClose(false)} scroll="paper" fullWidth maxWidth="xs">
      <ClosableDialogTitle onClose={() => props.handleClose(false)}>{`Reassign INA${
        props.inas.length > 1 ? "s" : ""
      }`}</ClosableDialogTitle>
      <Formik initialValues={initialValues} onSubmit={reassignInas} validationSchema={validationSchema} enableReinitialize>
        {(formikProps) => (
          <FormikForm>
            <DialogContent>
              <Grid container item direction="column" spacing={2}>
                <Grid item>
                  <StackedStaticDataDisplay label="Type" value={props.inas[0].type.friendlyName} />
                </Grid>
                {props.inas.every((ina) => ina.practiceReview) && (
                  <Grid item>
                    <PrList prs={props.inas.map((ina) => ina.practiceReview!)} />
                  </Grid>
                )}
                <Grid item>
                  <StackedStaticDataDisplay
                    label="Currently Assigned to"
                    value={uniqueUsersInasAssignedTo.length > 1 ? "Multiple people" : uniqueUsersInasAssignedTo[0].name}
                  />
                </Grid>
                <Grid item>
                  <Autocomplete
                    disableClearable
                    options={users}
                    getOptionLabel={(user: User | null) => (user === null ? "" : user.name)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        label="Reassign to"
                        error={formikProps.touched.userId && Boolean(formikProps.errors.userId)}
                        helperText={formikProps.touched.userId && formikProps.errors.userId}
                      />
                    )}
                    value={users.filter((u) => u.id === formikProps.values.userId)?.[0] ?? null}
                    onChange={(e, newUser) => {
                      formikProps.setFieldValue("userId", newUser.id);
                    }}
                    disabled={reassignMutation.loading}
                  />
                </Grid>
                <Grid item>
                  <label className={classes.label}>Priority</label>
                  <Autocomplete
                    disableClearable
                    options={priorities}
                    defaultValue={priorities[0]}
                    renderInput={(params: any) => <TextField {...params} defaultValue="Retain" margin="none" />}
                    onChange={(e, priority) => {
                      formikProps.setFieldValue("isRetain", priority == "Retain");
                      formikProps.setFieldValue("priority", priority);
                    }}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => props.handleClose(false)} disabled={reassignMutation.loading}>
                Cancel
              </Button>
              <LoadingButton
                color="primary"
                variant="contained"
                loading={reassignMutation.loading}
                onClick={() => {
                  formikProps.submitForm();
                }}>
                Reassign
              </LoadingButton>
            </DialogActions>
          </FormikForm>
        )}
      </Formik>
    </Dialog>
  );
};

export default ReassignInasDialog;
