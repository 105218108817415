import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText } from "@mui/material";
import { useNotifications } from "notifications";
import React from "react";
import ClosableDialogTitle from "common/ClosableDialogTitle";
import { useMutation } from "@apollo/client";
import { SaveScheduleMutation } from "scheduling";
import { PracticeReview } from "practice-reviews";
import { formatDate, formatTime } from "../../util/formats";
import { useUnsavedChanges } from "../../UnsavedChangesProvider";
import { LoadingButton } from "@mui/lab";

interface Props {
  isReschedule: boolean;
  isEstimateFinalized: boolean;
  practiceReviewId: number;
  startDate: string;
  endDate: string;
  schedulingNotes: string | null;
  leadReviewer: number | null;
  otherReviewers: number[];

  open: boolean;
  handleClose: () => void;
  changeKey?: string;
}

const FinalizeScheduleDialog: React.FunctionComponent<Props> = (props) => {
  const notifications = useNotifications();
  const { changesSaved } = useUnsavedChanges();

  const [saveMutate, saveMutation] = useMutation<
    { practiceReview: { saveSchedule: PracticeReview } },
    {
      practiceReviewId: number;
      schedulingNotes: string | null;
      startDate: string | null;
      endDate: string | null;
      leadReviewer: number | null;
      otherReviewers: number[] | null;
      finalize: boolean;
    }
  >(SaveScheduleMutation, {
    onCompleted: (result) => {
      if (result.practiceReview.saveSchedule.id) {
        notifications.success("Schedule finalized.");
        changesSaved(props.changeKey);
      }

      props.handleClose();
    }
  });

  return (
    <Dialog open={props.open} onClose={props.handleClose} fullWidth={true} scroll="paper">
      <ClosableDialogTitle onClose={props.handleClose}>{props.isReschedule ? "Reschedule?" : "Finalize schedule?"}</ClosableDialogTitle>
      <DialogContent>
        <DialogContentText>
          {`The review will start on ${formatDate(props.startDate)} at ${formatTime(props.startDate)} and will finish on
          ${formatDate(props.endDate)}.`}
        </DialogContentText>
        <DialogContentText>
          {props.isReschedule
            ? "Do you want to update the review's scheduled dates and notify the firm of the new dates?"
            : "Do you want to complete Schedule Review INAs and notify the firm of the scheduled review dates?"}
        </DialogContentText>
        {!props.isEstimateFinalized && (
          <Alert severity="warning">
            The estimate for this review has not yet been finalized. This action will also mark the estimate as final and close any Estimate
            Time INAs for this review.
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} disabled={saveMutation.loading}>
          Cancel
        </Button>
        <LoadingButton
          color="primary"
          variant="contained"
          loading={saveMutation.loading}
          onClick={() =>
            saveMutate({
              variables: {
                practiceReviewId: props.practiceReviewId,
                startDate: props.startDate,
                endDate: props.endDate,
                schedulingNotes: props.schedulingNotes,
                leadReviewer: props.leadReviewer,
                otherReviewers: props.otherReviewers,
                finalize: true
              }
            })
          }>
          {props.isReschedule ? "Reschedule" : "Finalize"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default FinalizeScheduleDialog;
