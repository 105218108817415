import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  Button,
  MenuItem,
  Link,
  Avatar,
  Tooltip,
  Menu,
  IconButton,
  Autocomplete,
  TextField,
  Divider,
  Theme,
  Stack
} from "@mui/material";
import { lighten } from "@mui/material/styles";

import React, { useEffect, useState } from "react";
import { Link as RouterLink, Route, useHistory } from "react-router-dom";
import { AppRoute } from "Routes";
import { useMsal } from "@azure/msal-react";
import { useCurrentUser, User, Permissions, UsersWhoseInasMayBeViewedByOthersQuery } from "users";
import { useAppConfig } from "util/AppConfig";
import { ResetTestDataDialog } from "test-infra/ResetTestDataDialog";
import NavSubMenu from "./NavSubMenu";
import GoToPrField from "practice-reviews/GoToPrField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons/faCaretDown";
import { makeStyles } from "../makeStyles";
import { useQuery } from "@apollo/client";
import { useInasUser } from "../InasUserProvider";
import _ from "lodash";
import { CustomReport, FetchCustomReportsQuery } from "../custom-reports";
import { versionIndicatorStyles } from "styles/common";

export function getMainBarHeight(theme: Theme) {
  return theme.spacing(12);
}

const useStyles = makeStyles()((theme) => {
  const mainBarHeight = getMainBarHeight(theme);
  return {
    appBar: {
      transition: "unset",
      boxShadow: "unset"
    },
    mainBar: {
      height: mainBarHeight,
      backgroundColor: theme.palette.common.white,
      borderWidth: "6px 0 0 0",
      borderStyle: "solid",
      borderImageSlice: "1",
      borderImageSource: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`
    },
    grow: {
      flexGrow: 1
    },
    logoBg: {
      padding: theme.spacing(1.25, 0.5, 2.5, 0),
      margin: theme.spacing(0, 0.5, 0, 0),
      height: mainBarHeight,
      backgroundColor: "transparent",
      "& img": {
        maxWidth: "100%",
        height: "100%"
      }
    },
    homeLink: {
      flexShrink: 0,
      display: "flex",
      alignItems: "center",
      color: "inherit",
      textDecoration: "inherit"
    },
    title: {
      color: theme.palette.text.primary,
      fontWeight: 700,
      marginRight: theme.spacing(5)
    },
    versionIndicator: {
      ...versionIndicatorStyles(theme).versionIndicator,
      color: theme.palette.text.secondary,
      marginRight: theme.spacing(5),
      marginBottom: "-15px"
    },
    tabs: {
      display: "flex",
      height: "100%"
    },
    tab: {
      display: "flex",
      alignItems: "center",
      justifyItems: "center",
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
      color: theme.palette.primary.main,
      fontSize: theme.typography.h4.fontSize,
      fontFamily: theme.typography.fontFamily,
      fontWeight: 500,
      cursor: "pointer",
      "&.selected": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white
      },
      "&:hover:not(.selected)": {
        backgroundColor: lighten(theme.palette.primary.main, 0.9)
      }
    },
    tabPanel: {
      height: theme.spacing(7.5),
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3)
    },
    navButton: {
      color: theme.palette.common.white,
      borderRadius: 0,
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      fontFamily: theme.typography.fontFamily,
      "&:hover": {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.dark
      }
    },
    userInfo: {
      fontWeight: 500
    },
    goToPrField: {
      marginLeft: "auto",
      marginRight: theme.spacing(3)
    },
    initialsAvatar: {
      color: theme.palette.getContrastText(theme.palette.secondary.main),
      backgroundColor: theme.palette.secondary.main
    },
    viewOtherUsersInasSelector: {
      width: "20em",
      "& .MuiOutlinedInput-root": {
        backgroundColor: theme.palette.common.white
      }
    },
    reportMenuButton: {
      textTransform: "unset"
    }
  };
});

enum Tabs {
  Main,
  AdminTasks,
  Reports
}

const SiteAppBar: React.FunctionComponent = () => {
  const { classes, cx } = useStyles();
  const history = useHistory();
  const { instance: msalInstance } = useMsal();
  const { user, userLoading, userHasPermission } = useCurrentUser();
  const { isDevTestResetEnabled, sharepointDocumentTemplateLibraryUrl, version } = useAppConfig();

  const [resetDataDialogIsVisible, setResetDataDialogIsVisible] = useState(false);

  const [tabIndex, setTabIndex] = useState<Tabs>(Tabs.Main);
  const [route, setRoute] = useState(history.location.pathname);

  const [adminMenuOpen, setAdminMenuOpen] = useState<boolean>(false);
  const adminMenuButton = React.useRef<any>();

  const [reportsMenuOpen, setReportsMenuOpen] = useState<boolean>(false);
  const reportsMenuButton = React.useRef<any>();

  const [userMenuAnchorElement, setUserMenuAnchorElement] = useState<HTMLElement | null>(null);

  const { inasUser, setInasUser } = useInasUser();

  const userQuery = useQuery<{ usersWhoseInasMayBeViewedByOthers: User[] }>(UsersWhoseInasMayBeViewedByOthersQuery);
  const usersWhoseInasCanBeViewed = userQuery.data?.usersWhoseInasMayBeViewedByOthers ?? [];

  const customReportsQuery = useQuery<{ customReports: CustomReport[] }>(FetchCustomReportsQuery);
  const customReports = customReportsQuery.data?.customReports || [];

  const AlphabetizedAdminMenuItems = () => {
    interface AdminNavItem {
      title: string;
      path?: AppRoute | string;
      isHref?: true;
      condition?: any;
    }
    const adminMenuItems: Array<AdminNavItem> = [
      { title: "Adjustment Question Templates", path: AppRoute.AdjustmentQuestionTemplates },
      { title: "Custom Reports", path: AppRoute.CustomReports },
      { title: "Committee Members", path: AppRoute.CommitteeMembers },
      { title: "App Role Assignments", path: AppRoute.AppRoles },
      { title: "Document Template Sandbox", path: AppRoute.DocumentTemplateSandbox },
      {
        title: "Document Template Storage",
        path: sharepointDocumentTemplateLibraryUrl,
        condition: sharepointDocumentTemplateLibraryUrl,
        isHref: true
      },
      { title: "Email Templates", path: AppRoute.EmailTemplates },
      { title: "Engagement Types", path: AppRoute.EngagementTypeTemplates },
      { title: "Motions", path: AppRoute.Motions },
      { title: "Additional Paragraphs for Decisions", path: AppRoute.DecisionAdditionalParagraphs },
      { title: "Time Conversion Formulas", path: AppRoute.TimeConversionFormulas },
      { title: "Users", path: AppRoute.Users },
      { title: "Workflow INAs", path: AppRoute.WorkflowINAs },
      { title: "Non-working Days", path: AppRoute.StatHolidays },
      { title: "Recommendations", path: AppRoute.PdCourses },
      { title: "Sign-off Checklist Items", path: AppRoute.SignOffChecklistItems },
      { title: "Timed Event Margins", path: AppRoute.TimedEventMargins },
      { title: "System Estimated Time Rules", path: AppRoute.SystemEstimatedTimeRules },
      { title: "Retention Policies", path: AppRoute.RetentionPolicies },
      { title: "Master Checklist", path: AppRoute.MasterChecklist },
      { title: "Exemptions", path: AppRoute.Exemptions }
    ];

    const visibleMenuItems = [...(userHasPermission(Permissions.AdminTasks) ? adminMenuItems : [])];

    return (
      <>
        {_.orderBy(visibleMenuItems, (item) => item.title).map(
          (ani) =>
            (ani.condition === undefined || ani.condition) && (
              <MenuItem
                key={ani.title}
                className={classes.navButton}
                component={ani.isHref ? Link : RouterLink}
                {...{
                  to: ani.isHref ? undefined : ani.path,
                  href: ani.isHref ? ani.path : undefined,
                  target: ani.isHref ? "_blank" : undefined
                }}>
                {ani.title}
              </MenuItem>
            )
        )}
      </>
    );
  };

  useEffect(() => {
    const deregisterListener = history.listen(() => {
      setRoute(history.location.pathname);
    });

    return () => {
      deregisterListener();
    };
  }, [history]);

  return (
    <AppBar position="sticky" color="default" className={classes.appBar}>
      <Toolbar className={classes.mainBar}>
        <RouterLink to="/" className={classes.homeLink}>
          <Box className={classes.logoBg} title={route !== AppRoute.INAs ? "Return to My INAs" : undefined}>
            <img src="/CPA_SK_Logo.jpg" alt="CPASK logo" />
          </Box>
        </RouterLink>
        <div style={{ textAlign: "right" }}>
          <Typography className={classes.title} variant="h3" noWrap>
            Practice Review System
          </Typography>
          <Typography className={classes.versionIndicator} variant="subtitle2">
            v{version}
          </Typography>
        </div>
        <div className={classes.tabs}>
          <div className={cx(classes.tab, { selected: tabIndex === Tabs.Main })} onClick={() => setTabIndex(Tabs.Main)}>
            Main
          </div>
          {userHasPermission(Permissions.AdminTasks) && (
            <div className={cx(classes.tab, { selected: tabIndex === Tabs.AdminTasks })} onClick={() => setTabIndex(Tabs.AdminTasks)}>
              Administrative Tasks
            </div>
          )}
          {userHasPermission(Permissions.ReportsView) && (
            <Button className={cx(classes.tab, classes.reportMenuButton)} onClick={() => setReportsMenuOpen(true)} ref={reportsMenuButton}>
              Reports
              <Box sx={{ ml: 1 }}>
                <FontAwesomeIcon icon={faCaretDown} />
              </Box>
            </Button>
          )}
        </div>
        <div className={classes.grow} />
        <div className={classes.goToPrField}>
          <GoToPrField />
        </div>
        {!userLoading && (
          <div>
            <IconButton onClick={(e) => setUserMenuAnchorElement(e.currentTarget)} size="large">
              <Tooltip title={user.name}>
                <Avatar className={classes.initialsAvatar}>
                  {user.firstName && user.lastName ? `${user.firstName.slice(0, 1)}${user.lastName.slice(0, 1)}` : user.name.slice(0, 1)}
                </Avatar>
              </Tooltip>
            </IconButton>
            <Menu
              anchorEl={userMenuAnchorElement}
              keepMounted
              open={!!userMenuAnchorElement}
              onClose={() => setUserMenuAnchorElement(null)}>
              <MenuItem onClick={() => msalInstance.logoutRedirect()}>Sign out</MenuItem>
            </Menu>
          </div>
        )}
      </Toolbar>
      {tabIndex === Tabs.Main && (
        <Stack direction="row" spacing={1} alignItems="center" className={classes.tabPanel} flexWrap="nowrap">
          <Button className={classes.navButton} size="large" onClick={() => history.push(AppRoute.INAs)}>
            INAs
          </Button>
          {userHasPermission(Permissions.InaViewOthers) && (
            <Route exact path={AppRoute.INAs}>
              <Autocomplete
                className={classes.viewOtherUsersInasSelector}
                options={usersWhoseInasCanBeViewed}
                getOptionLabel={(user: User | null) => (user === null ? "" : user.name)}
                renderInput={(params) => <TextField {...params} placeholder="View Other User's INAs" margin="none" />}
                value={inasUser}
                onChange={(e, newUser) => {
                  setInasUser(newUser);
                }}
              />
            </Route>
          )}
          <Button className={classes.navButton} size="large" onClick={() => history.push(AppRoute.AdvancedPrSearch)}>
            Advanced PR Search
          </Button>
          <Button className={classes.navButton} size="large" component={RouterLink} to={AppRoute.MyActivityLog}>
            My Activity Log
          </Button>
        </Stack>
      )}
      {tabIndex === Tabs.AdminTasks && (
        <Stack direction="row" spacing={1} alignItems="center" className={classes.tabPanel} flexWrap="nowrap">
          {userHasPermission(Permissions.AdminTasks) && (
            <>
              <Button className={classes.navButton} size="large" onClick={() => history.push(AppRoute.GenerateUpcomingReviewNotices)}>
                Generate Notices
              </Button>
              <Button className={classes.navButton} size="large" onClick={() => history.push(AppRoute.ApproveUpcomingReviewNotices)}>
                Approve Notices
              </Button>
              <Button className={classes.navButton} size="large" onClick={() => history.push(AppRoute.CommitteeMeetings)}>
                Committee Meetings
              </Button>
              <Button className={classes.navButton} size="large" onClick={() => history.push(AppRoute.EnterCommitteeDecisions)}>
                Enter Committee Decisions
              </Button>
              <Button className={classes.navButton} size="large" onClick={() => history.push(AppRoute.ReviewCommitteeDecisions)}>
                Review Final Reports
              </Button>
              <Button className={classes.navButton} size="large" component={RouterLink} to={AppRoute.ImportPrs}>
                Import Reviews
              </Button>
            </>
          )}
          <Button className={classes.navButton} size="large" onClick={() => setAdminMenuOpen(true)} ref={adminMenuButton}>
            System Administration
            <Box sx={{ ml: 1 }}>
              <FontAwesomeIcon icon={faCaretDown} />
            </Box>
          </Button>
        </Stack>
      )}

      <ResetTestDataDialog isOpen={resetDataDialogIsVisible} setIsOpen={setResetDataDialogIsVisible} />

      {adminMenuOpen && (
        <NavSubMenu anchor={adminMenuButton} open={adminMenuOpen} onClose={() => setAdminMenuOpen(false)}>
          <AlphabetizedAdminMenuItems />
          {isDevTestResetEnabled && (
            <>
              <Divider />
              <MenuItem
                className={classes.navButton}
                onClick={() => {
                  setResetDataDialogIsVisible(true);
                }}>
                Reset Test Data
              </MenuItem>
            </>
          )}
        </NavSubMenu>
      )}

      {reportsMenuOpen && (
        <NavSubMenu anchor={reportsMenuButton} open={reportsMenuOpen} onClose={() => setReportsMenuOpen(false)}>
          {_.orderBy(customReports, (r) => r.reportName).map((r) => {
            const normalizedUrl =
              r.url.toLocaleLowerCase().startsWith("https://") || r.url.toLocaleLowerCase().startsWith("http://")
                ? r.url
                : `https://${r.url}`;
            return (
              <MenuItem key={r.id} className={classes.navButton} onClick={() => window.open(normalizedUrl)}>
                {r.reportName}
              </MenuItem>
            );
          })}
        </NavSubMenu>
      )}
    </AppBar>
  );
};

export default SiteAppBar;
