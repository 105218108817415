import React from "react";
import { gql, useMutation } from "@apollo/client";
import { Button, Dialog, DialogActions, DialogContent, Grid } from "@mui/material";
import * as Yup from "yup";
import { AdjustmentQuestionTemplate } from "./models";
import ClosableDialogTitle from "common/ClosableDialogTitle";
import { TextField as FmuiTextField } from "formik-mui";
import { Field as FormikField, Form as FormikForm, Formik } from "formik";
import { AdjustmentQuestionTemplatesQuery } from "./AdjustmentQuestionTemplatesScreen";
import { Validations } from "common/validations/common-yup-validations";
import { LoadingButton } from "@mui/lab";

const AddAdjustmentQuestionTemplateMutation = gql`
  mutation AddAdjustmentQuestionTemplate($template: AdjustmentQuestionTemplateInput!) {
    adjustmentQuestionTemplates {
      add(template: $template) {
        id
        questionText
        sortOrder
      }
    }
  }
`;

const EditAdjustmentQuestionTemplateMutation = gql`
  mutation EditAdjustmentQuestionTemplate($template: AdjustmentQuestionTemplateInput!) {
    adjustmentQuestionTemplates {
      update(template: $template) {
        id
        questionText
        sortOrder
      }
    }
  }
`;

interface EditAdjustmentQuestionTemplateDialogProps {
  handleClose: () => void;
  title: string;
  confirmButtonText: string;
  data?: AdjustmentQuestionTemplate;
}

const EditAdjustmentQuestionTemplateDialog = (props: EditAdjustmentQuestionTemplateDialogProps) => {
  const validationSchema = Yup.object().shape({
    questionText: Validations.requiredText()
  });

  const [addAdjustmentQuestionTemplate, { loading: loadingAdd }] = useMutation<
    { adjustmentQuestionTemplates: { add: AdjustmentQuestionTemplate } },
    { template: AdjustmentQuestionTemplate }
  >(AddAdjustmentQuestionTemplateMutation, {
    refetchQueries: [{ query: AdjustmentQuestionTemplatesQuery }]
  });
  const [editAdjustmentQuestionTemplate, { loading: loadingEdit }] = useMutation<
    { adjustmentQuestionTemplates: { update: AdjustmentQuestionTemplate } },
    { template: AdjustmentQuestionTemplate }
  >(EditAdjustmentQuestionTemplateMutation);

  const mutate = Boolean(props.data?.id) ? editAdjustmentQuestionTemplate : addAdjustmentQuestionTemplate;
  const loading = Boolean(props.data?.id) ? loadingEdit : loadingAdd;

  return (
    <Dialog open={true} onClose={props.handleClose} fullWidth={true} maxWidth="sm">
      <ClosableDialogTitle onClose={props.handleClose}>{props.title}</ClosableDialogTitle>
      <Formik
        initialValues={{ questionText: props.data?.questionText ?? "" }}
        onSubmit={async (values) => {
          let template: AdjustmentQuestionTemplate = {
            id: props.data?.id,
            questionText: values.questionText
          };
          await mutate({
            variables: { template: template }
          });
          props.handleClose();
        }}
        validationSchema={validationSchema}>
        {(formikProps) => (
          <FormikForm>
            <DialogContent>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <FormikField component={FmuiTextField} name="questionText" label="Question Text" multiline fullWidth required />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={props.handleClose}>Cancel</Button>
              <LoadingButton color="primary" variant="contained" loading={loading} onClick={() => formikProps.submitForm()}>
                {props.confirmButtonText}
              </LoadingButton>
            </DialogActions>
          </FormikForm>
        )}
      </Formik>
    </Dialog>
  );
};

export default EditAdjustmentQuestionTemplateDialog;
