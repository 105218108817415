import {
  ApolloProvider as RootApolloProvider,
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  HttpLink,
  NormalizedCacheObject,
  FieldFunctionOptions
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { useNotifications } from "notifications";
import { ReactNode, useRef } from "react";
import { LoadingScreen } from "chrome/LoadingScreen";
import { useAppConfig } from "util/AppConfig";
import { setContext } from "@apollo/client/link/context";
import { AuthError } from "@azure/msal-browser";
import { useAuthentication } from "auth/AuthenticationProvider";
import { PracticeReview } from "../practice-reviews";

export const ApolloProvider = (props: { children: ReactNode }) => {
  const notifications = useNotifications();
  const appConfig = useAppConfig();
  const authentication = useAuthentication();

  function createAuthLink(notifyError: (authError: any) => void) {
    return setContext(async (operation, { headers }) => {
      try {
        const token = await authentication.getAccessToken();
        if (!token) throw new AuthError("Could not get access token");

        return {
          headers: {
            ...headers,
            Authorization: token ? `Bearer ${token}` : ""
          }
        };
      } catch (authErr) {
        notifyError(authErr);
      }
    });
  }

  function createOmitTypenameLink() {
    // Adapted from https://github.com/apollographql/apollo-client/issues/1564#issuecomment-357492659
    return new ApolloLink((operation, forward) => {
      if (operation.variables) {
        operation.variables = JSON.parse(JSON.stringify(operation.variables), (key, value) => {
          return key === "__typename" ? undefined : value;
        });
      }

      return forward(operation);
    });
  }

  const apolloClient = useRef<ApolloClient<NormalizedCacheObject> | null>(null);

  if (appConfig && appConfig.graphQLEndpoint && apolloClient.current === null) {
    apolloClient.current = new ApolloClient({
      link: ApolloLink.from([
        onError(notifications.handleApolloErrorResponse),
        createAuthLink((authError: AuthError) => {
          console.warn(authError.message);
        }),
        createOmitTypenameLink(),
        new HttpLink({ uri: appConfig.graphQLEndpoint })
      ]),
      cache: new InMemoryCache({
        typePolicies: {
          Query: {
            fields: {
              practiceReviewSearch: {
                keyArgs: ["options"],
                merge(existing = [], incoming: PracticeReview[], options: FieldFunctionOptions) {
                  // The "existing" array is immutable here, so we need to copy it with slice before mucking with it
                  const merged = existing ? existing.slice(0) : [];
                  for (let i = 0; i < incoming.length; ++i) {
                    merged[options.args!.skip + i] = incoming[i];
                  }
                  return merged;
                }
              }
            }
          },
          ClienteleProfile: {
            keyFields: ["firmId", "reviewYear"]
          },
          FirmProfile: {
            keyFields: ["firmId", "reviewYear"]
          },
          Reviewer: {
            keyFields: ["practiceReviewId", "userId"]
          },
          SystemEstimatedTimeRule: {
            keyFields: ["minimumPartnersForRule"]
          },
          CommitteeMeetingTabledReview: {
            keyFields: ["committeeMeetingId", "practiceReviewId"]
          }
        },
        possibleTypes: {
          QuestionContainer: ["ChecklistSection", "QuestionHeader"],
          QuestionContainerTemplate: ["ChecklistSectionTemplate", "QuestionHeaderTemplate"],
          IActivity: ["Activity", "TimedEvent", "Ina", "InaAsActivity"]
        }
      })
    });
  }

  if (apolloClient.current !== null) {
    return <RootApolloProvider client={apolloClient.current}>{props.children}</RootApolloProvider>;
  } else {
    return <LoadingScreen mode="init" />;
  }
};
