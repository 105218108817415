import { Button, Dialog, DialogActions, DialogContent, Grid } from "@mui/material";
import ClosableDialogTitle from "common/ClosableDialogTitle";
import { useMutation } from "@apollo/client";
import * as Yup from "yup";
import { Form as FormikForm, Formik } from "formik";
import StackedStaticDataDisplay from "../common/StackedStaticDataDisplay";
import { Ina, InaPriority, SetPriorityOfInasMutation } from "inas";
import _ from "lodash";
import PrioritySelector from "./PrioritySelector";
import PrList from "./PrList";
import { LoadingButton } from "@mui/lab";

interface Props {
  inas: Ina[];
  handleClose: (completedSettingPriority: boolean) => void;
}

interface FormValues {
  priority: InaPriority;
}

const SetPriorityOfInasDialog = (props: Props) => {
  const validationSchema = Yup.object({ priority: Yup.string().required("Select a priority.") });

  const [setPriorityMutate, setPriorityMutation] = useMutation<
    { ina: { setPriority: Ina[] } },
    { inaIds: number[]; priority: InaPriority }
  >(SetPriorityOfInasMutation);

  async function setPriorityOfInas(values: FormValues) {
    const mutationResult = await setPriorityMutate({
      variables: { inaIds: props.inas.map((ina) => ina.id), priority: values.priority }
    });

    if ((mutationResult.data?.ina.setPriority.length ?? 0) > 0) {
      props.handleClose(true);
    }
  }

  function handleClose() {
    props.handleClose(false);
  }

  const uniqueCurrentPriorities = _.uniq(props.inas.map((ina) => ina.priority));
  const initialValues: FormValues = {
    priority: uniqueCurrentPriorities.length > 1 ? InaPriority.Normal : uniqueCurrentPriorities[0]
  };

  return (
    <Dialog open={true} onClose={handleClose} scroll="paper" fullWidth maxWidth="xs">
      <ClosableDialogTitle onClose={handleClose}>{`Set priority of INA${props.inas.length > 1 ? "s" : ""}`}</ClosableDialogTitle>
      <Formik initialValues={initialValues} onSubmit={setPriorityOfInas} validationSchema={validationSchema}>
        {(formikProps) => (
          <FormikForm>
            <DialogContent>
              <Grid container item direction="column" spacing={2}>
                {props.inas.every((ina) => ina.practiceReview) && (
                  <Grid item>
                    <PrList prs={props.inas.map((ina) => ina.practiceReview!)} />
                  </Grid>
                )}
                <Grid item>
                  <StackedStaticDataDisplay
                    label="Current Priority"
                    value={uniqueCurrentPriorities.length > 1 ? "Multiple priorities" : InaPriority[uniqueCurrentPriorities[0]]}
                  />
                </Grid>
                <Grid item>
                  <PrioritySelector
                    priority={formikProps.values.priority}
                    setPriority={(newPriority) => formikProps.setFieldValue("priority", newPriority)}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} disabled={setPriorityMutation.loading}>
                Cancel
              </Button>
              <LoadingButton
                color="primary"
                variant="contained"
                loading={setPriorityMutation.loading}
                onClick={() => formikProps.submitForm()}>
                Set priority
              </LoadingButton>
            </DialogActions>
          </FormikForm>
        )}
      </Formik>
    </Dialog>
  );
};

export default SetPriorityOfInasDialog;
