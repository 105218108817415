import React from "react";
import { IconButton } from "@mui/material";
import { ErrorResponse } from "@apollo/client/link/error";
import { SnackbarMessage, useSnackbar, VariantType } from "notistack";
import { NotificationContext } from "./NotificationContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { useAppInsights } from "util/AppInsightsProvider";
import { useAppConfig } from "util/AppConfig";

export interface Notification {
  message: SnackbarMessage;
  variant: VariantType;
  autoDismiss: number;
}

const NotificationProvider: React.FunctionComponent = (props) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const appInsights = useAppInsights();
  const { showOriginalExceptionMessages, showStackTraces } = useAppConfig();

  function addNotification(notification: Notification) {
    enqueueSnackbar(notification.message, {
      variant: notification.variant,
      autoHideDuration: notification.autoDismiss !== 0 ? notification.autoDismiss * 1000 : undefined,
      persist: notification.autoDismiss === 0,
      preventDuplicate: notification.variant === "error",
      action: (key: string | number) => (
        <IconButton size="small" aria-label="close" color="inherit" onClick={() => closeSnackbar(key)}>
          <FontAwesomeIcon icon={faTimes} />
        </IconButton>
      ),
      style: notification.variant === "error" ? { whiteSpace: "pre-line" } : undefined
    });
  }

  function error(message: any) {
    addNotification({
      message: typeof message === "object" ? message.toString() : message,
      variant: "error",
      autoDismiss: 0
    });
  }

  function serverError(serverError: Error) {
    appInsights.trackException({ exception: serverError });
    if (showOriginalExceptionMessages) {
      error(serverError.toString());
    } else {
      error("A server error occurred.");
    }
  }

  function handleApolloErrorResponse(errorResponse: ErrorResponse) {
    if (errorResponse.graphQLErrors)
      errorResponse.graphQLErrors.forEach(({ message, locations, path }) =>
        console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
      );

    if (errorResponse.networkError) console.log(`[Network error]: ${errorResponse.networkError}`);

    let errorMessage = "Server error";

    if (errorResponse.graphQLErrors && errorResponse.graphQLErrors.length > 0) {
      errorMessage = errorResponse.graphQLErrors.map((e) => e.message).join(";");
    } else if (errorResponse.networkError) {
      appInsights.trackException({ exception: errorResponse.networkError });
      const statusCode = (errorResponse.networkError as any).statusCode ? ` (${(errorResponse.networkError as any).statusCode})` : "";
      errorMessage = `${errorResponse.networkError.message}${statusCode}`;
    }

    errorMessage = showOriginalExceptionMessages
      ? showStackTraces
        ? // put an extra line break before the first item on the call stack, for readability
          errorMessage.replace(/(\r?\n)   at /, "$1$1   at ")
        : // wipe out the call stack completely
          errorMessage
            .replace(/\r?\n   at[\s\S]*$/, "")
            // take exception class names off the front of the error message lines, because they're just noise to the user
            .replace(/^( ?---> )?[A-Za-z0-9\.]+: /gm, "$1")
      : "An error occurred. Please contact the administrators.";

    error(errorMessage);
    if (errorResponse.response) errorResponse.response.errors = undefined;
  }

  function success(message: string, timeOutInSeconds?: number) {
    addNotification({
      message,
      variant: "success",
      autoDismiss: timeOutInSeconds !== undefined ? timeOutInSeconds : 5
    });
  }

  function warning(message: string) {
    addNotification({
      message,
      variant: "warning",
      autoDismiss: 0
    });
  }

  function info(message: string, timeOutInSeconds?: number) {
    addNotification({
      message,
      variant: "info",
      autoDismiss: timeOutInSeconds !== undefined ? timeOutInSeconds : 5
    });
  }

  function removeAll() {
    closeSnackbar();
  }

  return (
    <NotificationContext.Provider
      value={{
        addNotification: (notification: Notification) => addNotification(notification),
        success: (message: string, timeOutInSeconds?: number) => success(message, timeOutInSeconds),
        warning: (message: string) => warning(message),
        error: (message: string) => error(message),
        serverError: (serverErrorObject: Error) => serverError(serverErrorObject),
        info: (message: string, timeOutInSeconds?: number) => info(message, timeOutInSeconds),
        removeAll: () => removeAll(),
        handleApolloErrorResponse: (errorResponse: ErrorResponse) => handleApolloErrorResponse(errorResponse)
      }}>
      {props.children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
