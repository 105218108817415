import { PublicClientApplication, Configuration } from "@azure/msal-browser";
import { MsalProvider as RootMsalProvider } from "@azure/msal-react";
import { useAppConfig } from "util/AppConfig";
import { LoadingScreen } from "chrome/LoadingScreen";

export const MsalProvider: React.FunctionComponent = (props) => {
  const appConfig = useAppConfig();

  if (!appConfig?.azureAdAuthority || !appConfig?.azureAdClientId) {
    return <LoadingScreen mode="init" />;
  }

  const msalConfig: Configuration = {
    auth: {
      clientId: appConfig.azureAdClientId,
      authority: appConfig.azureAdAuthority,
      redirectUri: `${window.location.origin}/authCompleted`,
      navigateToLoginRequestUrl: true
    }
  };
  const msalInstance = new PublicClientApplication(msalConfig);

  return <RootMsalProvider instance={msalInstance}>{props.children}</RootMsalProvider>;
};
